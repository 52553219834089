import { jsx as _jsx } from "react/jsx-runtime";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";
import { useCheckout } from "../hooks/api";
import { ErrorPage, Errors } from ".";
export const CheckoutError = () => {
    const { isError: isFetchError, error: fetchError } = useCheckout();
    const error = useRouteError();
    if (error?.message === Errors.SessionMissing) {
        return _jsx(ErrorPage, { error: error });
    }
    // BE Response: status not ok
    if (isFetchError) {
        // eslint-disable-next-line no-console
        console.error(fetchError);
        return _jsx(ErrorPage, { error: fetchError });
    }
    // Router Error
    if (isRouteErrorResponse(error)) {
        return _jsx(ErrorPage, { error: error });
    }
    // eslint-disable-next-line no-console
    console.error(error);
    // Undetermined Error
    return _jsx(ErrorPage, { error: error });
};
