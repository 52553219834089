import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
const StyledHeader = styled("header") `
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding-top: ${({ theme }) => theme.space.m}px;
  margin-bottom: ${({ theme }) => theme.space.xl}px;

  .offer-logo {
    display: block;
    max-width: 150px;
    max-height: 50px;
  }
`;
const Headline = styled("span") `
  font-family: ${({ theme }) => theme.fonts.text};
  font-size: 24px;
  line-height: normal;
  font-weight: 700;
`;
const SubHeadline = ({ text }) => {
    return (_jsxs(_Fragment, { children: ["\u00A0- ", _jsx("span", { children: text })] }));
};
const AccessoriesName = styled("span") `
  flex: 1 0 100%;
  font-weight: 600;
`;
const Header = ({ accessories, headline, subHeadline, provider = "", }) => (_jsxs(StyledHeader, { className: "logoheadline", children: [_jsxs(Headline, { "data-qa": headline.replaceAll(" ", "-").toLowerCase(), children: [headline, subHeadline && _jsx(SubHeadline, { text: subHeadline })] }), _jsx("img", { className: "offer-logo", src: `https://content.ekontor24.net/mediamarkt/img/internet/${provider}-logo.svg`, alt: provider }), accessories.length === 1 && (_jsx(AccessoriesName, { children: accessories[0].name })), accessories.length > 1 && (_jsx(AccessoriesName, { children: "mit Pr\u00E4mie deiner Wahl" }))] }));
export default Header;
