import React from "react";
import styled from "styled-components";

import { Box } from "@mm/ui";

const StyledInfoBox = styled("div")`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  color: white;
  padding: 40px;
  background-color: ${({ theme }) => theme.colors.brand.primary};
  border-radius: ${({ theme }) => theme.borders.radius};
  overflow: hidden;

  ::before {
    content: "";
    display: block;
    position: absolute;
    top: -80px;
    left: -14%;
    width: 80%;
    height: 160%;
    border-top-right-radius: 180%;
    border-bottom-right-radius: 180%;
    background-color: ${({ theme }) => theme.colors.brand.primary};
    filter: brightness(0.9);
    z-index: 1;
  }

  @media (min-width: ${({ theme }) => theme.breakpointsMap.md.min}px) {
    flex-direction: row;
    gap: 12px;
  }
`;

const StyledSpacer = styled("span")`
  position: relative;
  display: none;
  z-index: 3;

  ::after {
    content: "";
    display: block;
    position: absolute;
    top: calc(50% - 2px);
    height: 2px;
    width: 100%;
    background-color: white;
  }

  @media (min-width: ${({ theme }) => theme.breakpointsMap.md.min}px) {
    display: block;
    height: 100px;
    width: 10vw;
    max-width: 200px;
  }

  @media (min-width: ${({ theme }) => theme.breakpointsMap.md.min}px) {
    margin: 0 -50px;
  }
`;

const StyledBox = styled(Box)`
  display: flex;
  align-items: center;
  z-index: 3;

  p {
    text-align: left;
    padding: 20px;
    margin: 0;
    max-width: 250px;
  }

  @media (min-width: ${({ theme }) => theme.breakpointsMap.md.min}px) {
    flex-direction: column;

    p {
      text-align: center;
      padding: 20px 20px 0 20px;
    }
  }
`;

const StyledIcon = styled("div")`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: white;

  svg {
    width: auto;
    height: 56px;
    fill: ${({ theme }) => theme.colors.brand.primary};
  }
`;

const EnergyInfoBox = () => {
  return (
    <StyledInfoBox>
      <StyledBox>
        <StyledIcon>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 35 56"
            xmlSpace="preserve"
          >
            <path d="M32.4 17.5H19.8l4.7-14.2c.4-1.7-.9-3.3-2.6-3.3H6.1C4.8 0 3.7 1 3.5 2.3L0 28.5c-.2 1.6 1 3 2.6 3h13l-5 21.3c-.4 1.7.9 3.2 2.5 3.2.9 0 1.8-.5 2.3-1.3l19.2-33.2c1.1-1.8-.2-4-2.2-4zM15.3 47.8 19 32.3l1-4.3H3.6L6.9 3.5h13.8l-4.2 12.9-1.6 4.6h15.9L15.3 47.8z" />
          </svg>
        </StyledIcon>
        <p>Stromtarif berechnen und auswählen.</p>
      </StyledBox>
      <StyledSpacer />
      <StyledBox>
        <StyledIcon>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 56"
            xmlSpace="preserve"
          >
            <g id="_007-writing" transform="translate(26.97 22)">
              <path
                id="Path_00000084488832796164491260000003774403048130370962_"
                className="st0"
                d="M18.1 0c0 .4-.2.8-.4 1.1-.3.3-.7.5-1.1.5-.8 0-1.5-.7-1.5-1.5v-13.8c0-2.9-2.3-5.2-5.2-5.2h-27.6c-2.9 0-5.2 2.3-5.2 5.2v39.5c0 2.9 2.3 5.2 5.2 5.2h7.5c.8 0 1.5.7 1.5 1.5 0 .4-.2.8-.5 1.1s-.7.4-1.1.4h-7.5c-4.6 0-8.2-3.7-8.3-8.3v-39.5c0-4.6 3.7-8.2 8.3-8.3H9.8c4.6 0 8.3 3.7 8.3 8.3V0z"
              />
              <path
                id="Shape_00000107570824918866312910000005009306264137676455_"
                className="st0"
                d="M22.3 10.3c-1.1-1.1-2.7-1.8-4.3-1.8-1.6 0-3.1.6-4.3 1.8L1.5 22.6c-.2.2-.3.4-.4.7L-1.6 32c-.2.5 0 1.1.4 1.5s1 .6 1.5.4l9.1-2.5c.3-.1.5-.2.7-.4l12.3-12.3c1.1-1.1 1.8-2.7 1.8-4.3-.1-1.5-.8-3-1.9-4.1zM8.1 28.6l-6 1.6L4 24.3l8.8-8.8 4.2 4.2-8.9 8.9zm12-12-.9.9-4.2-4.1.9-.9c1.2-1.1 3-1.1 4.2 0 .6.6.9 1.3.9 2.1 0 .7-.3 1.5-.9 2z"
              />
              <path
                id="Path"
                className="st0"
                d="M9.1 2c0 .8-.7 1.5-1.5 1.5h-23.1c-.9 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5H7.6c.9-.1 1.5.6 1.5 1.5z"
              />
              <path
                id="Path_00000023265063819689075810000010168655178319183256_"
                className="st0"
                d="M-1.5 12.5h-14c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5h14c.8 0 1.5.7 1.5 1.5s-.6 1.5-1.5 1.5z"
              />
              <path
                id="Path_00000066485920572314774990000002497679090052615296_"
                className="st0"
                d="M9.1-7c0 .8-.7 1.5-1.5 1.5h-23.1c-.9 0-1.5-.7-1.5-1.5 0-.4.2-.8.5-1.1s.7-.4 1.1-.4h23c.4 0 .8.2 1.1.4s.4.7.4 1.1z"
              />
            </g>
          </svg>
        </StyledIcon>
        <p>
          Einfach wechseln, Kündigung beim Altanbieter übernimmt der neue
          Netzanbieter.
        </p>
      </StyledBox>
      <StyledSpacer />
      <StyledBox>
        <StyledIcon>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 60 56"
            xmlSpace="preserve"
          >
            <path
              id="Fill-1"
              className="st0"
              d="m19.4 29.3 1.2.3c.6.2.9.2 1.5.2h17.8c2.3 0 4.6-1.4 6-3.6l4.8-8.3c-1-.1-1.9-.3-2.7-.6l-4.4 7.6c-.6 1.3-2 2.1-3.4 2.1H22.5c-1.5 0-2.9-.8-3.6-2L10.2 9.8h31.6c-.1-.6-.3-1.2-.3-1.9 0-.2 0-.6.1-.9H8.2L6.3 3.3c-.7-1.2-2.1-1.9-3.8-1.9H0V4h3.4l12.5 22.2c.1.1.2.3.3.4.1.1.2.2.2.3l.7.7-5.8 11.3c-.1.3-.1.5 0 .8l.6 1c.1.1.2.4.8.4h35.4s.1 0 .1-.1v-2.5H15.1l4.3-9.2zM44.1 47c-2.4 0-4.3 1.9-4.3 4.3 0 2.3 2 4.3 4.3 4.3s4.3-2 4.3-4.3c-.2-2.4-2.1-4.3-4.3-4.3zm-25.3 1.2c-.8-.8-1.9-1.2-2.9-1.2h-.2c-2.3 0-4.1 1.9-4.1 4.3 0 2.3 1.9 4.1 4.3 4.1 2.3 0 4.1-1.9 4.1-4.3-.2-1.3-.6-2.3-1.2-2.9z"
            />
            <path
              id="Fill-9"
              className="st0"
              d="m55.5 6.9-5.6 5.6c-.4.2-.6.2-.8 0l-2-2-.6-.6c-.2-.2-.2-.4 0-.6l.6-.6c.2-.2.4-.2.6 0l1.6 1.6 3.4-3.4 1.8-1.8c.2-.2.4-.2.6 0l.6.6c.2.2.2.4.2.6l-.4.6zM51.2 0c-4.4 0-8 3.6-8 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8z"
            />
          </svg>
        </StyledIcon>
        <p>Bestellung online abschließen.</p>
      </StyledBox>
    </StyledInfoBox>
  );
};

export default EnergyInfoBox;
