import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Text, Surface } from "@mm/ui";

const StyledAnchor = styled("a")`
  padding: ${({ theme }) => `${theme.space.xxs}px ${theme.space.s}px`};
  margin-right: ${({ theme }) => theme.space.l}px;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.shade[2]};
  border-radius: 4px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const Anchors = () => {
  const [anchors, setAnchors] = useState(null);

  const anchorHeadlines = document.querySelectorAll(".anchor-headline");

  useEffect(() => {
    setAnchors(Array.from(anchorHeadlines));
  }, [anchorHeadlines.length]);

  if (anchors === null || anchors?.length < 1) return null;

  return (
    <Surface variant="narrow" mt={[4, 4, 4, 6, 6]}>
      {anchors.map((anchor) => (
        <StyledAnchor key={anchor.id} href={`#${anchor.id}`}>
          <Text highlighted>{anchor.id}</Text>
        </StyledAnchor>
      ))}
    </Surface>
  );
};

export default Anchors;
