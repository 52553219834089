import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { noop } from "lodash";

import { Image, Text, Box } from "@mm/ui";

const StyledIconTile = styled.div(
  (props) => css`
    display: flex;
    width: 100%;
    cursor: ${props.href ? "pointer" : "initial"};
    align-items: center;
    justify-content: center;
    padding: 10px 0 16px 0;
    border-radius: 4px;
    border: 1px solid ${props.theme.colors.grey[2]};
    text-decoration: none;

    transition-duration: 0.15s;
    transition-timing-function: ease-in-out;
    transition-property: border-color, box-shadow, transform;

    &:active,
    &:focus,
    &:hover {
      border-color: rgba(0, 0, 0, 0);
      box-shadow: rgb(0 0 0 / 7%) 0px 8px 10px 1px,
        rgb(0 0 0 / 6%) 0px 3px 14px 2px, rgb(0 0 0 / 10%) 0px 5px 5px -3px;
    }
  `
);

const StyledImg = styled(Image)`
  margin-bottom: 0.5rem;
  max-height: 3rem;
`;
const StyledText = styled(Text)`
  text-decoration: ${(props) => (props.isWithLink ? "underline" : "")};
`;

const tileClick = (onClick, url) => {
  if (onClick && url) onClick(url);
};

const IconTile = ({ imagePath, title, link, text, className, onClick }) => (
  <StyledIconTile
    as={link && "a"}
    href={link}
    className={className}
    onClick={() => tileClick(onClick, link)}
  >
    <Box textAlign="center">
      <StyledImg src={imagePath} alt={title} />
      <StyledText highlighted display="block" color="black" isWithLink={!!link}>
        {title}
      </StyledText>
      {text && (
        <Text py="2" px="5" display="block" color="black">
          {text}
        </Text>
      )}
    </Box>
  </StyledIconTile>
);

IconTile.propTypes = {
  onClick: PropTypes.func,
  imagePath: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  link: PropTypes.string,
  className: PropTypes.string,
  text: PropTypes.string,
};

IconTile.defaultProps = {
  onClick: noop,
  className: "",
  text: null,
  link: null,
};

export default IconTile;
