import _ from "lodash/fp";

export const MANUFACTURERS = "manufacturers";
export const OPERATING_SYSTEMS = "operatingSystems";
export const COLORS = "colors";
export const STORAGE = "storage";
export const PRICE_RANGE = "priceRange";
export const DISPLAY_RANGE = "displayRange";
export const ACCESSORIES = "accessories";
export const CONTRACT_TERMS = "contractTerms";

/**
 *  returns true if
 *  the the filter item with filterName in the list of list[groupName] has selected flag set true
 */
export const isSelected = (list, groupName, filterName) => {
  if (!list || !list[groupName]) return false;
  return !!list[groupName].find(
    (filter) => filter.name === filterName && filter.selected === true
  );
};

// returns filter if found
export const getFilterByName = (items, name) =>
  items.find((item) => item.name === name);

// increase the count of a color by 1
export const increaseCount = (colors, name) => {
  const index = colors.findIndex((item) => item.name === name);
  if (index !== -1) {
    // eslint-disable-next-line no-param-reassign
    colors[index].count += 1;
  }
  return colors;
};

/**
 * filters the filterGroup and returns the list only with elements that are selected
 */
export const getActiveFilters = (filterGroup) =>
  _.cond([
    [_.isArray, _.filter((filter) => filter.selected)],
    [_.T, _.identity],
  ])(filterGroup);

/**
 * removes the item with name in group from filters and returns list with updated elements
 *
 * @param filters {Object<string,array|string>} - the current value of `activeFilters`
 * @param group {string} - the group, i.e. key path, to update
 * @param name {string} - the value in `group` that will be removed
 * @returns {Object<string,array>}
 */
export const removeFromFilters = (filters, group, name) =>
  _.update(
    group,
    _.cond([
      [_.isArray, _.filter((filter) => filter.name !== name)],
      [_.T, _.constant(undefined)],
    ])
  )(filters);

// increases the min/max values based on the value
export const updateRangeFilter = (filter, value) => ({
  ...filter,
  min: Math.min(value, filter.min),
  max: Math.max(value, filter.max),
  minVal: Math.min(value, filter.min),
  maxVal: Math.max(value, filter.max),
});

// increases the min/max values based on the value for prices
export const updatePriceRangeFilter = (filter, value) => ({
  ...filter,
  min: Math.min(Math.floor(value), filter.min),
  max: Math.max(Math.ceil(value), filter.max),
  minVal: Math.min(Math.floor(value), filter.min),
  maxVal: Math.max(Math.ceil(value), filter.max),
});

// extends filter with minVal/maxVal from previousFilter
export const mergeRangeValues = (filter, previousFilter) => ({
  ...filter,
  minVal: (previousFilter && previousFilter.minVal) || filter.minVal,
  maxVal: (previousFilter && previousFilter.maxVal) || filter.maxVal,
});

/** checks if filter group with name is already in and list and if not adds a new item to it
 * increases the count of the available products afterward,
 * but only, if the product is within the currently filtered product list
 */
export const buildFilterArrayGroup = (
  list,
  group,
  name,
  value,
  oldFilterOptions
) => {
  const result = !getFilterByName(list, name)
    ? [
        ...list,
        {
          name,
          value,
          count: 0,
          selected: isSelected(oldFilterOptions, group, name),
        },
      ]
    : list;

  return increaseCount(result, name);
};

/**
 * Every offer group should deliver an offer with following
 * priority: 1. promotion, 2. default 3. rest
 */
export const getOfferFromGroupWithPriority = (group) => {
  // 1. promotion
  let offer = _.find({ promotion: true }, group.offers);
  if (offer) return offer;
  // 2. default
  offer = _.find({ default: true }, group.offers);
  if (offer) return offer;
  // 3. rest (edge case: no promotion, no default. Let's take the first one)
  return group.offers[0];
};
