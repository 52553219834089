export var BuildingType;
(function (BuildingType) {
    BuildingType["Detached"] = "detached";
    BuildingType["Apartment"] = "apartment";
    BuildingType["FrontApartment"] = "frontApartment";
    BuildingType["BackApartment"] = "backApartment";
})(BuildingType || (BuildingType = {}));
export var BuildingFloor;
(function (BuildingFloor) {
    BuildingFloor["TopFloor"] = "topFloor";
    BuildingFloor["UpperFloor"] = "upperFloor";
    BuildingFloor["GroundFloor"] = "groundFloor";
    BuildingFloor["Basement"] = "basement";
})(BuildingFloor || (BuildingFloor = {}));
export var BuildingAlignment;
(function (BuildingAlignment) {
    BuildingAlignment["Left"] = "left";
    BuildingAlignment["Right"] = "right";
    BuildingAlignment["MidLeft"] = "midLeft";
    BuildingAlignment["MidRight"] = "midRight";
})(BuildingAlignment || (BuildingAlignment = {}));
export const getBuildingTypeLabel = (buildingType) => {
    switch (buildingType) {
        case BuildingType.Detached: {
            return "Einfamilienhaus";
        }
        case BuildingType.Apartment: {
            return "Mehrfamilienhaus";
        }
        case BuildingType.FrontApartment: {
            return "Mehrfamilienhaus (Vorderhaus)";
        }
        case BuildingType.BackApartment: {
            return "Mehrfamilienhaus (Hinterhaus)";
        }
        default: {
            throw new Error(`BuildingType ${buildingType} not defined`);
        }
    }
};
export const getBuildingFloorLabel = (buildingFloor) => {
    switch (buildingFloor) {
        case BuildingFloor.TopFloor: {
            return "Dachgeschoss";
        }
        case BuildingFloor.UpperFloor: {
            return "Obergeschoss";
        }
        case BuildingFloor.GroundFloor: {
            return "Erdgeschoss";
        }
        case BuildingFloor.Basement: {
            return "Keller";
        }
        default: {
            throw new Error(`BuildingFloor ${buildingFloor} not defined`);
        }
    }
};
export const getBuildingAlignmentLabel = (buildingAlignment) => {
    switch (buildingAlignment) {
        case BuildingAlignment.Left: {
            return "Links";
        }
        case BuildingAlignment.Right: {
            return "Rechts";
        }
        case BuildingAlignment.MidLeft: {
            return "Mitte Links";
        }
        case BuildingAlignment.MidRight: {
            return "Mitte Rechts";
        }
        default: {
            throw new Error(`BuildingAlignment ${buildingAlignment} not defined`);
        }
    }
};
