import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import styled from "styled-components";
import { z } from "zod";
import { sectionComponents } from "../../sections";
import { useCheckoutState } from "@/pages/CheckoutPage/context/CheckoutContext";
const SectionGroupHeadline = styled.h2 `
  margin: 0;
`;
const renderSectionComponent = ({ component, defaultValues, hide, schema, options }, checkoutOptions) => {
    const { state } = useCheckoutState();
    if (hide) {
        if (typeof hide === "function") {
            if (hide(checkoutOptions, state))
                return null;
        }
        else {
            return null;
        }
    }
    const Component = component in sectionComponents ? sectionComponents[component] : undefined;
    if (Component) {
        return (_jsx(Component, { defaultValues: defaultValues, options: options, schema: schema ?? z.object({}) }, component));
    }
    throw new Error(`Section ${component} not defined`);
};
export const renderSections = (sections, options) => sections.map((item) => {
    if ("component" in item) {
        return renderSectionComponent(item, options);
    }
    return (_jsxs(Fragment, { children: [item?.title && (_jsx(SectionGroupHeadline, { children: item.title })), renderSections(item.sections, options)] }, item.title));
});
