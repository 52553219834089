import _ from "lodash/fp";

import PropTypes from "prop-types";
import React, { createContext, useContext, useEffect, useReducer } from "react";

import {
  extendURLParams,
  getPdpRequestPayload,
  tariffHasBookableHardware,
} from "utils/fetchData";

import { extendTariffDetails } from "utils/tariffUtils";

import * as hardwareUtils from "./hardware";
import { INITIAL_HARDWARE_FILTERS } from "./hardware";
import {
  getActiveFilters,
  getOfferFromGroupWithPriority,
  removeFromFilters,
} from "./products";
import * as tariffUtils from "./tariff";
import { INITIAL_TARIFF_FILTERS } from "./tariff";

/* Reducer Actions */

/**
 * Reset the currently active filters, i.e. the top row of tags
 * @constant
 * @type {string}
 */
const RESET_FILTER = "RESET_FILTER";

/**
 * Update filters by pushing a modified filter group
 *
 * @constant
 * @type {string}
 */
const UPDATE_FILTER_GROUP = "UPDATE_FILTER_GROUP";

/**
 * Update open filters accordions
 *
 * @constant
 * @type {string}
 */
const UPDATE_ACTIVE_KEYS = "UPDATE_ACTIVE_KEYS";

/**
 * Remove one element from `activeFilters`
 * @constant
 * @type {string}
 */
const REMOVE_FILTER = "REMOVE_FILTER";

/**
 * Sync the state of react-query into the filter
 * @private
 * @constant
 * @type {string}
 */
export const SYNC = "SYNC";

/**
 * Set params (like sortByName etc...)
 * @constant
 * @type {string}
 */
export const SET_PARAM = "SET_PARAM";

/**
 * The public API for components consuming the reducer
 */
export const ACTIONS = {
  RESET_FILTER,
  REMOVE_FILTER,
  UPDATE_FILTER_GROUP,
  UPDATE_ACTIVE_KEYS,
};

/**
 * The big util to set all things filter
 *
 * * updates `facets` given the current filter criteria
 * * filters `items` to be the subset of `data` matching the current filter criteria
 *
 * @param stateUpdate - the new state to mangle, must be a complete state object
 * @returns {object<*>} - a new state
 * FIXME add test
 */
const filterizer = (stateUpdate) => {
  const variantUtils = _.cond([
    [_.eq("tariff"), _.constant(tariffUtils)],
    [_.eq("smartphone"), _.constant(hardwareUtils)],
    [_.T, _.constant(new Error("Filter used without variant"))],
  ])(stateUpdate.variant);

  const keys = _.keys(stateUpdate.filters);
  const { isListingPage, isProductDetailInterface, data, variant } =
    stateUpdate;

  // take every offer in every offer group
  // For listing pages, only the promotion->default->first
  // (on that specific order) offers will be listed.
  let unfilteredItems = isListingPage
    ? data?.map(getOfferFromGroupWithPriority)
    : _.flatMap((group) => group.offers, data);

  if (variant === "smartphone") {
    if (isProductDetailInterface) {
      unfilteredItems =
        unfilteredItems &&
        hardwareUtils.splitProductsByVariants(unfilteredItems);
    }
  } else {
    // for tariffs, we need to parse the data and create additional properties
    // like data volume/data capacity and speed/bandwidth
    unfilteredItems =
      unfilteredItems &&
      tariffUtils.sanitizeProducts(unfilteredItems).map(extendTariffDetails);
  }

  const filteredItems = _.map((product) => {
    const matches = _.filter((group) =>
      _.cond([
        [
          _.isArray,
          () =>
            _.find((filterItem) =>
              variantUtils.filterTests[group](product, filterItem)
            )(stateUpdate.filters),
        ],
        [
          _.T,
          (currentGroup) =>
            variantUtils.filterTests[group](product, currentGroup),
        ],
      ])(_.get(group)(stateUpdate.filters))
    )(keys);
    return {
      ...product,
      filtered: _.negate(_.eq(_.size(matches)))(_.size(keys)),
    };
  })(unfilteredItems);

  const updatedFacets = variantUtils.getFilterOptions(
    filteredItems,
    stateUpdate.filters
  );

  return {
    ...stateUpdate,
    items: _.filter({ filtered: false })(filteredItems),
    unfilteredItems,
    facets: updatedFacets,
  };
};

/** *
 * Updates the the tariffs for the filter provider
 * @param dispatch - Dispatch function from the useFilterProvider()
 * @param item - ebootisId and storage from the active hardware item
 */
export const updateTariffs = (dispatch, item) => {
  const { offerGroupUrl, color } = getPdpRequestPayload();
  const { environment, restUrl, tenant } = extendURLParams();

  fetch(
    `${restUrl}/v1/offergroup/${offerGroupUrl}/tariff?color=${color}&storage=${item.storage}&tenant=${tenant}&environment=${environment}`
  )
    .then((response) => response.json())
    .then((data) => {
      dispatch({
        type: SYNC,
        payload: {
          data: [{ offers: data.payload }],
        },
      });
    });
};

export const getInitialFilter = (isTariff, isListingPage) => {
  // get current URL and check if page is specific listing page
  const currentURL = window.location.pathname.split("/");

  // check the sessionStorage for existing filters for this url
  const watchForActiveFilters = sessionStorage.getItem(window.location);

  // if there are existing filters in the sessionStorage then return these filters
  if (watchForActiveFilters) {
    return JSON.parse(watchForActiveFilters);
  }

  // this means the page ist not a specific subpage
  if (currentURL.length === 3) {
    return {};
  }
  let subPageName = currentURL[currentURL.length - 2];

  const INITIAL_FILTER = isTariff
    ? INITIAL_TARIFF_FILTERS
    : INITIAL_HARDWARE_FILTERS;

  // if we're on a HW sub page we have to consider "other" manufacturers like Huawei, HTC..
  if (
    !isTariff &&
    subPageName !== "" &&
    !INITIAL_FILTER[subPageName] &&
    isListingPage
  ) {
    subPageName = "others";
  }

  return INITIAL_FILTER[subPageName] || {};
};

function reducer(state, action) {
  switch (action.type) {
    case UPDATE_FILTER_GROUP: {
      const activeFilter = filterizer({
        ...state,
        filters: _.pickBy((value) => !!_.size(value))({
          ...state.filters,
          [action.payload.name]: getActiveFilters(action.payload.filterGroup),
        }),
      });
      // store the filter-settings in the sessionStorage
      sessionStorage.setItem(
        window.location,
        JSON.stringify(activeFilter.filters)
      );
      return activeFilter;
    }
    case UPDATE_ACTIVE_KEYS: {
      const keys = {
        ...state,
        activeKeys: action.payload,
      };

      return keys;
    }
    case REMOVE_FILTER:
      // remove the filter-settings in the sessionStorage
      sessionStorage.removeItem(window.location);
      return filterizer({
        ...state,
        filters: _.pickBy((value) => !!_.size(value))(
          removeFromFilters(
            state.filters,
            action.payload.group,
            action.payload.name
          )
        ),
      });
    case RESET_FILTER:
      // remove the filter-settings in the sessionStorage
      sessionStorage.removeItem(window.location);
      return filterizer({ ...state, filters: {} });
    case SET_PARAM:
      return { ...state, ...action.payload };
    case SYNC: {
      const { data } = action.payload;
      return filterizer({
        ...state,
        data,
      });
    }
    default:
      throw new Error(`Reducer action not set: ${action.type}`);
  }
}

const ctx = createContext(null);

export const FilterProvider = ({
  queryParams,
  variant,
  children,
  isListingPage,
  isProductDetailInterface,
  data: filterData,
  shownFacets,
}) => {
  const isTariff = variant === "tariff";

  const [state, dispatch] = useReducer(
    reducer,
    {
      data: null,
      variant,
      activeKeys: ["1"],
      collapseFilter: true,
      // set initial filters based on URL
      filters: getInitialFilter(isTariff, isListingPage),
      sortByName: "recommendations",
      sortDirection: "initial",
      displayType: "tile",
      facets: {},
      isListingPage,
      isProductDetailInterface,
      productType: queryParams?.type,
      shownFacets,
      showAllFacets: undefined,
    },
    (initialState) => filterizer(initialState)
  );

  useEffect(() => {
    if (filterData) {
      dispatch({
        type: SYNC,
        payload: {
          // for PDP we fake an offer group, the key is only available on the listing page
          // we should probably adjust that in the future on the BE side
          data: isProductDetailInterface
            ? [{ offers: [...filterData] }]
            : filterData,
        },
      });
    }
  }, [filterData]);

  // simOnly tariffs do not have any variants and the backend calls will fail
  // so do not render the filter then
  const isSimOnlyPDP =
    !isListingPage &&
    variant === "smartphone" &&
    !tariffHasBookableHardware(queryParams);

  return (
    <ctx.Provider value={{ state, dispatch }}>
      {!isSimOnlyPDP && children}
    </ctx.Provider>
  );
};

FilterProvider.propTypes = {
  queryParams: PropTypes.shape({}),
  variant: PropTypes.oneOf(["tariff", "smartphone"]).isRequired,
  children: PropTypes.node.isRequired,
  isProductDetailInterface: PropTypes.bool,
  isListingPage: PropTypes.bool,
  shownFacets: PropTypes.shape({}).isRequired,
};

FilterProvider.defaultProps = {
  queryParams: null,
  isProductDetailInterface: false,
  isListingPage: false,
};

export const useFilterProvider = () => useContext(ctx);
