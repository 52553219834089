import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  compose,
  color as themeColor,
  typography,
  layout,
  variant as themeVariant,
  space,
} from "styled-system";

const StyledHeadline = styled("span")(
  { marginBlockStart: 0 },
  { marginBottom: 12 },
  ({ highlighted }) => ({
    fontWeight: highlighted ? "600" : "normal",
  }),
  compose(themeColor, typography, layout, space),
  themeVariant({
    scale: "headlines",
    // https://github.com/styled-system/styled-system/issues/1145#issuecomment-587389487
    variants: {
      h1: {},
      h2: {},
      h3: {},
      h4: {},
      h5: {},
      teaserSmall: {},
      teaserBig: {},
    },
  })
);

export const Headline = ({
  variant,
  color,
  highlighted,
  children,
  className,
  ...props
}) => (
  <StyledHeadline
    className={className}
    as={variant.length === 2 ? variant : "span"}
    variant={variant}
    color={color}
    highlighted={highlighted}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    {children}
  </StyledHeadline>
);

Headline.defaultProps = {
  color: "black",
  highlighted: false,
  className: null,
};

Headline.propTypes = {
  variant: PropTypes.oneOf([
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "teaserSmall",
    "teaserBig",
  ]).isRequired,
  color: PropTypes.oneOf([
    "brand.primary",
    "black",
    "shade.4",
    "shade.3",
    "white",
  ]),
  highlighted: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Headline;
