import React from "react";
import { SectionHeadline, Surface } from "@mm/ui";

const HeadlineSection = ({ data }) => {
  return (
    <Surface variant="narrow" shape="roundedSmall">
      <SectionHeadline
        color={data.color}
        text={data.content}
        font={data.font}
        size={data.size}
        type={data.type}
        weight={data.weight}
      />
    </Surface>
  );
};

export default HeadlineSection;
