import React, { useMemo } from "react";
import PropTypes from "prop-types";

import { extendedVariantPropTypes } from "sharedPropTypes";
import { findVariantById } from "utils/variantUtils";
import { getActiveAccessoryVariantStock } from "../../utils/variantUtils";

export const WithVariantColors = (Component) => {
  const HOC = (props) => {
    const {
      extendedVariants,
      activeVariantId,
      accessories,
      activeAccessoryVariantIds,
    } = props;

    const colors = useMemo(() => {
      const {
        variant: { storage },
      } = findVariantById(extendedVariants, activeVariantId);

      const filteredVariantsByStorage = extendedVariants.filter((item) => {
        const {
          variant: { storage: variantsStorage },
        } = item;

        if (variantsStorage === storage) {
          return item;
        }
        return null;
      });

      const buildColorObject = filteredVariantsByStorage.map((item) => {
        const {
          color: { name, hex },
          stock,
          ebootisId,
        } = item.variant;

        const activeAccessoryVariantStock = getActiveAccessoryVariantStock(
          accessories,
          activeAccessoryVariantIds
        );

        return {
          name,
          value: ebootisId,
          color: hex,
          stock,
          soldout: stock <= 0 || activeAccessoryVariantStock <= 0,
        };
      });

      return buildColorObject;
    }, [extendedVariants, activeVariantId]);
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Component {...props} colors={colors} />;
  };

  HOC.propTypes = {
    extendedVariants: PropTypes.arrayOf(extendedVariantPropTypes).isRequired,
    activeVariantId: PropTypes.string.isRequired,
  };

  return HOC;
};

export default WithVariantColors;
