import React from "react";
import PropTypes from "prop-types";
import SlickCarousel from "react-slick";

import Manufacturers from "helper/manufacturers";
import { Loading } from "components/molecules";

import { StyledTeaserCarousel, carouselSettings } from "./SlickCarouselSetting";

import "./styles.scss";

const ManufacturerTileContainer = ({
  manufacturers,
  handleManufacturerClick,
}) => {
  if (!manufacturers) return <Loading variant="manufacturer" />;

  return (
    <StyledTeaserCarousel>
      <SlickCarousel {...carouselSettings}>
        {manufacturers.map((name, index) => (
          <React.Fragment key={`${name}-${index}`}>
            <a
              className={`msd-teaser-box msd-manufacturer-link msd-hover--box-shadow ${name}`}
              href={Manufacturers[name].link}
              onClick={() =>
                handleManufacturerClick && handleManufacturerClick(index, name)
              }
            >
              <span className="msd-screen-reader-only">
                {Manufacturers[name].name}
              </span>
              {Manufacturers[name].logo}
            </a>
          </React.Fragment>
        ))}
      </SlickCarousel>
    </StyledTeaserCarousel>
  );
};

ManufacturerTileContainer.propTypes = {
  manufacturers: PropTypes.arrayOf(PropTypes.string).isRequired,
  parent: PropTypes.string,
};
export default React.memo(ManufacturerTileContainer);
