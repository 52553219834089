import React from "react";

import { Image } from "@mm/ui";

// TODO: getBreakpoint not available any more in the new system.

export const RichSEOImageSection = ({ image }) => {
  // breakpoint = getBreakpoint(mediaType);
  const breakpoint = {}; // TODO: see above
  const imageUrl =
    image && breakpoint && breakpoint in image ? image[breakpoint].path : "";

  return <Image src={imageUrl} alt={""} />;
};

export default RichSEOImageSection;
