import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const StyledHeadline = styled.h3`
  color: ${(props) => props.theme.colors.primary[0]};
  font-family: ${({ theme }) => theme.fonts.headlineBig};
  font-size: 30px;
  line-height: 1.25;
  margin: 0 0 3px 0;

  ${(props) =>
    props.fatheadline
      ? `
    @media (min-width: ${props.theme.breakpointsMap.lg.min}px) {
      font-size: 40px;
      max-height: calc(4 * 1.2 * 20px);
      line-height: 1.2;
    }
  `
      : ""};
`;

const StyledSubtitle = styled.h4`
  font-family: ${({ theme }) => theme.fonts.headline};
  font-size: 20px;
  font-weight: 600;
  line-height: 1.25;
  margin: 0;
`;

const StyledParagraph = styled.p`
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
  line-height: 1.43;
  white-space: normal;
  margin: 12px 0 0 0;

  @media (min-width: ${(props) => props.theme.breakpointsMap.md.min}px) {
    font-size: ${({ theme }) => theme.fontSizes[3]}px;
    line-height: 1.5;
  }
  > p:first-of-type {
    margin-top: 0;
  }
`;

const Copy = ({ className, title, subtitle, content, fatheadline }) => {
  return (
    <div className={className}>
      {title ? (
        <StyledHeadline
          fatheadline={fatheadline}
          dangerouslySetInnerHTML={{ __html: title }}
        />
      ) : (
        ""
      )}
      {subtitle ? (
        <StyledSubtitle dangerouslySetInnerHTML={{ __html: subtitle }} />
      ) : (
        ""
      )}

      {content && React.isValidElement(content) ? (
        <StyledParagraph>{content}</StyledParagraph>
      ) : (
        <StyledParagraph
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: content }}
        />
      )}
    </div>
  );
};

Copy.propTypes = {
  /**
   * used to allow usage of styled(Image)
   */
  className: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  content: PropTypes.node,
  fatheadline: PropTypes.bool,
};

Copy.defaultProps = {
  className: "",
  title: "",
  subtitle: "",
  content: "",
  fatheadline: false,
};

export default Copy;
