import { jsx as _jsx } from "react/jsx-runtime";
import { ACTIONS as ModalActions, useModal, } from "@/utils/context/ModalContext";
import Star from "@core/svgs/sternchen.svg";
import styled from "styled-components";
const Asterisk = styled.div `
  cursor: pointer;
  align-self: flex-start;
  margin-left: 0.125rem;
  max-height: 0.75rem;
  max-width: 0.75rem;
  display: flex;

  svg {
    width: 100%;
    height: 100%;
  }
`;
export const LegalNoteAsterisk = ({ legalNote }) => {
    const { dispatch } = useModal();
    return (_jsx(Asterisk, { role: "dialog", "aria-modal": "true", "aria-label": "Rechtliche Hinweise", onClick: (e) => {
            e.stopPropagation();
            dispatch({
                type: ModalActions.SET_MODAL,
                payload: {
                    title: legalNote.headline,
                    type: "legalNote",
                    content: legalNote.text,
                },
            });
        }, children: _jsx(Star, {}) }));
};
