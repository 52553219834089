import { useQuery } from "react-query";
import { extendURLParams } from "@/utils/fetchData";
export const useInternetOffers = (options, pageUrl) => {
    const { tenant, environment, restUrl } = extendURLParams();
    return useQuery([`useInternetOffers`, tenant, environment], () => fetch(`${restUrl}/v2/internet/page/${pageUrl}?tenant=${tenant}&environment=${environment}`, {
        method: "get",
        credentials: "include",
    })
        .then((res) => {
        return res.json();
    })
        .catch((error) => new Error(error)), {
        ...options,
        cacheTime: 5 * 60 * 1000,
        refetchOnWindowFocus: false,
    });
};
