import React from "react";
import PropTypes from "prop-types";

import { Surface } from "@mm/ui";

import { ThemeProvider } from "@core/theme";

import { OfferTeaserFourColumns } from "components/organisms";

const StandardTeaser = ({ data }) => (
  <ThemeProvider>
    <Surface variant="narrow">
      <OfferTeaserFourColumns data={data} />
    </Surface>
  </ThemeProvider>
);

StandardTeaser.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.any.isRequired,
};

export default StandardTeaser;
