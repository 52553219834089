import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Surface } from "@mm/ui";

import PhoneHalfWidth from "./Phone";
import TariffHalfWidth from "./Tariff";

const StyledCol = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;

  @media (min-width: ${({ theme }) => theme.breakpointsMap.md.max}px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const OfferTeaser = ({ data, voucherCode }) => {
  if (!data) {
    return (
      <Surface variant="narrow">
        <div>Es können keine Angebote angezeigt werden</div>
      </Surface>
    );
  }

  return (
    <StyledCol>
      {data.map((element, index) => {
        const isHardware = element.hardware;

        return isHardware ? (
          <PhoneHalfWidth
            data={element}
            key={element.tariff.id + element.hardware.id + index}
            voucherCode={voucherCode}
          />
        ) : (
          <TariffHalfWidth
            data={element}
            key={element.tariff.id + index}
            voucherCode={voucherCode}
          />
        );
      })}
    </StyledCol>
  );
};

OfferTeaser.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array]),
  voucherCode: PropTypes.string,
};

OfferTeaser.defaultProps = {
  data: [],
  voucherCode: "",
};

export default OfferTeaser;
