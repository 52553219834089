import { useQuery } from "react-query";
import { extendURLParams } from "@/utils/fetchData";
import { useValidation } from "..";
export const useIdCardNumber = (number, options) => {
    const { tenant, environment, restUrl } = extendURLParams();
    const { addError, clearErrors } = useValidation();
    return useQuery(["useIdCardNumber", tenant, environment], () => fetch(`${restUrl}/v1/checkout/validate/identityCard?tenant=${tenant}&environment=${environment}`, {
        method: "post",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            number,
        }),
    }).then(async (res) => {
        if (!res.ok) {
            const data = await res.json();
            if (data.error) {
                const error = data.error;
                addError("idCardNumber", error);
                return { error };
            }
            const error = "Beim Überprüfen der Ausweisnummer ist ein Fehler aufgetreten";
            addError("idCardNumber", error);
            return { error };
        }
        clearErrors("idCardNumber");
        return {};
    }), {
        ...options,
        cacheTime: 0,
        refetchOnWindowFocus: false,
        enabled: false,
        retry: 0,
    });
};
