import _ from "lodash/fp";

import {
  updateRangeFilter,
  updatePriceRangeFilter,
  buildFilterArrayGroup,
  mergeRangeValues,
} from "./products";

export const CARRIERS = "carriers";
export const PRICE_RANGE = "priceRange";
export const ONE_TIME_PRICE_FROM_RANGE = "oneTimePriceFromRange";
export const VOLUME_RANGE = "volumeRange";
export const SPEED_RANGE = "speedRange";
export const ADDITIONAL_OPTIONS = "additionalOptions";
export const CONTRACT_TERMS = "contractTerms";

/**
 * Validator to check if the current product matches the given filter criteria
 * @param {object} product- the product to check
 * @param {Array<object>|object} filterItem - the filter condition
 * @returns {boolean}
 */
export const carrierTest = (product, filterItem) => {
  /**
   * utility to check if something is equal to the carrier name
   */
  const carrierEq = _.eq(product.carrier);

  return _.cond([
    [_.isArray, _.some((item) => carrierEq(item.value))],
    [_.T, carrierEq],
  ])(filterItem);
};
export const priceRangeTest = (
  { pricingIntervals: { intervals } },
  filterItem
) => {
  const price = Math.floor(intervals[0].price);
  return price >= filterItem.minVal && price <= filterItem.maxVal;
};
export const oneTimePriceFromRangeTest = ({ oneTimePriceFrom }, filterItem) => {
  const price = Math.floor(oneTimePriceFrom);
  return price >= filterItem.minVal && price <= filterItem.maxVal;
};
// eslint-disable-next-line max-len
export const volumeRangeTest = ({ dataCapacity }, filterItem) =>
  dataCapacity >= filterItem.minVal && dataCapacity <= filterItem.maxVal;
// eslint-disable-next-line max-len
export const speedRangeTest = ({ speed }, filterItem) =>
  speed >= filterItem.minVal && speed <= filterItem.maxVal;
export const additionalOptionsTest = ({ features }, filterItem) =>
  _.some((item) => _.includes(item.value)(features))(filterItem);

export const contractTermTest = ({ contractTerm }, filterItem) => {
  return _.some(({ value }) => contractTerm === value)(filterItem);
};

// TODO refactor this - seems to be expensive and clunky
const bulletsByLabel = (details, search) =>
  _.isArray(details.bullets)
    ? details.bullets
        .map((bullet) => bullet.bullets.find(({ label }) => label === search))
        .filter((x) => x)
    : "";

const unlimitedRegex = /unbegrenzt/;
// FIXME add test
const getBulletFreetextValue = (tariff, key, regex) => {
  const bullets = bulletsByLabel(tariff.details, key);
  if (bullets.length) {
    const { text } = bullets[0];
    const match = text.match(regex);
    if (match?.length) return parseFloat(match[0]);
    if (unlimitedRegex.test(text)) return 999;
  }
  return null;
};

const dataCapacityRegex = /(\d+)\s?[Gg][Bb]/;
// FIXME add test
const getDataCapacity = (tariff) =>
  getBulletFreetextValue(tariff, "Datenvolumen", dataCapacityRegex);

const bandwidthRegex = /(\d+)\s?[Mm][Bb]it/;
// FIXME add test
const getBandwidth = (tariff) =>
  getBulletFreetextValue(tariff, "Bandbreite", bandwidthRegex);

// FIXME add test
/**
 * Add the fields dataCapacity and speed  to be used
 * on filter
 */
export const sanitizeProducts = (products) =>
  products.map((product) => {
    const dataCapacity = getDataCapacity(product);
    const speed = getBandwidth(product);
    return {
      ...product,
      dataCapacity,
      speed,
    };
  });

export const filterTests = {
  carriers: carrierTest,
  additionalOptions: additionalOptionsTest,
  priceRange: priceRangeTest,
  oneTimePriceFromRange: oneTimePriceFromRangeTest,
  volumeRange: volumeRangeTest,
  speedRange: speedRangeTest,
  contractTerms: contractTermTest,
};

// return possible filter groups and their filters based on products
export const getFilterOptions = (products, oldFilterOptions) => {
  let carriers = [];

  let additionalOptions = [];

  let contractTerms = [];

  let priceRange = {
    min: 10000, // we start with unrealistic inversed values so it will be updated
    max: 0,
    minVal: 0,
    maxVal: 0,
  };

  let oneTimePriceFromRange = {
    min: 10000, // we start with unrealistic inversed values so it will be updated
    max: 0,
    minVal: 0,
    maxVal: 0,
  };

  let volumeRange = {
    min: 10000, // we start with unrealistic inversed values so it will be updated
    max: 0,
    minVal: 0,
    maxVal: 0,
  };

  let speedRange = {
    min: 10000, // we start with unrealistic inversed values so it will be updated
    max: 0,
    minVal: 0,
    maxVal: 0,
  };

  products.forEach((product) => {
    // carrier
    carriers = buildFilterArrayGroup(
      carriers,
      CARRIERS,
      product.carrier,
      product.carrier,
      oldFilterOptions
    );

    // contractTerm
    contractTerms = buildFilterArrayGroup(
      contractTerms,
      CONTRACT_TERMS,
      product.contractTerm,
      product.contractTerm,
      oldFilterOptions
    );

    // additional options
    if (product.features) {
      product.features.forEach((feature) => {
        additionalOptions = buildFilterArrayGroup(
          additionalOptions,
          ADDITIONAL_OPTIONS,
          feature,
          feature,
          oldFilterOptions
        );
      });
    }

    // priceRange
    priceRange = updatePriceRangeFilter(
      priceRange,
      product.pricingIntervals.intervals[0].price
    );

    // oneTimePriceFromRange
    oneTimePriceFromRange = updatePriceRangeFilter(
      oneTimePriceFromRange,
      product.oneTimePriceFrom
    );

    // volumeRange
    volumeRange = updateRangeFilter(volumeRange, product.dataCapacity);

    // speedRange
    speedRange = updateRangeFilter(speedRange, product.speed);
  });
  priceRange = mergeRangeValues(priceRange, oldFilterOptions.priceRange);
  oneTimePriceFromRange = mergeRangeValues(
    oneTimePriceFromRange,
    oldFilterOptions.oneTimePriceFromRange
  );
  volumeRange = mergeRangeValues(volumeRange, oldFilterOptions.volumeRange);
  speedRange = mergeRangeValues(speedRange, oldFilterOptions.speedRange);

  return {
    carriers,
    priceRange,
    oneTimePriceFromRange,
    volumeRange,
    speedRange,
    additionalOptions,
    contractTerms,
  };
};

export const INITIAL_TARIFF_FILTERS = {
  "mobilcom-debitel": {
    carriers: [
      { name: "Vodafone", value: "Vodafone", selected: true },
      { name: "Telekom", value: "Telekom", selected: true },
    ],
  },
  vodafone: {
    carriers: [{ name: "Vodafone", value: "Vodafone", selected: true }],
  },
  telekom: {
    carriers: [{ name: "Telekom", value: "Telekom", selected: true }],
  },
  o2: {
    carriers: [{ name: "o2", value: "o2", selected: true }],
  },
  superselect: {
    carriers: [{ name: "Super Select", value: "Super Select", selected: true }],
  },
};
