import dataLayer from "helper/dataLayer";

export const addTrackFunction = (content, section, idx) => (url) =>
  dataLayer({
    eventCategory: "Teaser",
    eventAction: "click",
    eventLabel: `${section}:${idx}:${url || content.linkUrl}`,
  });

export default addTrackFunction;
