import React from "react";

import { ThemeProvider } from "@core/theme";

import { Surface } from "@mm/ui";

import { Copy } from "components/molecules";

const CopySection = ({ data }) => (
  <ThemeProvider>
    <Surface variant="narrow">
      <Copy
        title={data.title}
        subtitle={data.subtitle}
        content={data.content_md || data.content}
        fatheadline={data.fatheadline}
      />
    </Surface>
  </ThemeProvider>
);

export default CopySection;
