import styled from "styled-components";
import { Image, Flex } from "@mm/ui";

import { TileAnchor, SubHeadline, Price } from "components/atoms";

import StageOfferHeadline from "./StageOfferHeadline";
import StageHeadline from "./StageHeadline";

export const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("${window.CDN_URL}/img/redesign/hero/stage_background_mobile.png");

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    background-image: url("${window.CDN_URL}/img/redesign/hero/stage_background_desktop.png");
  }

  @media (min-width: ${(props) => props.theme.breakpoints.xs}) {
    margin-top: -16px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    margin-top: auto;
  }
`;

export const DynamicStageImage = styled(Image)`
  position: relative;
  z-index: 1;
  width: 100% !important;
`;

export const StyledTariffBadge = styled.div`
  position: absolute;
  top: 108%;
  right: 24%;

  @media (min-width: ${(props) => props.theme.breakpointsMap.xs.min}px) {
    img {
      width: 48px !important;
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    img {
      width: 72px !important;
    }
  }
`;

export const StyledPriceDisplayBackground = styled.div`
  position: relative;
  margin-left: auto;
  left: 18px;
  min-height: 100%;
  max-width: ${({ theme }) => theme.flexHero.maxWidth};
  background-color: ${({ theme }) => theme.colors.white};
  padding: 20px 20px 20px 40px;
  z-index: 1;
  border-bottom-left-radius: ${({ theme }) =>
    theme.flexHero.dynamicStageStyles.borderBottomRightRadius};

  @media (min-width: ${(props) => props.theme.breakpointsMap.xs.min}px) {
    clip-path: ${({ theme }) => theme.flexHero.cornerxs.clipPath}, none;
    min-height: 105px;
    width: 45%;
    padding: 5px 0;
    left: -28px;
    left: 0;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    clip-path: ${({ theme }) => theme.flexHero.corner.clipPath};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    width: 45%;
    left: -18px;
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    left: ${({ theme }) => theme.flexHero.dynamicStageStyles.after.left};
    top: 0;
    background-color: ${({ theme }) => theme.colors.white};
    left: -20px;
    height: 100%;
    max-width: 300px;
    width: 100%;
    transform: skew(14deg);
    z-index: -1;

    border-top-left-radius: 2.16px;
    border-bottom-left-radius: 21.8px;

    @media (min-width: ${({ theme }) => theme.breakpointsMap.xs.min}px) {
      left: -29px;
    }

    @media (min-width: ${({ theme }) => theme.breakpointsMap.xs.max}px) {
      left: -8px;
    }
  }
  &:after {
    position: absolute;
    top: 0;
    right: -33px;
    background-color: ${({ theme }) => theme.colors.white};
    content: "";
    display: block;
    width: 50px;
    height: 100.3%;
    z-index: -3;

    @media (min-width: ${({ theme }) => theme.breakpointsMap.xs.min}px) {
      width: 50px;
    }

    @media (min-width: ${({ theme }) => theme.breakpointsMap.xs.max}px) {
      z-index: -3;
      width: 75px;
    }
  }
`;

export const PriceInfoText = styled.div`
  display: flex;
  align-self: flex-end;
  font-weight: bold;

  @media (min-width: ${(props) => props.theme.breakpointsMap.xs.min}px) {
    font-size: 12px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    font-size: 14px;
  }
`;

export const MobileConnectImage = styled(Image)`
  position: absolute;
  left: 8px;
  z-index: 3;
  width: 65px;
  bottom: 300px;

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    position: absolute;
    bottom: 220px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    position: relative;
    left: unset;
    right: 8px;
    z-index: 3;
    width: 85px;
    bottom: 6px;
    margin: 0;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    position: absolute;
    right: ${({ theme }) => theme.flexHero.connectLogo.positionRight};
    left: unset;
  }
`;

export const StyledManufacturerLogo = styled.div`
  display: block;
  max-width: 110px;
  margin: 0 auto;
  svg {
    display: block;
    max-height: 40px;
    max-width: 62%;
    margin: 0 auto;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    margin-bottom: 6px;
  }
`;

export const StyledSubHeadline = styled(SubHeadline)`
  /* TODO: add line-clamp css fix */
  display: block !important;
  -webkit-line-clamp: 0 !important;
  -webkit-box-orient: 0 !important;
  font-weight: normal;
  line-height: 24px;
  height: unset;
  color: rgb(39, 36, 34);

  @media (min-width: ${(props) => props.theme.breakpointsMap.xs.min}px) {
    min-height: 42px;
    margin-bottom: 6px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    max-width: 260px;
    margin-top: 10px;
    font-size: 16px;
    min-height: 40px;
  }

  @media (min-width: ${(props) => props.theme.breakpointsMap.sm.min}px) {
    max-width: 400px;
  }
`;

export const StyledStageHeadline = styled(StageOfferHeadline)`
  margin: 25px 0 36px 0;
  margin-bottom: ${({ theme }) => theme.space.s}px;

  @media (min-width: ${(props) => props.theme.breakpointsMap.xs.min}px) {
    margin-top: 0;
    font-size: 24px;
  }

  @media (min-width: ${(props) => props.theme.breakpointsMap.xs.max}px) {
    font-size: 40px;
  }
`;

export const ColoredStageHeadline = styled(StageHeadline)`
  font-size: 36px;
  margin-top: 0;
  line-height: 1.2;
  height: unset;
  color: ${({ theme }) => theme.ColoredStageHeadline.color};

  @media (min-width: ${(props) => props.theme.breakpointsMap.xs.min}px) {
    font-size: 24px;
    margin-bottom: 10px;
  }

  @media (min-width: ${(props) => props.theme.breakpointsMap.xs.max}px) {
    font-size: ${({ theme }) => theme.ColoredStageHeadline.fontsize};
  }

  @media (min-width: ${(props) => props.theme.breakpointsMap.md}px) {
    margin-bottom: ${({ theme }) => theme.space.s}px;
    font-size: ${({ theme }) => theme.ColoredStageHeadline.fontsize};
  }
`;

export const StyledTileAnchor = styled(TileAnchor)`
  background-color: ${({ theme }) => theme.colors.white};
  height: 100% !important;
`;

export const StyledPrice = styled(Price)`
  width: fit-content;
  @media (min-width: ${(props) => props.theme.breakpointsMap.xs.min}px) {
    font-size: 24px;
  }

  @media (min-width: ${(props) => props.theme.breakpointsMap.xs.max}px) {
    font-size: 28px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    font-size: 30px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.md.max}) {
    font-size: 35px;
  }
`;

export const StyledText = styled.div`
  @media (min-width: ${(props) => props.theme.breakpointsMap.xs.min}px) {
    margin-top: 12px;
    font-size: 12px;
    padding: 0 8px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    font-size: 14px;
  }
`;

export const StyledPlus = styled.div`
  position: absolute;
  bottom: 50%;
  top: 42%;
  right: 28%;
  z-index: 99;
  border-radius: 50%;
  display: block;
  background-color: white;

  &:after {
    height: 100%;
    width: 100%;
    font-size: 150%;
    font-weight: bold;
    content: "🛓";
    font-family: "mm-icons";
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.xs}) {
    width: 25px;
    height: 25px;
    &:after {
      font-size: 100%;
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    width: 35px;
    height: 35px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    right: 23.5%;
    width: 40px;
    height: 40px;
  }
`;

export const StyledFlexbox = styled(Flex)`
  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    min-height: 340px;
  }
`;
