import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { CheckboxWithLabel, CheckoutSection, CheckoutSectionHeadline, RadioList, StyledInputText as InputText, } from "../components";
import { ACTIONS, useCheckoutState } from "../context/CheckoutContext";
import { useCheckout } from "../hooks/api";
import styled from "styled-components";
import { useField, useValidation, } from "../hooks";
const Row = styled.div `
  display: flex;
  width: 100%;
  gap: 1.5rem;
  @media (max-width: 760px) {
    flex-wrap: wrap;
  }
`;
const InputWrapper = styled.div `
  flex: 1;
  @media (max-width: 760px) {
    flex: 1 0 100%;
  }
`;
export const PhoneSubscriberSection = ({ defaultValues, schema, }) => {
    const { dispatch } = useCheckoutState();
    const { errors: solePhoneSubscriberErrors, fieldState: solePhoneSubscriber, setFieldState: setSolePhoneSubscriber, } = useField("solePhoneSubscriber", schema, Boolean(defaultValues?.solePhoneSubscriber));
    const { errors: firstNameErrors, fieldState: firstName, setFieldState: setFirstName, } = useField("phoneSubscriberFirstName", schema, "");
    const { errors: lastNameErrors, fieldState: lastName, setFieldState: setLastName, } = useField("phoneSubscriberLastName", schema, "");
    const { errors: canDecideProviderChangeErrors, fieldState: canDecideProviderChange, setFieldState: setCanDecideProviderChange, } = useField("canDecideProviderChange", schema, Boolean(defaultValues?.canDecideProviderChange));
    const { isFetching, isLoading } = useCheckout();
    const { clearErrors, } = useValidation();
    return (_jsxs(CheckoutSection, { children: [_jsx(CheckoutSectionHeadline, { children: "Alleiniger Anschlussinhaber" }), _jsx(RadioList, { checked: solePhoneSubscriber
                    ? "solePhoneSubscriber--yes"
                    : "solePhoneSubscriber--no", id: "solePhoneSubscriber", items: [
                    {
                        key: "solePhoneSubscriber--yes",
                        label: "Ich bin alleiniger Anschlussinhaber",
                    },
                    {
                        key: "solePhoneSubscriber--no",
                        label: "Es gibt neben mir einen weiteren Anschlussinhaber",
                    },
                ], onChange: (key) => {
                    clearErrors([
                        "canDecideProviderChange",
                        "phoneSubscriberFirstName",
                        "phoneSubscriberLastName",
                    ]);
                    if (key === "solePhoneSubscriber--yes") {
                        dispatch({
                            type: ACTIONS.RESET_FIELDS,
                            payload: [
                                "canDecideProviderChange",
                                "phoneSubscriberFirstName",
                                "phoneSubscriberLastName",
                            ],
                        });
                    }
                    setSolePhoneSubscriber(key === "solePhoneSubscriber--yes");
                }, disabled: isLoading || isFetching, error: solePhoneSubscriberErrors?.[0]?.message }), !solePhoneSubscriber && (_jsxs(_Fragment, { children: [_jsxs(Row, { children: [_jsx(InputWrapper, { children: _jsx(InputText, { id: "phoneSubscriberFirstName", label: "Vorname*", value: firstName, message: firstNameErrors?.[0]?.message, layout: firstNameErrors && "error", onChange: (value) => {
                                        setFirstName(value);
                                    }, disabled: isLoading || isFetching, autoComplete: "none" }) }), _jsx(InputWrapper, { children: _jsx(InputText, { id: "phoneSubscriberLastName", label: "Nachname*", value: lastName, message: lastNameErrors?.[0]?.message, layout: lastNameErrors && "error", onChange: (value) => {
                                        setLastName(value);
                                    }, disabled: isLoading || isFetching, autoComplete: "none" }) })] }), _jsx(CheckboxWithLabel, { checked: canDecideProviderChange, error: canDecideProviderChangeErrors?.[0]?.message, onChange: () => setCanDecideProviderChange(!canDecideProviderChange), label: "Ich bin befugt, \u00FCber den Wechsel des Telekommunikationsanschlusses allein zu entscheiden.*" })] }))] }));
};
