import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { CheckoutSection, CheckoutSectionHeadline, CheckoutSectionText, } from "../../components";
import { useCheckout } from "../../hooks/api";
import { Spinner } from "@/components/atoms";
import { useInterval } from "@/utils/hooks";
export const ContractSummarySection = () => {
    const { data, isFetching, refetch } = useCheckout();
    const [refetched, setRefetched] = useState(false);
    const [startInterval, setStartInterval] = useState(false);
    useInterval(async () => {
        if (refetched)
            return;
        refetch().finally(() => {
            setRefetched(true);
        });
    }, startInterval ? 5000 : null, 5000, [startInterval, refetched], () => {
        setStartInterval(false);
    });
    useEffect(() => {
        if (!data?.data?.eeccDocumentsRequested && !refetched) {
            setStartInterval(true);
        }
    }, []);
    return (_jsxs(CheckoutSection, { children: [_jsx(CheckoutSectionHeadline, { children: "Vertragszusammenfassung" }), data?.data?.eeccDocumentsRequested ? (_jsx(CheckoutSectionText, { children: "Die Vertragszusammenfassung sowie weitere gesetzliche Pflichtinformationen zum Download hast du per E-Mail erhalten." })) : refetched && !isFetching ? (_jsx(CheckoutSectionText, { children: "Leider ist es im Moment nicht m\u00F6glich, die E-Mail zum Versand der Vertragszusammenfassung zu verschicken. Du erh\u00E4ltst nach Abschluss der Bestellung eine E-Mail mit der M\u00F6glichkeit, alle Vertragsdokumente anzufordern. Erst nachdem du die Vertragsunterlagen erhalten hast, k\u00F6nnen wir die Bestellung an o2 weiterleiten." })) : (_jsx(Spinner, { color: "secondary" })), _jsxs(CheckoutSectionText, { children: ["Weitere Informationen findest du im", " ", _jsx("a", { href: data?.summary?.tariff.pibUrl, target: "_blank", rel: "noreferrer", children: "Produktinformationsblatt" }), "."] }), _jsx(CheckoutSectionText, { children: "Wenn du auf dieser Grundlage bestellen m\u00F6chtest, klicke auf \"Kostenpflichtig bestellen\"." })] }));
};
