import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import dataLayer from "helper/dataLayer";

import Link from "@/components/atoms/Link";
import { Text } from "@mm/ui";

const Navigation = styled("nav")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0 0;
  padding: 0;
  list-style-type: none;
`;

const Anchor = styled(Link)`
  padding-left: 0;
  padding-bottom: 20px;
  color: ${(props) => props.theme.colors.white} !important;
  font-weight: 400;

  :after {
    display: none;
  }
  :before {
    left: 0;
    top: 1.5rem;
    width: 100%;
    background: ${(props) => props.theme.colors.white};
  }
`;

const DesktopNavigation = ({ navigation, handleAnchorHover }) => {
  return (
    <Navigation aria-label="Main">
      {navigation.map((navpoint, idx) => {
        const { id, linkUrl, category, name, hasFlyout } = navpoint;
        return (
          <Anchor
            key={idx}
            href={linkUrl}
            onClick={() =>
              dataLayer({
                eventCategory: `${category}`,
                eventAction: `click`,
                eventLabel: `${name}:${linkUrl}`,
              })
            }
            data-qa={name.replace(/\s+/g, "-").toLowerCase()}
            onMouseEnter={() => handleAnchorHover(id, name, hasFlyout)}
            className={hasFlyout && "hasFlyout"}
            aria-haspopup={hasFlyout && "true"}
          >
            <Text variant="smallCopy">{name}</Text>
          </Anchor>
        );
      })}
    </Navigation>
  );
};

DesktopNavigation.propTypes = {
  navigation: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string,
      mobileText: PropTypes.string,
      linkUrl: PropTypes.string,
    })
  ).isRequired,
};

export default DesktopNavigation;
