import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { variant } from "styled-system";

import Manufacturers from "helper/manufacturers";

import { ManufacturerTile } from "components/atoms";
import TileBox from "../../atoms/TileBox";
import { Loading } from "components/molecules";

const StyledCol = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 9px;
  ${variant({
    variants: {
      tile: {
        gap: "24px",
      },
      navMobileProviderSection: {
        flexDirection: "column",
      },
      navDesktopProviderSection: {
        flexDirection: "column",
      },
      navDesktopManufacturerSection: {
        gap: "1rem 1.5rem",
      },
    },
  })}
`;

const ScreenReaderOnly = styled.span`
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
`;

const StyledTileBox = styled(TileBox)`
  flex-basis: calc(50% - 24px);
  flex-grow: 1;
  padding: 0;

  @media (min-width: ${({ theme }) => theme.breakpointsMap.md.min}px) {
    flex-basis: calc(33.33% - 24px);
  }
  ${variant({
    variants: {
      navMobileManufacturerSection: {
        borderRadius: "0px",
        minWidth: "6rem",
        height: "3.5rem",
      },
      navDesktopManufacturerSection: {
        minWidth: "7.7rem",
        height: "3.5rem",
        borderRadius: "0px",
      },
      navMobileProviderSection: {
        borderRadius: "0px",
      },
      navDesktopProviderSection: {
        borderRadius: "0px",
      },
    },
  })}
`;

const StyledAnchor = styled.a`
  align-items: center;
  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.black};
  display: flex;
  flex-basis: calc(50% - 24px);
  flex-direction: row;
  flex-grow: 1;
  font-size: 16px;
  font-weight: 400;
  height: 100%;
  justify-content: center;
  min-width: 6rem;
  padding: 0;
  text-align: center;
  text-decoration: inherit;
  ${"svg"} {
    transform: scale(0.7) rotate(90deg);
    margin-left: 0.5rem;
    fill: ${(props) => props.theme.colors.black};
  }
  @media (min-width: ${({ theme }) => theme.breakpointsMap.md.min}px) {
    flex-basis: calc(33.33% - 24px);
    margin-top: auto;
    min-width: 7.6rem;
  }
`;

const ManufacturerTileContainer = ({
  manufacturers,
  variant,
  handleManufacturerClick,
  handleExtraTileClick,
  extraTile,
}) => {
  if (!manufacturers) return <Loading variant="manufacturer" />;

  return (
    <StyledCol variant={variant}>
      {manufacturers.map((name, index) => (
        <ManufacturerTile
          link={Manufacturers[name].link}
          onClick={() =>
            handleManufacturerClick && handleManufacturerClick(index, name)
          }
          key={`${name}-${Math.random()}`}
          variant={variant}
          className={name}
        >
          <ScreenReaderOnly>{name} Logo</ScreenReaderOnly>
          {Manufacturers[name].logo}
        </ManufacturerTile>
      ))}
      {extraTile?.url && extraTile?.label && (
        <StyledTileBox variant={variant}>
          <StyledAnchor
            href={extraTile.url}
            onClick={() => handleExtraTileClick && handleExtraTileClick()}
          >
            {extraTile.label}
          </StyledAnchor>
        </StyledTileBox>
      )}
    </StyledCol>
  );
};

ManufacturerTileContainer.propTypes = {
  manufacturers: PropTypes.arrayOf(PropTypes.string).isRequired,
  variant: PropTypes.string,
  parent: PropTypes.string,
};
export default React.memo(ManufacturerTileContainer);
