import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ACTIONS as ModalActions, useModal, } from "@/utils/context/ModalContext";
import { Headline } from "@mm/ui";
import styled from "styled-components";
import { DetailsAccordion, GiftcardDisplay, Modal } from "@/components/atoms";
import { extendURLParams } from "@/utils/fetchData";
const Content = styled.main `
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: flex-start;
`;
const DetailsWrapper = styled.div `
  flex: 1;
`;
export const GiftCardDetailsModal = ({ details, meta, title, }) => {
    const { dispatch } = useModal();
    const { cdnUrl } = extendURLParams();
    return (_jsxs(Modal, { onClose: () => dispatch({
            type: ModalActions.CLEAR_MODAL,
        }), children: [_jsx(Headline, { variant: "h2", children: title }), _jsxs(Content, { children: [_jsx(GiftcardDisplay, { giftcard: {
                            image: `${cdnUrl}/img/giftcard.png`,
                            value: meta?.coupon?.value ?? 0,
                        }, giftCardDisplayWidthNonPx: "16rem", giftCardDisplayTop: "0", giftCardClampMaxWidth: true, size: "m" }), _jsx(DetailsWrapper, { children: _jsx(DetailsAccordion, { details: details }) })] })] }));
};
