export const CDN_URL = window.CDN_URL || "";
export const HOTLINE_NUMBER = window.HOTLINE_NUMBER || "";
export const HOTLINE_NUMBER_CLICKABLE =
  HOTLINE_NUMBER !== ""
    ? `49${HOTLINE_NUMBER.replace(/\D/g, "").substring(1)}`
    : "";

export default {
  CDN_URL,
  HOTLINE_NUMBER,
  HOTLINE_NUMBER_CLICKABLE,
};
