import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { variant } from "styled-system";

import { namePropTypes } from "sharedPropTypes";

const StyledHeadline = styled.span`
  display: block;
  font-weight: 400;
  min-height: 44px;
  line-height: 1.5;
  margin: 16px 0;

  .firstLetterUpperCase:first-letter {
    display: inline-block;
    text-transform: uppercase;
  }

  strong.no-upper {
    text-transform: none;
  }

  ${variant({
    variants: {
      normal: {
        fontSize: "16px",
      },
      Konnect: {
        fontSize: "20px",
      },
      offerTeaser: {
        fontSize: "0.9rem",
        fontWeight: "normal",
        minHeight: "unset",
        margin: "0",
      },
    },
  })}
`;

const renderAdditionalData = (additionalData, doubleCardOffer) => {
  let preparedAdditionalData = <></>;

  if (!additionalData || !additionalData.length) {
    preparedAdditionalData = <></>;
  } else if (typeof additionalData === "string") {
    preparedAdditionalData = additionalData;
  } else if (doubleCardOffer) {
    preparedAdditionalData = (
      <>
        {" und"}{" "}
        {additionalData.map((accessory, idx) => (
          <React.Fragment key={idx}>
            {idx > 0 ? " und " : " "}
            <b>{accessory.manufacturer}</b>
            {` ${accessory.name}`}
          </React.Fragment>
        ))}
      </>
    );
  } else {
    preparedAdditionalData = (
      <>
        {" inkl."}{" "}
        {additionalData.map(
          (accessory, idx) =>
            `${idx > 0 ? " und " : " "} ${accessory.manufacturer} ${
              accessory.name
            }`
        )}
      </>
    );
  }

  return preparedAdditionalData;
};

const OfferHeadline = ({
  prefix,
  text,
  lines,
  additionalData,
  doubleCardOffer,
  variant,
}) => {
  const checkForVariant = prefix !== "Konnect" ? "normal" : "Konnect";
  let carrierString = prefix;

  switch (prefix) {
    case "Vodafone klarmobil":
      carrierString = "klarmobil";
      break;

    case "Telekom klarmobil":
      carrierString = "klarmobil";
      break;

    default:
      break;
  }

  return (
    <StyledHeadline lines={lines} variant={variant || checkForVariant}>
      <b>
        {carrierString} {text.replace("Super Select", "")}
      </b>{" "}
      <b>{renderAdditionalData(additionalData, prefix, doubleCardOffer)}</b>
    </StyledHeadline>
  );
};

OfferHeadline.propTypes = {
  text: PropTypes.string.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  variant: PropTypes.string,
  lines: PropTypes.number,
  additionalData: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(namePropTypes),
  ]),
};

OfferHeadline.defaultProps = {
  lines: 1,
  additionalData: [],
  variant: "normal",
};

export default OfferHeadline;
