import PropTypes from "prop-types";
import styled from "styled-components";

const ShadowBox = styled.div`
  border-radius: 4px;
  box-shadow: ${(props) => {
    switch (props.size) {
      case "s":
        return "0 2px 10px 0 rgba(0, 0, 0, 0.07), 0 2px 5px 0 rgba(0, 0, 0, 0.06)";
      case "l":
      default:
        return "0 16px 28px 0 rgba(0, 0, 0, 0.07), 0 1px 5px 0 rgba(0, 0, 0, 0.06), 0 25px 55px 0 rgba(0, 0, 0, 0.1)";
    }
  }};
`;

ShadowBox.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ShadowBox;
