import { useQuery } from "react-query";
import { extendURLParams } from "@/utils/fetchData";
export const useNavigation = (options) => {
    const { tenant, environment, restUrl } = extendURLParams();
    const initialData = window.NAVIGATION
        ? { payload: window.NAVIGATION }
        : { payload: [] };
    return useQuery([`useNavigation`, tenant, environment], () => fetch(`${restUrl}/v1/section/navigation?tenant=${tenant}&environment=${environment}`, {
        method: "get",
        credentials: "include",
    })
        .then((res) => {
        return res.json();
    })
        .catch((error) => new Error(error)), {
        ...options,
        cacheTime: 5 * 60 * 1000,
        refetchOnWindowFocus: false,
        initialData,
        enabled: !window.NAVIGATION || window.NAVIGATION.length < 1,
    });
};
