import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Flex, Box, Headline, Text, Row, Col, Grid } from "@mm/ui";
import { useViewport } from "@core/theme";

import TradeInActionIcon from "@core/svgs/trade-in-icons/trade-in.svg";

import dataLayer from "helper/dataLayer";

import {
  useOfferConfigurator,
  SET_ACTIVE_VARIANT,
  SET_ACTIVE_ACCESSORY_VARIANT,
  SET_ACTIVE_TARIFF,
} from "features/Offer";

import {
  filterVariantsByColor,
  findSelectedTariffInExtendedVariants,
} from "utils/tariffUtils";

import {
  Bulletlist,
  Eyecatchers,
  OfferHeadline,
  TileBox,
  TariffBadge,
  SmallInlineLink,
  GeneralModalShell,
  VariantsStorageDropdown,
} from "components/atoms";

import {
  HardwareInfo,
  DeliveryInfoModal,
  VariantsColorDropdown,
  ProductAccessoryDisplay,
  TariffDetailsLinks,
  Loading,
} from "components/molecules";

import { PriceSection } from "sections";

export const BoxWithBorderTop = styled(Box)`
  border-top: 1px solid ${({ theme }) => theme.colors.shade.xs};
`;

const TradeInPrice = styled("div")`
  color: ${({ theme }) => theme.colors.brand.primary};
  font-weight: 700;
  margin: 0 0 5px 0;
  padding: 0;
  text-align: right;

  > span {
    text-decoration: line-through;
  }
`;

const VoucherBonus = styled("div")`
  position: relative;
  background-image: url(${(props) => props.backgroundUrl});
  width: 100px;
  height: 100px;

  .bonusValue {
    line-height: 1.3;
    position: absolute;
    background-color: white;
    top: 48px;
    left: 7px;
    padding: 0 5px;
    width: 86px;
    text-align: center;
  }
`;

const PhoneHalfWidth = ({ voucherCode }) => {
  const viewport = useViewport();
  const { state, dispatch } = useOfferConfigurator();

  const {
    accessories,
    extendedVariant,
    activeAccessoryVariantIds,
    activeTariff,
    activeVariantId,
    extendedVariants,
    isSoldOut,
    rootInfo: {
      hardware,
      hardware: { name, totalVoucher },
      tariff: { linkText, link },
      eyeCatchers,
      protectedVoucher,
    },
  } = state;

  const availableVariants = filterVariantsByColor(
    extendedVariants,
    extendedVariant?.variant.colorGroup.hex
  );

  if (!state || !extendedVariant) return <Loading variant="teaser" />;

  const {
    tariff,
    tariff: { bullets, serviceProvider, carrier },
    variant: { basisPrice, tradeInActive, tradeInValue },
    manufacturer,
  } = extendedVariant;

  const isDoubleCard = activeTariff?.doubleCard || false;

  const tariffUrl = `tarife/${serviceProvider}/${link}`;

  const handleVariantChange = (val) => {
    dispatch({
      type: SET_ACTIVE_VARIANT,
      payload: {
        activeVariantId: val,
      },
    });
    dispatch({
      type: SET_ACTIVE_TARIFF,
      payload: {
        calculateExtendTariff: findSelectedTariffInExtendedVariants(
          extendedVariants,
          val
        ),
      },
    });
  };

  return (
    <TileBox variant="tile">
      {eyeCatchers && (
        <Eyecatchers eyecatchers={eyeCatchers} variant="offerTeaser" />
      )}
      <Box mb={6} minHeight="380px">
        <OfferHeadline
          doubleCardOffer={isDoubleCard}
          additionalData={accessories}
          text={name}
          prefix={manufacturer}
          lines={2}
        />
        <Row px={2}>
          <Col md={12}>
            <ProductAccessoryDisplay
              accessories={accessories}
              activeAccessoryVariantIds={activeAccessoryVariantIds}
              isSoldOut={isSoldOut}
              name={name}
              image={extendedVariant?.variant.images[0]}
              manufacturer={manufacturer}
              imageHeight={accessories?.length > 0 ? "230px" : "300px"}
            />
          </Col>
          <Col md={12}>
            {activeVariantId && (
              <VariantsColorDropdown
                viewport={viewport}
                extendedVariants={extendedVariants}
                label={!isDoubleCard ? "Farbe" : "Gerätefarbe 1"}
                activeVariantId={activeVariantId}
                onChange={handleVariantChange}
              />
            )}
            {activeVariantId && !isDoubleCard && (
              <Box mt={3}>
                <VariantsStorageDropdown
                  viewport={viewport}
                  selected={activeVariantId}
                  variants={availableVariants}
                  onChange={handleVariantChange}
                />
              </Box>
            )}
            {accessories &&
              accessories.map((accessory, index) => {
                // find the active variant ID from each accessory, if not present
                // the first one from its extendedVariants will be taken
                const variantId =
                  activeAccessoryVariantIds?.[index].ebootisId ||
                  accessory.extendedVariants[0].variant.ebootisId;
                return (
                  <Box mt={3} key={variantId}>
                    <VariantsColorDropdown
                      extendedVariants={accessory.extendedVariants}
                      label={
                        !isDoubleCard
                          ? `Zubehörfarbe ${accessory.name}`
                          : "Gerätefarbe 2"
                      }
                      activeVariantId={variantId}
                      viewport={viewport}
                      onChange={(activeAccessoryVariantId) => {
                        dispatch({
                          type: SET_ACTIVE_ACCESSORY_VARIANT,
                          payload: {
                            activeAccessoryVariantId,
                            activeAccessoryHardwareId: accessory._id,
                            index,
                          },
                        });
                      }}
                    />
                  </Box>
                );
              })}
            {activeVariantId && isDoubleCard && (
              <Box mt={3}>
                <VariantsStorageDropdown
                  viewport={viewport}
                  selected={activeVariantId}
                  variants={availableVariants}
                  onChange={handleVariantChange}
                />
              </Box>
            )}
            <Box my={5}>
              <GeneralModalShell
                opener={
                  <SmallInlineLink
                    href="#"
                    onClick={(evt) => {
                      evt.preventDefault();
                      dataLayer({
                        eventCategory: "Hardwaredetails",
                        eventAction: "click",
                        eventLabel:
                          "Geräte-Details:undefined:undefined:OfferTeaserPhone",
                      });
                    }}
                  >
                    Gerätedetails
                  </SmallInlineLink>
                }
              >
                <Headline variant="h2">Geräte-Details</Headline>
                <HardwareInfo hardware={hardware} />
                {accessories &&
                  accessories.map((item) => {
                    return (
                      <Box mt={4} key={item.name}>
                        <Headline variant="h2">{item.name}</Headline>
                        <HardwareInfo hardware={item} />
                      </Box>
                    );
                  })}
              </GeneralModalShell>
            </Box>
          </Col>
        </Row>
      </Box>
      <BoxWithBorderTop pt={6}>
        <Row px={3}>
          <Flex
            alignItems="center"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Flex alignItems="center" flexWrap="wrap">
              <Box mr={2} mb={[2, 2, 0, 0, 0]}>
                <TariffBadge tariff={tariff} framed />
              </Box>
              <Text>
                <b>{carrier}</b> {tariff.name.replace("Super Select", "")}
              </Text>
            </Flex>
            {linkText && (
              <Box my={2}>
                <SmallInlineLink href={tariffUrl}>{linkText}</SmallInlineLink>
              </Box>
            )}
          </Flex>
        </Row>
        <Row mt={20}>
          <Col md={12}>
            <Bulletlist
              variant="checkmark"
              list={bullets.slice(0, 3)} // maximal 3 bullets
              size="m"
            />
            <TariffDetailsLinks
              tariff={tariff}
              analytics={{
                context: "TariffOfferLandingPage",
              }}
            />
            {voucherCode && !tradeInActive && (
              <Grid
                gridTemplateColumns="auto auto"
                gridGap="10px"
                mb={[4, 4, 4, 0]}
                mt="auto"
              >
                {voucherCode && protectedVoucher && totalVoucher && (
                  <VoucherBonus backgroundUrl="https://storage.googleapis.com/content-ekontor24/mediamarkt/svgs/campains/direct-bonus/100-sofortbonus.svg">
                    <span className="bonusValue">
                      <strong>{totalVoucher}€</strong>
                    </span>
                  </VoucherBonus>
                )}
              </Grid>
            )}
            {tradeInActive && (
              <Grid
                gridTemplateColumns="auto auto"
                gridGap="10px"
                mb={[4, 4, 4, 0]}
                mt="auto"
              >
                {tradeInValue && totalVoucher && (
                  <VoucherBonus backgroundUrl="https://storage.googleapis.com/content-ekontor24/mediamarkt/svgs/campains/trade-in/200-sofortbonus.svg">
                    <span className="bonusValue">
                      <strong>{totalVoucher}€</strong>
                    </span>
                  </VoucherBonus>
                )}
                <TradeInActionIcon height="100" />
              </Grid>
            )}
          </Col>
          {extendedVariant && (
            <Col md={12}>
              {(tradeInActive || protectedVoucher) && (
                <TradeInPrice>
                  <span>{basisPrice}</span>€
                </TradeInPrice>
              )}
              <PriceSection
                mt={tradeInActive || protectedVoucher ? "0" : "27px"}
                voucherCode={voucherCode}
                tradeInActive={tradeInActive}
                priceTextVariant="smallCopy"
                shippingTextVariant="s"
                priceShippingInfo={
                  <DeliveryInfoModal
                    deliveryPrice={extendedVariant.variant.deliveryPrice}
                  />
                }
              />
            </Col>
          )}
        </Row>
      </BoxWithBorderTop>
    </TileBox>
  );
};

PhoneHalfWidth.propTypes = {
  voucherCode: PropTypes.string,
};

PhoneHalfWidth.defaultProps = {
  voucherCode: "",
};

export default PhoneHalfWidth;
