const dataLayer = (obj) => {
  const gtmObj = {
    ...{ event: "gaEvent" },
    ...obj,
  };
  if (window.dataLayer) {
    window.dataLayer.push(gtmObj);
  } else {
    // eslint-disable-next-line no-console
    console.log(gtmObj);
  }
};

export const reduceCheckboxValues = (group) =>
  group.reduce(
    (acc, { selected, value }) => (selected ? `${acc}${value},` : acc),
    ""
  );

// track if both slider endpoints have been touched
export const getSliderTouchPoints = ({ min, max, minVal, maxVal }) =>
  `${(min !== minVal ? 1 : 0) + (max !== maxVal ? 1 : 0)}/2`;

export const sanitizeUrl = (url) => url.replace(/http[s]?:\/\//g, "");

export default dataLayer;
