import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCheckout } from "../../hooks/api";
import { OverviewGrid, CheckoutSection } from "../../components";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ConnectionOverviewSection = (props) => {
    const { data } = useCheckout();
    return (_jsx(CheckoutSection, { children: _jsx(OverviewGrid, { items: [
                {
                    title: "Anschlussadresse",
                    content: (_jsx(_Fragment, { children: _jsxs("p", { children: [data?.availability.address.street, " ", data?.availability.address.streetNumber, _jsx("br", {}), data?.availability.address.zip, " ", data?.availability.address.city] }) })),
                },
                {
                    title: "Aktivierungszeitpunkt",
                    content: data?.data.activationDateSelection
                        ? data?.data.activationDate
                        : "Schnellstmöglich",
                },
            ] }) }));
};
