import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledHeader = styled("header")`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  margin-bottom: ${({ theme }) => theme.space.xl}px;
`;

const SubHeadline = (props) => {
  const { text } = props;
  return (
    <>
      &nbsp;- <span>{text}</span>
    </>
  );
};

const Header = (props) => {
  const { headline, subheadline, provider, providerlogo } = props;

  return (
    <StyledHeader className="logoheadline">
      <h2>
        {headline}
        {subheadline && <SubHeadline text={subheadline} />}
      </h2>
      <div className="offer-logo">
        <img
          src={`https://content.ekontor24.net/mediamarkt/img/energy/${providerlogo}-logo.png`}
          alt={`${provider}-Logo`}
          width="130"
        />
      </div>
    </StyledHeader>
  );
};

Header.propTypes = {
  headline: PropTypes.string,
  subheadline: PropTypes.string,
  provider: PropTypes.string,
  providerlogo: PropTypes.string,
};

Header.defaultProps = {
  headline: "",
  subheadline: "",
  provider: "",
  providerlogo: "",
};
export default Header;
