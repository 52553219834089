import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCheckoutState } from "../../context/CheckoutContext";
import { useCheckout } from "../../hooks/api";
import { CheckoutSection, OverviewGrid } from "../../components";
import { getProviderNameByCode } from "../../providers";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ConnectionOverviewSection = (props) => {
    const { state } = useCheckoutState();
    const { serviceProvider } = state;
    const { data } = useCheckout();
    return (_jsx(CheckoutSection, { children: _jsx(OverviewGrid, { items: [
                {
                    title: "Anschlussadresse",
                    content: (_jsx(_Fragment, { children: _jsxs("p", { children: [data?.availability.address.street, " ", data?.availability.address.streetNumber, _jsx("br", {}), data?.availability.address.zip, " ", data?.availability.address.city] }) })),
                },
                {
                    title: "Anbieterwechsel",
                    content: (_jsxs(_Fragment, { children: [_jsx("p", { children: data?.data.providerChange ? "Ja" : "Nein" }), data?.data.providerChange && data?.data.formerProvider && (_jsxs("p", { children: ["Bisheriger Anbieter:", " ", getProviderNameByCode(data.data.formerProvider, serviceProvider)] }))] })),
                },
                {
                    title: "Vertragsende",
                    content: (_jsxs(_Fragment, { children: [_jsx("p", { children: data?.data.contractTerminationDate }), _jsx("p", { children: data?.data.contractTerminated
                                    ? "Anschluss bereits gekündigt"
                                    : "Anschluss nicht gekündigt" }), data?.data.digitalLetterOfIntent && (_jsx("p", { children: "Wechsel-Service beauftragt" }))] })),
                    hide: !data?.data.providerChange,
                },
                {
                    title: data?.data?.currentProviderNumberExtension01
                        ? "Bisherige Rufnummern"
                        : "Bisherige Rufnummer",
                    content: (_jsxs(_Fragment, { children: [_jsxs("p", { children: [data?.data.currentProviderNumberPrefix, " ", data?.data.currentProviderNumberExtension] }), data?.data?.currentProviderNumberExtension01 && (_jsxs("p", { children: [data?.data.currentProviderNumberPrefix, " ", data?.data.currentProviderNumberExtension01] })), data?.data?.currentProviderNumberExtension02 && (_jsxs("p", { children: [data?.data.currentProviderNumberPrefix, " ", data?.data.currentProviderNumberExtension02] }))] })),
                    hide: !data?.data.providerChange ||
                        !data?.data.currentProviderNumberPrefix,
                },
                {
                    title: "Aktivierungszeitpunkt",
                    content: data?.data.activationDateSelection
                        ? data?.data.activationDate
                        : "Schnellstmöglich",
                },
            ] }) }));
};
