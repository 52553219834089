/* eslint-disable react/forbid-prop-types */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import _ from "lodash";

import { Image } from "@mm/ui";
import { pdpBannerData } from "@core/config";
import { useViewport } from "@core/theme";

const PdpBannerImage = styled(Image)({
  height: "auto",
  width: "100%",
});

const determineTargetBanner = (conditions) => {
  let targetBanner = null;
  [
    "hardwareVariant",
    "offergroup",
    "tariffId",
    "manufacturer",
    "provider",
  ].forEach((conditionName) => {
    if (
      !targetBanner &&
      !!conditions[conditionName] &&
      // eslint-disable-next-line no-prototype-builtins
      pdpBannerData.conditions[conditionName].hasOwnProperty(
        conditions[conditionName]
      )
    ) {
      targetBanner =
        pdpBannerData.conditions[conditionName][conditions[conditionName]];
    }
  });

  // https://skon.atlassian.net/browse/MSHPM-1015 REMOVE THIS later.
  /* if (targetBanner === null && TENANT !== "SATURN") {
        targetBanner = "blackweek_2022_gewinnspiel";
    } */
  return targetBanner;
};

const ProductDetailPageTopBanner = ({ fallbackNode, conditions }) => {
  let image = null;

  const { currentWidth } = useViewport();

  const targetBanner = determineTargetBanner(conditions);
  if (targetBanner) {
    const { images, href, useBlankTarget } = pdpBannerData.teaser[targetBanner];

    // find image that fits viewport.
    // If none found, we're on the biggest viewport, therefore take the biggest image.
    const currentImageBasedOnViewport =
      Object.keys(images).find((key) => key > currentWidth) ||
      _.last(Object.keys(images));

    image = <PdpBannerImage src={images[currentImageBasedOnViewport]} />;

    if (href) {
      image = (
        // eslint-disable-next-line react/jsx-no-target-blank
        <a href={`${href}`} target={useBlankTarget ? "_blank" : "_self"}>
          {image}
        </a>
      );
    }
  }

  return image || fallbackNode;
};

ProductDetailPageTopBanner.propTypes = {
  fallbackNode: PropTypes.node,
  conditions: PropTypes.shape({
    hardwareVariant: PropTypes.string,
    offergroup: PropTypes.string,
    tariffId: PropTypes.string,
    manufacturer: PropTypes.string,
    provider: PropTypes.string,
  }),
};

ProductDetailPageTopBanner.defaultProps = {
  fallbackNode: <></>,
  conditions: {
    hardwareVariant: "",
    offergroup: "",
    tariffId: "",
    manufacturer: "",
    provider: "",
  },
};

export default ProductDetailPageTopBanner;
