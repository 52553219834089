import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { variant } from "styled-system";
import { useViewport } from "@core/theme";
import { Text } from "@mm/ui";

import Logo from "@core/svgs/MediaMarkSaturnLogo.svg";

const Anchor = styled("a")(
  (props) => css`
    display: inline-flex;
    align-items: flex-end;
    flex-direction: ${props.lessThanLg && !props.wide ? "column" : "row"};
    text-decoration: none;

    svg,
    .logo_svg__c {
      height: 30px;
      margin-right: ${props.theme.space.xs}px;

      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        height: 37px;
      }

      @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        height: 47px;
      }
    }
  `
);

const TextContainer = styled("span")`
  margin-top: 3px;
  @media (max-width: ${(props) => props.theme.breakpointsMap.md.max}px) {
    display: flex;
    align-items: baseline;
    margin-top: 0;
  }
`;

const StyledText = styled("span")`
  display: ${(props) => (props.lessThanLg ? "inline" : "block")};
  margin-right: 5px;
  font-size: ${(props) => (props.lessThanLg ? "20px" : "26px")};
  line-height: 1;
  font-family: ${({ theme }) => theme.fonts.headlineBig};
  font-weight: normal;
  color: ${(props) => {
    switch (props.color) {
      case "black":
        return props.theme.colors.grey[0];
      case "primary":
        return props.theme.colors.primary[0];
      default:
        return props.theme.colors.white;
    }
  }};
`;

const StyledSubText = styled(Text)`
  display: block;
  font-size: ${(props) => (props.lessThanMd ? "12px" : "14px")};
  line-height: 1;
  color: ${(props) => {
    switch (props.color) {
      case "black":
        return props.theme.colors.grey[0];
      case "primary":
        return props.theme.colors.primary[0];
      default:
        return props.theme.colors.white;
    }
  }};
  ${variant({
    variants: {
      navMob: {
        fontSize: "12px",
      },
    },
  })};
`;

const Pagelogo = ({ color, href, wide, showSubline, variant }) => {
  const { lessThan: lt, greaterThan: gt } = useViewport();
  return (
    <Anchor
      href={href}
      lessThanLg={lt.lg}
      color={color}
      wide={wide}
      variant={variant}
    >
      <Logo />
      <TextContainer>
        <StyledText lessThanLg={lt.lg} lessThanMd={lt.md} color={color}>
          Tarifwelt
        </StyledText>
        {(gt.md || showSubline) && (
          <StyledSubText
            lessThanLg={lt.lg}
            lessThanMd={lt.md}
            color={color}
            variant={variant}
          >
            powered by S-KON
          </StyledSubText>
        )}
      </TextContainer>
    </Anchor>
  );
};

Pagelogo.propTypes = {
  color: PropTypes.oneOf(["black", "primary", "white"]),
  href: PropTypes.string.isRequired,
  wide: PropTypes.bool,
  showSubline: PropTypes.bool,
  variant: PropTypes.string,
};

Pagelogo.defaultProps = {
  wide: false,
  showSubline: false,
  color: "black",
};

export default Pagelogo;
