import React, { useState } from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import styled from "styled-components";

import { Button, Row, Col } from "@mm/ui";
import { THEME } from "@core/theme";

import { ProgressBar } from "components/atoms";

import { Loading } from "components/molecules";

import TariffOfferListItem from "../TariffOfferListItem";
import HardwareOfferListItem from "../HardwareOfferListItem";

const NoItemsBanner = styled("div")({
  color: ({ theme: { colors } }) => colors.brand.primary,
  padding: ({ theme: { space } }) => `${space.xxs}px 0`,
});

const StyledCol = styled(Col)`
  margin-bottom: 1.5em;
  display: flex !important;
`;

const ProgressBarContainer = styled(Col)({
  margin: "0 auto",
  flexDirection: "column",
});

const StyledButton = styled(Button)({
  marginTop: ({ theme: { space } }) => `${space.s}px`,
});

const OfferList = ({
  items,
  mode,
  initialItemAmount,
  onClick,
  isLoading,
  isTariff,
  activeItem,
}) => {
  const [showAllItems, setShowAllItems] = useState(false);
  const isRowMode = mode === "row";

  const grid = {
    xs: THEME.maxcols,
    sm: THEME.maxcols,
    md: isRowMode ? THEME.maxcols : THEME.maxcols / 2,
    lg: isRowMode ? THEME.maxcols : THEME.maxcols / 3,
    xl: isRowMode ? THEME.maxcols : THEME.maxcols / 3,
  };

  // limit the amount of items we can show to the length of the array "items"
  const itemAmount = Math.min(initialItemAmount, items.length);

  const visibleItems = showAllItems ? items : items.slice(0, itemAmount);

  return (
    <>
      <Row justifyContent="flex-start">
        {!items.length && (
          <Col>
            <NoItemsBanner>
              Zu Ihren Filtereinstellungen gibt es keine Angebote.
            </NoItemsBanner>
          </Col>
        )}
        {isLoading && <Loading variant="filterContent" />}
        {visibleItems.map((offer, index) => (
          <StyledCol
            // eslint-disable-next-line no-underscore-dangle
            key={`${offer.id}_${offer.idx}`}
            xs={grid.xs}
            md={grid.md}
            lg={grid.lg}
            xl={grid.xl}
          >
            {isTariff ? (
              <TariffOfferListItem
                mode={mode}
                tariff={offer}
                onClick={onClick}
                isActive={
                  // eslint-disable-next-line no-underscore-dangle
                  activeItem && offer.id === activeItem.id
                }
                qaName={`offer${index}`}
              />
            ) : (
              <HardwareOfferListItem
                mode={mode}
                offer={offer}
                onClick={onClick}
                accessories={offer.accessories || []}
                isActive={
                  activeItem && offer.defaultVariant === activeItem.ebootisId
                }
                qaName={`offer${index}`}
              />
            )}
          </StyledCol>
        ))}
      </Row>
      {!showAllItems && itemAmount !== items.length && (
        <ProgressBarContainer
          xs={grid.xs}
          md={grid.md}
          lg={grid.lg}
          xl={grid.xl}
        >
          <ProgressBar
            current={itemAmount}
            max={items.length}
            hint={`Sie haben ${itemAmount} aus ${items.length} Produkten gesehen`}
          />

          <StyledButton
            color="primary"
            size="fullWidth"
            onClick={(e) => {
              e.preventDefault();

              setShowAllItems(true);

              return false;
            }}
            data-codeception="buttonMore"
          >
            Mehr laden
          </StyledButton>
        </ProgressBarContainer>
      )}
    </>
  );
};

const itemPropType = PropTypes.shape({
  carrier: PropTypes.string,
  _id: PropTypes.string,
  ebootisId: PropTypes.string,
});

OfferList.propTypes = {
  // TODO update me
  items: PropTypes.arrayOf(itemPropType).isRequired,
  initialItemAmount: PropTypes.number,
  activeItem: itemPropType,
  mode: PropTypes.string,
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
  isTariff: PropTypes.bool,
};

OfferList.defaultProps = {
  mode: "row",
  initialItemAmount: 12,
  onClick: noop,
  isLoading: false,
  isTariff: false,
  activeItem: null,
};

export default OfferList;
