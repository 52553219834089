import { BuildingTypeSection } from "./BuildingTypeSection";
import { ConnectionOverviewSection } from "./ConnectionOverviewSection";
import { ActivationDateSection } from "./ActivationDateSection";
import { ContractSummaryApprovalSection } from "./ContractSummaryApprovalSection";
import { ESimSection } from "./ESimSection";
import { InstallationTimeWindowSection } from "./InstallationTimeWindowSection";
import { ItemisedBillingSection } from "./ItemisedBillingSection";
import { ContractSummarySection } from "./ContractSummarySection";
import { NumberPortabilityInfoSection } from "./NumberPortabilityInfoSection";
import { PhoneNumberAmountSection } from "./PhoneNumberAmountSection";
import { PersonalCustomerCodeSection } from "./PersonalCustomerCodeSection";
import { ProviderChangeSection } from "./ProviderChangeSection";
import { TariffOverviewSection as InternetTariffOverviewSection } from "./internet/TariffOverviewSection";
import { TariffOverviewSection as SimOnlyTariffOverviewSection } from "./simOnly/TariffOverviewSection";
export { ActivationDateSection as O2ActivationDateSection } from "./ActivationDateSection";
export { BuildingTypeSection as O2BuildingTypeSection } from "./BuildingTypeSection";
export { ConnectionOverviewSection as O2ConnectionOverviewSection } from "./ConnectionOverviewSection";
export { ContractSummaryApprovalSection as O2ContractSummaryApprovalSection } from "./ContractSummaryApprovalSection";
export { ContractSummarySection as O2ContractSummarySection } from "./ContractSummarySection";
export { ESimSection as O2ESimSection } from "./ESimSection";
export { InstallationTimeWindowSection as O2InstallationTimeWindowSection } from "./InstallationTimeWindowSection";
export { ItemisedBillingSection as O2ItemisedBillingSection } from "./ItemisedBillingSection";
export { NumberPortabilityInfoSection as O2NumberPortabilityInfoSection } from "./NumberPortabilityInfoSection";
export { PersonalCustomerCodeSection as O2PersonalCustomerCodeSection } from "./PersonalCustomerCodeSection";
export { PhoneNumberAmountSection as O2PhoneNumberAmountSection } from "./PhoneNumberAmountSection";
export { ProviderChangeSection as O2ProviderChangeSection } from "./ProviderChangeSection";
export { TariffOverviewSection as O2InternetTariffOverviewSection } from "./internet/TariffOverviewSection";
export { TariffOverviewSection as O2SimOnlyTariffOverviewSection } from "./simOnly/TariffOverviewSection";
export const o2SectionComponents = {
    "o2.activationDateSection": ActivationDateSection,
    "o2.buildingTypeSection": BuildingTypeSection,
    "o2.connectionOverviewSection": ConnectionOverviewSection,
    "o2.contractSummarySection": ContractSummarySection,
    "o2.contractSummaryApprovalSection": ContractSummaryApprovalSection,
    "o2.installationTimeWindowSection": InstallationTimeWindowSection,
    "o2.itemisedBillingSection": ItemisedBillingSection,
    "o2.numberPortabilityInfoSection": NumberPortabilityInfoSection,
    "o2.personalCustomerCodeSection": PersonalCustomerCodeSection,
    "o2.phoneNumberAmountSection": PhoneNumberAmountSection,
    "o2.providerChangeSection": ProviderChangeSection,
    "o2.internet.tariffOverviewSection": InternetTariffOverviewSection,
    "o2.simOnly.tariffOverviewSection": SimOnlyTariffOverviewSection,
    "o2.eSimSection": ESimSection,
};
