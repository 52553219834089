import React from "react";
import PropTypes from "prop-types";

import { Surface } from "@mm/ui";

import { ThemeProvider } from "@core/theme";

import { StandardTeaserSection } from "../../components/organisms/Teaser";

const StandardTeaser = ({ data, ...props }) => (
  <ThemeProvider>
    <Surface variant="narrow">
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <StandardTeaserSection contents={data} {...props} />
    </Surface>
  </ThemeProvider>
);
StandardTeaser.propTypes = {
  data: PropTypes.oneOfType([PropTypes.any]),
};

StandardTeaser.defaultProps = {
  data: <></>,
};

export default StandardTeaser;
