import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Modal from "react-modal";
import { noop } from "lodash";
import { THEME as theme, useViewport } from "@core/theme";

import Xsvg from "@core/svgs/icon-x.svg";

import Box from "../Box";

const OpenerButton = styled.button`
  cursor: pointer;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CloseButton = styled(Xsvg)`
  cursor: pointer;
  height: 16px;
  width: 16px;
`;

const toggleModal = (isOpen, setIsOpen, trackingObj) => {
  if (!isOpen) {
    if (trackingObj && window.dataLayer) {
      window.dataLayer.push(trackingObj);
    }
  }
  setIsOpen(!isOpen);
};

const handleKeyDown = (ev, isOpen, setIsOpen, trackingObj) => {
  ev.preventDefault();
  // trigger modal on enter key (a11y)
  if (ev.keyCode === 13) {
    toggleModal(isOpen, setIsOpen, trackingObj);
  }
};

const StyledModal = ({
  children,
  onAfterOpen,
  trackingObj,
  onRequestClose,
  size,
  dynamicHeight,
  isInitialOpen,
  opener,
  closable,
  maxHeightOffSet,
}) => {
  const viewport = useViewport();
  const [isOpen, setIsOpen] = useState(isInitialOpen);
  const contentStyles = {
    top: "0",
    left: "50%",
    padding: "16px",
    height: "100%",
    width: "100%",
    maxWidth: theme.modalSizes[size],
    transform: "translate(-50%)",
    maxHeight: "100%",
  };

  if (viewport.greaterThan.md) {
    contentStyles.maxHeight = `calc(100vh - ${maxHeightOffSet.md})`;
    contentStyles.top = "32px";
  }

  if (viewport.greaterThan.lg) {
    contentStyles.maxHeight = `calc(100vh - ${maxHeightOffSet.lg})`;
    contentStyles.top = "64px";
  }

  if (dynamicHeight) {
    contentStyles.bottom = "unset";
    contentStyles.height = "unset";
  }

  const customStyles = {
    overlay: {
      backgroundColor: "rgb(0, 0, 0, 0.4)",
      zIndex: "100",
    },
    content: contentStyles,
  };

  return (
    <Box>
      {opener && (
        <OpenerButton
          role="button"
          onClick={(e) => {
            e.stopPropagation();
            toggleModal(isOpen, setIsOpen, trackingObj);
          }}
          onKeyDown={(e) => handleKeyDown(e, isOpen, setIsOpen, trackingObj)}
        >
          {opener}
        </OpenerButton>
      )}
      {(!opener || isOpen) && (
        <>
          <Modal
            ariaHideApp={false}
            isOpen={isInitialOpen}
            style={customStyles}
            onAfterOpen={onAfterOpen}
            onRequestClose={onRequestClose}
            contentLabel="Example Modal"
          >
            <>
              {closable && (
                <ButtonWrapper>
                  <CloseButton
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleModal(isOpen, setIsOpen, trackingObj);
                      onRequestClose(e);
                    }}
                  />
                </ButtonWrapper>
              )}
              <div>{children}</div>
            </>
          </Modal>
        </>
      )}
    </Box>
  );
};

StyledModal.propTypes = {
  isInitialOpen: PropTypes.bool,
  children: PropTypes.node.isRequired,
  onAfterOpen: PropTypes.func,
  onRequestClose: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  trackingObj: PropTypes.any,
  size: PropTypes.oneOf(["sm", "md", "lg", "xl"]),
  dynamicHeight: PropTypes.bool,
  opener: PropTypes.node,
  closable: PropTypes.bool,
  maxHeightOffSet: PropTypes.shape({}),
};

StyledModal.defaultProps = {
  opener: null,
  isInitialOpen: false,
  onAfterOpen: noop,
  size: "lg",
  dynamicHeight: false,
  trackingObj: {},
  closable: true,
  maxHeightOffSet: {
    md: "64px",
    lg: "128px",
  },
};

export default StyledModal;
