import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Combobox } from "@headlessui/react";
import styled from "styled-components";
import classNames from "classnames";
// @ts-ignore
import Flex from "../../Flex";
// @ts-ignore
import Label from "../Label";
const Wrapper = styled(Flex) `
  position: relative;
  min-width: ${({ value }) => (value ? "20px" : "auto")};
  min-height: 56px;
  border-bottom: 2px solid;
  border-bottom-color: ${({ theme: { colors }, value }) => value ? colors.grey[5] : colors.grey[2]};
  outline: none;

  &:not(.disabled) {
    &:hover,
    &:focus,
    &:active {
      border-bottom-color: ${({ theme }) => theme.colors.grey[5]};

      svg {
        fill: ${({ theme }) => theme.colors.grey[5]};
      }
    }

    &:hover,
    &:focus,
    &:focus-within {
      label {
        top: 0;
        font-size: ${({ theme }) => theme.typography.size.s}px;
        line-height: 1;
        margin-top: ${({ theme }) => theme.space.xs}px;
        z-index: 2;
      }
    }
  }

  svg {
    fill: ${({ theme: { colors }, value }) => value ? colors.grey[5] : colors.grey[2]};
  }

  ${(props) => {
    switch (props.layout) {
        case "error":
            return `
                    border-bottom-color: ${props.theme.colors.brand.primary} !important;
                `;
        case "disabled":
            return `
                    border-bottom-color: #cdcaca;
                `;
        default:
            return ``;
    }
}}
`;
const Message = styled("div") `
  font-size: ${({ theme }) => theme.typography.size.s}px;
  line-height: 1;
  color: ${({ theme }) => theme.colors.grey[4]};
  margin-top: ${({ theme }) => theme.space.xs}px;
  margin-bottom: ${({ theme }) => theme.space.xs}px;

  ${(props) => {
    switch (props.layout) {
        case "error":
            return `
                    color: ${props.theme.colors.brand.primary};
                `;
        default:
            return ``;
    }
}}
`;
const Input = styled(Combobox.Input) `
  border: 0;
  font-size: ${({ theme }) => theme.typography.size.m}px;
  line-height: 2;
  color: inherit;
  width: 100%;
  min-width: 20px;
  background: transparent;
  outline: none;
  padding-right: 21px;
`;
const Options = styled(Combobox.Options) `
  position: absolute;
  top: 3.5rem;
  list-style: none;
  padding: 0;
  background: #fff;
  z-index: 10;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;

  li:first-of-type {
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
  }

  li:last-of-type {
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
  }
`;
const OptionItem = styled(Combobox.Option) `
  padding: 0.5rem 1rem;
  background-color: #fff;

  &:hover,
  &[data-headlessui-state="active"] {
    background-color: #f0f0f0;
  }

  &[aria-selected="true"] {
    background-color: #bad9f1 !important;
  }

  &#combobox-more:hover,
  &#combobox-more[data-headless-ui-state="active"],
  &#combobox-more[aria-selected="true"] {
    background-color: #fff;
  }
`;
/**
 * TODO:
 * - [ ] Allow custom values? how do we handle cases where klicktel does not have the street in their data? (!!!)
 */
export const InputComboBox = ({ className = "", disabled = false, id, items = [], label, layout = "normal", message, onBlur, onChange, onClick, onFocus, onKeyPress, qaName, value, width = "100%", }) => {
    const [selectedValue, setSelectedValue] = useState(value);
    const [visible, setVisible] = useState(false);
    const [query, setQuery] = useState("");
    const filteredItems = query === ""
        ? items
        : items.filter((item) => item.toLowerCase().includes(query.toLowerCase()));
    useEffect(() => {
        setQuery("");
        setSelectedValue(value);
    }, [value]);
    const handleFocus = (event) => {
        if (event.relatedTarget) {
            setVisible(true);
            onFocus && onFocus(event.currentTarget.value);
        }
    };
    const handleClick = (event) => {
        if (event.currentTarget) {
            setVisible(true);
            onClick && onClick(event.currentTarget.value);
        }
    };
    const handleBlur = (event) => {
        if (event.relatedTarget?.id.includes("headlessui-combobox-option")) {
            return;
        }
        onBlur && onBlur(event.currentTarget.value);
        setVisible(false);
    };
    useEffect(() => {
        setQuery("");
        if (items?.length === 1) {
            setSelectedValue(items[0]);
            setVisible(false);
            onChange && onChange(items[0]);
        }
    }, [items]);
    return (_jsxs("div", { "data-tk-cobrowse-hidden": "true", children: [_jsxs(Wrapper, { className: classNames(disabled ? "disabled" : "", className), justifyContent: "space-between", flexWrap: "wrap", alignContent: "flex-end", layout: layout, value: value, width: width, children: [_jsx(Label, { htmlFor: id, text: label, size: selectedValue && "small", layout: layout }), _jsx(_Fragment, { children: _jsx(Combobox, { disabled: disabled, value: selectedValue, onChange: (value) => {
                                setSelectedValue(value);
                                setVisible(false);
                                onChange && onChange(value);
                            }, children: ({ open }) => (_jsxs(_Fragment, { children: [_jsx(Input, { id: id, "data-testid": id, "data-qa": qaName, onBlur: handleBlur, onChange: (event) => {
                                            setQuery(event.target.value);
                                            setVisible(false);
                                        }, onClick: handleClick, onFocus: handleFocus, onKeyPress: (event) => onKeyPress && onKeyPress(event.currentTarget.value) }), (open || visible) && !disabled && (_jsxs(Options, { id: `${id}-listbox`, static: true, children: [filteredItems.slice(0, 10).map((item) => (_jsx(OptionItem, { value: item, onClick: () => setVisible(false), children: item }, item))), filteredItems.length > 10 && (_jsx(OptionItem, { id: "combobox-more", value: "...", disabled: true, children: "..." }, "more"))] }))] })) }) })] }), message && _jsx(Message, { layout: "error", children: message })] }));
};
export default InputComboBox;
