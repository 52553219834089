import React, { Children, cloneElement } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Flex } from "@mm/ui";
import noop from "lodash/noop";

export const Button = styled.button`
  border-width: 1px;
  border-radius: 0;
  border-right-width: 0;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.grey[2]};
  background: ${({ theme }) => theme.colors.white};
  flex: 1;
  ${({ theme, selected }) => `
    padding: ${theme.padding[3]};
    color: ${theme.colors.grey[4]};

    ${
      selected &&
      `
      color: ${theme.colors.grey[0]};
      font-weight: bold;
      border-color: ${theme.colors.primary[0]};
      border-width: 2px;
      border-top-left-radius: ${theme.borderRadius[0]};
      border-bottom-left-radius: ${theme.borderRadius[0]};
    `
    };
  `};

  &:nth-of-type(1) {
    border-top-left-radius: ${({ theme }) => theme.borderRadius[0]};
    border-bottom-left-radius: ${({ theme }) => theme.borderRadius[0]};
  }

  &:last-of-type {
    border-top-right-radius: ${({ theme }) => theme.borderRadius[0]};
    border-bottom-right-radius: ${({ theme }) => theme.borderRadius[0]};
    border-right-width: 1px;
  }
`;

Button.propTypes = {
  children: PropTypes.string.isRequired,
};

const extendChildren = (children, onChange, value) =>
  Children.map(children, (child) =>
    cloneElement(child, {
      selected: child.props.value === value,
      onClick: () => onChange(child.props.value),
    })
  );

export const ButtonGroup = ({
  children,
  className,
  onChange,
  value,
  ...props
}) => (
  // we spread props here in order
  // to make the styled-variant composition properties (space etc..) available to the outside
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Flex {...props} className={className}>
    {extendChildren(children, onChange, value)}
  </Flex>
);

ButtonGroup.defaultProps = {
  className: "",
  value: null,
  onChange: noop,
};

ButtonGroup.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node.isRequired),
    PropTypes.node,
  ]).isRequired,
};

export default ButtonGroup;
