import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { compose, space } from "styled-system";

const Headline = styled("div")(
  {
    fontWeight: (props) => props.weight,
    fontSize: (props) => props.size,
    padding: 0,
    margin: 0,
  },
  compose(space)
);

const HeadlineSourceSansPro = (props) => {
  const { type, text } = props;
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Headline {...props} as={type}>
      {text}
    </Headline>
  );
};

HeadlineSourceSansPro.defaultProps = {
  type: "h1",
  text: "Das ist eine Headline",
  size: "20px",
  weight: "400",
};

HeadlineSourceSansPro.propTypes = {
  type: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "h6"]),
  text: PropTypes.string,
  size: PropTypes.string,
  weight: PropTypes.string,
};

export default HeadlineSourceSansPro;
