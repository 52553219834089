import React, { useState, useCallback, createContext, useContext } from "react";
import PropTypes from "prop-types";
import { debounce } from "lodash";

import { breakpoints } from "./theme";

const generateViewportSettings = (innerWidth, innerHeight) => {
  // eslint-disable-next-line no-restricted-globals
  const breakpointNames = Object.keys(breakpoints).filter((v) => isNaN(v));
  const firstBreakpoint = breakpointNames[0];
  const lastBreakpoint = breakpointNames[breakpointNames.length - 1];

  let currentBreakpoint = null;
  const viewportObj = {
    currentWidth: innerWidth,
    currentHeight: innerHeight,
    orientation: innerHeight >= innerWidth ? "portrait" : "landscape",
    breakpoints: {},
    greaterThan: {},
    is: {},
    lessThan: {},
  };

  const isGreaterThan = (breakpointName) =>
    innerWidth >= parseInt(breakpoints[breakpointName], 10);
  const isLessThan = (breakpointName) =>
    innerWidth < parseInt(breakpoints[breakpointName], 10);

  if (isLessThan(firstBreakpoint)) {
    currentBreakpoint = firstBreakpoint;
  } else if (isGreaterThan(lastBreakpoint)) {
    currentBreakpoint = lastBreakpoint;
  }

  breakpointNames.forEach((bp, bpIdx) => {
    const nextBreakpoint = breakpointNames[bpIdx + 1] || 0;

    // Set current breakpoint
    if (!currentBreakpoint && isGreaterThan(bp) && isLessThan(nextBreakpoint)) {
      currentBreakpoint = bp;
    }

    // Now fill greaterThan, lessThan, is
    viewportObj.greaterThan[bp] = isGreaterThan(bp);
    viewportObj.lessThan[bp] = isLessThan(bp);
    viewportObj.is[bp] = currentBreakpoint === bp;
    viewportObj.breakpoints[bp] = breakpoints[bp];
  });

  return {
    ...viewportObj,
    is: {
      ...viewportObj.is,
      mobile: viewportObj.is.xs || viewportObj.is.sm,
      tablet: viewportObj.is.md,
      desktop: viewportObj.is.lg || viewportObj.is.xl,
    },
    mediaType: currentBreakpoint,
    touchDevice: "ontouchstart" in document.documentElement,
  };
};

export const ViewportContext = createContext(
  generateViewportSettings(window.innerWidth, window.innerHeight)
);

export const ViewportProvider = ({ children }) => {
  const [viewportData, setViewportData] = useState(
    generateViewportSettings(window.innerWidth, window.innerHeight)
  );

  const refreshViewportData = useCallback(
    debounce((innerWidth, innerHeight) => {
      setViewportData(generateViewportSettings(innerWidth, innerHeight));
    }, 50),
    [setViewportData]
  );

  // Add listener
  window.addEventListener("resize", (event) => {
    refreshViewportData(event.target.innerWidth, event.target.innerHeight);
  });

  return (
    <ViewportContext.Provider value={viewportData}>
      {children}
    </ViewportContext.Provider>
  );
};

export const useViewport = () => useContext(ViewportContext);

ViewportProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
