import styled from "styled-components";
import PropTypes from "prop-types";

export const List = styled.ul`
  padding: 0 ${({ theme }) => theme.space.xl}px;
`;

List.propTypes = {
  children: PropTypes.node.isRequired,
};

export default List;
