import React from "react";
import { Image, Surface } from "@mm/ui";

import styled from "styled-components";

import { AdTeaser } from "components/organisms";
import dataLayer from "helper/dataLayer";
import { buildImageUrl } from "utils/productImage";

const PdpBannerImage = styled(Image)({
  height: "auto",
  width: "100%",
});

const trackingPush = () =>
  dataLayer({
    eventCategory: "Teaser",
    eventAction: "click",
    eventLabel: `Variante_Displayschutz`,
  });

const DisplayProtectionSection = ({ pdpLink, position }) => {
  const chooseTeaserImage = (link, pos) => {
    const chooseImages = link.includes("-mit-displayschutz");

    const imgWithProtection = {
      xlUrl: "/img/teaser/mm_displayschutz_liste/1104x236.png",
      lgUrl: "/img/teaser/mm_displayschutz_liste/1104x196.png",
      mdUrl: "/img/teaser/mm_displayschutz_liste/946x254.png",
      smUrl: "/img/teaser/mm_displayschutz_liste/700x251.png",
      xsUrl: "/img/teaser/mm_displayschutz_liste/478x249.png",
    };

    const imgWithProtectionTop = {
      xlUrl: "/img/teaser/mm_displayschutz_info_liste/1104x236.png",
      lgUrl: "/img/teaser/mm_displayschutz_info_liste/1104x196.png",
      mdUrl: "/img/teaser/mm_displayschutz_info_liste/946x254.png",
      smUrl: "/img/teaser/mm_displayschutz_info_liste/700x251.png",
      xsUrl: "/img/teaser/mm_displayschutz_info_liste/478x249.png",
    };

    const imgWithOutProtection = {
      xlUrl: "/img/teaser/mm_ohne_displayschutz_liste/1104x236.png",
      lgUrl: "/img/teaser/mm_ohne_displayschutz_liste/1104x196.png",
      mdUrl: "/img/teaser/mm_ohne_displayschutz_liste/946x254.png",
      smUrl: "/img/teaser/mm_ohne_displayschutz_liste/700x251.png",
      xsUrl: "/img/teaser/mm_ohne_displayschutz_liste/478x249.png",
    };

    if (!chooseImages && pos === "top") {
      return imgWithProtectionTop;
    }
    if (chooseImages) {
      return imgWithProtection;
    }
    return imgWithOutProtection;
  };

  const images = chooseTeaserImage(pdpLink, position);

  const imageUrl = buildImageUrl(images);

  const image = () => {
    if (!pdpLink.includes("-mit-displayschutz") && position === "top") {
      return <PdpBannerImage src={imageUrl} />;
    }
    return (
      <AdTeaser
        type="ImageOnlyAdTeaser"
        onClick={trackingPush}
        content={{
          headlineText: "Haben Sie Fragen zu diesem Angebot?",
          linkUrl: pdpLink,
          images: chooseTeaserImage(pdpLink, position),
        }}
      />
    );
  };

  return (
    <Surface variant="narrow" mt={[4, 4, 4, 6, 6]}>
      {image()}
    </Surface>
  );
};

export default DisplayProtectionSection;
