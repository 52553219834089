export const DATA_SECURITY_MODAL = "datasecurity";
export const DATA_SECURITY_COOKIES_MODAL = "datasecurity-cookies";
export const TERMS_MODAL = "terms";
export const TERMS_TELEFONICA_MODAL = "terms-telefonica";
export const TERMS_MOBILCOM_MODAL = "terms-mobilcom";
export const TERMS_WEBID_MODAL = "terms-webid";
export const TERMS_SKY_MODAL = "terms-sky";
export const TERMS_VODAFONE_MODAL = "terms-vodafone";
export const TERMS_KLARMOBIL_MODAL = "terms-klarmobil";
export const TERMS_1UND1_MODAL = "terms-1und1";
export const TERMS_UNITYMEDIA_MODAL = "terms-unitymedia";
export const IMPRINT_MODAL = "imprint";
export const WITHDRAWAL_MODAL = "withdrawal";
export const PAYMENT_METHODS_MODAL = "paymentMethods";
