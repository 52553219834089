import React from "react";

import { ThemeProvider } from "@core/theme";

import { Surface } from "@mm/ui";

import { CopyImg, Loading } from "components/molecules";

const CopyImgSection = ({ data }) => {
  if (!data) return <Loading variant="manufacturer" />;
  return (
    <ThemeProvider>
      <Surface variant="narrow">
        <CopyImg
          title={data.title}
          subtitle={data.subtitle}
          content={data.content}
          fatheadline={data.fatheadline}
          image={data.image}
          imgPosition={data.imgPosition}
        />
      </Surface>
    </ThemeProvider>
  );
};

export default CopyImgSection;
