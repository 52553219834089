import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { noop } from "lodash";
import { variant } from "styled-system";

import TileBox from "../TileBox";

const StyledTileBox = styled(TileBox)`
  flex-basis: calc(50% - 24px);
  flex-grow: 1;
  padding: 0;

  @media (min-width: ${({ theme }) => theme.breakpointsMap.md.min}px) {
    flex-basis: calc(33.33% - 24px);
  }
  ${variant({
    variants: {
      navMobileManufacturerSection: {
        borderRadius: "0px",
        minWidth: "6rem",
        height: "3.5rem",
      },
      navDesktopManufacturerSection: {
        minWidth: "7.7rem",
        height: "3.5rem",
        borderRadius: "0px",
      },
      navMobileProviderSection: {
        borderRadius: "0px",
      },
      navDesktopProviderSection: {
        borderRadius: "0px",
      },
    },
  })}
`;

const Link = styled.a`
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;

  ${variant({
    variants: {
      navMobileManufacturerSection: {
        height: "3.5rem",
      },
      navDesktopManufacturerSection: {
        height: "3.5rem",
      },
      navMobileProviderSection: {
        height: "3.5rem",
      },
      navDesktopProviderSection: {
        height: "3.5rem",
      },
    },
  })}

  > svg {
    flex: 0.4 0;
    max-height: 50px;
    ${variant({
      variants: {
        navMobileManufacturerSection: {
          flex: "1 0",
          maxHeight: "30px",
          maxWidth: "95px",
          filter: "saturate(0%) brightness(70%) contrast(1000%)",
        },
        navDesktopManufacturerSection: {
          flex: "1 0",
          maxHeight: "30px",
          maxWidth: "95px",
          filter: "saturate(0%) brightness(70%) contrast(1000%)",
        },
      },
    })}
    > path {
      ${variant({
        variants: {
          navMobileManufacturerSection: {
            fill: "black",
          },
          navDesktopManufacturerSection: {
            fill: "black",
          },
        },
      })}
    }
  }

  &.telekom {
    background-color: #e2007a;
  }
  &.vodafone {
    background-color: #ed1c24;
  }
  &.o2 {
    background-color: #0e1f7f;
  }
  &.freenet {
    background-color: #21314d;
  }
  &.superselect {
    background-color: #ffffff;
  }
`;

const handleClick = (link, onClick) => {
  if (onClick) onClick(); // for tracking handling etc..
  window.location.href = link;
};

const ManufacturerTile = ({ link, onClick, children, variant, className }) => {
  return (
    <StyledTileBox variant={variant}>
      <Link
        href={link}
        onClick={() => handleClick(link, onClick)}
        variant={variant}
        className={className}
      >
        {children}
      </Link>
    </StyledTileBox>
  );
};

ManufacturerTile.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  link: PropTypes.string.isRequired,
};

ManufacturerTile.defaultProps = {
  onClick: noop,
};

export default ManufacturerTile;
