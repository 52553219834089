import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  variant as variants,
  space,
  layout,
  compose,
  flexbox,
} from "styled-system";

const SurfaceComp = styled("div")(
  {
    paddingLeft: ({ theme }) => `${theme.space.m}px`,
    paddingRight: ({ theme }) => `${theme.space.m}px`,
    margin: "0 auto",
    width: "100%",
  },
  variants({
    scale: "pages",
    variants: {
      wide: {},
      narrow: {},
      ultra: {},
    },
  }),
  variants({
    prop: "shape",
    variants: {
      sharp: {
        borderRadius: "0px",
      },
      roundedSmall: {
        borderRadius: "4px",
      },
      roundedBig: {
        borderRadius: "8px",
      },
    },
  }),
  variants({
    prop: "elevation",
    variants: {
      0: {
        borderWidth: 0,
      },
      1: {
        border: ({ colors: { shade } }) => `solid 1px ${shade[2]}`,
      },
      2: {
        borderWidth: 0,
        boxShadow: ({ shadows }) => shadows.elevation2,
      },
      3: {
        borderWidth: 0,
        boxShadow: ({ shadows }) => shadows.elevation3,
      },
      4: {
        borderWidth: 0,
        boxShadow: ({ shadows }) => shadows.elevation4,
      },
    },
  }),
  variants({
    prop: "color",
    variants: {
      transparent: {
        backgroundColor: "transparent",
      },
      plain: {
        backgroundColor: ({ colors: { shade } }) => `${shade[0]}`,
      },
      grey: {
        backgroundColor: ({ colors: { shade } }) => `${shade[2]}`,
      },
      black: {
        backgroundColor: ({ colors: { grey } }) => `${grey[grey.length - 2]}`,
      },
      selected: {
        backgroundColor: ({ colors: { shade } }) => `${shade[1]}`,
      },
    },
  }),
  compose(layout, space, flexbox)
);

const Surface = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <SurfaceComp {...props} />
);

Surface.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(["wide", "narrow", "ultra"]),
  shape: PropTypes.oneOf(["sharp", "roundedSmall", "roundedBig"]),
  elevation: PropTypes.oneOf([0, 1, 2, 3, 4]),
  color: PropTypes.oneOf(["transparent", "plain", "grey", "black", "selected"]),
};

Surface.defaultProps = {
  variant: "wide",
  shape: "sharp",
  elevation: 0,
  color: "transparent",
};

export default Surface;
