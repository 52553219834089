import React from "react";

import { ThemeProvider } from "@core/theme";
import { IntlProvider, messages } from "@core/text";

import { PageHeader } from "components/organisms";

const WithThemeProvider = (props) => (
  <ThemeProvider>
    <IntlProvider locale="de" messages={messages}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <PageHeader {...props} />
    </IntlProvider>
  </ThemeProvider>
);
export default WithThemeProvider;
