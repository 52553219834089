import React from "react";
import PropTypes from "prop-types";

import { ThemeProvider } from "@core/theme";
import dataLayer from "@/helper/dataLayer";

import { Surface } from "@mm/ui";

import { ManufacturerTileContainer } from "components/molecules";

const WithThemeProvider = ({ data }) => (
  <ThemeProvider>
    <Surface variant="narrow">
      <ManufacturerTileContainer
        manufacturers={data}
        variant="tile"
        handleManufacturerClick={(index, name) => {
          dataLayer({
            eventCategory: "Markenlogo",
            eventAction: "click",
            eventLabel: `${name}:${index}`,
          });
        }}
      />
    </Surface>
  </ThemeProvider>
);

WithThemeProvider.propTypes = {
  data: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default WithThemeProvider;
