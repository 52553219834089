import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import TileBox from "../TileBox";
import { Image } from "@mm/ui";

const StyledTileBox = styled(TileBox)`
  flex-basis: calc(50% - 24px);
  flex-grow: 1;
  padding: 0;
  overflow: hidden;
  @media (min-width: ${({ theme }) => theme.breakpointsMap.md.min}px) {
    flex-basis: calc(33.33% - 24px);
  }
`;

const Link = styled.a`
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  background-color: ${({ provider }) => {
    switch (provider) {
      case "mobilcom-debitel":
        return "#21314d";

      case "waipu":
        return "#000000";

      default:
        return "transparent";
    }
  }};

  > img {
    max-height: ${(props) => (props.provider === "e.on" ? "40px" : "50px")};
    max-width: 185px;
    margin: 0 20px;
  }
`;

const ProviderQuittingTile = ({ link, provider }) => {
  return (
    <StyledTileBox variant="tile">
      <Link href={link} target="_blank" provider={provider}>
        <Image
          src={`/svgs/provider/${provider}.svg`}
          alt={`${provider} Logo`}
          provider={provider}
        />
      </Link>
    </StyledTileBox>
  );
};

ProviderQuittingTile.propTypes = {
  link: PropTypes.string.isRequired,
  provider: PropTypes.string.isRequired,
};

export default ProviderQuittingTile;
