import React from "react";
import styled from "styled-components";
import { Surface } from "@mm/ui";

import { breakpoints } from "@core/theme/src/theme/theme";

const ImageBanner = ({ data }) => {
  const PictureWrapper = styled.div`
    img {
      display: block;
      max-width: 100%;
      margin: 0 auto;
    }
  `;

  return (
    <Surface variant="narrow">
      <PictureWrapper>
        <picture>
          <source
            srcSet={window.CDN_URL + data.images.xlUrl}
            media={`(min-width: ${breakpoints.xl})`}
          />
          <source
            srcSet={window.CDN_URL + data.images.lgUrl}
            media={`(min-width: ${breakpoints.lg})`}
          />
          <source
            srcSet={window.CDN_URL + data.images.mdUrl}
            media={`(min-width: ${breakpoints.md})`}
          />
          <source
            srcSet={window.CDN_URL + data.images.smUrl}
            media={`(min-width: ${breakpoints.sm})`}
          />
          <source
            srcSet={window.CDN_URL + data.images.xsUrl}
            media={`(min-width: ${breakpoints.xs})`}
          />
          <img
            alt={data.imageAltText}
            src={window.CDN_URL + data.images.lgUrl}
          />
        </picture>
      </PictureWrapper>
    </Surface>
  );
};

export default ImageBanner;
