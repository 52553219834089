import React from "react";
import PropTypes from "prop-types";
import { chunk, noop } from "lodash";
import styled from "styled-components";

import { Slider } from "components/molecules";

const ThumbnailSliderContainer = styled("div")({
  textAlign: "center",
});

const ThumbnailImageContainer = styled("a")({
  padding: ({ theme: { space } }) => `${space.s}px`,
  border: ({ isActive, theme: { colors } }) =>
    `1px solid ${isActive ? colors.primary[0] : colors.shade[2]}`,
  borderRadius: ({ theme: { space } }) => `${space.xxs}px`,
  backgroundColor: ({ theme: { colors } }) => colors.white,
  display: "inline-block",
  width: "62px",
  height: "62px",
  margin: ({ theme: { space } }) => `0 ${space.xxs}px`,
  textAlign: "center",
  cursor: "pointer",
});

const ThumbnailImage = styled("img")({
  maxWidth: "36px",
  maxHeight: "36px",
  margin: "0 auto",
});

export const ThumbnailSlider = ({
  images,
  amountPerPage,
  selectedItem,
  onClickThumbnail,
}) => {
  const preparedImages = chunk(images, amountPerPage);

  return (
    <ThumbnailSliderContainer>
      <Slider
        sliderSettings={{
          selectedItem: Math.floor(selectedItem / amountPerPage),
        }}
      >
        {preparedImages.map((chunkedImages, chunkIdx) => (
          <div key={`ThumbnailSlider_${chunkIdx}`}>
            {chunkedImages.map((image, imageIdx) => {
              const imageType = Object.keys(image)[0];
              return (
                <ThumbnailImageContainer
                  key={image[imageType].small}
                  onClick={() => {
                    onClickThumbnail(chunkIdx * amountPerPage + imageIdx);
                  }}
                  isActive={
                    chunkIdx === Math.floor(selectedItem / amountPerPage) &&
                    imageIdx === selectedItem - chunkIdx * amountPerPage
                  }
                  data-codeception={`devicePreview${imageIdx}`}
                >
                  <ThumbnailImage
                    src={window.CDN_URL + image[imageType].small}
                    alt={`Miniaturansicht ${imageIdx + 1}`}
                  />
                </ThumbnailImageContainer>
              );
            })}
          </div>
        ))}
      </Slider>
    </ThumbnailSliderContainer>
  );
};

ThumbnailSlider.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
  amountPerPage: PropTypes.number,
  selectedItem: PropTypes.number,
  onClickThumbnail: PropTypes.func,
};

ThumbnailSlider.defaultProps = {
  amountPerPage: 3,
  selectedItem: 0,
  onClickThumbnail: noop,
};

export default ThumbnailSlider;
