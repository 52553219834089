import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Text } from "@mm/ui";

import CheckmarkSvg from "@core/svgs/checkmark.svg";

import { formatTariffText } from "utils/tariffUtils";

const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const Item = styled.li`
  align-items: flex-start;
  display: flex;

  font-size: ${(props) => {
    switch (props.size) {
      case "s":
        return "12px";
      case "m":
        return "14px";
      case "l":
      default:
        return "16px";
    }
  }};
  line-height: ${(props) => {
    switch (props.size) {
      case "s":
        return "1.3";
      case "m":
        return "1.43";
      case "l":
      default:
        return "1.5";
    }
  }};
  margin-bottom: 0.5em;

  ${({ variant, theme }) =>
    variant !== "checkmark"
      ? `
        margin-left: -0.4em;
        &:before {
            display: block;
            content: "";
            width: 4px;
            height: 4px;
            border-radius: 2px;
            background-color: ${theme.colors.grey[0]};
            margin: auto 0.5em;
            flex-shrink: 0;
        }
    `
      : ""}

  svg {
    height: ${(props) => {
      switch (props.size) {
        case "s":
          return "16px";
        case "m":
        case "l":
        default:
          return "20px";
      }
    }};
    flex-shrink: 0;
    margin: 0.1em 5px 0 0;
    width: ${(props) => {
      switch (props.size) {
        case "s":
          return "16px";
        case "m":
        case "l":
        default:
          return "20px";
      }
    }};
  }
`;

const Bulletlist = ({ list, variant, size, listLength }) => {
  if (!list) return null;

  const sliceLimit = listLength > 0 ? listLength : list.length;

  return (
    <List>
      {list.slice(0, sliceLimit).map((item) => {
        const key = typeof item === "string" ? item : item.props.id;
        return (
          <Item key={key} variant={variant} size={size}>
            {variant === "checkmark" && <CheckmarkSvg />}
            {/* local shop translations are not text but react components */}
            {typeof item === "string" ? (
              <Text
                dangerouslySetInnerHTML={{
                  __html: formatTariffText(item),
                }}
              />
            ) : (
              item
            )}
          </Item>
        );
      })}
    </List>
  );
};

Bulletlist.propTypes = {
  list: PropTypes.arrayOf(PropTypes.node).isRequired,
  listLength: PropTypes.number,
  variant: PropTypes.oneOf(["default", "checkmark"]),
  size: PropTypes.oneOf(["s", "m", "l"]),
};

Bulletlist.defaultProps = {
  listLength: 0,
  variant: "default",
  size: "l",
};

export default Bulletlist;
