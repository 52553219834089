import styled from "styled-components";

import InlineLink from ".";

const SmallInlineLink = styled(InlineLink)`
  font-size: ${({ theme }) => theme.legacyFontSize[2]};
  color: ${({ theme }) => theme.colors.grey[4]};
`;

SmallInlineLink.propTypes = InlineLink.propTypes;

export default SmallInlineLink;
