import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { compose, space } from "styled-system";

const Headline = styled("div")(
  {
    color: ({ color, theme }) =>
      (color === "brand" && theme.colors.brand.primary) || "black",
    fontFamily: ({ font, theme }) =>
      (font === "text" && theme.fonts.text) || theme.fonts.headline,
    fontSize: (props) => `${props.size}`,
    fontWeight: (props) => props.weight,
    padding: 0,
    margin: 0,
  },
  compose(space)
);

const SectionHeadline = (props) => {
  const { type, text, size } = props;
  return (
    // props-spreading is necessary for space functionality
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Headline {...props} as={type} size={size}>
      {text}
    </Headline>
  );
};

SectionHeadline.defaultProps = {
  color: "black",
  font: "brand",
  type: "h1",
  text: "Das ist eine Headline",
  size: "32px",
  weight: "600",
};

SectionHeadline.propTypes = {
  color: PropTypes.oneOf(["black", "brand"]),
  font: PropTypes.oneOf(["text", "brand"]),
  type: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "h6"]),
  text: PropTypes.string,
  size: PropTypes.oneOf(["40px", "32px", "24px", "20px", "18px", "16px"]),
  weight: PropTypes.oneOf(["600", "400"]),
};

export default SectionHeadline;
