import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { manufacturerPropTypes } from "sharedPropTypes";

import Manufacturers from "helper/manufacturers";

const Frame = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.space.xxs}px;
  box-shadow: ${({ theme }) => theme.shadows.elevation2};

  svg {
    display: block;
    height: ${({ size }) => {
      switch (size) {
        case "l":
          return "50px";
        case "m":
          return "40px";
        case "s":
        default:
          return "30px";
      }
    }};

    width: auto;
    max-width: 205px;

    padding: ${({ size }) => {
      switch (size) {
        case "l":
          return "12px 32px";
        case "m":
          return "8px 24px";
        case "s":
        default:
          return "4px 16px";
      }
    }};
  }
`;

const getLogo = (manufacturer) => {
  switch (manufacturer) {
    case "apple":
      return Manufacturers.apple.logo;
    case "google":
      return Manufacturers.google.logo;
    case "honor":
      return Manufacturers.honor.logo;
    case "huawei":
      return Manufacturers.huawei.logo;
    case "lg":
      return Manufacturers.lg.logo;
    case "motorola":
      return Manufacturers.motorola.logo;
    case "oppo":
      return Manufacturers.oppo.logo;
    case "samsung":
      return Manufacturers.samsung.logo;
    case "xiaomi":
      return Manufacturers.xiaomi.logo;
    case "sony":
      return Manufacturers.sony.logo;
    case "oneplus":
      return Manufacturers.oneplus.logo;
    case "realme":
      return Manufacturers.realme.logo;
    case "soflow":
      return Manufacturers.soflow.logo;
    case "nintendo":
      return Manufacturers.nintendo.logo;
    case "fitbit":
      return Manufacturers.fitbit.logo;
    case "ryze":
      return Manufacturers.ryze.logo;
    case "babyliss":
      return Manufacturers.babyliss.logo;
    case "bose":
      return Manufacturers.bose.logo;
    case "enders":
      return Manufacturers.enders.logo;
    case "realpower":
      return Manufacturers.realpower.logo;
    case "rollei":
      return Manufacturers.rollei.logo;
    case "corn-technology":
      return Manufacturers.corntechnology.logo;
    case "pioneer":
      return Manufacturers.pioneer.logo;
    case "xplora":
      return Manufacturers.xplora.logo;
    case "rephone":
      return Manufacturers.rephone.logo;
    case "nothing":
      return Manufacturers.nothing.logo;
    case "kfa2":
      return Manufacturers.kfa2.logo;
    case "avm":
      return Manufacturers.avm.logo;
    case "renewd":
      return Manufacturers.renewd.logo;
    case "microsoft":
      return Manufacturers.microsoft.logo;
    default:
      return null;
  }
};

const ManufacturerLogo = ({ framed, manufacturer, size }) => {
  const logo = getLogo(manufacturer.toLowerCase());
  return framed ? <Frame size={size}>{logo}</Frame> : logo;
};

ManufacturerLogo.propTypes = {
  framed: PropTypes.bool,
  manufacturer: manufacturerPropTypes.isRequired,
  size: PropTypes.oneOf(["s", "m", "l"]).isRequired,
};

ManufacturerLogo.defaultProps = {
  framed: false,
};

export default ManufacturerLogo;
