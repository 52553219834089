import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Text } from "@mm/ui";

const BreadcrumbContainer = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;

  &:after {
    clear: both;
  }

  @media (max-width: ${({ theme }) => theme.breakpointsMap.md.max}px) {
    margin-bottom: ${({ theme, isListingPage }) =>
      isListingPage && `${theme.space.m}px`};
  }
`;

const BreadcrumbItem = styled.li`
  margin: 0;
  padding: 0;
  flex-shrink: 0;

  @media (max-width: ${({ theme }) => theme.breakpointsMap.md.max}px) {
    display: none;

    /* On mobile viewport: ProductDetailsPage shows the second to last item, ListingPage shows the last one */

    ${({ isProductDetailInterface }) =>
      isProductDetailInterface
        ? `
            :nth-last-of-type(2) {
                display: inline-block;

                i {
                    margin-left: 0;
                }
            }

        `
        : `
            :last-of-type {
                display: inline-block;

                i:nth-child(2) {
                    margin-left: 0;
                }
            }
        `}
  }
`;
const BreadcrumbItemLink = styled.a`
  text-decoration: none;
  color: inherit;
  font-size: 16px;
`;
const BreadcrumbHomeIcon = styled.svg`
  width: 16px;
  height: 16px;

  > path {
    fill: #000000;
  }

  @media (max-width: ${(props) => props.theme.breakpointsMap.md.max}px) {
    display: none;
  }
`;
const BreadcrumbItemIcon = styled.i`
  position: relative;
  top: -2px;
  font-size: 8px;
  display: inline-block;
  margin: 0 16px;
`;
const BreadcrumbItemIconMobile = styled(BreadcrumbItemIcon)`
  @media (min-width: ${(props) => props.theme.breakpointsMap.md.max}px) {
    display: none;
  }
`;
const BreadcrumbItemIconDesktop = styled(BreadcrumbItemIcon)`
  @media (max-width: ${(props) => props.theme.breakpointsMap.md.max}px) {
    display: none;
  }
`;

const Breadcrumb = ({
  items,
  hideHomeItem,
  isListingPage,
  isProductDetailInterface,
}) => {
  const linkedItems = items.map(({ text, link, url }) => ({
    label: text,
    link: link ? `/${link}` : url,
  }));

  const preparedItems = hideHomeItem
    ? linkedItems
    : [
        {
          label: (
            <BreadcrumbHomeIcon
              xmlns="http://www.w3.org/2000/svg"
              baseProfile="tiny"
              viewBox="0 0 19 15"
            >
              <path
                fill="#df0000"
                d="M16 8v7h-5v-4H8v4H3V8l6.5-4.3L16 8zm1-1L9.4 2 2 7H0l9.4-7L19 7h-2z"
                fillRule="evenodd"
              />
            </BreadcrumbHomeIcon>
          ),
          link: "/",
        },
        ...linkedItems,
      ];

  return (
    <BreadcrumbContainer
      isListingPage={isListingPage}
      isProductDetailInterface={isProductDetailInterface}
    >
      {preparedItems.map(({ link, label }, idx) => {
        const itemElemKey = `breadcrumbs-${idx}`;

        return (
          <BreadcrumbItem
            key={itemElemKey}
            isListingPage={isListingPage}
            isProductDetailInterface={isProductDetailInterface}
          >
            {idx !== 0 && (
              <>
                <BreadcrumbItemIconDesktop className="icon-MM_iconfont_ArrowR" />
                <BreadcrumbItemIconMobile className="icon-MM_iconfont_ArrowL" />
              </>
            )}

            <BreadcrumbItemLink
              href={
                // add preceding "/" to make paths relative to root dir
                link &&
                (idx === 0 ? link : `http://${window.location.host}${link}`)
              }
            >
              {typeof label === "string" ? <Text>{label}</Text> : label}
            </BreadcrumbItemLink>
          </BreadcrumbItem>
        );
      })}
    </BreadcrumbContainer>
  );
};

Breadcrumb.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.node.isRequired,
      link: PropTypes.string,
    })
  ).isRequired,
  hideHomeItem: PropTypes.bool,
  isListingPage: PropTypes.bool,
  isProductDetailInterface: PropTypes.bool,
};

Breadcrumb.defaultProps = {
  hideHomeItem: false,
  isListingPage: false,
  isProductDetailInterface: false,
};

export default React.memo(Breadcrumb);
