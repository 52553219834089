import React from "react";

import styled from "styled-components";

import NavigationButton from "../../../atoms/NavigationButton";

export const StyledTeaserCarousel = styled("div")`
  position: relative;

  .slick-arrow {
    position: absolute;
    z-index: 10;

    &.slick-disabled {
      background-color: #958f8f;
      border: solid 2px #958f8f;
    }

    &.slick-prev {
      right: 37px;
      bottom: 0;

      svg {
        right: calc(50% - 5px);
      }
    }

    &.slick-next {
      right: 0;
      bottom: 0;

      svg {
        left: calc(50% - 5px);
        transform: rotate(90deg);
      }
    }
  }

  .slick-list {
    overflow: hidden;
    padding: 0 0 53px;
    margin-left: -12px;
    margin-right: -12px;
  }

  .slick-track {
    display: flex;
  }

  .slick-slide {
    padding: 12px;
  }

  .slick-dots {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 32px;
    width: 100%;
    z-index: 1;
  }

  .control-dots {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    height: 32px;
    margin: 0;
    padding: 0;

    ::marker {
      display: none;
    }

    > li {
      display: block;
      position: relative;
      background: rgb(204, 204, 204);
      border-radius: 2px;
      width: 40px;
      height: 4px;
      cursor: pointer;

      button {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 40px;
        height: 4px;
        padding: 0;
        margin: 0;
        opacity: 0;
        border: 0;
        cursor: pointer;
      }

      &.slick-active {
        background: #000000;
        opacity: 1;
      }
    }
  }
`;

export const carouselSettings = {
  autoplay: false,
  arrows: true,
  slidesToScroll: 4,
  slidesToShow: 4,
  prevArrow: (
    <NavigationButton
      className="slick-prev slider-nav-btn"
      ariaLabel="Ein Karussell-Element zurück"
    />
  ),
  nextArrow: (
    <NavigationButton
      className="slick-next slider-nav-btn"
      ariaLabel="Ein Karussell-Element vor"
    />
  ),
  centerMode: false,
  centerPadding: 0,
  dots: true,
  // eslint-disable-next-line react/display-name
  appendDots: (dots) => (
    <div>
      <ul className="control-dots">{dots}</ul>
    </div>
  ),
  // eslint-disable-next-line react/display-name
  customPaging: (i) => (
    <button
      type="button"
      label="slick-control"
      aria-label={`gehe zu Karussell-Element ${i + 1}`}
    />
  ),
  lazyLoad: true,
  infinite: false,
  speed: 500,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 751,
      settings: {
        slidesToScroll: 1,
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 1007,
      settings: {
        slidesToScroll: 2,
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 1230,
      settings: {
        slidesToScroll: 4,
        slidesToShow: 4,
      },
    },
  ],
};
