import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import InputRange from "react-input-range";
import styled from "styled-components";
import debounce from "lodash/debounce";
import "react-input-range/lib/css/index.css";

const draggableHeight = 1.4;
const barHeight = 0.35;

const Wrapper = styled.div`
  padding: 0 1em 0 0.5em;
  .input-range {
    height: ${draggableHeight}em;
    .input-range__slider {
      background-color: ${({ theme }) => theme.colors.primary[0]};
      border: none;
      width: ${draggableHeight}em;
      height: ${draggableHeight}em;
      margin-top: -${draggableHeight / 2 + barHeight / 2}em;
    }

    .input-range__track {
      height: ${barHeight}em;

      &--active {
        background-color: ${({ theme }) => theme.colors.grey[5]};
      }
    }

    .input-range__label-container {
      display: none;
    }
  }
`;

const handleChange = (value, setValue, setDragging, fn) => {
  setDragging(true);
  setValue(value);
  fn(value);
};

const SliderFilter = ({ value, min, max, onChange, step }) => {
  const [stateValue, setValue] = useState(value);
  const [isDragging, setDragging] = useState(false);
  const fnObj = useCallback(
    debounce((val) => onChange(val) && setDragging(false), 500),
    [onChange]
  );
  useEffect(() => setValue(value), [value]);
  return (
    <Wrapper>
      <InputRange
        step={step}
        minValue={min}
        maxValue={max}
        value={!isDragging ? value : stateValue}
        onChange={(val) => handleChange(val, setValue, setDragging, fnObj)}
      />
    </Wrapper>
  );
};

SliderFilter.defaultProps = {
  step: 0.1,
};

SliderFilter.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({
      min: PropTypes.number.isRequired,
      max: PropTypes.number.isRequired,
    }),
  ]).isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  step: PropTypes.number,
};

export default SliderFilter;
export { InputRange };
