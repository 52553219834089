import React from "react";
import { Button } from "@mm/ui";

const ToTopBtn = () => (
  <Button
    color="prominent"
    position="absolute"
    right="10px"
    bottom={["90px", "180px", "180px", "124px", "90px"]}
    size="circle"
    onClick={() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }}
  >
    <i className="icon-MM_iconfont_ArrowU" />
  </Button>
);

ToTopBtn.propTypes = {};

ToTopBtn.defaultProps = {};

export default ToTopBtn;
