import { reduceArrayOfObjectsToObject } from "@/utils/reduceArrayOfObjectsToObject";
const getDefaultValuesForSections = (sections) => {
    return sections.flatMap((section) => {
        if ("sections" in section) {
            return getDefaultValuesForSections(section.sections);
        }
        if ("defaultValues" in section && section.defaultValues) {
            return [section.defaultValues];
        }
        return [];
    });
};
export const getDefaultValues = (config) => {
    if ("sections" in config) {
        return getDefaultValuesForSections(config.sections);
    }
    return config.steps.flatMap(({ sections }) => getDefaultValuesForSections(sections));
};
export const getDefaultValuesForFields = (fields, config) => {
    const defaultValues = reduceArrayOfObjectsToObject(getDefaultValues(config));
    const keys = Array.isArray(fields) ? fields : [fields];
    return keys.reduce((acc, key) => ({
        ...acc,
        [key]: defaultValues[key] ?? undefined,
    }), {});
};
export const mergeDataWithDefaultValues = (data, defaultValues) => {
    const defaultValuesObject = reduceArrayOfObjectsToObject(defaultValues);
    return {
        ...data,
        ...Object.entries(defaultValuesObject).reduce((output, [key, value]) => {
            if (!(key in data) || data[key] === "") {
                output[key] = value;
            }
            // key is present in data and is not "", so we don't overwrite with default value
            return output;
        }, {}),
    };
};
