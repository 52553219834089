import React, { useMemo } from "react";
import PropTypes from "prop-types";

import { Dropdown } from "@mm/ui";

import { map } from "lodash";

import { WithVariantColors } from "hocs/WithVariantColors";
import { colorPropTypes } from "sharedPropTypes";
import ColorRow from "components/atoms/ColorRow";

const getColorOptions = (colors) =>
  map(colors, (item) => ({
    label: (
      <ColorRow
        color={{
          name: item.name,
          value: item.color,
          count: item.stock,
          storage: item.storage,
        }}
        colorSize="small"
        hideCount
      />
    ),
    value: item.value,
  }));

const VariantsColorDropdown = ({
  className,
  activeVariantId,
  colors,
  onChange,
  label,
}) => {
  const options = useMemo(() => getColorOptions(colors), [colors]);

  return (
    <Dropdown
      className={className}
      label={label}
      options={options}
      onChange={onChange}
      selected={activeVariantId}
    />
  );
};

VariantsColorDropdown.defaultProps = {
  className: "",
  label: "Farbe",
};

VariantsColorDropdown.propTypes = {
  activeVariantId: PropTypes.string.isRequired,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  colors: PropTypes.arrayOf(colorPropTypes).isRequired,
  label: PropTypes.string,
};

export default WithVariantColors(VariantsColorDropdown);
