import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCheckout } from "../../../hooks/api";
import { OverviewGrid, CheckoutSection } from "../../../components";
import { IdentificationMode } from "@/pages/CheckoutPage/types";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const TariffOverviewSection = (props) => {
    const { data } = useCheckout();
    return (_jsx(CheckoutSection, { children: _jsx(OverviewGrid, { items: [
                {
                    title: "Persönliche Kundenkennzahl",
                    content: data?.data.personalCustomerCode,
                },
                {
                    title: "Identifikationsart",
                    content: "Online per Video",
                },
                {
                    title: "Ausweisdaten",
                    content: (_jsxs("p", { children: ["Geburtsdatum: ", data?.data.identificationBirthday, _jsx("br", {}), "Geburtsort: ", data?.data.identificationBirthplace, _jsx("br", {}), "Nationalit\u00E4t: ", data?.data.nationality, _jsx("br", {}), "Ausweis:", " ", data?.data.identificationMode === IdentificationMode.idCard
                                ? "Personalausweis"
                                : "Reisepass", _jsx("br", {}), "Ausweisnummer:", " ", data?.data.identificationMode === IdentificationMode.idCard
                                ? data?.data.idCardNumber
                                : data?.data.passportNumber, _jsx("br", {}), "g\u00FCltig bis:", " ", data?.data.identificationMode === IdentificationMode.idCard
                                ? data?.data.idCardExpirationDate
                                : data?.data.passportExpirationDate] })),
                },
            ] }) }));
};
