import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { space, layout } from "styled-system";

export const SkeletonComp = styled("div")(
  space,
  layout,
  {
    background: "#eee",
    backgroundSize: "1000px 104px",
    position: "relative",
    display: "block",
  },
  `
background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #eee), color-stop(18%, #ddd), color-stop(33%, #eee));
background-image: linear-gradient(to right, #eee 8%, #ddd 18%, #eee 33%);
`
);

const Skeleton = ({ children, isLoading, ...props }) => {
  if (!isLoading) {
    return children;
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <SkeletonComp {...props} />;
};

Skeleton.defaultProps = {
  ...space.defaultProps,
  ...layout.defaultProps,
  isLoading: true,
};

Skeleton.propTypes = {
  ...space.propTypes,
  ...layout.propTypes,
  isLoading: PropTypes.bool,
  children: PropTypes.node,
};

export default Skeleton;
