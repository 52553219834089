import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { variant } from "styled-system";
import { Box, Flex, Headline, Text, Surface } from "@mm/ui";
export const BackgroundSurface = styled(Surface) `
  position: relative;
  display: flex;
  align-items: center;
  background-image: url(${(props) => props.backgroundImg});
  background-position: center;
  background-size: cover;
  padding-top: ${({ theme }) => theme.space.m}px;
  padding-bottom: ${({ theme }) => theme.space.xxlhuge}px;
  min-height: 410px;
  border-radius: 0;
  box-shadow: ${({ theme }) => theme.shadows.elevation2};
  max-width: 1440px;
`;
export const ContentColor = styled("div") `
  h1 {
    color: ${(props) => (props.variant ? props.variant : "white")};
  }

  p,
  span {
    color: ${(props) => (props.variant ? props.variant : "white")};
  }

  p,
  span {
    display: block;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }

  @media (min-width: ${({ theme }) => theme.breakpointsMap.sm.min}px) {
    p {
      margin-bottom: 0;
    }
  }

  ${variant({
    variants: {
        black: {
            color: "black",
        },
        white: {
            color: "white",
        },
    },
})}
`;
const InnerWrapper = styled("div") `
  margin: 0 auto;
  width: 100%;
  max-width: 1024px;
`;
const energyHeroConfig = {
    content: {
        flexDirection: ["column", "column", "row", "row", "row"],
        colSizeRight: ["100%", "100%", "50%", "50%", "50%"],
        colSizeLeft: ["100%", "100%", "50%", "50%", "50%"],
    },
};
const PageHeroSection = ({ page, logo }) => {
    const { hero } = page ?? {
        hero: {
            backgroundDesktop: "https://storage.googleapis.com/content-ekontor24/placeholder/hero/502-1341x642.jpg",
            fontColor: "white",
            headline: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit.",
            intro: "Foobar Foobar Foobar",
            text: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque",
        },
    };
    const { backgroundDesktop, fontColor, headline, intro, text } = hero;
    return (_jsx(Box, { mb: 6, children: _jsx("section", { className: "internet-hero", children: _jsxs(BackgroundSurface, { variant: "wide", shape: "sharp", color: "black", backgroundImg: backgroundDesktop, children: [logo, _jsx(InnerWrapper, { children: _jsx(Flex, { flexDirection: energyHeroConfig.content.flexDirection, children: _jsx(Box, { width: energyHeroConfig.content.colSizeLeft, children: _jsxs(ContentColor, { variant: fontColor, children: [intro && _jsx(Text, { variant: "smallCopy", children: intro }), headline && _jsx(Headline, { variant: "h1", children: headline }), text && _jsx(Text, { children: text })] }) }) }) })] }) }) }));
};
export default PageHeroSection;
