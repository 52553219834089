import React from "react";
import PropTypes from "prop-types";

import { Slider } from "components/molecules";
import { teaserContentPropTypes } from "sharedPropTypes";

import { addTrackFunction } from "../helper";
import AdTeaser from "../../AdTeaser";

const SliderTeaserSection = ({ contents, sectionType }) => {
  if (contents.length === 1) {
    return (
      <>
        {contents
          .filter(({ images }) => Boolean(images)) // only render teaser with images
          .map((content, idx) => (
            <AdTeaser
              key={content.linkUrl}
              type={content.type}
              content={content}
              onClick={addTrackFunction(content, sectionType, idx + 1)}
              qaName={`teaser${idx}`}
            />
          ))}
      </>
    );
  }

  return (
    <Slider>
      {contents
        .filter(({ images }) => Boolean(images)) // only render teaser with images
        .map((content, idx) => (
          <AdTeaser
            key={content.linkUrl}
            type={content.type}
            content={content}
            onClick={addTrackFunction(content, sectionType, idx + 1)}
            qaName={`teaser${idx}`}
          />
        ))}
    </Slider>
  );
};

SliderTeaserSection.propTypes = {
  contents: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      props: PropTypes.shape({
        content: teaserContentPropTypes.isRequired,
      }),
    })
  ).isRequired,
  sectionType: PropTypes.string.isRequired,
};

export default SliderTeaserSection;
