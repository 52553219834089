import React, { Fragment } from "react";
import PropTypes from "prop-types";

const StandardPageTemplate = ({ breadcrumb, header, footer, sections }) => (
  <>
    {header}
    {breadcrumb}
    <main id="page-main-content">
      {sections.map((Component, index) => {
        const elemKey = `fragment-${index}`; // @TODO: ESLint: Set correct key
        return <Fragment key={elemKey}>{Component}</Fragment>;
      })}
    </main>
    {footer}
  </>
);

StandardPageTemplate.propTypes = {
  breadcrumb: PropTypes.node,
  header: PropTypes.node.isRequired,
  footer: PropTypes.node.isRequired,
  sections: PropTypes.arrayOf(PropTypes.node).isRequired,
};

StandardPageTemplate.defaultProps = {
  breadcrumb: <></>,
};

export default StandardPageTemplate;
