import { isPromotionOffer } from "@/types";
import { formatNumberAsCurrencyString } from "@/utils/price/formatNumberAsCurrencyString";
export const constructAccessoriesLabel = (accessories) => {
    if (accessories.length === 1)
        return `mit ${accessories[0].name}`;
    return "mit Prämie deiner Wahl";
};
export const constructAccessoriesStartingPrice = (offer) => {
    const { accessories } = offer;
    return isPromotionOffer(offer) && accessories.length > 1
        ? ` ab ${offer.accessoriesAlternativePrice} €`
        : accessories[0].extendedVariants?.[0]
            ? ` für ${accessories[0].extendedVariants?.[0].variant.price} €`
            : "";
};
export const constructOfferLabel = (offer) => {
    const { tariff } = offer;
    return `${tariff?.name} für ${formatNumberAsCurrencyString(tariff?.pricingIntervals.averagePrice)} mtl.`;
};
