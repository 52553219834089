import styled from "styled-components";
import PropTypes from "prop-types";

const InlineLink = styled.a`
  /* stylelint-disable */

  text-decoration: none;
  display: block;

  font-size: ${(props) => (props.small ? "14px" : "16px")};
  font-weight: ${(props) => (props.small ? "400" : "600")};
  line-height: ${(props) => (props.small ? "1.43" : "1.5")};
  position: relative;
  padding-left: 14px;
  color: ${(props) => props.theme.colors.grey[6]};
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  &:before {
    background: ${(props) => props.theme.colors.primary[0]};
    bottom: 0;
    content: "";
    height: ${(props) => (props.small ? "1px" : "2px")};
    left: 11px;
    opacity: 0;
    position: absolute;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: left;
    transform-origin: left;
    -webkit-transition: opacity 350ms ease-in-out,
      -webkit-transform 350ms ease-in-out;
    transition: opacity 350ms ease-in-out, -webkit-transform 350ms ease-in-out;
    transition: transform 350ms ease-in-out, opacity 350ms ease-in-out;
    transition: transform 350ms ease-in-out, opacity 350ms ease-in-out,
      -webkit-transform 350ms ease-in-out;
    width: calc(100% - 11px);
    will-change: opacity, transform;
  }
  &:focus::before,
  &:hover::before {
    opacity: 1;
    transform: scaleX(1);
  }
  &:after {
    content: ">";
    position: absolute;
    left: 0;
    top: 0;
    color: ${(props) => props.theme.colors.primary[0]};
  }
`;

InlineLink.propTypes = {
  small: PropTypes.bool,
};

export default InlineLink;
