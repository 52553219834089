import styled from "styled-components";
import { InputText } from "@mm/ui";
import { THEME } from "@core/theme";
import { provideIsLoadingProp, } from "@/utils/context/LoadingContext";
const { skeletonBase } = THEME;
export const StyledInputText = styled(InputText).attrs(provideIsLoadingProp) `
  ${({ isLoading }) => isLoading &&
    skeletonBase +
        `
      height: 2rem;
      border-bottom-color: transparent;
      label, select, option {
        color: transparent;
      }
  `};
`;
