import { uniqBy } from "lodash";
import _ from "lodash/fp";

import { getUniqueItemWithStorageId } from "utils/hardwareUtils";

import {
  updateRangeFilter,
  updatePriceRangeFilter,
  buildFilterArrayGroup,
  mergeRangeValues,
} from "./products";

export const MANUFACTURERS = "manufacturers";
export const OPERATING_SYSTEMS = "operatingSystems";
export const COLORS = "colors";
export const STORAGE = "storage";
export const PRICE_RANGE = "priceRange";
export const DISPLAY_RANGE = "displayRange";
export const ACCESSORIES = "accessories";

const ORDERED_MANUFACTURERS = [
  "Samsung",
  "Apple",
  "Xiaomi",
  "Oppo",
  "Huawei",
  "Google",
  "Honor",
  "Sony",
  "realme",
  "Xplora",
];

const OTHER_MANUFACTURERS = ["Bose", "Fitbit", "Ryze", "SOFLOW"];

const OTHERS = "others";

// returns the name/value key pair for manufacturer
export const getManufacturer = (manufacturer) => {
  const value = !OTHER_MANUFACTURERS.includes(manufacturer)
    ? manufacturer
    : OTHERS;
  return {
    name: value === "others" ? "Weitere Marken" : manufacturer,
    value,
  };
};

// test functions (used to filter products)
// each function will be attached directly to the filterGroup
export const colorTest = ({ variants }, filterItem) =>
  !!Object.values(variants).find(({ colorGroup }) =>
    _.some(({ value }) => colorGroup.hex === value)(filterItem)
  );
export const storageTest = ({ variants }, filterItem) =>
  !!Object.values(variants).find(({ storageSize, storage }) =>
    _.some(({ value }) => (storageSize || storage) === value)(filterItem)
  );

/**
 * Validator to check if the current item's manufacturer matches the given filter criteria
 * @param {object} manufacturer - manufacturer to check
 * @param {Array<object>|object} filterItem - the filter condition
 * @returns {boolean}
 */
export const manufacturerTest = ({ manufacturer }, filterItem) => {
  /**
   * utility to check if something is equal to the carrier name
   */
  const manufacturerCheck = (filter) =>
    (!OTHER_MANUFACTURERS.includes(filter.name) &&
      manufacturer === filter.value) ||
    (filter.value === OTHERS && OTHER_MANUFACTURERS.includes(manufacturer));

  return _.cond([
    [_.isArray, _.some(manufacturerCheck)],
    [_.T, manufacturerCheck],
  ])(filterItem);
};
export const operatingSystemTest = ({ os }, filterItem) =>
  _.some(({ value }) => os === value)(filterItem);

export const priceRangeTest = ({ variants }, filterItem) =>
  !!Object.values(variants).find((variant) => {
    if (variant.default === true) {
      const price = Math.floor(variant.price);
      const isIncluded =
        price >= filterItem.minVal && price <= filterItem.maxVal;
      return isIncluded;
    }
    return null;
  });
// eslint-disable-next-line max-len
export const displayRangeTest = ({ displaySize }, filterItem) =>
  displaySize >= filterItem.minVal && displaySize <= filterItem.maxVal;

export const accessoriesTest = ({ accessories }, filterItem) => {
  const accessoriesNames = accessories.map((acc) => acc.name);
  return _.some(({ value }) => accessoriesNames.includes(value))(filterItem);
};

export const filterTests = {
  colors: colorTest,
  manufacturers: manufacturerTest,
  operatingSystems: operatingSystemTest,
  storage: storageTest,
  priceRange: priceRangeTest,
  displayRange: displayRangeTest,
  accessories: accessoriesTest,
};

// return possible filter groups and their filters based on products
export const getFilterOptions = (products, oldFilterOptions) => {
  let colors = [];

  let manufacturers = [];
  let accessories = [];

  let operatingSystems = [];

  let storage = [];

  let priceRange = {
    min: 10000, // we start with unrealistic inversed values so it will be updated
    max: 0,
    minVal: 0,
    maxVal: 0,
  };

  let displayRange = {
    min: 10000, // we start with unrealistic inversed values so it will be updated
    max: 0,
    minVal: 0,
    maxVal: 0,
    step: 0.1,
  };

  // Filters that only use unique items
  uniqBy(products, getUniqueItemWithStorageId).forEach(
    ({
      displaySize,
      os,
      manufacturer,
      variants,
      accessories: productAccessories = [],
    }) => {
      // display range
      displayRange = updateRangeFilter(displayRange, displaySize);

      // manufacturer
      const parsedManufacturer = getManufacturer(manufacturer);
      manufacturers = buildFilterArrayGroup(
        manufacturers,
        MANUFACTURERS,
        parsedManufacturer.name,
        parsedManufacturer.value,
        oldFilterOptions
      );

      // operating system
      operatingSystems = buildFilterArrayGroup(
        operatingSystems,
        OPERATING_SYSTEMS,
        os,
        os,
        oldFilterOptions
      );

      // variants
      Object.keys(variants).forEach((variantId) => {
        const variant = variants[variantId];

        // storage
        const storageName = `${variant.storageSize || variant.storage} GB`;
        const storageValue = variant.storageSize || variant.storage;
        storage = buildFilterArrayGroup(
          storage,
          STORAGE,
          storageName,
          storageValue,
          oldFilterOptions
        );
      });

      // accessories
      if (productAccessories.length) {
        const accessoriesNames = productAccessories.map((acc) => acc.name);

        accessoriesNames.forEach((name) => {
          accessories = buildFilterArrayGroup(
            accessories,
            ACCESSORIES,
            name,
            name,
            oldFilterOptions
          );
        });
      }
    }
  );

  // Regular filters
  products.forEach(({ variants }) => {
    // variants
    Object.keys(variants).forEach((variantId) => {
      const variant = variants[variantId];

      // colors
      colors = buildFilterArrayGroup(
        colors,
        COLORS,
        variant.colorGroup.name,
        variant.colorGroup.hex,
        oldFilterOptions
      );

      // priceRange
      priceRange = updatePriceRangeFilter(priceRange, variant.price);
    });
  });

  priceRange = mergeRangeValues(priceRange, oldFilterOptions.priceRange);
  displayRange = mergeRangeValues(displayRange, oldFilterOptions.displayRange);

  const listedManufacturers = manufacturers
    .filter((manufacturer) => ORDERED_MANUFACTURERS.includes(manufacturer.name))
    .sort(
      (a, b) =>
        ORDERED_MANUFACTURERS.indexOf(a.name) -
        ORDERED_MANUFACTURERS.indexOf(b.name)
    );
  const unlistedManufactures = manufacturers
    .filter(
      (manufacturer) => !ORDERED_MANUFACTURERS.includes(manufacturer.name)
    )
    .sort((a, b) => a.name.localeCompare(b.name));

  return {
    operatingSystems,
    manufacturers: [...listedManufacturers, ...unlistedManufactures],
    colors,
    storage,
    priceRange,
    displayRange,
    accessories,
  };
};

/**
 * All hardwares contain .variants subobject.
 * To make them work frictionless in the filter,
 * we create all products based on all variants with only one variant per product
 * This way, the items in the filter do contain the correct color and storage size
 * and the user can pick the one that he/she likes.
 */
export const splitProductsByVariants = (hardwares) =>
  _.flatten(
    hardwares.map((hardware) =>
      Object.entries(hardware.variants).map(([id, variant]) => ({
        ...hardware,
        defaultVariant: id,
        variants: {
          [id]: variant,
        },
      }))
    )
  );

export const INITIAL_HARDWARE_FILTERS = {
  apple: {
    manufacturers: [{ name: "Apple", value: "Apple", selected: true }],
  },
  samsung: {
    manufacturers: [{ name: "Samsung", value: "Samsung", selected: true }],
  },
  xiaomi: {
    manufacturers: [{ name: "Xiaomi", value: "Xiaomi", selected: true }],
  },
  google: {
    manufacturers: [{ name: "Google", value: "Google", selected: true }],
  },
  microsoft: {
    manufacturers: [{ name: "Microsoft", value: "Microsoft", selected: true }],
  },
  oppo: {
    manufacturers: [{ name: "Oppo", value: "Oppo", selected: true }],
  },
  huawei: {
    manufacturers: [{ name: "Huawei", value: "Huawei", selected: true }],
  },
  oneplus: {
    manufacturers: [{ name: "OnePlus", value: "OnePlus", selected: true }],
  },
  honor: {
    manufacturers: [{ name: "Honor", value: "Honor", selected: true }],
  },
  sony: {
    manufacturers: [{ name: "Sony", value: "Sony", selected: true }],
  },
  cat: {
    manufacturers: [{ name: "CAT", value: "CAT", selected: true }],
  },
  emporia: {
    manufacturers: [{ name: "EMPORIA", value: "EMPORIA", selected: true }],
  },
  motorola: {
    manufacturers: [{ name: "Motorola", value: "Motorola", selected: true }],
  },
  realme: {
    manufacturers: [{ name: "realme", value: "realme", selected: true }],
  },
  others: {
    manufacturers: [
      { name: "Weitere Marken", value: "others", selected: true },
    ],
  },
};
