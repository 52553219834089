export var Errors;
(function (Errors) {
    Errors["ThankYouPageNoOrderNumber"] = "NO_ORDER_NO";
    Errors["CheckoutTypeMissing"] = "CHECKOUT_TYPE_MISSING";
    // TODO: get clearer, explicit error from BE
    Errors["SessionMissing"] = "wrong state definition";
    Errors["NoSessionFoundOnOrderSubmit"] = "MSB-0003";
    // TODO: handle /w error page
    // TODO: not clear what this means
    Errors["CouldNotFetchData"] = "could not fetch data";
})(Errors || (Errors = {}));
