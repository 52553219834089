import { useQuery, useQueryClient } from "react-query";
import { extendURLParams } from "@/utils/fetchData";
import { useState } from "react";
export const useAddressSuggestions = (payload, options) => {
    const queryClient = useQueryClient();
    const { tenant, environment, restUrl } = extendURLParams();
    const [success, setSuccess] = useState(false);
    const { data: citiesResponse, refetch: refetchCities } = useQuery(["getCitiesForZipCode", tenant, environment], () => fetch(`${restUrl}/v1/address/cities/${payload.zip}?tenant=${tenant}&environment=${environment}`, {
        method: "get",
        credentials: "include",
    })
        .then((res) => {
        if (res.ok) {
            setSuccess(true);
        }
        return res.json();
    })
        .catch((error) => new Error(error)), {
        cacheTime: 0,
        enabled: false,
        refetchOnWindowFocus: false,
        onSettled: () => queryClient.resetQueries(["getStreetsForZipCodeAndCity"]),
        ...options,
    });
    const { data: streetsResponse, refetch: refetchStreets } = useQuery(["getStreetsForZipCodeAndCity", tenant, environment], () => fetch(`${restUrl}/v1/address/streets/${payload.zip}/${payload.city}?tenant=${tenant}&environment=${environment}`, {
        method: "get",
        credentials: "include",
    })
        .then((res) => {
        if (res.ok) {
            setSuccess(true);
        }
        return res.json();
    })
        .catch((error) => new Error(error)), {
        cacheTime: 0,
        enabled: false,
        refetchOnWindowFocus: false,
        ...options,
    });
    const cities = citiesResponse?.payload ?? [];
    const streets = streetsResponse?.payload ?? [];
    const clear = () => {
        queryClient.resetQueries([
            "getCitiesForZipCode",
            "getStreetsForZipCodeAndCity",
        ]);
        queryClient.removeQueries([
            "getCitiesForZipCode",
            "getStreetsForZipCodeAndCity",
        ]);
    };
    return {
        clear,
        data: {
            zip: payload.zip,
            cities,
            streets,
        },
        refetch: () => {
            clear();
            if (payload.zip &&
                payload.zip !== "" &&
                payload.zip?.match(/^[0-9]{5}$/)) {
                refetchCities().then((response) => {
                    if (payload.city || response?.data?.payload?.length === 1) {
                        refetchStreets();
                    }
                });
            }
        },
        success,
    };
};
