/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
import { useViewport } from "@core/theme";
import styled from "styled-components";

import SlickCarousel from "react-slick";

import { LinkedImageContainer } from "components/atoms";

import DynamicStage from "../DynamicStage/index";

import { StyledTeaserCarousel, carouselSettings } from "./SlickCarouselSetting";

const BorderWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.shade[2]};
  border-radius: 1rem;
  overflow: hidden;
`;

const ImageContainer = ({ element, idx, parent, qaName }) => {
  const viewport = useViewport();
  const imagePath = element.images?.[`${viewport.mediaType}Url`];

  return (
    <LinkedImageContainer
      linkUrl={
        element.differentProductLink || element.imageLink || element.pdpLink
      }
      imageUrl={imagePath}
      alt={element.imageAltText}
      type={element.type}
      idx={idx}
      parent={parent}
      qaName={qaName}
    />
  );
};

const StageSlider = ({ data, type }) => (
  <StyledTeaserCarousel>
    <SlickCarousel {...carouselSettings}>
      {data.map((element, index) =>
        element.type === "DynamicStage" ? (
          <BorderWrapper key={`BorderWrapper-${element.offerGroup}`}>
            <DynamicStage
              key={element.offerGroup}
              idx={index}
              parent={type}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...element}
              qaName={`slider${index}`}
            />
          </BorderWrapper>
        ) : (
          <BorderWrapper key={`BorderWrapper-${element.offerGroup}`}>
            <ImageContainer
              key={element.offerGroup}
              element={element}
              idx={index}
              parent={type}
              qaName={`slider${index}`}
            />
          </BorderWrapper>
        )
      )}
    </SlickCarousel>
  </StyledTeaserCarousel>
);

ImageContainer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  element: PropTypes.any,
  idx: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  parent: PropTypes.string.isRequired,
};

StageSlider.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.any,
  type: PropTypes.string.isRequired,
};

ImageContainer.defaultProps = {
  element: {},
};

StageSlider.defaultProps = {
  data: [],
};

export default StageSlider;
