import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  variant as styledVariant,
  compose,
  space as styledSpace,
  layout,
} from "styled-system";
import Collapse, { Panel } from "rc-collapse";

const CollapseContainer = styled(Collapse)(
  ({ theme: { fontIcons, fonts, mediaQueries, padding } }) => `
        &.rc-collapse {
            max-width: none;
            margin: 0;
        }

        &.rc-collapse-anim-active {
            transition: height 0.2s ease-out;
        }

        &.rc-collapse > .rc-collapse-item {
            border-bottom-width: 1px;
            border-bottom-style: solid;
        }

        &.rc-collapse > .rc-collapse-item > .rc-collapse-header {
            align-items: center;
            display: flex;
            justify-content: space-between;
            font-weight: 600;
            position: relative;
            min-height: 56px;
            cursor: pointer;
            outline: 0;
            justify-content: flex-start;

            i, .rc-collapse-expand-icon {
                display: none;
            }

            :before, :after {
                content: "${fontIcons.ArrowD}";
                font-family: ${fonts.icon} !important;
                font-size: 12px;
                line-height: 16px;
                transition: transform 0.2s ease-out;
                margin-right: 10px;
                display: none;
            }

            ${mediaQueries.xs} {
                :before {
                    display: inline;
                }
            }

            ${mediaQueries.md} {
                justify-content: space-between;

                :before {
                    display: none;
                }
                :after {
                    display: inline;
                }
            }
        }

        &.rc-collapse > .rc-collapse-item-active > .rc-collapse-header {
            :after, :before {
                transform: rotate(180deg);
            }
        }

        &.rc-collapse > .rc-collapse-item > .rc-collapse-header {
            padding-left: 0;
        }

        .rc-collapse-content {
            background-color: transparent;
            overflow: hidden;
            width: 100%;
            padding: 0 5px;

            .rc-collapse-content-box {
                margin: 0 0 ${padding[1]} 0;

                ul {
                    margin: 0;
                }
            }
        }

        .rc-collapse-content-inactive {
            display: none;
        }
    `,
  compose(styledSpace, layout),
  styledVariant({
    prop: "variant",
    variants: {
      default: {
        "&.rc-collapse > .rc-collapse-item > .rc-collapse-header": {
          lineHeight: "1.5",
          fontSize: ({
            typography: {
              size: { l },
            },
          }) => l,
          color: ({ colors }) => colors.shade[6],
          ":before": {
            color: ({ colors }) => colors.shade[6],
          },
        },
        "&.rc-collapse, &.rc-collapse > .rc-collapse-item": {
          borderBottomColor: ({ colors }) => colors.shade[6],
        },
      },
      service: {
        "&.rc-collapse > .rc-collapse-item > .rc-collapse-header": {
          lineHeight: "1.5",
          fontSize: "16px",
          color: ({ colors }) => colors.shade[5],
          ":before": {
            color: ({ colors }) => colors.shade[5],
          },
        },
        "&.rc-collapse, &.rc-collapse > .rc-collapse-item": {
          borderBottomColor: ({ colors }) => colors.shade[5],
        },
      },
      footer: {
        "&.rc-collapse > .rc-collapse-item": {
          lineHeight: "1.5",
          fontSize: "14px",
          color: ({ colors }) => colors.shade[2],
          borderBottomColor: ({ colors }) => colors.shade[2],
          ":before": {
            color: ({ colors }) => colors.shade[2],
          },
        },
      },
      details: {
        "&.rc-collapse > .rc-collapse-item > .rc-collapse-header": {
          lineHeight: "1.5",
          fontSize: "16px",
          color: ({ colors }) => colors.shade[5],
          ":before": {
            color: ({ colors }) => colors.shade[5],
          },
          borderBottomColor: ({ colors }) => colors.shade[2],
          backgroundColor: ({ colors }) => colors.shade[1],
          paddingLeft: ({ space }) => `${space.l}px`,
        },
        "&.rc-collapse, &.rc-collapse > .rc-collapse-item": {
          borderBottomColor: "transparent",
        },
        ".rc-collapse-content > .rc-collapse-content-box": {
          margin: "0",
        },
      },
      filter: {
        "&.rc-collapse > .rc-collapse-item > .rc-collapse-header": {
          lineHeight: "1.5",
          fontSize: ({
            typography: {
              size: { m },
            },
          }) => m,
          color: ({ colors }) => colors.shade[6],
          ":before": {
            color: ({ colors }) => colors.shade[6],
          },
        },
        "&.rc-collapse, &.rc-collapse > .rc-collapse-item": {
          borderBottomColor: ({ colors }) => colors.shade[6],
        },
      },

      horizontal: {
        "&.rc-collapse": {
          display: "flex",
          flexWrap: "wrap",
          gap: "12px",
        },
        "&.rc-collapse > .rc-collapse-item": {
          flexGrow: "1",
          flexBasis: "250px",
          alignSelf: "flex-start",
          borderBottomColor: ({ colors }) => colors.shade[2],
        },
        "&.rc-collapse > .rc-collapse-item > .rc-collapse-header": {
          minHeight: "0",
          paddingTop: ({ space }) => space.s,
          paddingBottom: ({ space }) => space.s,
          ":after": {
            marginLeft: "10px",
          },
        },
      },
    },
  })
);

const Accordion = ({
  className,
  entryList,
  variant,
  multiOpen,
  activeKey,
  ...props
}) => {
  const [activeKeys, setActiveKeys] = useState(activeKey);

  useEffect(() => {
    setActiveKeys(activeKey);
  }, [activeKey]);

  const onChange = (newActiveKey) => {
    setActiveKeys(newActiveKey);
  };

  return (
    <CollapseContainer
      // using prop spreading here due to styled systems space composition (mt etc..)
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      variant={variant}
      accordion={!multiOpen}
      showArrow={false}
      className={className}
      onChange={onChange}
      activeKey={activeKeys}
    >
      {entryList.map((entry, idx) => {
        const key = idx + 1;
        return (
          <Panel key={key} header={entry.header}>
            {React.isValidElement(entry.content) ? (
              entry.content
            ) : (
              <span
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: entry.content,
                }}
              />
            )}
          </Panel>
        );
      })}
    </CollapseContainer>
  );
};

Accordion.propTypes = {
  entryList: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
        .isRequired,
      content: PropTypes.node.isRequired,
    })
  ).isRequired,
  className: PropTypes.string,
  variant: PropTypes.oneOf([
    "details",
    "footer",
    "service",
    "default",
    "filter",
    "horizontal",
  ]),
  // if multiple accordion rows can be open at the same time
  multiOpen: PropTypes.bool,
  activeKey: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.number),
  ]),
};

Accordion.defaultProps = {
  multiOpen: false,
  activeKey: null,
  variant: "default",
  className: "",
};

export default Accordion;
