import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { CheckboxWithLabel, CheckoutSection, CheckoutSectionHeadline, CheckoutSectionText, IconButton, RadioList, StyledInputText as InputText, } from "../../components";
import { Button } from "@mm/ui";
import { useCheckout } from "../../hooks/api";
import { ACTIONS as CONTEXT_ACTIONS, useCheckoutState, } from "../../context/CheckoutContext";
import styled from "styled-components";
import { useField, useValidation, ValidateOnEvent, } from "../../hooks";
const Row = styled.div `
  display: flex;
  width: 100%;
  gap: 1.5rem;
  @media (max-width: 760px) {
    flex-wrap: wrap;
  }
`;
const ExtensionButtonWrapper = styled.div `
  display: flex;
  width: 100%;
  /* flex-basis: 80%;
  flex-grow: 1; */
`;
const PrefixWrapper = styled.div `
  flex: 0 0 33%;
  @media (max-width: 760px) {
    flex: 1 0 100%;
  }
`;
const InputWrapper = styled.div `
  flex: 1;
`;
const ButtonWrapper = styled.div `
  align-self: end;
  width: 55%;
  display: flex;
  margin-top: 1.5rem;
  margin-left: auto;

  > * {
    flex: 1;
  }
`;
export const NumberPortingSection = ({ defaultValues, schema, }) => {
    const { state, dispatch: dispatchContext } = useCheckoutState();
    const [numberExtensionCount, setNumberExtensionCount] = useState(1);
    const { errors: numberPortingErrors, fieldState: numberPorting, setFieldState: setNumberPorting, } = useField("numberPorting", schema, Boolean(defaultValues?.numberPorting));
    const { errors: numberPortingIntentErrors, fieldState: numberPortingIntent, setFieldState: setNumberPortingIntent, } = useField("numberPortingIntent", schema, Boolean(state?.numberPortingIntent || defaultValues?.numberPortingIntent) ??
        false);
    const { errors: numberPrefixErrors, fieldState: numberPrefix, setFieldState: setNumberPrefix, trigger: triggerNumberPrefix, } = useField("currentProviderNumberPrefix", schema, "", {
        validateOn: ValidateOnEvent.Manual,
    });
    const { errors: numberExtensionErrors, fieldState: numberExtension, setFieldState: setNumberExtension, trigger: triggerNumberExtension, } = useField("currentProviderNumberExtension", schema, "", {
        validateOn: ValidateOnEvent.Manual,
    });
    const { errors: numberExtensionErrors01, fieldState: numberExtension01, setFieldState: setNumberExtension01, trigger: triggerNumberExtension01, } = useField("currentProviderNumberExtension01", schema, "", {
        validateOn: ValidateOnEvent.Manual,
    });
    const { errors: numberExtensionErrors02, fieldState: numberExtension02, setFieldState: setNumberExtension02, trigger: triggerNumberExtension02, } = useField("currentProviderNumberExtension02", schema, "", {
        validateOn: ValidateOnEvent.Manual,
    });
    const { isFetching, isLoading } = useCheckout();
    const { clearErrors, } = useValidation();
    useEffect(() => {
        if (state?.currentProviderNumberExtension01) {
            if (state?.currentProviderNumberExtension02) {
                setNumberExtensionCount(3);
            }
            else {
                setNumberExtensionCount(2);
            }
        }
        else {
            setNumberExtensionCount(1);
        }
    }, [
        state?.currentProviderNumberExtension01,
        state?.currentProviderNumberExtension02,
    ]);
    return (_jsx(CheckoutSection, { children: _jsxs(_Fragment, { children: [_jsx(CheckoutSectionHeadline, { children: "Rufnummermitnahme" }), _jsx(RadioList, { checked: numberPorting ? "numberPorting--yes" : "numberPorting--no", id: "numberPorting", items: [
                        {
                            key: "numberPorting--yes",
                            label: "Ja",
                        },
                        {
                            key: "numberPorting--no",
                            label: "Nein",
                        },
                    ], onChange: (key) => {
                        setNumberPorting(key === "numberPorting--yes");
                        clearErrors([
                            "numberPortingIntent",
                            "currentProviderNumberPrefix",
                            "currentProviderNumberExtension",
                            "currentProviderNumberExtension01",
                            "currentProviderNumberExtension02",
                        ]);
                        dispatchContext({
                            type: CONTEXT_ACTIONS.RESET_FIELDS,
                            payload: [
                                "numberPortingIntent",
                                "currentProviderNumberPrefix",
                                "currentProviderNumberExtension",
                                "currentProviderNumberExtension01",
                                "currentProviderNumberExtension02",
                            ],
                        });
                    }, disabled: isLoading || isFetching, error: numberPortingErrors?.[0]?.message }), numberPorting && (_jsxs(_Fragment, { children: [_jsx(CheckoutSectionText, { children: "Bitte beachte, dass eine Rufnummermitnahme nur innerhalb des selben Vorwahlgebietes m\u00F6glich ist." }), _jsx(CheckboxWithLabel, { id: "numberPortingIntent", checked: numberPortingIntent, error: numberPortingIntentErrors?.[0]?.message, onChange: () => {
                                setNumberPortingIntent(!numberPortingIntent);
                            }, label: "Hiermit beauftrage ich die Mitnahme meiner Rufnummer(n). Ich bevollm\u00E4chtige die 1&1 Telecom GmbH mit deren \u00DCbermittlung an den derzeitigen Anbieter. M\u00F6gliche weitere Anschlussinhaber sind hiermit einverstanden." }), _jsxs(Row, { children: [_jsx(PrefixWrapper, { children: _jsx(InputText, { id: "currentProviderNumberPrefix", label: "Vorwahl*", value: numberPrefix, message: numberPrefixErrors?.[0]?.message, layout: numberPrefixErrors && "error", onChange: (value) => {
                                            setNumberPrefix(value);
                                        }, onBlur: () => triggerNumberPrefix(), disabled: isLoading || isFetching, autoComplete: "none" }) }), _jsxs(InputWrapper, { children: [numberExtensionCount >= 1 && (_jsx(ExtensionButtonWrapper, { children: _jsx("div", { style: { flexBasis: "80%", flexGrow: 1 }, children: _jsx(InputText, { id: "currentProviderNumberExtension", label: "Rufnummer*", value: numberExtension, message: numberExtensionErrors?.[0]?.message, layout: numberExtensionErrors && "error", onChange: (value) => {
                                                        setNumberExtension(value);
                                                    }, onBlur: () => triggerNumberExtension(), disabled: isLoading || isFetching, autoComplete: "none" }) }) })), numberExtensionCount >= 2 && (_jsxs(ExtensionButtonWrapper, { children: [_jsx("div", { style: { flexBasis: "80%", flexGrow: 1 }, children: _jsx(InputText, { id: "currentProviderNumberExtension01", label: "Rufnummer*", value: numberExtension01, message: numberExtensionErrors01?.[0]?.message, layout: numberExtensionErrors01 && "error", onChange: (value) => {
                                                            setNumberExtension01(value);
                                                        }, onBlur: () => triggerNumberExtension01(), disabled: isLoading || isFetching, autoComplete: "none" }) }), _jsx(IconButton, { src: "https://content.ekontor24.net/mediamarkt/img/mm-neu/trashcan.svg", alt: "delete icon", handleDelete: () => {
                                                        setNumberExtensionCount(numberExtensionCount - 1);
                                                        clearErrors(["currentProviderNumberExtension01"]);
                                                        dispatchContext({
                                                            type: CONTEXT_ACTIONS.RESET_FIELDS,
                                                            payload: ["currentProviderNumberExtension01"],
                                                        });
                                                    } })] })), numberExtensionCount >= 3 && (_jsxs(ExtensionButtonWrapper, { children: [_jsx("div", { style: { flexBasis: "80%", flexGrow: 1 }, children: _jsx(InputText, { id: "currentProviderNumberExtension02", label: "Rufnummer*", value: numberExtension02, message: numberExtensionErrors02?.[0]?.message, layout: numberExtensionErrors02 && "error", onChange: (value) => {
                                                            setNumberExtension02(value);
                                                        }, onBlur: () => triggerNumberExtension02(), disabled: isLoading || isFetching, autoComplete: "none" }) }), _jsx(IconButton, { src: "https://content.ekontor24.net/mediamarkt/img/mm-neu/trashcan.svg", alt: "delete icon", handleDelete: () => {
                                                        setNumberExtensionCount(numberExtensionCount - 1);
                                                        clearErrors(["currentProviderNumberExtension02"]);
                                                        dispatchContext({
                                                            type: CONTEXT_ACTIONS.RESET_FIELDS,
                                                            payload: ["currentProviderNumberExtension02"],
                                                        });
                                                    } })] })), (!state?.currentProviderNumberExtension01 ||
                                            !state?.currentProviderNumberExtension02) && (_jsx(ButtonWrapper, { children: _jsx(Button, { type: "button", color: "secondary", size: "small", onClick: () => setNumberExtensionCount(numberExtensionCount + 1), loading: isLoading, disabled: isLoading || isFetching, children: "weitere Rufnummer" }) }))] })] })] }))] }) }));
};
