import { ConnectionActivationSection } from "./ConnectionActivationSection";
import { ConnectionOverviewSection } from "./ConnectionOverviewSection";
import { ContractTerminationSection } from "./ContractTerminationSection";
import { NumberPortingSection } from "./NumberPortingSection";
import { ProviderChangeSection } from "./ProviderChangeSection";
import { ContractSummarySection } from "./ContractSummarySection";
export { ConnectionActivationSection as EinsUndEinsConnectionActivationSection } from "./ConnectionActivationSection";
export { ContractSummarySection as EinsUndEinsContractSummarySection } from "./ContractSummarySection";
export { NumberPortingSection as EinsUndEinsNumberPortingSection } from "./NumberPortingSection";
export { ProviderChangeSection as EinsUndEinsProviderChangeSection } from "./ProviderChangeSection";
export const einsUndEinsSectionComponents = {
    "1und1.connectionActivationSection": ConnectionActivationSection,
    "1und1.connectionOverviewSection": ConnectionOverviewSection,
    "1und1.contractTerminationSection": ContractTerminationSection,
    "1und1.contractSummarySection": ContractSummarySection,
    "1und1.numberPortingSection": NumberPortingSection,
    "1und1.providerChangeSection": ProviderChangeSection,
};
