import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CheckoutSection, CheckoutSectionHeadline, CheckoutSectionText, RadioList, } from "../components";
import { useCheckoutState } from "../context/CheckoutContext";
import { useField, useValidation, } from "../hooks";
import { useCheckout } from "@/pages/CheckoutPage/hooks/api";
export const ExistingCustomerToggleSection = ({ defaultValues, schema, }) => {
    const { state } = useCheckoutState();
    const { isFetching, isLoading } = useCheckout();
    const { clearErrors, } = useValidation();
    const { errors, fieldState: existingCustomer, setFieldState: setChecked, } = useField("existingCustomer", schema, Boolean(state?.existingCustomer || defaultValues?.existingCustomer) ?? true);
    return (_jsxs(CheckoutSection, { children: [_jsx(CheckoutSectionHeadline, { children: "Hattest du in den letzten drei Monaten einen Internet-Vertrag bei Vodafone oder bist du Neukunde?" }), _jsx(RadioList, { "data-tk-cobrowse-hidden": "true", id: "existingCustomer", checked: existingCustomer
                    ? "existingCustomer--true"
                    : "existingCustomer--false", items: [
                    {
                        key: "existingCustomer--false",
                        label: "Neukunde",
                    },
                    {
                        key: "existingCustomer--true",
                        label: "Bestandskunde",
                    },
                ], onChange: (key) => {
                    if (!isLoading && !isFetching) {
                        clearErrors("existingCustomer");
                        setChecked(key === "existingCustomer--true");
                    }
                }, error: errors?.[0]?.message, disabled: isLoading || isFetching }), existingCustomer && (_jsxs(CheckoutSectionText, { children: ["Bitte beachte, dass sich die Angebote ausschlie\u00DFlich an Kunden richten, in deren Haushalt in den letzten drei Monaten kein Internet- & Festnetz-Anschluss von Vodafone vorhanden war. Wenn du Fragen oder W\u00FCnsche zu deinem laufenden Vertrag hast, wende dich bitte direkt an Vodafone unter ", _jsx("a", { href: "tel:+498001721212", children: "0800-172 1212" }), " oder an", " ", _jsx("a", { href: "https://www.vodafone.de/hilfe/kontakt-zu-vodafone.html", target: "_blank", rel: "noreferrer", children: "https://www.vodafone.de/hilfe/kontakt-zu-vodafone.html" })] }))] }));
};
