import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { Box, Flex } from "@mm/ui";

import { Bulletlist, SmallInlineLink, TariffBadge } from "components/atoms";

import { TariffDetailsLinks } from "components/molecules";

import dataLayer from "helper/dataLayer";

import { tariffPropTypes } from "sharedPropTypes";

const Headline = styled.div`
  font-size: 20px;
`;

const SelectedTariff = ({
  tariff,
  tariff: { bullets, linkText, carrier, name },
  tariffUrl,
}) => {
  return (
    <>
      <Flex mb={5} alignItems="center">
        <Box flexShrink="0">
          <TariffBadge tariff={tariff} framed />
        </Box>
        <Box ml={2}>
          <Headline data-codeception="nameTariff">
            <b>{carrier}</b> {name.replace("Super Select", "")}
          </Headline>
          {!tariffUrl && (
            <SmallInlineLink
              href="#filter"
              onClick={() => {
                dataLayer({
                  eventAction: "click",
                  eventCategory: "Link",
                  eventLabel: "Change tariff",
                });
              }}
            >
              Tarif ändern
            </SmallInlineLink>
          )}
        </Box>
      </Flex>
      {tariffUrl && (
        <Box mb={4} mt={2}>
          <SmallInlineLink href={tariffUrl}>{linkText}</SmallInlineLink>
        </Box>
      )}
      <Bulletlist list={bullets} />
      <TariffDetailsLinks
        tariff={tariff}
        analytics={{ context: "SelectedTariff" }}
      />
    </>
  );
};

SelectedTariff.defaultProps = {
  tariffUrl: "",
};

SelectedTariff.propTypes = {
  tariff: tariffPropTypes.isRequired,
  tariffUrl: PropTypes.string,
};

export default SelectedTariff;
