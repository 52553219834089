import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { CheckboxWithLabel, CheckoutSection, CheckoutSectionHeadline, CheckoutSectionText, DatePicker, RadioList, } from "../../components";
import { useCheckout } from "../../hooks/api";
import { ACTIONS as CONTEXT_ACTIONS, useCheckoutState, } from "../../context/CheckoutContext";
import styled from "styled-components";
import { useField, useValidation, ValidateOnEvent, } from "../../hooks";
import { ACTIONS as MODAL_ACTIONS, useModal, } from "@/utils/context/ModalContext";
import { addDays, parse, startOfDay } from "date-fns";
import { formatDate } from "@/utils/formatDate";
const Row = styled.div `
  display: flex;
  width: 100%;
  gap: 1.5rem;
  @media (max-width: 760px) {
    flex-wrap: wrap;
  }
`;
const InputWrapper = styled.div `
  flex: 1;
`;
const Icon = styled.i `
  cursor: pointer;
  margin: auto 0 auto 0.25rem;
`;
const StyledRow = styled(Row) `
  margin-bottom: 1rem;
`;
export const ContractTerminationSection = ({ defaultValues, schema, options, }) => {
    const { state, dispatch: dispatchContext } = useCheckoutState();
    const { dispatch: dispatchModal } = useModal();
    const { selectableStartInDays, selectableEndInDays } = options ?? {
        selectableStartInDays: 0,
        selectableEndInDays: 365,
    };
    const startDate = startOfDay(addDays(new Date(), Number(selectableStartInDays)));
    const endDate = startOfDay(addDays(new Date(), Number(selectableEndInDays)));
    const { errors: contractTerminationDateErrors, fieldState: contractTerminationDate, setFieldState: setContractTerminationDate, } = useField("contractTerminationDate", schema, String(defaultValues?.contractTerminationDate) ?? "", {
        validateOn: ValidateOnEvent.Change,
        forceContextState: true,
    });
    const { errors: contractTerminatedErrors, fieldState: contractTerminated, setFieldState: setContractTerminated, } = useField("contractTerminated", schema, defaultValues?.contractTerminated
        ? Boolean(defaultValues.contractTerminated)
        : undefined);
    const { errors: digitalLetterOfIntentErrors, fieldState: digitalLetterOfIntent, setFieldState: setDigitalLetterOfIntent, } = useField("digitalLetterOfIntent", schema, Boolean(state?.digitalLetterOfIntent || defaultValues?.digitalLetterOfIntent) ?? false, {
        validateOn: ValidateOnEvent.Change,
        forceContextState: true,
    });
    const { isFetching, isLoading } = useCheckout();
    const { clearErrors, } = useValidation();
    const checkedRadio = () => {
        if (contractTerminated === undefined)
            return undefined;
        return contractTerminated
            ? "contractTerminated--true"
            : "contractTerminated--false";
    };
    return (_jsxs(CheckoutSection, { children: [_jsx(CheckoutSectionHeadline, { children: "Anbieterwechsel" }), _jsxs(_Fragment, { children: [_jsx(RadioList, { id: "contractTerminated", checked: checkedRadio(), items: [
                            {
                                key: "contractTerminated--true",
                                label: "Anschluss gekündigt",
                            },
                            {
                                key: "contractTerminated--false",
                                label: "Anschluss noch nicht gekündigt",
                            },
                        ], onChange: (key) => {
                            if (!isLoading && !isFetching) {
                                clearErrors([
                                    "contractTerminated",
                                    "contractTerminationDate",
                                    "digitalLetterOfIntent",
                                ]);
                                dispatchContext({
                                    type: CONTEXT_ACTIONS.RESET_FIELDS,
                                    payload: ["contractTerminationDate", "digitalLetterOfIntent"],
                                });
                                setContractTerminated(key === "contractTerminated--true");
                            }
                        }, error: contractTerminatedErrors?.[0]?.message }), contractTerminated === true && (_jsxs(StyledRow, { children: [_jsx(InputWrapper, { children: _jsx(DatePicker, { id: "contractTerminationDate", label: "Vertragsende*", selectableStart: startDate, selectableEnd: endDate, selected: contractTerminationDate
                                        ? parse(contractTerminationDate, "dd.MM.yyyy", new Date())
                                        : undefined, openToDate: startDate, onChange: (date) => {
                                        clearErrors(["contractTerminationDate"]);
                                        setContractTerminationDate(date ? formatDate(date) : date);
                                    }, disabled: isLoading || isFetching, message: contractTerminationDateErrors?.[0]?.message, layout: contractTerminationDateErrors && "error" }) }), _jsx(Icon, { onClick: (e) => {
                                    e.stopPropagation();
                                    dispatchModal({
                                        type: MODAL_ACTIONS.SET_MODAL,
                                        payload: {
                                            type: "generic",
                                            title: "Informationen zum Vertragsende",
                                            content: (_jsx("p", { children: "Wenn dein aktueller Vertrag bereits ausgelaufen ist, ist eine unterbrechungsfreie Schaltung nicht mehr m\u00F6glich. W\u00E4hle dann bitte im Kalender das heutige Datum aus." })),
                                        },
                                    });
                                }, className: "icon-MM_iconfont_Info", role: "dialog", "aria-modal": "true", "aria-label": "Informationen zum Vertragsende" })] })), contractTerminated === false && (_jsx(CheckboxWithLabel, { id: "digitalLetterOfIntent", checked: digitalLetterOfIntent, error: digitalLetterOfIntentErrors?.[0]?.message, onChange: () => setDigitalLetterOfIntent(!digitalLetterOfIntent), label: "Hiermit beauftrage ich die 1&1 Telecom GmbH mit\nder K\u00FCndigung des derzeitigen Vertrages. Ich bevollm\u00E4chtige\ndie 1&1 Telecom GmbH mit deren \u00DCbermittlung an\nden derzeitigen Anbieter. M\u00F6gliche weitere\nAnschlussinhaber sind hiermit einverstanden." })), contractTerminated &&
                        contractTerminationDate &&
                        parse(contractTerminationDate, "dd.MM.yyyy", new Date()) <=
                            addDays(startOfDay(new Date()), 7) ? (_jsx(_Fragment, { children: _jsxs(CheckoutSectionText, { children: [_jsx("strong", { children: "Dein Anschluss wird schnellstm\u00F6glich geschaltet" }), _jsx("br", {}), "Auf Wunsch kannst du nach der Bestellung die \u00DCbernahme deiner Rufummer direkt bei 1&1 beauftragen."] }) })) : (_jsx(_Fragment, { children: !contractTerminated && (_jsx(CheckoutSectionText, { children: "Wir erledigen alle Formalit\u00E4ten und k\u00FCndigen deinen aktuellen Vertrag f\u00FCr dich." })) }))] })] }));
};
