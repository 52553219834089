import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import dataLayer from "helper/dataLayer";

import Link from "../../../atoms/Link";

const Ul = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0 0 20px;
`;

const Li = styled.li`
  margin: 0;
  padding: 0;
`;

const A = styled(Link)`
  color: ${(props) => props.theme.colors.grey[2]}!important;
  line-height: 2;
  padding: 0;
  :after {
    display: none;
  }
  :before {
    background: ${(props) => props.theme.colors.grey[2]};
    left: 0;
    width: 100%;
  }

  :hover {
    color: ${(props) => props.theme.colors.grey[2]};
  }
`;

const Linklist = ({ header, links }) => (
  <Ul>
    {links.map((link, index) => {
      const elemKey = index; // @TODO: ESLint: Set correct key

      return (
        <Li key={elemKey}>
          <A
            href={link.url}
            onClick={() =>
              dataLayer({
                eventCategory: "Footer",
                eventAction: "click",
                eventLabel: `${header}:${link.text}:${link.url}`,
              })
            }
            small
          >
            {link.text}
          </A>
        </Li>
      );
    })}
  </Ul>
);

Linklist.propTypes = {
  header: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default Linklist;
