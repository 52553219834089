import React from "react";
import PropTypes from "prop-types";

import { GlobalStyles as MMGlobalStyles } from "@mm/theme";

const GlobalStylesComp = {
  MEDIAMARKT: MMGlobalStyles,
}[TENANT];

const GlobalStyles = ({ children }) => (
  <GlobalStylesComp>{children}</GlobalStylesComp>
);

GlobalStyles.defaultProps = {
  children: null,
};

GlobalStyles.propTypes = {
  children: PropTypes.node,
};

export default GlobalStyles;
