import React from "react";

import { OfferProvider } from "features/Offer";

import TariffHalfWidth from "./TariffHalfWidth";

const OfferTeaserConfiguratorTariff = ({ data }) => {
  return (
    <OfferProvider rootInfoData={data}>
      <TariffHalfWidth />
    </OfferProvider>
  );
};

export default OfferTeaserConfiguratorTariff;
