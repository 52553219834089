import React from "react";
import * as Sentry from "@sentry/react";
import { useQuery } from "react-query";
import _ from "lodash";

import axios from "axios";

import { Headline, Text, Button } from "@mm/ui";

import { useModals, actions } from "features/Modals";

/**
 * TODO: maybe fix payload structure on switch form getPDPHardware to getRootInfo,
 * or maybe even generalize it completely depending on expected payload structure in different calls
 */
export const getPdpRequestPayload = () => {
  const paths = window.location.pathname
    .split("/")
    .filter((path) => path !== "");
  const queryStrings = decodeURIComponent(window.location.search)
    .slice(1) // remove '?'
    .split("&")
    .reduce((acc, queryString) => {
      const [key, value] = queryString.split("=");
      acc[key] = value;
      return acc;
    }, {});

  const [offerType, manufacturer, offerGroupUrl] = paths;
  // map german query param names to english websocket message param names
  const {
    farbe: color,
    speicher: storage,
    carrier,
    tarif: tariff,
    hardware,
    deeplink,
  } = queryStrings;

  return {
    offerType,
    offerGroupUrl,
    color,
    storage,
    hardware,
    carrier,
    tariff,
    deeplink,
    ...(offerType === "tarife"
      ? { serviceProvider: manufacturer }
      : { manufacturer }),
  };
};

export const showErrorModal = (dispatch) =>
  dispatch({
    type: actions.SHOW,
    payload: {
      Component: (
        <>
          <Headline variant="h2">Es ist ein Fehler aufgetreten.</Headline>
          <Text>
            Aktuell kann Ihre Anfrage leider nicht bearbeitet werden. Bitte
            versuchen Sie es später noch einmal.
          </Text>
          <Button
            size="small"
            mt={3}
            // as long as we dont have react-router to use its browser history,
            // we're using the global history
            onClick={() => {
              // eslint-disable-next-line no-restricted-globals
              history.back();
            }}
          >
            Zurück
          </Button>
        </>
      ),
    },
  });

export const extendURLParams = (params) => ({
  ...params,
  tenant: TENANT.toLowerCase(), // webpack variable
  environment: window.APP_ENV,
  restUrl: window.REST_URL,
  cdnUrl: window.CDN_URL,
  appUrl: window.APP_URL,
});

export const useREST = (path, params, opts) => {
  const { dispatch } = useModals();
  const queryParams = [path, params];
  return useQuery(
    queryParams,
    async () => {
      try {
        const requestURL = window.REST_URL.concat(path);
        const { data: responseData } = await axios.get(requestURL, {
          params,
        });
        const { payload: data, errors } = responseData;
        if (errors.length) {
          // eslint-disable-next-line no-console
          console.error(errors);
          return showErrorModal(dispatch);
        }
        return data;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);

        // Bugfix (MMSD-5557):
        // Navigation while a fetch was not finished led to
        // error modals popping up.
        // We hide the modal for ECONNABORTED, since we can't avoid
        // triggering a connection abort
        if (error.code === "ECONNABORTED") {
          return;
        }

        return showErrorModal(dispatch);
      }
    },
    {
      refetchOnWindowFocus: false,
      ...opts,
    }
  );
};

export const useRESTv1 = (path, params, opts) => {
  const { dispatch } = useModals();
  const queryParams = [path, params];
  const restUrl = `${window.REST_URL}/v1`;
  return useQuery(
    queryParams,
    async () => {
      try {
        const urlParams = new URLSearchParams(window.location.search);

        const referrer =
          document.referrer !== ""
            ? document.referrer
            : urlParams.has("referrer")
            ? urlParams.get("referrer")
            : "";

        if (urlParams.has("referrer")) {
          history.replaceState({}, document.title, location.pathname);
        }

        const requestURL = restUrl.concat(path);
        const { data: responseData } = await axios.get(requestURL, {
          params,
          withCredentials: true,
          headers: {
            "X-Referrer": referrer,
          },
        });
        const { payload: data, errors } = responseData;
        // We need a empty array when no errors accrue
        if (errors.length > 0) {
          // eslint-disable-next-line no-console
          console.error(errors);
          return showErrorModal(dispatch);
        }
        return data;
      } catch (error) {
        if (error?.response?.status == 403) {
          return window.location.replace("/404");
        }
        // eslint-disable-next-line no-console
        console.log("v1error", error.response);
        Sentry.captureException(error);

        return showErrorModal(dispatch);
      }
    },
    {
      refetchOnWindowFocus: false,
      ...opts,
    }
  );
};

export const apiStatusCall = (path, params, opts) => {
  const queryParams = [path, params];
  const restUrl = `${window.REST_URL}/v1`;
  return useQuery(
    queryParams,
    async () => {
      try {
        const requestURL = restUrl.concat(path);
        const payload = await fetch(requestURL);
        // const { payload: data, errors } = responseData;
        return payload;
      } catch (error) {
        return error;
      }
    },
    {
      refetchOnWindowFocus: false,
      ...opts,
    }
  );
};

/**
 * Smartphone/Tablet detail calls
 */

export const useHardwareRootInfo = (queryParams, opts) => {
  let params = {};

  if (queryParams) {
    params = queryParams;
  } else params = getPdpRequestPayload();

  // checks if offer contains a deeplink, https://skon.atlassian.net/browse/MMSD-4673
  if (params?.deeplink?.length > 0) {
    return useRESTv1(
      `/offergroup/${params.offerGroupUrl}?color=${params.color}&storage=${params.storage}&tariff=${params.tariff}&carrier=${params.carrier}&deeplink=${params.deeplink}`,
      extendURLParams(),
      opts
    );
  }

  return useRESTv1(
    `/offergroup/${params.offerGroupUrl}?color=${params.color}&storage=${params.storage}&tariff=${params.tariff}&carrier=${params.carrier}`,
    extendURLParams(),
    opts
  );
};

export const useRawHardwareVariants = (queryParams, opts) =>
  useREST(
    "/productDetail/extendedVariants",
    extendURLParams(queryParams || getPdpRequestPayload()),
    opts
  );

export const useTariffs = (queryParams, opts) => {
  let params = {};

  if (queryParams) {
    params = queryParams;
  } else params = getPdpRequestPayload();

  return useRESTv1(
    `/offergroup/${params.offerGroupUrl}/tariff?color=${params.color}&storage=${params.storage}`,
    extendURLParams(),
    opts
  );
};

export const useOffer = (id, opts) =>
  useRESTv1(`/section/offer/${id}`, extendURLParams(), opts);

export const useEnergyTariffs = (productType, zipCode, city, cons) =>
  fetch(
    `${
      window.REST_URL
    }/v1/energy?productType=${productType}&postalCode=${zipCode}&place=${city}&consumption=${cons}&environment=${
      window.APP_ENV
    }&tenant=${TENANT.toLowerCase()}`
  )
    .then((response) => response.json())
    .catch((error) => new Error("Looks like there was a problem: ", error));

/**
 * Tariff detail calls
 * */

export const useTariffRootInfo = (queryParams, opts) =>
  useREST(
    "/productDetail/root",
    extendURLParams(queryParams || getPdpRequestPayload()),
    opts
  );

// for tariff only: if root call returns hasBookableHardware === false, we don't make any other call
export const tariffHasBookableHardware = (queryParams, opts) => {
  const rootInfo = useTariffRootInfo(queryParams, opts);
  if (!rootInfo || !rootInfo.data) return false;
  const {
    data: { hasBookableHardware },
  } = rootInfo;
  return hasBookableHardware;
};

export const useRawTariffVariants = (queryParams, opts) =>
  useREST(
    "/productDetail/extendedVariants",
    extendURLParams(queryParams || getPdpRequestPayload()),
    {
      ...opts,
      enabled: tariffHasBookableHardware(queryParams, opts),
    }
  );

export const useHardwares = (queryParams) =>
  useREST(
    "/productDetail/tariffHardwares",
    extendURLParams(queryParams || getPdpRequestPayload()),
    {
      enabled: tariffHasBookableHardware(queryParams),
    }
  );

/**
 * Listingpage Calls
 * */

export const useListingSections = (queryParams, type) =>
  useRESTv1(`/section/page/${type}`, extendURLParams(queryParams));

export const useHardwaresListing = (queryParams) =>
  useREST("/listing", extendURLParams(queryParams));

export const useTariffsListing = (queryParams) =>
  useREST("/listing", extendURLParams(queryParams));

// Shows active manufacturers
export const useManufacturer = (queryParams) =>
  useRESTv1(`/manufacturer`, extendURLParams(queryParams));
// Get ExtendedVariants for handleClick in FilteredItems with new tariff params
export const getExtendedVariants = (queryParams) =>
  fetch(
    `${window.REST_URL}productDetail/extendedVariants?offerType=${
      queryParams.offerType
    }&offerGroupUrl=${queryParams.offerGroupUrl}&carrier=${queryParams.carrier
      .toLowerCase()
      .replace(" ", "-")}&tariff=${encodeURIComponent(
      queryParams.urlName
    )}&manufacturer=${
      queryParams.manufacturerUrl
    }&tenant=${TENANT.toLowerCase()}&environment=${window.APP_ENV}`,
    {
      credentials: "include",
    }
  );

/**
 * Status calls
 * */

export const verifyOfferGroup = (offerGroupeUrl, opts) =>
  apiStatusCall(
    `/offergroup/${offerGroupeUrl}/exists`,
    extendURLParams(),
    opts
  );

export const verifyOfferGroupWithStorageAndColor = (
  offerGroupeUrl,
  color,
  storage,
  opts
) =>
  useRESTv1(
    `/offergroup/${offerGroupeUrl}/exists?color=${color}&storage=${storage}`,
    extendURLParams(),
    opts
  );

export const verifyOfferGroupWithStorageAndColorAndTariff = (
  offerGroupeUrl,
  color,
  storage,
  tariff,
  opts
) =>
  useRESTv1(
    `/offergroup/${offerGroupeUrl}/exists?color=${color}&storage=${storage}&tariff=${tariff}`,
    extendURLParams(),
    opts
  );

/**
 * General calls
 * */

export const usePage = (opts) => {
  const { pathname } = window.location;
  // remove / since DB only searches for page name
  const params = _.replace(pathname, /\//g, "");

  // if this is true we are on the homepage and the homepage has no URL/name in the DB.
  // Therefore we need to search for startpage
  if (pathname !== "/") {
    return useRESTv1(`/section/page/${params}`, extendURLParams(), opts);
  }

  return useRESTv1(`/section/page`, extendURLParams(), opts);
};

export const useLegalNote = (tariffId, opts) =>
  useREST(
    "/legalNote",
    extendURLParams({
      tariffId,
    }),
    opts
  );

export const useHardwareDetails = (hardwareId, opts) =>
  useRESTv1(`/hardware/${hardwareId}/details`, extendURLParams(), opts);

export const useTariffDetails = (tariffId, opts) =>
  useRESTv1(`/tariff/${tariffId}/details`, extendURLParams(), opts);

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const postToCart = () => {};
