import React from "react";
import { HeadlineSourceSansPro, Surface } from "@mm/ui";

import { InternetServiceProvider } from "components/organisms";

import { useInternetProvider } from "features/Internet";

const InternetServiceProviderSection = () => {
  const {
    state: {
      availabilityChecked,
      availableProviders,
      page: { url },
    },
  } = useInternetProvider();

  if (availabilityChecked) {
    return null;
  }
  return (
    <Surface variant="narrow" shape="roundedSmall" mt={[6, 6, 6, 0, 0]}>
      <HeadlineSourceSansPro
        type="h2"
        weight="600"
        size="24px"
        mb="4"
        text="Unsere Internet Anbieter"
      />
      <InternetServiceProvider
        availableProviders={availableProviders}
        url={url}
      />
    </Surface>
  );
};

export default InternetServiceProviderSection;
