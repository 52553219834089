import styled from "styled-components";

const Header = styled.p`
  color: ${(props) => props.theme.colors.grey[2]};
  font-size: 16px;
  line-height: 1.25;
  padding: 0;
  margin: 0 0 16px;
`;

export default Header;
