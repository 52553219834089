import React from "react";

import styled from "styled-components";

import { Spinner } from "@/components/atoms";

import { useInternetProvider } from "@/features/Internet";

const SpinnerWrapper = styled.div`
  position: sticky;
  top: 33vh;
  bottom: 50vh;
  height: 4rem;
  width: 4rem;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9998;
  background-color: ${({ theme }) => theme.colors.white};
  display: ${(props) => (props.show ? "flex" : "none")};
  justify-content: center;
  padding-top: 33vh;
  padding-bottom: 50vh;
  opacity: 0.5;
`;

const LoadingOverlay = () => {
  const {
    state: { showLoadingOverlay },
  } = useInternetProvider();

  return (
    <Overlay show={showLoadingOverlay}>
      <SpinnerWrapper>
        <Spinner color="ghost" size="4rem" />
      </SpinnerWrapper>
    </Overlay>
  );
};

export default LoadingOverlay;
