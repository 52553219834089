import { zodKeys } from "@/utils/zodKeys";
export const getSchemas = (sections, options, state) => {
    return sections.flatMap((section) => {
        // exclude schemas for hidden sections
        if ("hide" in section) {
            if (section.hide) {
                if (typeof section.hide === "function") {
                    if (section.hide(options, state))
                        return [];
                }
                else {
                    return [];
                }
            }
        }
        if ("sections" in section) {
            return getSchemas(section.sections, options, state);
        }
        if ("schema" in section && section.schema) {
            return section.schema;
        }
        return [];
    });
};
export const getSchema = (config, options, state) => {
    if ("sections" in config) {
        return getSchemas(config.sections, options, state);
    }
    return config.steps.flatMap(({ sections }) => getSchemas(sections, options, state));
};
/**
 * Expects a `path` or array of `paths` and returns the index of the first step that
 * is found to include one of the paths in its schema.
 */
export const getFirstStepWithField = (path, config, options, state) => {
    const steps = config.steps;
    const schemas = steps.map((step) => getSchemas(step.sections, options, state));
    const schemaKeysByStep = schemas.map((step) => step.flatMap(zodKeys));
    const index = schemaKeysByStep.findIndex((fields) => Array.isArray(path)
        ? path.some((field) => fields.includes(field))
        : fields.includes(path));
    const step = index > -1 ? steps[index] : undefined;
    return {
        index: index > -1 ? index + 1 : undefined,
        step,
    };
};
