import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Fragment } from "react";
import styled from "styled-components";
import dataLayer from "@/helper/dataLayer";
import { useViewport } from "@core/theme";
import Arrow from "@core/svgs/arrow.svg";
import { HorizontalDivider, LinkedImageContainer } from "@/components/atoms";
import { FlyoutManufacturerTileContainer } from "@/components/molecules";
import { FlyoutSectionElementType, } from "@/types";
const LayoutSection = styled.section `
  &.navDesktop {
    display: flex;
    flex-direction: column;
    padding: 0.5rem 3rem;
    min-width: 20rem;
    max-width: 32rem;
  }
  &.navDesktopTeaserSection {
    max-width: none;
  }
  &.navMobile {
    margin: 0.5rem 0;
  }
  &.navMobileProviderSection {
    margin: 0.5rem 0.5rem;
  }
  &.navMobileLinkSection {
    margin: 0.5rem 0 0;
  }
`;
const SectionHeadline = styled.h2 `
  font-size: 1.25rem;
  color: ${(props) => props.theme.colors.grey[6]};
  padding: 0 0.5rem;
  margin: 0 0 1rem 0;

  &.navDesktopOfferGroupSection {
    margin: 0 0 0.25rem 0;
  }
  &.navDesktopLinkSection {
    margin: 0 0 0.25rem 0;
  }
`;
const ListWrapper = styled.ul `
  padding: 0;
  margin: 0;
  list-style-type: none;
  &.navDesktop {
    ${"li"} {
      height: 30px;
    }
  }
`;
const StyledAnchor = styled.a `
  color: ${(props) => props.theme.colors.grey[6]};
  display: flex;
  flex-direction: row;
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;
  width: 100%;
  &.navMobile {
    padding: 0.75rem 0.5rem;
    :active {
      background-color: ${(props) => props.theme.colors.grey[1]};
    }
  }
  &.navDesktop {
    padding: 0.5rem 0.25rem;
  }
`;
const StyledPrimaryAnchor = styled.a `
  align-items: center;
  background-color: ${(props) => props.theme.colors.brand.primary};
  border: 1px solid ${(props) => props.theme.colors.grey[2]};
  box-shadow: none;
  color: ${(props) => props.theme.colors.white};
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 600;
  height: 3rem;
  justify-content: center;
  padding: 0;
  text-align: center;
  text-decoration: inherit;

  transition-duration: 0.15s;
  transition-timing-function: ease-in-out;
  transition-property: border-color, box-shadow, transform;

  &:active,
  &:focus,
  &:hover {
    border-color: rgba(0, 0, 0, 0);
    box-shadow: rgb(0 0 0 / 7%) 0px 8px 10px 1px,
      rgb(0 0 0 / 6%) 0px 3px 14px 2px, rgb(0 0 0 / 10%) 0px 5px 5px -3px;
  }
  ${"svg"} {
    fill: ${(props) => props.theme.colors.white};
    margin-left: 0.5rem;
    transform: scale(0.7) rotate(90deg);
  }
  &.navDesktop {
    margin-top: auto;
  }
  &.navMobile {
    margin: 1rem 0.5rem 0 0.5rem;
  }
`;
const VerticalDivider = styled.div `
  :not(:first-child) {
    background-color: ${(props) => props.theme.colors.grey[2]};
    min-width: 1px;
  }
`;
const renderSection = (section, layoutVariant, topName, topUrl, headlineText) => {
    if (section.type === FlyoutSectionElementType.offerGroupSection ||
        section.type === FlyoutSectionElementType.linkSection) {
        return (_jsxs(_Fragment, { children: [_jsx(ListWrapper, { className: layoutVariant, children: section.data.map(({ manufacturer, name, linkUrl }, index) => {
                        return (_jsx("li", { className: layoutVariant, children: _jsx(StyledAnchor, { href: linkUrl, onClick: () => dataLayer({
                                    eventCategory: `Flyout`,
                                    eventAction: "click",
                                    eventLabel: `${headlineText}:position${index}:${name}:${linkUrl}`,
                                }), className: layoutVariant, children: _jsxs("span", { children: [manufacturer && _jsxs("b", { children: [manufacturer, "\u00A0"] }), name] }) }) }, `flyout-anchor-${name}-${index}`));
                    }) }), _jsxs(StyledPrimaryAnchor, { className: layoutVariant, href: topUrl, onClick: () => dataLayer({
                        eventCategory: `Flyout`,
                        eventAction: "click",
                        eventLabel: `${headlineText}:Alle ${topName}:${topUrl}`,
                    }), children: ["Alle", " ", topName === "Handy mit Vertrag" ? "Handys mit Vertrag" : "Tarife", _jsx(Arrow, {})] })] }));
    }
    if (section.type === FlyoutSectionElementType.providerSection) {
        return (_jsx(FlyoutManufacturerTileContainer, { manufacturers: section.data.map((item) => item.name.toLowerCase()), variant: layoutVariant + section.type, handleManufacturerClick: (index, name) => {
                dataLayer({
                    eventCategory: "Flyout",
                    eventAction: "click",
                    eventLabel: `${name}:${index}`,
                });
            } }));
    }
    if (section.type === FlyoutSectionElementType.manufacturerSection) {
        const extraTile = {
            text: "Weitere",
            get label() {
                return (_jsxs(_Fragment, { children: [this.text, _jsx(Arrow, {})] }));
            },
            url: "/smartphones",
        };
        return (_jsx(FlyoutManufacturerTileContainer, { manufacturers: section.data.map((item) => item.name.toLowerCase()), variant: layoutVariant + section.type, handleManufacturerClick: (index, name) => {
                dataLayer({
                    eventCategory: "Flyout",
                    eventAction: "click",
                    eventLabel: `${name}:${index}`,
                });
            }, handleExtraTileClick: () => {
                dataLayer({
                    eventCategory: "Flyout",
                    eventAction: "click",
                    eventLabel: `${extraTile.text}:${extraTile.url}`,
                });
            }, extraTile: extraTile }));
    }
    if (section.type === FlyoutSectionElementType.teaserSection) {
        return (_jsx(LinkedImageContainer, { alt: section.data[0].name, imageUrl: section.data[0].smUrl, linkUrl: section.data[0].linkUrl, qaName: "LinkedImageContainer", variant: layoutVariant, parent: "Flyout", type: "Teaser" }));
    }
};
const FlyoutSections = ({ sections, topName, topUrl, layoutVariant, }) => {
    const { is } = useViewport();
    return (_jsx(Fragment, { children: sections.map(({ headlineText, elements }, index) => {
            return (_jsxs(Fragment, { children: [is?.desktop ? _jsx(VerticalDivider, {}) : _jsx(HorizontalDivider, {}), _jsxs(LayoutSection, { className: `${layoutVariant} flyout-section-${index} ${layoutVariant + elements[0].type}`, children: [headlineText && (_jsx(SectionHeadline, { className: layoutVariant + elements[0].type, children: headlineText })), renderSection(elements[0], layoutVariant, topName, topUrl, headlineText)] }, `flyout-section-${index}`)] }, index));
        }) }, "FlyoutSections"));
};
export default FlyoutSections;
