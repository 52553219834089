import React from "react";
import PropTypes from "prop-types";

import SectionContainer from "sections/SectionContainer";

import Sections from "../../pages/sections";

function RenderListingSection({ section }) {
  if (!section) {
    // eslint-disable-next-line no-console
    console.warn("There are no Sections for this Page");
    return <></>;
  }

  const { elements, headlineText, useAsAnchor, id } = section;

  if (!elements[0]) {
    return null;
  }

  const sectionKey = `${elements[0].type}-${Math.random()}`;

  const sectionComponents = elements.map(({ type, ...props }) => {
    if (!type) {
      return null;
    }
    if (Object.prototype.hasOwnProperty.call(Sections, type)) {
      return React.createElement(Sections[type], {
        ...props,
        type,
        section,
        key: `${id}-${type}`,
      });
    }
    return null;
  });

  return (
    <SectionContainer
      useAsAnchor={useAsAnchor}
      id={sectionKey}
      headlineText={headlineText}
      key={sectionKey}
    >
      {sectionComponents}
    </SectionContainer>
  );
}

RenderListingSection.defaultProps = {
  section: undefined,
};

RenderListingSection.propTypes = {
  section: PropTypes.shape({}),
};

export default RenderListingSection;
