import React from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import styled from "styled-components";
import classNames from "classnames";

import PlusBubble from "@core/svgs/plus-bubble.svg";
import CheckBubble from "@core/svgs/checkmark.svg";
import Arrow from "@core/svgs/arrow.svg";

import Flex from "../Flex";

import Label from "./Label";

const Wrapper = styled(Flex)`
  position: relative;
  min-width: ${({ value }) => (value ? "20px" : "auto")};
  min-height: 56px;
  border-bottom: 2px solid;
  border-bottom-color: ${({ theme: { colors }, value }) =>
    value ? colors.grey[5] : colors.grey[2]};
  outline: none;

  &:not(.disabled) {
    &:hover,
    &:focus,
    &:active {
      border-bottom-color: ${({ theme }) => theme.colors.grey[5]};

      svg {
        fill: ${({ theme }) => theme.colors.grey[5]};
      }
    }

    &:hover,
    &:focus,
    &:focus-within {
      label {
        top: 0;
        font-size: ${({ theme }) => theme.typography.size.s}px;
        line-height: 1;
        margin-top: ${({ theme }) => theme.space.xs}px;
        z-index: 2;
      }
    }
  }

  svg {
    fill: ${({ theme: { colors }, value }) =>
      value ? colors.grey[5] : colors.grey[2]};
  }

  ${(props) => {
    switch (props.layout) {
      case "error":
        return `
                    border-bottom-color: ${props.theme.colors.brand.primary} !important;
                `;
      case "disabled":
        return `
                    border-bottom-color: #cdcaca;
                `;
      default:
        return ``;
    }
  }}
`;

export const Input = styled("input")`
  border: 0;
  font-size: ${({ theme }) => theme.typography.size.m}px;
  line-height: 2;
  color: inherit;
  width: 100%;
  min-width: 20px;
  background: transparent;
  outline: none;
  padding-right: 21px;
`;

const Message = styled("div")`
  font-size: ${({ theme }) => theme.typography.size.s}px;
  line-height: 1;
  color: ${({ theme }) => theme.colors.grey[4]};
  margin-top: ${({ theme }) => theme.space.xs}px;
  margin-bottom: ${({ theme }) => theme.space.xs}px;

  ${(props) => {
    switch (props.layout) {
      case "error":
        return `
                    color: ${props.theme.colors.brand.primary};
                `;
      default:
        return ``;
    }
  }}
`;

const PlusIcon = styled(PlusBubble)`
  position: absolute;
  bottom: 8px;
  right: 0;
  display: none;
  transform: rotate(45deg);

  ${(props) => {
    switch (props.layout) {
      case "error":
        return `
                    display: block;
                `;
      case "disabled":
        return `
                    display: block;
                `;
      default:
        return `
                    display: none;
                `;
    }
  }}
`;

const CheckMark = styled(CheckBubble)`
  position: absolute;
  bottom: 8px;
  right: 0;
  display: none;

  ${(props) => {
    switch (props.layout) {
      case "success":
        return `
                    display: block;
                `;
      default:
        return `
                    display: none;
                `;
    }
  }}
`;

const ArrowIcon = styled(Arrow)`
  position: absolute;
  bottom: 8px;
  right: 0;
  transition: all 0.25s;
  transform-origin: center;

  ${(props) => {
    switch (props.layout) {
      case "success":
        return `
                    display: none;
                `;
      case "error":
        return `
                    display: none;
                `;
      default:
        return ``;
    }
  }}

  ${(props) => {
    if (props.open) {
      return `transform: rotate(0deg)`;
    }

    return `transform: rotate(180deg)`;
  }}
`;

const OptionList = styled("div")`
  position: absolute;
  bottom: -232px;
  width: 300px;
  height: 200px;
  background: white;
  padding: 12px 0;
  border-radius: 4px;
  border: 2px solid #c2c2c2;
  overflow: hidden;
  z-index: 99;

  .list {
    display: block;
    height: 100%;
    padding: 0 12px;
    margin: 0;
    overflow-x: hidden;
    list-style: none;

    .listItem {
      &:hover,
      &:focus {
        background: #cdcaca;
      }

      &__button {
        appearance: none;
        border: 0;
        background: transparent;
        width: 100%;
        text-align: left;
        padding: 4px 12px;
        margin: 2px;
        cursor: pointer;
      }
    }
  }
`;

const StyledSuffix = styled("span")`
  line-height: 2;
`;

const InputSelect = ({
  value,
  label,
  suffix,
  layout,
  message,
  disabled,
  onChange,
  onFocus,
  onBlur,
  onClick,
  maxLength,
  onKeyPress,
  width,
  id,
  options,
  open,
  qaName,
  className,
}) => {
  return (
    <div data-tk-cobrowse-hidden="true">
      <Wrapper
        className={classNames(disabled ? "disabled" : "", className)}
        justifyContent="space-between"
        flexWrap="wrap"
        alignContent="flex-end"
        layout={layout}
        value={value}
        width={width}
      >
        <Label
          htmlFor={id}
          text={label}
          size={value && "small"}
          layout={layout}
        />
        <>
          <Input
            id={id}
            value={value}
            disabled={disabled}
            layout={layout}
            onChange={(evt) => onChange && onChange(evt.currentTarget.value)}
            onFocus={(evt) => onFocus && onFocus(evt.currentTarget.value)}
            onBlur={(evt) => onBlur && onBlur(evt.currentTarget.value)}
            onKeyPress={onKeyPress}
            maxLength={maxLength}
            data-qa={qaName}
          />
          <StyledSuffix>{value && suffix}</StyledSuffix>
          <PlusIcon
            layout={layout}
            width="16"
            height="16"
            viewBox="0 0 25 25"
          />
          <CheckMark layout={layout} width="16" height="16" />
          <ArrowIcon
            layout={layout}
            open={open}
            width="13"
            height="10"
            onClick={onClick}
          />
        </>
        {options && open && (
          <OptionList>
            <ul className="list" data-tk-cobrowse-hidden="true">
              {options.map((item) => (
                <li key={item} className="listItem">
                  <button
                    type="button"
                    onClick={() => onChange && onChange(item)}
                    className="listItem__button"
                  >
                    {item}
                  </button>
                </li>
              ))}
            </ul>
          </OptionList>
        )}
      </Wrapper>
      {message && <Message layout={layout}>{message}</Message>}
    </div>
  );
};

InputSelect.defaultProps = {
  suffix: null,
  layout: "normal",
  message: null,
  disabled: false,
  onBlur: noop,
  onFocus: noop,
  onKeyPress: noop,
  maxLength: undefined,
  width: "100%",
  qaName: "default-qa-name",
};

InputSelect.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  suffix: PropTypes.string,
  message: PropTypes.string,
  layout: PropTypes.oneOf(["error", "success", "disabled", "normal"]),
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyPress: PropTypes.func,
  maxLength: PropTypes.string,
  width: PropTypes.string,
  qaName: PropTypes.string,
  className: PropTypes.string,
};

export default InputSelect;
