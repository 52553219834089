import React from "react";

import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

import prepMarkdown from "utils/prepMarkdown";

export const RichSEOTextSection = ({ markdown }) => {
  return (
    <ReactMarkdown rehypePlugins={[rehypeRaw]}>
      {prepMarkdown(markdown)}
    </ReactMarkdown>
  );
};

export default RichSEOTextSection;
