import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { noop } from "lodash";

import Disabled from "components/atoms/Disabled";

// TODO migrate to @mm/ui/Box
const Box = styled("div")`
  position: relative;
  display: inline-block;
  width: 80px;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px ${({ theme }) => theme.colors.grey[2]} solid;
  border-radius: ${({ theme }) => theme.borderRadius[0]};
  padding: ${({ theme }) => theme.padding[0]} ${({ theme }) => theme.padding[3]};
  ${({ selected, theme }) =>
    selected && `border: 2px ${theme.colors.primary[0]} solid;`}
  ${({ disabled }) => !disabled && `cursor: pointer;`}
`;

const Selectable = ({
  children,
  selected,
  disabled,
  onClick,
  onMouseEnter,
  onMouseLeave,
  codeception,
}) => (
  <Box
    data-codeception={codeception}
    selected={!disabled && selected}
    disabled={disabled}
    onMouseEnter={() => !disabled && onMouseEnter && onMouseEnter()}
    onMouseLeave={() => !disabled && onMouseLeave && onMouseLeave()}
    onClick={() => !disabled && onClick && onClick()}
  >
    <>
      {children}
      {disabled && <Disabled />}
    </>
  </Box>
);

Selectable.defaultProps = {
  selected: false,
  disabled: false,
  onMouseEnter: noop,
  onMouseLeave: noop,
  codeception: "",
};

Selectable.propTypes = {
  onClick: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  codeception: PropTypes.string,
};

export default Selectable;
