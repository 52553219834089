import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { LoadingOverlay, PageHeader, PageFooter } from "@/components/organisms";
import { PageHeroSection, WaipuTariffSection } from "@/sections";
import { StandardPageTemplate } from "@/components/templates";
import { usePage } from "@/utils/fetchData";
import { Loading } from "@/components/molecules";
import PageSections from "@/pages/PageSections";
import { useLocation } from "react-router-dom";
import dataLayer from "@/helper/dataLayer";
import styled from "styled-components";
const WaipuPage = () => {
    const { data: page } = usePage();
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    useEffect(() => {
        if (page) {
            dataLayer({
                pageType: "Landingpage",
                category: "Waipu",
            });
        }
    }, [page]);
    const StyledWaipuLogo = styled("div") `
    position: absolute;
    left: 20px;
    bottom: 20px;
    color: white;

    .pageHeroLogoText {
      display: block;
    }

    @media (min-width: ${({ theme }) => theme.breakpointsMap.md.min}px) {
      left: unset;
      bottom: unset;
      right: 40px;
      top: 40px;
    }
  `;
    const WaipuLogo = () => {
        return (_jsxs(StyledWaipuLogo, { children: [_jsx("span", { className: "pageHeroLogoText", children: "Unser Streaming-Partner" }), _jsx("img", { src: "https://storage.googleapis.com/content-ekontor24/mediamarkt/img/campaign/waiputv/logo.svg", alt: "Waipu Logo with white font", className: "pageHeroLogo", width: "232", height: "44.75" })] }));
    };
    if (!page)
        return _jsx(Loading, { variant: "home" });
    return (_jsx(StandardPageTemplate, { header: _jsx(PageHeader, { itemCount: 1 }), sections: [
            _jsx(PageHeroSection, { page: page, logo: _jsx(WaipuLogo, {}) }, "PageHeroSection"),
            _jsx(WaipuTariffSection, { offers: page.offers }, "WaipuTariffSection"),
            _jsx(PageSections, { sections: page.sections, variant: page.siteType }, "PageSections"),
            _jsx(LoadingOverlay, {}, "LoadingOverlay"),
        ], footer: _jsx(PageFooter, {}) }));
};
export default WaipuPage;
