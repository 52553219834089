import PropTypes from "prop-types";

export const optionPropTypes = PropTypes.shape({
  label: PropTypes.node.isRequired,
  value: PropTypes.string.isRequired,
});

export default {
  optionPropTypes,
};
