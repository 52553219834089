import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import IconButton from "../IconButton";

const Header = styled.p`
  color: ${(props) => props.theme.colors.grey[2]};
  font-size: 12px;
  line-height: 1.33;
  padding: 0;
  margin: 0 0 16px;
`;

const Ul = styled.ul`
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const Li = styled.li`
  margin-right: 16px;
`;

const IconList = ({ header, icons }) => (
  <>
    <Header>{header}</Header>
    <Ul>
      {icons.map((icon, index) => {
        const elemKey = index; // @TODO: ESLint: Set correct key

        return (
          <Li key={elemKey}>
            <IconButton svg={icon} />
          </Li>
        );
      })}
    </Ul>
  </>
);

IconList.propTypes = {
  header: PropTypes.string.isRequired,
  icons: PropTypes.arrayOf(PropTypes.node).isRequired,
};
export default IconList;
