export const getCarrierOverwrite = (carrier) => {
    if (carrier === "Super Select") {
        return "in den Netzen der Telefónica";
    }
    if (carrier === "Telefonica") {
        return "im Telefónica Netz";
    }
    if (carrier === "Vodafone klarmobil") {
        return "im D-Netz";
    }
    if (carrier === "Telekom klarmobil") {
        return "im D-Netz";
    }
    return undefined;
};
