export var InstallationTimeWindow;
(function (InstallationTimeWindow) {
    InstallationTimeWindow["NotSpecified"] = "NOT_SPECIFIED";
    InstallationTimeWindow["EightToTwelve"] = "EIGHT_TO_TWELVE";
    InstallationTimeWindow["TwelveToFour"] = "TWELVE_TO_FOUR";
})(InstallationTimeWindow || (InstallationTimeWindow = {}));
export const getInstallationTimeWindowLabel = (installationTimeWindow) => {
    switch (installationTimeWindow) {
        case InstallationTimeWindow.NotSpecified: {
            return "Kein bestimmtes Zeitfenster ausgewählt";
        }
        case InstallationTimeWindow.EightToTwelve: {
            return "8 - 12 Uhr";
        }
        case InstallationTimeWindow.TwelveToFour: {
            return "12 - 16 Uhr";
        }
        default: {
            throw new Error(`InstallationTimeWindow ${installationTimeWindow} not defined`);
        }
    }
};
