import styled from "styled-components";

// TODO: remove !important once we can build the new shop without the old one. See: MMSD-2711
const InlineLink = styled.a`
  text-decoration: underline !important;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.grey[6]};
`;

InlineLink.propTypes = {};

export default InlineLink;
