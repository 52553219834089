import PropTypes from "prop-types";
import React, { useState } from "react";
import styled from "styled-components";
import { noop } from "lodash";

import Checkbox from "../../atoms/Checkbox";
import InlineLink from "../../atoms/InlineLink";

export const Row = styled.div`
  display: flex;
  margin: 0.5em 0;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
`;

const Label = styled.div`
  flex: 1;
  margin-left: 0.5em;
  color: ${({ disabled, theme }) =>
    disabled ? theme.colors.grey[3] : theme.colors.grey[0]};
`;

const toggleSelection = (onChange, list, item, index, disabled) => {
  // clunky workaround for eslint/no-param-reassign
  if (disabled) return;
  const updated = list;
  updated[index].selected = !updated[index].selected;
  onChange(updated);
};

const CheckboxList = ({ list, onChange, limit = 0, suffix = "" }) => {
  const [expanded, setExpanded] = useState(false);
  // MMSD-2509: force a bool here, using `!!` to prevent `showMoreLink`
  // rendering the number "0" when it's supposed to be false
  const showMoreLink = !!(limit && list.length > limit);
  const visibleList =
    (showMoreLink && !expanded && list.slice(0, limit)) || list;

  const getQaTag = (name) => {
    switch (name) {
      case "o2":
        return "filterO2";
      case "Vodafone":
        return "filterVF";
      case "Super Select":
        return "filterSuSe";
      case "Telekom":
        return "filterTelekom";
      default:
        return null;
    }
  };

  return (
    <>
      {visibleList.map((item, index) => {
        const disabled = item.count === 0;
        return (
          <Row
            onClick={() =>
              toggleSelection(onChange, list, item, index, disabled)
            }
            key={item.name}
            disabled={disabled}
          >
            <Checkbox disabled={disabled} checked={item.selected} />
            <Label disabled={disabled} data-qa={getQaTag(item.name)}>
              {`${item.name} ${suffix} ${
                item.count !== -1 ? `(${item.count})` : ""
              }`}
            </Label>
          </Row>
        );
      })}
      {showMoreLink && (
        <InlineLink onClick={() => setExpanded(!expanded)}>
          {`${(expanded && "Weniger anzeigen") || "Mehr anzeigen"} (${
            list.length - limit
          })`}
        </InlineLink>
      )}
    </>
  );
};

/// PropTypes.object

CheckboxList.propTypes = {
  // how many items should be visible (rest can be shown by expanding the list)
  limit: PropTypes.number,
  onChange: PropTypes.func,
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
};

CheckboxList.defaultProps = {
  onChange: noop,
  limit: 10,
};

export default CheckboxList;
