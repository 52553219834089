import React from "react";

import PropTypes from "prop-types";

import { noop } from "lodash";

import { Headline, Text, Box, Modal, Row, Col } from "@mm/ui";

import { VerticalDivider } from "components/atoms";

const CartFilledModal = ({
  leftItem,
  rightItem,
  modalOpened,
  modalOnRequestClose,
}) => (
  <Modal
    dynamicHeight
    isInitialOpen={modalOpened}
    onRequestClose={modalOnRequestClose}
    size="xl"
  >
    <Headline variant="h1">
      Ihre Bestellung ist noch nicht abgeschlossen
    </Headline>
    <Box mb={4}>
      <Text>
        Es ist nur möglich, ein Gerät pro Bestellung zu kaufen. Möchten Sie
        beide Geräte kaufen, dann schließen Sie bitte erst die alte Bestellung
        ab und starten Sie anschließend eine neue Bestellung.
      </Text>
    </Box>
    <Row flexDirection="row">
      <Col xs={11} md={10}>
        {leftItem}
      </Col>
      <Col xs={2} md={4}>
        <VerticalDivider>ODER</VerticalDivider>
      </Col>
      <Col xs={0} md={0}>
        <Box mt={6} />
      </Col>
      <Col xs={11} md={10}>
        {rightItem}
      </Col>
    </Row>
  </Modal>
);

CartFilledModal.defaultProps = {
  modalOpened: false,
  modalOnRequestClose: noop,
};

CartFilledModal.propTypes = {
  leftItem: PropTypes.node.isRequired,
  rightItem: PropTypes.node.isRequired,
  modalOpened: PropTypes.bool,
  modalOnRequestClose: PropTypes.func,
};

export default CartFilledModal;
