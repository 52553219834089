import React from "react";
import PropTypes from "prop-types";

import { ThemeProvider } from "@core/theme";
import { IntlProvider, messages } from "@core/text";

import { PageFooter } from "components/organisms";

const PageFooterSection = ({ reducedFooter, openModal }) => (
  <ThemeProvider>
    <IntlProvider locale="de" messages={messages}>
      <PageFooter reduced={reducedFooter} openModal={openModal} />
    </IntlProvider>
  </ThemeProvider>
);

PageFooterSection.propTypes = {
  openModal: PropTypes.string,
  reducedFooter: PropTypes.bool,
};

PageFooterSection.defaultProps = {
  reducedFooter: false,
  openModal: null,
};

export default PageFooterSection;
