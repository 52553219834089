import React from "react";
import PropTypes from "prop-types";
import { breakpoints } from "@core/theme/src/theme/theme";

import StyledImg from "./StyledImg";

const deleteCDNUrl = (imagepath) => {
  return imagepath.replace(window.CDN_URL, "");
};

const Image = ({ src, srcSet, alt, className, height, width }) => {
  if (srcSet) {
    const imageType = Object.keys(srcSet)[0];
    return (
      <picture className={className}>
        <source
          srcSet={window.CDN_URL + srcSet[imageType].large}
          media={`(min-width: ${breakpoints.md})`}
        />
        <source
          srcSet={window.CDN_URL + srcSet[imageType].medium}
          media={`(min-width: ${breakpoints.xs})`}
        />
        <StyledImg
          alt={alt}
          src={window.CDN_URL + srcSet[imageType].medium}
          height={height}
          width={width}
        />
      </picture>
    );
  }

  if (src) {
    return (
      <StyledImg
        alt={alt}
        className={className}
        src={window.CDN_URL + deleteCDNUrl(src)}
        height={height}
        width={width}
      />
    );
  }

  return <p>Es konnten kein Bilderpfade gefunden werden.</p>;
};

Image.defaultProps = {
  className: "",
  alt: "",
  src: undefined,
  srcSet: undefined,
  fallback: undefined,
  styledHeight: undefined,
  styledWidth: undefined,
};

Image.propTypes = {
  /**
   * used to allow usage of styled(Image)
   */
  className: PropTypes.string,
  src: PropTypes.string,
  srcSet: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
  /**
   * fallback image in case of error
   */
  fallback: PropTypes.string,
  alt: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default Image;
