import { jsx as _jsx } from "react/jsx-runtime";
import { Surface } from "@mm/ui";
import { ThemeProvider } from "@core/theme";
import { Spinner } from "@/components/atoms";
import { InternetOfferTeaser } from "@/components/organisms";
import { useInternetProvider } from "@/features/Internet";
import styled from "styled-components";
const SpinnerWrapper = styled.div `
  display: flex;
  justify-content: center;
`;
const InternetOfferGroupsTeaser = () => {
    const { state: { allOffers = [], availabilityChecked, availableProviders, isLoadingOffers, offers = [], showOffers, }, } = useInternetProvider();
    if (showOffers && isLoadingOffers) {
        return (_jsx(Surface, { variant: "narrow", mt: "44px", children: _jsx(SpinnerWrapper, { children: _jsx(Spinner, { color: "ghost", size: "4rem" }) }) }));
    }
    if (allOffers.length < 1) {
        return null;
    }
    return (_jsx(ThemeProvider, { children: _jsx(Surface, { variant: "narrow", mt: "44px", children: _jsx(InternetOfferTeaser, { availabilityChecked: availabilityChecked, data: offers, availableProviders: availableProviders }) }) }));
};
export default InternetOfferGroupsTeaser;
