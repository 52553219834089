import React from "react";

import { Box, Headline, Surface, Row, Col } from "@mm/ui";

import { useViewport } from "@core/theme";

import { Breadcrumb, ProductDetailPageTopBanner } from "components/atoms";

import {
  Loading,
  DeliveryInfoModal,
  DetailsCoupon,
} from "components/molecules";

import { TariffDetails, TariffHardwareSelector } from "components/organisms";

import { PriceSection } from "sections";

import { ButtonGroup, Button } from "components/atoms/ButtonGroup";

import {
  useOfferConfigurator,
  COUPON,
  HARDWARE,
  SET_SELECTED_ACCESSORY_TYPE,
} from "features/Offer";

import dataLayer from "helper/dataLayer";

import { ProductHeadline } from "./ProductHeadline";

const TariffDetailsSection = () => {
  const { is } = useViewport();
  // FIXME move this useOfferConfigurator call to sub components whenever possible
  const { dispatch, state } = useOfferConfigurator();

  const {
    rootInfo,
    extendedVariant: activeExtendedVariant,
    extendedVariants,
    selectedAccessoryType,
    hasCoupon,
    activeAccessoryVariantIds,
  } = state;

  const loadingElem = <Loading variant="details" marginTop={4} gridWrapper />;

  if (!rootInfo) {
    return loadingElem;
  }

  const {
    breadcrumb,
    tariff,
    coupon,
    hasBookableHardware,
    deliveryPrice: rootDeliveryPrice,
  } = rootInfo;

  if (hasBookableHardware && (!extendedVariants || !activeExtendedVariant)) {
    return loadingElem;
  }

  const deliveryPrice =
    activeExtendedVariant?.variant?.deliveryPrice || rootDeliveryPrice;

  if (!tariff) return null;

  /**
   * TODO: Different prop names (and different data sources)
   * are caused by strange state structure in general.
   * This needs to get fixed by a clean defined state structure.
   */
  const manufacturer =
    hasBookableHardware &&
    (state.manufacturer || activeExtendedVariant.manufacturer);
  const hardwareName =
    hasBookableHardware && (state.name || activeExtendedVariant.hardwareName);

  return (
    <>
      <Surface variant="narrow" mt={[4, 4, 4, 6, 6]}>
        {is.desktop && <ProductHeadline tariff={tariff} variant="h2" />}
        {breadcrumb && breadcrumb.length && (
          <Breadcrumb isProductDetailInterface items={breadcrumb} />
        )}
      </Surface>
      <Surface variant="narrow" mt={[4, 4, 4, 6, 6]}>
        <ProductDetailPageTopBanner
          conditions={{
            tariff: tariff?.id,
            provider: tariff?.serviceProvider,
          }}
        />
      </Surface>
      <Surface variant="narrow" mt={[4, 4, 4, 6, 6]}>
        <Row>
          <Col xs={24} xl={12}>
            {tariff.carrier && (
              <TariffDetails
                eyeCatchers={
                  rootInfo.eyeCatchers || activeExtendedVariant.eyeCatchers
                }
                headline={
                  (is.tablet || is.mobile) && (
                    <Box mt={3}>
                      <ProductHeadline tariff={tariff} variant="h3" />
                    </Box>
                  )
                }
                tariff={tariff}
              />
            )}
          </Col>
          <Col xs={24} xl={12}>
            {(hasCoupon || hasBookableHardware) && (
              <>
                {hasCoupon && hasBookableHardware && (
                  <Headline variant="h4">Wählen Sie aus:</Headline>
                )}
                <ButtonGroup
                  my={3}
                  value={selectedAccessoryType}
                  onChange={(val) => {
                    dispatch({
                      type: SET_SELECTED_ACCESSORY_TYPE,
                      payload: {
                        selectedAccessoryType: val,
                      },
                    });
                    dataLayer({
                      eventAction: "select",
                      eventCategory: "AccessoryType",
                      eventLabel: val.toLowerCase(),
                    });
                  }}
                >
                  {/* use tenaries here or button component draws error */}
                  {hasCoupon ? (
                    <Button value={COUPON}>Geschenk-Coupon</Button>
                  ) : (
                    <></>
                  )}
                  {hasBookableHardware ? (
                    <Button value={HARDWARE}>
                      {manufacturer
                        ? `${manufacturer} ${hardwareName}`
                        : "Smartphone"}
                    </Button>
                  ) : (
                    <></>
                  )}
                </ButtonGroup>
                {selectedAccessoryType === COUPON && hasCoupon && (
                  <DetailsCoupon value={coupon} />
                )}
                {extendedVariants && selectedAccessoryType === HARDWARE && (
                  <TariffHardwareSelector />
                )}
              </>
            )}
            <PriceSection
              mt={[4, 4, 4, 6, 6]}
              sticky
              priceMonthly={
                tariff.pricingIntervals.intervals.length > 1
                  ? tariff.pricingIntervals.intervals[0].price
                  : tariff.monthlyPrice
              }
              priceMonthlySub={
                tariff.pricingIntervals.intervals.length > 1
                  ? `Ab dem ${
                      tariff.pricingIntervals.intervals[1].start
                    }. Monat € ${
                      tariff.pricingIntervals.intervals[1].price / 100
                    }`
                  : null
              }
              activeAccessoryVariantIds={activeAccessoryVariantIds}
              selectedAccessoryType={selectedAccessoryType}
              priceOnce={tariff.connectionFee}
              priceMonthlyLegalNote={tariff.legalNotes}
              priceOnceLegalNote={tariff.legalNotes}
              amountShippingDaysMin={7}
              amountShippingDaysMax={8}
              priceShipping={0}
              priceShippingInfo={
                deliveryPrice ? (
                  <DeliveryInfoModal deliveryPrice={deliveryPrice} />
                ) : null
              }
            />
          </Col>
        </Row>
      </Surface>
    </>
  );
};

TariffDetailsSection.defaultProps = {};

TariffDetailsSection.propTypes = {};

export default TariffDetailsSection;
