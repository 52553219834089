/*
 * Custom hook to calculate current offSet of order button onScroll
 * If the order button is not visible anymore, the sticky checkout bar at the bottom will be visible
 * */
import { useEffect, useState } from "react";

const useBtnPosition = (cartBtnRef) => {
  const [cartBtnPosition, setCartBtnPosition] = useState(500);
  const createCartBtnPositionCalculator = () => {
    if (
      cartBtnRef?.current?.offsetTop &&
      cartBtnPosition !== cartBtnRef.current.offsetTop
    ) {
      setCartBtnPosition(cartBtnRef.current.offsetTop);
    }
  };

  useEffect(() => {
    if (cartBtnRef.current) {
      window.addEventListener("scroll", createCartBtnPositionCalculator);
    }

    return () => {
      window.removeEventListener("scroll", createCartBtnPositionCalculator);
    };
  }, [createCartBtnPositionCalculator, cartBtnRef]);

  return cartBtnPosition;
};

export default useBtnPosition;
