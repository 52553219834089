/**
 * ModalsProvider enables the trigger of modals
 * from anywhere within the app based on action dispatches
 */
import PropTypes from "prop-types";
import React, { createContext, useContext, useReducer } from "react";

import { GeneralModalShell } from "components/atoms";

/**
 * Show modal
 * @private
 * @constant
 * @type {string}
 */
const SHOW = "SHOW";
const HIDE = "HIDE";

export const actions = {
  SHOW,
  HIDE,
};

export function reducer(state, action) {
  switch (action.type) {
    case SHOW: {
      const { Component, closable, maxHeightOffSet } = action.payload;
      return {
        ...state,
        Component,
        closable,
        maxHeightOffSet,
      };
    }
    case HIDE: {
      return {
        Component: null,
        closable: true,
      };
    }
    default:
      throw new Error(`Reducer action not set: ${action.type}`);
  }
}

const ctx = createContext(null);

export const ModalsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    Component: null,
    closable: true,
  });

  return (
    <ctx.Provider value={{ state, dispatch }}>
      {children}
      {state.Component && (
        <GeneralModalShell
          openModal
          closable={state.closable}
          maxHeightOffSet={state.maxHeightOffSet}
        >
          {state.Component}
        </GeneralModalShell>
      )}
    </ctx.Provider>
  );
};

ModalsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

ModalsProvider.defaultProps = {};

export const useModals = () => useContext(ctx);
