import React from "react";
import PropTypes from "prop-types";
import styled, { keyframes } from "styled-components";

export const loadingRing = keyframes`
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
`;

const StyledLoadingCircle = styled.div`
  display: inline-block;
  height: 20px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 20px;

  > div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    border: 2px solid ${(props) => props.theme.colors.white};
    border-radius: 50%;
    animation: ${loadingRing} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${(props) => props.theme.colors.white} transparent transparent
      transparent;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }
    &:nth-child(2) {
      animation-delay: -0.3s;
    }
    &:nth-child(3) {
      animation-delay: -0.15s;
    }
    &:nth-child(4) {
      animation-delay: -0.45s;
    }
  }

  &.secondary,
  &.ghost {
    > div {
      border-color: ${(props) => props.theme.colors.grey[0]} transparent
        transparent transparent;
    }
  }
`;

const LoadingCircle = (props) => {
  const { className } = props;

  return (
    <StyledLoadingCircle className={className}>
      <div />
      <div />
      <div />
      <div />
    </StyledLoadingCircle>
  );
};

LoadingCircle.propTypes = {
  className: PropTypes.string,
};

LoadingCircle.defaultProps = {
  className: "",
};

export default LoadingCircle;
