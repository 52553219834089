import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

const withBlurHandler = (OriginalComponent) => {
  const EnhancedComponent = ({ maxLength, value, ...props }) => {
    const [stateValue, setStateValue] = useState(value);
    const [isEditing, setEditing] = useState(false);
    useEffect(() => {
      if (!isEditing && stateValue !== value) setStateValue(value);
    }, [value]);

    return (
      <OriginalComponent
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        onChange={(val) =>
          (!maxLength || (maxLength && val.length <= maxLength)) &&
          setStateValue(val)
        }
        value={isEditing ? stateValue : value}
        onFocus={() => setEditing(true)}
        onBlur={(val) => props.onChange(val) && setEditing(false)}
      />
    );
  };
  EnhancedComponent.propTypes = {
    maxLength: PropTypes.number.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };
  return EnhancedComponent;
};

export default withBlurHandler;
