import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { CheckoutSection, CheckoutSectionHeadline, CheckoutSectionText, RadioList, } from "../components";
import { InputComboBox, InputText } from "@mm/ui";
import styled from "styled-components";
import { ACTIONS, useCheckoutState } from "../context/CheckoutContext";
import { useCheckout } from "../hooks/api";
import { useField, useValidation, ValidateOnEvent, } from "../hooks";
import { useAddressSuggestions } from "@/features/Internet";
const Row = styled.div `
  display: flex;
  width: 100%;
  gap: 1.5rem;
  @media (max-width: 760px) {
    flex-wrap: wrap;
  }
`;
const BlockRow = styled.div `
  display: block;
  width: 100%;
  gap: 1.5rem;
`;
const FullWidthWrapper = styled.div `
  flex: 1;
  @media (max-width: 760px) {
    flex: 1 0 100%;
  }
`;
const OneThirdWrapper = styled.div `
  flex: 0 0 33%;
  @media (max-width: 760px) {
    flex: 1 0 100%;
  }
`;
const KlicktelOverwriteButton = styled.button `
  display: inline;
  border: 0;
  outline: 0;
  padding: 0;
  margin: 0;
  background: none;
  font-size: 1rem;
  text-decoration: underline;
  cursor: pointer;
`;
export const ShippingAddressSection = ({ defaultValues, schema, options, }) => {
    const { state, dispatch } = useCheckoutState();
    const { isFetching, isLoading } = useCheckout();
    const { enableAddressSuggestions: enableAddressSuggestionByOption, title } = options ?? {
        enableAddressSuggestions: false,
        title: undefined,
    };
    const [overwriteStreetInput, setOverwriteStreetInput] = useState(false);
    const enableAddressSuggestions = enableAddressSuggestionByOption && state?.differingShippingAddress;
    const { errors: differingShippingAddressErrors, fieldState: differingShippingAddress, setFieldState: setDifferingShippingAddress, trigger: triggerDifferingShippingAddress, } = useField("differingShippingAddress", schema, Boolean(state?.differingShippingAddress || defaultValues?.differingShippingAddress));
    const { errors: salutationErrors, fieldState: salutation, setFieldState: setSalutation, trigger: triggerSalutation, } = useField("shippingAddressSalutation", schema, "Frau");
    const { errors: firstNameErrors, fieldState: firstName, setFieldState: setFirstName, trigger: triggerFirstName, } = useField("shippingAddressFirstName", schema, "", {
        validateOn: ValidateOnEvent.Manual,
    });
    const { errors: lastNameErrors, fieldState: lastName, setFieldState: setLastName, trigger: triggerLastName, } = useField("shippingAddressLastName", schema, "", {
        validateOn: ValidateOnEvent.Manual,
    });
    const { errors: zipCodeErrors, fieldState: zipCode, setFieldState: setZipCode, trigger: triggerZipCode, } = useField("shippingAddressZipCode", schema, enableAddressSuggestions ? "" : state?.shippingAddressZipCode, {
        validateOn: ValidateOnEvent.Manual,
    });
    const { errors: placeErrors, fieldState: place, setFieldState: setPlace, } = useField("shippingAddressPlace", schema, enableAddressSuggestions ? "" : state?.shippingAddressPlace, {
        validateOn: ValidateOnEvent.Change,
        forceContextState: Boolean(enableAddressSuggestions),
    });
    const { errors: streetErrors, fieldState: street, setFieldState: setStreet, } = useField("shippingAddressStreet", schema, enableAddressSuggestions ? "" : state?.shippingAddressStreet, {
        validateOn: ValidateOnEvent.Change,
        forceContextState: Boolean(enableAddressSuggestions),
    });
    const { errors: streetNumberErrors, fieldState: streetNumber, setFieldState: setStreetNumber, trigger: triggerStreetNumber, } = useField("shippingAddressStreetNumber", schema, enableAddressSuggestions ? "" : state?.shippingAddressStreetNumber, {
        validateOn: ValidateOnEvent.Manual,
    });
    const { clearErrors, } = useValidation();
    const { data: addressSuggestions, clear: clearAddressSuggestions, refetch: refetchAddressSuggestions, success: klicktelSuccess, } = useAddressSuggestions(enableAddressSuggestions
        ? {
            zip: zipCode,
            city: place,
            street,
            streetNumber,
        }
        : {}, {
        enabled: false,
    });
    useEffect(() => {
        clearAddressSuggestions();
    }, []);
    useEffect(() => {
        if (enableAddressSuggestions) {
            if (addressSuggestions?.cities?.length === 1) {
                setPlace(addressSuggestions.cities[0]);
            }
            refetchAddressSuggestions();
        }
    }, [addressSuggestions?.cities]);
    return (_jsxs(CheckoutSection, { children: [_jsx(CheckoutSectionHeadline, { children: title ?? "Lieferadresse für die Vertragsunterlagen" }), _jsx(RadioList, { checked: differingShippingAddress
                    ? "differingShippingAddress--yes"
                    : "differingShippingAddress--no", id: "differingShippingAddress", items: [
                    {
                        key: "differingShippingAddress--no",
                        label: "Entspricht Kontaktadresse",
                    },
                    {
                        key: "differingShippingAddress--yes",
                        label: "Abweichende Lieferadresse",
                    },
                ], onChange: (key) => {
                    clearErrors([
                        "shippingAddressSalutation",
                        "shippingAddressFirstName",
                        "shippingAddressLastName",
                        "shippingAddressZipCode",
                        "shippingAddressPlace",
                        "shippingAddressStreet",
                        "shippingAddressStreetNumber",
                    ]);
                    if (key === "differingShippingAddress--no") {
                        dispatch({
                            type: ACTIONS.RESET_FIELDS,
                            payload: [
                                "shippingAddressSalutation",
                                "shippingAddressFirstName",
                                "shippingAddressLastName",
                                "shippingAddressZipCode",
                                "shippingAddressPlace",
                                "shippingAddressStreet",
                                "shippingAddressStreetNumber",
                            ],
                        });
                    }
                    setDifferingShippingAddress(key === "differingShippingAddress--yes");
                    triggerDifferingShippingAddress();
                }, disabled: isLoading || isFetching, error: differingShippingAddressErrors?.[0]?.message }), _jsx(CheckoutSectionText, { children: "Bitte beachte, dass eine Lieferung an eine Packstation nicht m\u00F6glich ist." }), differingShippingAddress && (_jsxs(_Fragment, { children: [_jsx(RadioList
                    // TODO: refactor RadioList keys to enum
                    , { 
                        // TODO: refactor RadioList keys to enum
                        checked: salutation === "Frau"
                            ? "shippingAddressSalutation--frau"
                            : "shippingAddressSalutation--herr", id: "shippingAddressSalutation", items: [
                            {
                                key: "shippingAddressSalutation--frau",
                                label: "Frau",
                                qaName: "shipping_salutation_female",
                            },
                            {
                                key: "shippingAddressSalutation--herr",
                                label: "Herr",
                                qaName: "shipping_salutation_male",
                            },
                        ], onChange: (key) => {
                            setSalutation(key === "shippingAddressSalutation--frau" ? "Frau" : "Herr");
                            triggerSalutation();
                        }, disabled: isLoading || isFetching, error: salutationErrors?.[0]?.message }), _jsxs(Row, { children: [_jsx(FullWidthWrapper, { children: _jsx(InputText, { id: "shippingAddressFirstName", label: "Vorname*", value: firstName, message: firstNameErrors?.[0]?.message, layout: firstNameErrors && "error", onChange: (value) => {
                                        setFirstName(value);
                                    }, onBlur: () => triggerFirstName(), autoComplete: "none", qaName: "shipping_firstname" }) }), _jsx(FullWidthWrapper, { children: _jsx(InputText, { id: "shippingAddressLastName", label: "Nachname*", value: lastName, message: lastNameErrors?.[0]?.message, layout: lastNameErrors && "error", onChange: (value) => {
                                        setLastName(value);
                                    }, onBlur: () => triggerLastName(), autoComplete: "none", qaName: "shipping_lastname" }) })] }), _jsxs(Row, { children: [_jsx(OneThirdWrapper, { children: _jsx(InputText, { id: "shippingAddressZipCode", label: "PLZ*", value: zipCode, message: zipCodeErrors?.[0]?.message, layout: zipCodeErrors && "error", onChange: (value) => {
                                        setZipCode(value);
                                        if (enableAddressSuggestions) {
                                            clearAddressSuggestions();
                                            clearErrors([
                                                "shippingAddressPlace",
                                                "shippingAddressStreet",
                                                "shippingAddressStreetNumber",
                                            ]);
                                            setPlace("");
                                            setStreet("");
                                            setStreetNumber("");
                                        }
                                    }, onBlur: () => {
                                        triggerZipCode().then((success) => {
                                            if (enableAddressSuggestions && success) {
                                                refetchAddressSuggestions();
                                            }
                                        });
                                    }, disabled: isLoading || isFetching, autoComplete: "none", qaName: "shipping_zip" }) }), _jsx(FullWidthWrapper, { children: enableAddressSuggestions && klicktelSuccess ? (_jsx(InputComboBox, { id: "shippingAddressPlace", label: "Ort*", value: place, message: placeErrors?.[0]?.message, layout: placeErrors && "error", onChange: (value) => {
                                        setPlace(value);
                                        clearAddressSuggestions();
                                        clearErrors([
                                            "shippingAddressStreet",
                                            "shippingAddressStreetNumber",
                                        ]);
                                        setStreet("");
                                        setStreetNumber("");
                                    }, onBlur: () => {
                                        refetchAddressSuggestions();
                                    }, onClick: () => {
                                        refetchAddressSuggestions();
                                    }, disabled: isLoading || isFetching || !zipCode, items: addressSuggestions?.cities ?? [], autoComplete: "none", qaName: "shipping_city" })) : (_jsx(InputText, { id: "shippingAddressPlace", label: "Ort*", value: place ?? "", message: placeErrors?.[0]?.message, layout: placeErrors && "error", onChange: (value) => {
                                        setPlace(value);
                                    }, onBlur: (value) => {
                                        setPlace(value);
                                    }, disabled: isLoading || isFetching, autoComplete: "none", qaName: "shipping_city" })) })] }), _jsxs(Row, { children: [_jsx(FullWidthWrapper, { children: enableAddressSuggestions &&
                                    klicktelSuccess &&
                                    !overwriteStreetInput ? (_jsx(InputComboBox, { id: "shippingAddressStreet", label: "Stra\u00DFe*", value: street, message: streetErrors?.[0]?.message, layout: streetErrors && "error", onClick: () => {
                                        refetchAddressSuggestions();
                                    }, onChange: (value) => {
                                        setStreet(value);
                                        clearErrors(["shippingAddressStreetNumber"]);
                                        setStreetNumber("");
                                    }, disabled: isLoading || isFetching || !zipCode || !place, items: addressSuggestions?.streets ?? [], autoComplete: "none", qaName: "shipping_street" })) : (_jsx(InputText, { id: "shippingAddressStreet", label: "Stra\u00DFe*", value: street, message: streetErrors?.[0]?.message, layout: streetErrors && "error", onChange: (value) => {
                                        setStreet(value);
                                    }, onBlur: (value) => {
                                        setStreet(value);
                                    }, disabled: isLoading || isFetching, autoComplete: "none", qaName: "shipping_street", autoFocus: overwriteStreetInput })) }), _jsx(OneThirdWrapper, { children: _jsx(InputText, { id: "shippingAddressStreetNumber", label: "Nummer*", value: streetNumber ?? "", message: streetNumberErrors?.[0]?.message, layout: streetNumberErrors && "error", onChange: (value) => {
                                        setStreetNumber(value);
                                    }, onBlur: () => triggerStreetNumber(), disabled: isLoading ||
                                        isFetching ||
                                        (enableAddressSuggestions && (!zipCode || !place || !street)), autoComplete: "none", qaName: "shipping_streetnumber" }) })] }), enableAddressSuggestions &&
                        klicktelSuccess &&
                        !overwriteStreetInput && (_jsxs(BlockRow, { children: ["Ist deine Stra\u00DFe nicht in den Vorschl\u00E4gen vorhanden, klicke", " ", _jsx(KlicktelOverwriteButton, { onClick: (e) => {
                                    e.preventDefault();
                                    setOverwriteStreetInput(true);
                                }, children: "hier" }), " ", "zur Eingabe deiner Adressdaten."] }))] }))] }));
};
