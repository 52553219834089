import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled, { keyframes } from "styled-components";
export const loadingRing = keyframes `
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
`;
const StyledLoadingCircle = styled.div `
  display: inline-block;
  height: ${({ size }) => size ?? "20px"};
  width: ${({ size }) => size ?? "20px"};

  > div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    height: ${({ size }) => size ?? "20px"};
    width: ${({ size }) => size ?? "20px"};
    border: ${({ size }) => (size ? "calc(" + size + " / 10)" : "2px")} solid
      ${(props) => props.theme.colors.white};

    border-radius: 50%;
    animation: ${loadingRing} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${(props) => props.theme.colors.white} transparent transparent
      transparent;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }
    &:nth-child(2) {
      animation-delay: -0.3s;
    }
    &:nth-child(3) {
      animation-delay: -0.15s;
    }
    &:nth-child(4) {
      animation-delay: -0.45s;
    }
  }

  &.secondary,
  &.ghost {
    > div {
      border-color: ${(props) => props.theme.colors.grey[0]} transparent
        transparent transparent;
    }
  }
`;
export const Spinner = ({ color, ...props }) => {
    return (_jsxs(StyledLoadingCircle, { className: color, ...props, children: [_jsx("div", {}), _jsx("div", {}), _jsx("div", {}), _jsx("div", {})] }));
};
