import React from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

// TODO: getBreakpoint not available any more in the new system.
// as Image, Video etc.. not used, migrate it if neede to use the viewport in the store instead
// import { getBreakpoint } from '../../support/page-utils';
import prepMarkdown from "utils/prepMarkdown";

export const RichSEOImageTextSection = ({
  /* mediaType, */ image,
  markdown,
}) => {
  // const breakpoint = getBreakpoint(mediaType);
  const breakpoint = {}; // TODO: see above
  const imageUrl =
    image && breakpoint && breakpoint in image ? image[breakpoint].path : "";

  return (
    <div className="image-text-container">
      <img src={imageUrl} alt="" />
      <ReactMarkdown rehypePlugins={[rehypeRaw]}>
        {prepMarkdown(markdown)}
      </ReactMarkdown>
    </div>
  );
};

export default RichSEOImageTextSection;
