import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import InputText from "../Input/Text";
import withBlurHandler from "../Input/withBlurHandler";

import SliderFilter from "./index";

const InputRow = styled.div`
  display: flex;
  margin-bottom: 0.8em;
  label {
    flex-basis: 100%;
  }
  input {
    flex: 1;
    padding-left: 0;
  }
`;

const InputCol = styled.div`
  flex: 1;
`;

const Dash = styled.div`
  color: ${({ theme }) => theme.colors.grey[0]};
  flex: 1;
  text-align: center;
  vertical-align: middle;
  padding-top: 0.8em;
`;

export const InputTextWithBlur = withBlurHandler(InputText);

const parseValue = (min, max, value) => Math.min(Math.max(min, value), max);
const getDecimalLength = (val) => {
  const parts = String(val).split(".");
  return (parts && parts.length > 1 && parts[1].length) || 0;
};

const SliderFilterWithInput = ({ value, suffix, onChange }) => {
  // make sure the values are within the min/max range
  const fixedValue = {
    ...value,
    minVal: parseValue(value.min, value.max, value.minVal),
    maxVal: parseValue(value.min, value.max, value.maxVal),
  };

  const { step, min, max, minVal, maxVal } = fixedValue;
  const decimalLength = getDecimalLength(step);
  const maxLength = String(value.max).length + decimalLength;

  return (
    <>
      <InputRow>
        <InputCol>
          <InputTextWithBlur
            // FIXME dont use dynamic keys on values
            key={`SliderFilterWithInput_min_${minVal}_${maxVal}`}
            value={parseFloat(minVal).toFixed(decimalLength)}
            label="Von"
            suffix={suffix}
            maxLength={maxLength}
            onChange={(val) =>
              onChange({
                ...fixedValue,
                minVal: val ? parseValue(min, max, Math.min(maxVal, val)) : min,
              })
            }
            qaName="filterPriceFrom"
          />
        </InputCol>
        <Dash>–</Dash>
        <InputCol>
          <InputTextWithBlur
            key={`SliderFilterWithInput_max_${minVal}_${maxVal}`}
            value={parseFloat(maxVal).toFixed(getDecimalLength(step))}
            label="Bis"
            suffix={suffix}
            maxLength={maxLength}
            onChange={(val) =>
              onChange({
                ...fixedValue,
                maxVal: val ? parseValue(min, max, Math.max(minVal, val)) : max,
              })
            }
            qaName="filterPriceTo"
          />
        </InputCol>
      </InputRow>
      <SliderFilter
        value={{ min: fixedValue.minVal, max: fixedValue.maxVal }}
        min={min}
        max={max}
        step={step}
        onChange={(val) =>
          onChange({
            ...fixedValue,
            minVal: val.min,
            maxVal: val.max,
          })
        }
      />
    </>
  );
};

SliderFilterWithInput.defaultProps = {
  suffix: "",
};

SliderFilterWithInput.propTypes = {
  value: PropTypes.shape({
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    minVal: PropTypes.number.isRequired,
    maxVal: PropTypes.number.isRequired,
    step: PropTypes.number,
  }).isRequired,
  suffix: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

SliderFilterWithInput.defaultProps = {
  suffix: "",
};

export default SliderFilterWithInput;
export { InputText };
