import React from "react";
import styled from "styled-components";

import { Box, Text } from "@mm/ui";

import { useIntl } from "@core/text";

import {
  Bulletlist,
  Eyecatchers,
  OfferHeadline,
  GeneralModalShell,
  TileAnchor,
  TileBox,
} from "components/atoms";

import {
  PriceDisplay,
  TariffMonthlyPriceLabel,
  TariffDetailsLinks,
  TarifCouponDisplay,
} from "components/molecules";

const TariffSection = styled("div")`
  ul {
    margin-top: 8px;
  }

  a {
    font-size: 12px;
    margin-right: 18px;
  }
`;

const KonnectTeaser = (props) => {
  const intl = useIntl();
  const {
    carrier,
    name,
    tariff,
    tariff: { commission, tariffId },
    bullets,
  } = props;
  return (
    <GeneralModalShell
      dynamicHeight
      size="xl"
      width="100%"
      opener={
        <div>
          <TileBox variant="tile">
            <OfferHeadline
              prefix={carrier}
              text={name}
              lines={2}
              variant={carrier}
            />
            <TarifCouponDisplay tariff={tariff} sim={false} />
            <TariffSection>
              <Bulletlist list={bullets} variant="checkmark" size="m" />
              <TariffDetailsLinks
                tariff={tariff}
                analytics={{ context: "SelectedTariff" }}
              />
              <PriceDisplay
                pretext={<TariffMonthlyPriceLabel commission={commission} />}
                price={tariff.monthlyPrice}
                size="s"
                tariffId={tariffId}
                direction="column"
              />
            </TariffSection>
          </TileBox>
        </div>
      }
    >
      <Box textAlign="center">
        <Text variant="l">
          Sie erreichen die konnect Neukunden Beratung unter:
        </Text>
        <br />
        <Text variant="h1" highlighted>
          {intl.formatMessage({
            id: "app.eutelsat.hotline",
            defaultMessage: null,
          })}
        </Text>
        <br />
        <Text variant="s">
          Montag-Freitag 10-18 Uhr (Anruf aus dem Festnetz 0,033 €/min)
        </Text>
        <br />
        <br />
      </Box>
    </GeneralModalShell>
  );
};

const TariffQuarterWidth = ({
  eyeCatchers,
  tariff,
  coupon,
  tariff: { bullets, carrier, name, id: tariffId, commission, link },
}) => {
  const tariffUrl = link;

  if (carrier === "konnect") {
    return (
      <KonnectTeaser
        carrier={carrier}
        name={name}
        tariff={tariff}
        bullets={bullets}
      />
    );
  }

  return (
    <TileBox variant="tile">
      <Eyecatchers eyecatchers={eyeCatchers || []} variant="offerItem" />
      <TileAnchor
        href={tariffUrl}
        dataLayerObj={{
          eventCategory: "Produktteaser",
          eventAction: "click",
          eventLabel: `Details:${tariffUrl}:TariffQuarterWidth`,
        }}
      >
        <OfferHeadline
          prefix={carrier}
          text={name}
          lines={2}
          variant={carrier}
        />
        <Box height="180px" mb={4} position="relative">
          <TarifCouponDisplay
            tariff={tariff}
            coupon={coupon}
            layoutVariant="row"
          />
        </Box>
        <Bulletlist list={bullets} variant="checkmark" size="m" />
      </TileAnchor>
      <TariffSection>
        <TariffDetailsLinks
          tariff={tariff}
          analytics={{ context: "SelectedTariff" }}
        />
        <PriceDisplay
          pretext={<TariffMonthlyPriceLabel commission={commission} />}
          price={tariff.monthlyPrice}
          size="s"
          tariffId={tariffId}
          direction="column"
        />
      </TariffSection>
    </TileBox>
  );
};

export default TariffQuarterWidth;
