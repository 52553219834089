import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Button, Surface, Row } from "@mm/ui";
import { ThemeProvider, useViewport } from "@core/theme";

import { RichSEOTextSection } from "./RichSEOTextSection";
import { RichSEOVideoSection } from "./RichSEOVideoSection";
import { RichSEOImageSection } from "./RichSEOImageSection";
import { RichSEOImageTextSection } from "./RichSEOImageTextSection";
import { RichSEOTextImageSection } from "./RichSEOTextImageSection";

const seoContent = window.RICH_SEO?.content;

const getSEOHeight = (viewport, showAllElements) => {
  if (showAllElements) {
    return "auto";
  }
  return `${viewport.lessThan.md ? 300 : 600}px`;
};

const SectionWrapper = styled.div`
  position: relative;
  overflow: hidden;
  height: ${({ viewport, showAllElements }) =>
    getSEOHeight(viewport, showAllElements)};
  padding: ${({ theme }) => theme.space.xl}px 0 0;

  &:after {
    position: absolute;
    bottom: 0;
    height: ${({ showAllElements }) => (showAllElements ? 0 : "40px")};
    width: 100%;
    content: "";
    background: ${({ theme }) => theme.whiteShadowGradient};
    pointer-events: none;
  }

  ol {
    margin: 1rem 0;
  }

  li {
    margin-left: 1rem;
  }
`;

const StyledRow = styled(Row)`
  margin-top: ${({ theme }) => theme.space.s}px;
`;

const RichSEOSection = ({ mediaType }) => {
  const [showAllElements, setShowAllElements] = useState(false);

  const seoInnerRef = useRef(null);

  const viewport = useViewport();

  const onExpandClick = () => {
    setShowAllElements(!showAllElements);
  };

  const determineSectionType = ({
    module: {
      data: { markdown, image, link },
      type,
    },
  }) => {
    switch (type) {
      case "text":
        return <RichSEOTextSection markdown={markdown} />;
      case "text-image":
        return (
          <RichSEOTextImageSection
            markdown={markdown}
            mediaType={mediaType}
            image={image}
          />
        );
      case "image-text":
        return (
          <RichSEOImageTextSection
            markdown={markdown}
            mediaType={mediaType}
            image={image}
          />
        );
      case "image":
        return <RichSEOImageSection mediaType={mediaType} image={image} />;
      case "video":
        return <RichSEOVideoSection link={link} />;
      default:
        return type;
    }
  };

  if (!seoContent) {
    return null;
  }

  return (
    <ThemeProvider>
      <Surface variant="narrow">
        <section>
          <>
            <SectionWrapper
              ref={seoInnerRef}
              showAllElements={showAllElements}
              viewport={viewport}
            >
              {seoContent.map((module, index) => {
                const elemKey = `${module.type}-${index}`; // @TODO: ESLint: Set correct key

                return (
                  <div key={elemKey}>
                    {determineSectionType({
                      module,
                      mediaType,
                    })}
                  </div>
                );
              })}
            </SectionWrapper>
            <StyledRow justifyContent="center">
              <br />
              <Button onClick={onExpandClick} size="small" color="primary">
                {showAllElements ? "Weniger anzeigen" : "Mehr anzeigen"}
              </Button>
            </StyledRow>
          </>
        </section>
      </Surface>
    </ThemeProvider>
  );
};

RichSEOSection.propTypes = {
  mediaType: PropTypes.string,
};

RichSEOSection.defaultProps = {
  mediaType: "",
};

export default RichSEOSection;
