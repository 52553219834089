import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import SlickCarousel from "react-slick";
import styled from "styled-components";
import { Surface } from "@mm/ui";
import { useViewport } from "@core/theme";
import { TileBox } from "@/components/atoms";
import OfferTeaserInternet from "../Teaser";
import { carouselSettings, StyledTeaserCarousel, } from "./SlickCarouselSetting/index";
const TeaserWrapper = styled("div") `
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 44px 24px;
`;
const InternetOfferTeaser = ({ availabilityChecked, availableProviders, data, }) => {
    const { greaterThan: { md }, } = useViewport();
    const serviceProviderNames = availableProviders.map((item) => item.providerName);
    const filterCarrierForMobileSlider = (offers, carrier) => {
        return offers.filter((item) => carrier === item.tariff?.serviceProvider);
    };
    if (data.length === 0) {
        return (_jsx(Surface, { variant: "narrow", children: _jsx("div", { children: "Es k\u00F6nnen keine Angebote angezeigt werden" }) }));
    }
    return (_jsxs(_Fragment, { children: [md && (_jsx(TeaserWrapper, { children: data.map(({ accessories, coupon, eyeCatchers, internetDetail, name, offerGroupUrl, offerId, pkCampaignId, accessoriesAlternativeImage, accessoriesAlternativePrice, tariff, }) => {
                    return (_jsx(TileBox, { variant: "tile", children: _jsx(OfferTeaserInternet, { accessories: accessories, availabilityChecked: availabilityChecked, coupon: coupon, detailEyeCatcher: {
                                eyeCatcher: internetDetail?.eyeCatcher,
                                eyeCatcherInfo: internetDetail?.eyeCatcherInfo,
                            }, eyeCatchers: eyeCatchers, name: name, offerGroupUrl: offerGroupUrl, pkCampaignId: pkCampaignId, accessoriesAlternativeImage: accessoriesAlternativeImage, accessoriesAlternativePrice: accessoriesAlternativePrice, tariff: tariff }) }, offerId));
                }) })), !md &&
                serviceProviderNames.map((carrier) => {
                    return (_jsx(StyledTeaserCarousel, { children: _jsx(SlickCarousel, { ...carouselSettings, children: filterCarrierForMobileSlider(data, carrier).map(({ accessories, coupon, eyeCatchers, name, internetDetail, offerGroupUrl, offerId, pkCampaignId, accessoriesAlternativeImage, accessoriesAlternativePrice, tariff, }) => {
                                return (_jsx(TileBox, { variant: "tile", children: _jsx(OfferTeaserInternet, { accessories: accessories, availabilityChecked: availabilityChecked, coupon: coupon, detailEyeCatcher: {
                                            eyeCatcher: internetDetail?.eyeCatcher,
                                            eyeCatcherInfo: internetDetail?.eyeCatcherInfo,
                                        }, eyeCatchers: eyeCatchers, name: name, offerGroupUrl: offerGroupUrl, pkCampaignId: pkCampaignId, accessoriesAlternativeImage: accessoriesAlternativeImage, accessoriesAlternativePrice: accessoriesAlternativePrice, tariff: tariff }) }, offerId));
                            }) }) }, carrier));
                })] }));
};
export default InternetOfferTeaser;
