import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { variant } from "styled-system";
import { transformString } from "utils/formatCssIdString";

import { Button, Surface, Col, Row, Box } from "@mm/ui";

const Container = styled.section`
  ${variant({
    variants: {
      first: {
        paddingTop: 0,
      },
    },
  })}
`;

const HeadlineFoobar = styled.h2`
  font-family: ${({ theme }) => theme.fonts.headline};
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: calc(1.4);
  margin: 0 0 4px 0;
`;

const SectionContainer = ({ id, headlineText, children, button, first }) => {
  return (
    <Container id={transformString(id)} first={first}>
      {headlineText && (
        <Surface variant="narrow" pt={3}>
          <Row>
            <Col xs={24}>
              <HeadlineFoobar>{headlineText}</HeadlineFoobar>
            </Col>
          </Row>
        </Surface>
      )}
      {children.map((child) => {
        if (child) {
          return (
            <React.Fragment key={child.key}>
              <Box pt={3} pb={3}>
                {child}
              </Box>
            </React.Fragment>
          );
        }
        return null;
      })}
      {button && (
        <Surface variant="narrow">
          <Row>
            <Col xs={12} lg={8}>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <Button {...button} />
            </Col>
          </Row>
        </Surface>
      )}
    </Container>
  );
};

SectionContainer.defaultProps = {
  headlineText: null,
  button: null,
  first: false,
};

SectionContainer.propTypes = {
  headlineText: PropTypes.string,
  children: PropTypes.node.isRequired,
  button: PropTypes.shape(Button.propTypes),
  first: PropTypes.bool,
};

export default SectionContainer;
