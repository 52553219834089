import MESSAGES_SAT from "./messages_sat.json";
import MESSAGES_MM from "./messages_mm.json";

import URLS_SAT from "./urls_sat.json";
import URLS_MM from "./urls_mm.json";

export {
  IntlProvider,
  FormattedMessage,
  FormattedNumber,
  useIntl,
} from "react-intl";

const COMBINED_MESSAGES_MM = {
  ...MESSAGES_MM,
  ...URLS_MM,
};
const COMBINED_MESSAGES_SAT = {
  ...MESSAGES_SAT,
  ...URLS_SAT,
};

export const messages = {
  MEDIAMARKT: COMBINED_MESSAGES_MM,
  SATURN: COMBINED_MESSAGES_SAT,
}[TENANT];
