import React from "react";
import PropTypes from "prop-types";

import Headline from ".";

const VariantHeadline = ({ className, hardware }) => (
  <Headline className={className}>
    <span>{hardware.manufacturer}</span> {hardware.name}
  </Headline>
);

VariantHeadline.propTypes = {
  className: PropTypes.string,
  hardware: PropTypes.shape({
    manufacturer: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

VariantHeadline.defaultProps = {
  className: "",
};

export default VariantHeadline;
