import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { LegalNoteAsterisk } from "@/components/atoms";
import { ArticleSelectList, } from "@/components/molecules";
import { formatNumberAsCurrencyString } from "@/utils/price/formatNumberAsCurrencyString";
import { ACTIONS as ModalActions, useModal, } from "@/utils/context/ModalContext";
export const HardwareSelectList = ({ disabled, hardwares = [], legalNote, onChange, }) => {
    const { dispatch } = useModal();
    const transformHardware = (hardware) => ({
        ...hardware,
        image: hardware.extendedVariants?.[0].variant.images?.[0],
        price: (_jsxs(_Fragment, { children: [formatNumberAsCurrencyString(hardware.extendedVariants?.[0].variant.price), " ", legalNote && _jsx(LegalNoteAsterisk, { legalNote: legalNote }), "monatlich"] })),
    });
    const aggregatedHardwares = hardwares?.map(transformHardware) ?? [];
    return (_jsx(ArticleSelectList, { articles: aggregatedHardwares, disabled: disabled, metaLinks: [
            {
                text: "Gerätedetails",
                onClick: (details) => dispatch({
                    type: ModalActions.SET_MODAL,
                    payload: {
                        details,
                        title: "Gerätedetails",
                        type: "hardwareDetails",
                    },
                }),
            },
        ], onChange: (selectedArticles) => {
            if (!disabled) {
                onChange(selectedArticles[0]);
            }
        } }));
};
