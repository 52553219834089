import { find } from "lodash";

import { getStock as getTariffStock } from "utils/tariffUtils";
import { getStock as getVariantStock } from "utils/hardwareUtils";
import { getSelectedExtendedVariant } from "utils/variantUtils";

/**
 * Util to change url params
 *
 * @param params [{param: string, value: string}]
 *
 */
const changeURLParams = (params) => {
  const currentURL = new URL(window.location.href);
  params.forEach(({ param, value }) => {
    currentURL.searchParams.set(param, value);
  });
  window.history.replaceState(null, null, currentURL.href);
};

/**
 * Util to sanitize the url params
 *
 * @param value string
 *
 */
export const sanitize = (value) => value.replace(/ /g, "-").toLowerCase();

/**
 * Util to find a variant based on color and storage.
 * If no variant is found, we return an empty stock indicating the variant is soldOut
 *
 * @param variants object
 * @param color string
 * @param storage string
 *
 */
export const findVariantByColorAndStorage = (variants, color, storage) =>
  (color || storage) &&
  (variants.find(
    ({
      variant: {
        storage: variantStorage,
        color: { hex },
      },
    }) => color === hex && storage === variantStorage
  ) || { stock: 0 });

/**
 * Util to find a variant based on the variantId
 *
 * @param variants object
 * @param variantId string
 *
 */
export const findVariantById = (variants, variantId) =>
  find(variants, {
    variant: { ebootisId: variantId },
  }) || variants?.[0];

/**
 * Util to generate the accessoryVariantIds initially
 *
 * @param extendedVariants array
 * @param rootInfo object
 *
 */
export const buildDefaultAccessoryVariants = (rootInfo) => {
  const accessoryVariantIds = {};
  if (rootInfo.accessories) {
    rootInfo.accessories.forEach((accessory, index) => {
      accessoryVariantIds[index] = {
        // ToDo: If the new page-calls are online delete the old "_id" sections
        hardwareId:
          accessory._id ||
          accessory.id ||
          rootInfo.hardware._id ||
          rootInfo.hardware.id,
        ebootisId: getSelectedExtendedVariant(accessory.extendedVariants)
          .variant.ebootisId,
        name: `${accessory.manufacturer} ${accessory.name}`,
      };
    });
  }

  return accessoryVariantIds;
};

/**
 * Calculate the stock for different scenarios (coupon selected, hardware sold out etc..)
 *
 * @param isTariff boolean
 * @param selectedAccessoryType string
 * @param rootInfo object
 * @param extendedVariants array
 * @param activeVariantId string
 * @param activeAccessoryVariantIds object
 *
 */
export const calculateStock = (
  isTariff,
  selectedAccessoryType,
  rootInfo,
  extendedVariants,
  activeVariantId,
  activeAccessoryVariantIds
) => {
  /** TODO: as soon as there's type insertion check if refactoring to
   * `getStock = isTariff ? getTariffStock : getVariantStock` is useful
   */
  // UPDATE: done. Why type insertion check is necessary?
  if (isTariff && selectedAccessoryType === "COUPON") return 9999;
  const func = isTariff ? getTariffStock : getVariantStock;
  const stock = func(
    rootInfo,
    extendedVariants || [],
    activeVariantId,
    activeAccessoryVariantIds
  );

  return stock;
};

export default changeURLParams;

/**
 * Build queryParams for the OfferProvider
 *  @param element object
 *  @returns object
 */

export const buildQueryParams = (element) => {
  const offerTypes = {
    tablet: "tablets",
    smartphone: "smartphones",
  };

  return {
    offerGroupUrl: element.offerGroup,
    manufacturer: element.hardware?.manufacturer,
    serviceProvider: element.tariff?.serviceProvider,
    offerType: offerTypes[element.hardware?.offerType] || "tarife",
  };
};
