import styled from "styled-components";
import { compose, space, layout, flexbox } from "styled-system";

const Row = styled("div")(
  {
    boxSizing: "border-box",
    display: "flex",
    flex: "0 1 auto",
    flexWrap: "wrap",
  },
  (props) => {
    const preparedStyle = {};
    let margin = 0;
    let lastSetMargin = margin;

    Object.keys(props.theme.breakpointsMap).forEach((bp) => {
      margin = props.theme.gutterWidths[bp] / 2;

      if (margin !== lastSetMargin) {
        lastSetMargin = margin;

        if (bp === "xs") {
          preparedStyle.marginLeft = `-${margin}px`;
          preparedStyle.marginRight = `-${margin}px`;
        } else {
          preparedStyle[
            `@media (min-width: ${props.theme.breakpointsMap[bp].min}px)`
          ] = {
            marginLeft: `-${margin}px`,
            marginRight: `-${margin}px`,
          };
        }
      }
    });

    return preparedStyle;
  },
  compose(space, layout, flexbox)
);

Row.defaultProps = {
  flexDirection: "row",
  justifyContent: "space-between",
};

export default Row;
