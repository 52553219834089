import React, { Fragment, isValidElement } from "react";
import PropTypes from "prop-types";
import { Box, Skeleton, Surface } from "@mm/ui";
import { useViewport } from "@core/theme";

import skeletonConfig from "./config";

export const availableVariants = Object.keys(skeletonConfig);

const LoadingElem = ({ config }) =>
  isValidElement(config) ? (
    config
  ) : (
    <Skeleton
      width={config.width || "100%"}
      height={config.height || "100%"}
      marginTop={config.marginTop || 0}
      marginRight={config.marginRight || 0}
      marginBottom={config.marginBottom || 0}
      marginLeft={config.marginLeft || 0}
    />
  );

const loadingElemConfigItem = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
]);

LoadingElem.propTypes = {
  config: PropTypes.oneOfType([
    PropTypes.shape({
      height: loadingElemConfigItem,
      width: loadingElemConfigItem,
      marginTop: loadingElemConfigItem,
      marginRight: loadingElemConfigItem,
      marginBottom: loadingElemConfigItem,
      marginLeft: loadingElemConfigItem,
    }),
    PropTypes.node,
  ]).isRequired,
};
LoadingElem.defaultProps = {};

const LoadingComp = ({ marginTop, spec }) => (
  <Box marginTop={marginTop}>
    {Array.isArray(spec) ? (
      spec.map(({ key, ...options }) => (
        <Fragment key={key}>
          <LoadingElem config={options} />
        </Fragment>
      ))
    ) : (
      <LoadingElem config={spec} />
    )}
  </Box>
);

const Loading = ({ variant, marginTop, gridWrapper }) => {
  if (!variant || !skeletonConfig[variant]) {
    return null;
  }

  const viewport = useViewport();

  const spec =
    typeof skeletonConfig[variant] === "function"
      ? skeletonConfig[variant](viewport)
      : skeletonConfig[variant];

  return gridWrapper ? (
    <Surface variant="narrow">
      <LoadingComp marginTop={marginTop} spec={spec} />
    </Surface>
  ) : (
    <>
      <LoadingComp marginTop={marginTop} spec={spec} />
    </>
  );
};

Loading.propTypes = {
  variant: PropTypes.oneOf(Object.keys(skeletonConfig)).isRequired,
  marginTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  gridWrapper: PropTypes.bool,
};

Loading.defaultProps = {
  marginTop: 0,
  gridWrapper: false,
};

export default Loading;
