import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import Collapse, { Panel } from "rc-collapse";
import styled from "styled-components";

import { Headline } from "@mm/ui";

import { GeneralModalShell } from "components/atoms";

import {
  DATA_SECURITY_MODAL,
  DATA_SECURITY_COOKIES_MODAL,
  TERMS_MODAL,
  TERMS_TELEFONICA_MODAL,
  TERMS_MOBILCOM_MODAL,
  TERMS_WEBID_MODAL,
  TERMS_SKY_MODAL,
  TERMS_VODAFONE_MODAL,
  TERMS_UNITYMEDIA_MODAL,
  TERMS_KLARMOBIL_MODAL,
  TERMS_1UND1_MODAL,
  IMPRINT_MODAL,
  WITHDRAWAL_MODAL,
  PAYMENT_METHODS_MODAL,
} from "./constants";

const StyledUl = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const StyledButton = styled.button`
  background: none;
  padding: 2px 5px;
  margin: 2px 0;
  border-radius: 5px;
  border-width: 1px;
  cursor: pointer;
  font: inherit;
  outline: inherit;
  :focus,
  :hover {
    color: ${({ theme }) => theme.colors.brand.primary};
  }
`;
class StaticsModal extends Component {
  static buildAccordion(contentArray) {
    return (
      <Collapse accordion={false}>
        {contentArray.map((list, key) => {
          const panelElemKey = `${key}`; // @TODO: ESLint: Set correct key

          return (
            <Panel key={panelElemKey} header={list.title}>
              <div
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: list.text }}
              />
              {list.subtext && (
                <Collapse accordion={false}>
                  {list.subtext.map((listInner, keyInner) => {
                    const collapsePanelElemKey = `${keyInner}`; // @TODO: ESLint: Set correct key
                    return (
                      <Panel
                        key={collapsePanelElemKey}
                        header={listInner.title}
                      >
                        <div
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{
                            __html: listInner.text,
                          }}
                        />
                      </Panel>
                    );
                  })}
                </Collapse>
              )}
            </Panel>
          );
        })}
      </Collapse>
    );
  }

  constructor(props) {
    super(props);
    this.state = {
      headline: "Inhalt wird geladen",
      content: "",
    };

    this.getContent = this.getContent.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  handleKeyDown(ev, contenttype) {
    if (ev.keyCode === 13) {
      this.getContent(contenttype);
    }
  }

  getContent(contenttype) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    let url;
    switch (contenttype) {
      case DATA_SECURITY_MODAL:
        url = "/datenschutz/";
        break;
      case DATA_SECURITY_COOKIES_MODAL:
        url = "/datenschutz-cookies/";
        break;
      case TERMS_MODAL:
        url = "/agb/";
        break;
      case TERMS_TELEFONICA_MODAL:
        url = "/agb-telefonica/";
        break;
      case TERMS_MOBILCOM_MODAL:
        url = "/agb-freenet/";
        break;
      case TERMS_WEBID_MODAL:
        url = "/agb-webid/";
        break;
      case TERMS_SKY_MODAL:
        url = "/agb-sky/";
        break;
      case TERMS_VODAFONE_MODAL:
        url = "/agb-vodafone/";
        break;
      case TERMS_KLARMOBIL_MODAL:
        url = "/agb-klarmobil/";
        break;
      case TERMS_1UND1_MODAL:
        url = "/agb-1und1/";
        break;
      case TERMS_UNITYMEDIA_MODAL:
        url = "/agb-unitymedia/";
        break;
      case IMPRINT_MODAL:
        url = "/impressum/";
        break;
      case WITHDRAWAL_MODAL:
        url = "/widerrufsrecht/";
        break;
      case PAYMENT_METHODS_MODAL:
        url = "/zahlungs-arten/";
        break;
      default:
        url = "";
        break;
    }

    if (url !== "") {
      axios
        .get(`${url}?xhr=true`, {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
          },
        })
        .then((response) => {
          if (response.status === 200) {
            self.setState({
              headline: response.data.title,
              content: response.data.text,
            });
          } else {
            self.showErrorMessage();
          }
        })
        .catch(() => {
          self.showErrorMessage();
        });
    } else {
      this.showErrorMessage();
    }
  }

  showErrorMessage() {
    this.setState({
      headline: "Fehler!",
      content: "Der angeforderte Inhalt konnte leider nicht geladen werden.",
    });
  }

  render() {
    const { contenttype, deliverySettings, opener, openModal } = this.props;
    const { headline, content } = this.state;

    if (contenttype === null) {
      return null;
    }

    return (
      <GeneralModalShell
        opener={opener}
        afterOpen={() => this.getContent(contenttype)}
        openModal={openModal}
      >
        <>
          <Headline variant="h1">{headline}</Headline>

          {contenttype.match(/terms/) && (
            <StyledUl className="agb_options">
              <li>
                <StyledButton
                  type="button"
                  onClick={() => this.getContent(TERMS_MODAL)}
                  onKeyDown={this.handleKeyDown(TERMS_MODAL)}
                >
                  AGB S-KON eKontor 24 GmbH anzeigen
                </StyledButton>
              </li>
              <li>
                <StyledButton
                  type="button"
                  onClick={() => this.getContent(TERMS_MOBILCOM_MODAL)}
                  onKeyDown={this.handleKeyDown(TERMS_MOBILCOM_MODAL)}
                >
                  AGB freenet anzeigen
                </StyledButton>
              </li>
              <li>
                <StyledButton
                  type="button"
                  onClick={() => this.getContent(TERMS_TELEFONICA_MODAL)}
                  onKeyDown={this.handleKeyDown(TERMS_TELEFONICA_MODAL)}
                >
                  AGB Telefónica anzeigen
                </StyledButton>
              </li>
              <li>
                <StyledButton
                  type="button"
                  onClick={() => this.getContent(TERMS_VODAFONE_MODAL)}
                  onKeyDown={this.handleKeyDown(TERMS_VODAFONE_MODAL)}
                >
                  AGB Vodafone anzeigen
                </StyledButton>
              </li>
              <li>
                <StyledButton
                  type="button"
                  onClick={() => this.getContent(TERMS_KLARMOBIL_MODAL)}
                  onKeyDown={this.handleKeyDown(TERMS_KLARMOBIL_MODAL)}
                >
                  AGB klarmobil anzeigen
                </StyledButton>
              </li>
              <li>
                <StyledButton
                  type="button"
                  onClick={() => this.getContent(TERMS_1UND1_MODAL)}
                  onKeyDown={this.handleKeyDown(TERMS_1UND1_MODAL)}
                >
                  AGB 1&1 anzeigen
                </StyledButton>
              </li>
              {/* <li>
                <button
                  type="button"
                  onClick={() => this.getContent(TERMS_UNITYMEDIA_MODAL)}
                  onKeyDown={this.handleKeyDown(TERMS_UNITYMEDIA_MODAL)}
                >
                  AGB Unitymedia anzeigen
                </button>
              </li> */}
              {deliverySettings && deliverySettings.webid.enabled ? (
                <li>
                  <StyledButton
                    type="button"
                    onClick={() => this.getContent(TERMS_WEBID_MODAL)}
                    onKeyDown={this.handleKeyDown(TERMS_WEBID_MODAL)}
                  >
                    AGB WebID Solutions anzeigen
                  </StyledButton>
                </li>
              ) : (
                ""
              )}
            </StyledUl>
          )}
          {contenttype.match(/datasecurity/) ? (
            <ul className="agb_options">
              <li>
                <StyledButton
                  type="button"
                  onClick={() => this.getContent(DATA_SECURITY_MODAL)}
                  onKeyDown={this.handleKeyDown(DATA_SECURITY_MODAL)}
                >
                  Allgemein
                </StyledButton>
              </li>
              <li>
                <StyledButton
                  type="button"
                  onClick={() => this.getContent(DATA_SECURITY_COOKIES_MODAL)}
                  onKeyDown={this.handleKeyDown(DATA_SECURITY_COOKIES_MODAL)}
                >
                  Cookies und Webanalyse-Dienste
                </StyledButton>
              </li>
            </ul>
          ) : null}
          {typeof content === "string" ? (
            // eslint-disable-next-line react/no-danger
            <div dangerouslySetInnerHTML={{ __html: content }} />
          ) : (
            <div>
              <Headline variant="h2">{content.intro.title}</Headline>
              <div
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: content.intro.text,
                }}
              />
              {StaticsModal.buildAccordion(content.body)}
              {content.validity ? <p>{content.validity}</p> : null}
            </div>
          )}
        </>
      </GeneralModalShell>
    );
  }
}

StaticsModal.propTypes = {
  contenttype: PropTypes.string.isRequired,
  deliverySettings: PropTypes.shape(PropTypes.any),
  opener: PropTypes.node.isRequired,
  openModal: PropTypes.bool,
};

StaticsModal.defaultProps = {
  openModal: false,
  deliverySettings: null,
};

export default StaticsModal;
