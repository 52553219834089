import { ActivationDateSection } from "./ActivationDateSection";
import { ContractSummarySection } from "./ContractSummarySection";
import { ConnectionOverviewSection } from "./ConnectionOverviewSection";
import { NumberPortabilityInfoSection } from "./NumberPortabilityInfoSection";
export { ActivationDateSection as VodafoneActivationDateSection } from "./ActivationDateSection";
export { ConnectionOverviewSection as VodafoneConnectionOverviewSection } from "./ConnectionOverviewSection";
export { ContractSummarySection as VodafoneContractSummarySection } from "./ContractSummarySection";
export { NumberPortabilityInfoSection as VodafoneNumberPortabilityInfoSection } from "./NumberPortabilityInfoSection";
export const vodafoneSectionComponents = {
    "vodafone.activationDateSection": ActivationDateSection,
    "vodafone.connectionOverviewSection": ConnectionOverviewSection,
    "vodafone.contractSummarySection": ContractSummarySection,
    "vodafone.numberPortabilityInfoSection": NumberPortabilityInfoSection,
};
