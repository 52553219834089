import React from "react";
import PropTypes from "prop-types";
import YouTube from "react-youtube";
import styled from "styled-components";

const Col = styled.div`
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const RichSEOVideoSection = ({ link }) => {
  const config = {
    height: "100%",
    width: "100%",
    playerVars: {
      autoplay: 0,
    },
  };

  const youtubeId = link ? link.substr(link.indexOf("=") + 1) : 0;

  return (
    <div className="row">
      <Col className="col-xs-24">
        <YouTube videoId={youtubeId} opts={config} />
      </Col>
    </div>
  );
};

RichSEOVideoSection.propTypes = {
  link: PropTypes.string.isRequired,
};

export default RichSEOVideoSection;
