import PropTypes from "prop-types";
import React from "react";

import { Price } from "components/atoms";

const TariffMonthlyPriceLabel = ({
  commission,
  price,
  priceString,
  tariff,
}) => {
  const changePriceTextForServiceProvider = (provider) => {
    const priceText = `Monatlicher ${
      commission || priceString ? "Durchschnittspreis" : "Grundpreis"
    }`;

    const priceTextEnergy = "Voraussichtlicher monatlicher Abschlag";

    if (provider === "E.ON" || provider === "eprimo") {
      return priceTextEnergy;
    }

    return priceText;
  };

  return (
    <>
      {changePriceTextForServiceProvider(tariff?.serviceProvider)}
      {price && (
        <>
          {" "}
          <Price price={price} preventMargin preventStyling forceCentDisplay />
        </>
      )}
    </>
  );
};

TariffMonthlyPriceLabel.defaultProps = {
  price: null,
  commission: 0,
};

TariffMonthlyPriceLabel.propTypes = {
  /**
   * Size of the price display
   */
  commission: PropTypes.number,
  price: PropTypes.number,
};

export default TariffMonthlyPriceLabel;
