import PropTypes from "prop-types";
import React from "react";

import { useOfferConfigurator } from "features/Offer";

import { FilterProvider } from "./FilterProvider";

/**
 * A facetted search component to narrow down tariff and hardware options
 */
const Filter = ({ queryParams, variant, data, shownFacets, children }) => {
  const {
    state: { isListingPage, isProductDetailInterface },
  } = useOfferConfigurator();

  return (
    <FilterProvider
      variant={variant}
      queryParams={queryParams}
      isListingPage={isListingPage}
      isProductDetailInterface={isProductDetailInterface}
      data={data}
      shownFacets={shownFacets}
    >
      {children}
    </FilterProvider>
  );
};

Filter.propTypes = {
  variant: PropTypes.oneOf(["smartphone", "tariff"]).isRequired,
  queryParams: PropTypes.shape({}),
  data: PropTypes.arrayOf(PropTypes.shape({})),
  shownFacets: PropTypes.shape({}).isRequired,
};

Filter.defaultProps = {
  queryParams: null,
  data: null,
};

export default Filter;
