import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { TariffBadge } from "components/atoms";
import { carrierPropTypes, serviceproviderPropTypes } from "sharedPropTypes";

const Container = styled.div`
  display: flex;
  align-items: center;

  img {
    margin-right: 16px;
  }
`;

const TariffHeadline = ({ className, tariff }) => (
  <Container className={className}>
    <TariffBadge tariff={tariff} />
    <div>
      <b>{tariff.carrier}</b> {tariff.name.replace("Super Select", "")}
    </div>
  </Container>
);

TariffHeadline.propTypes = {
  className: PropTypes.string,
  tariff: PropTypes.shape({
    carrier: carrierPropTypes.isRequired,
    name: PropTypes.string.isRequired,
    serviceProvider: serviceproviderPropTypes.isRequired,
  }).isRequired,
};

TariffHeadline.defaultProps = {
  className: "",
};

export default TariffHeadline;
