import styled from "styled-components";
import { provideIsLoadingProp, } from "@/utils/context/LoadingContext";
import { THEME } from "@core/theme";
const { skeletonBase } = THEME;
export const CheckoutSectionActionLink = styled.a.attrs(provideIsLoadingProp) `
  font-size: 0.75rem;
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.grey[4]};
  cursor: pointer;

  ${({ isLoading }) => isLoading && skeletonBase + "height: 0.875rem;"};
`;
