import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Box, Row } from "@mm/ui";

const CompContainer = styled("div")({
  height: "100%",
});

const RowComp = styled(Row)({
  height: "100%",
});

const renderDivider = () => (
  <Box
    backgroundColor="shade.2"
    flexShrink="initial"
    flexGrow={1}
    mx="auto"
    my={0}
    width="1px"
  />
);

const VerticalDivider = ({ children }) => (
  <CompContainer>
    <RowComp flexDirection="column">
      {renderDivider()}
      <Box flexShrink={0} flexBasis="auto" mx="auto" my={0} py={2}>
        {children}
      </Box>
      {renderDivider()}
    </RowComp>
  </CompContainer>
);

VerticalDivider.defaultProps = {
  children: <></>,
};

VerticalDivider.propTypes = {
  children: PropTypes.node,
};

export default VerticalDivider;
