import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  border,
  compose,
  color,
  layout,
  position,
  space,
  typography,
  variant as styledVariant,
} from "styled-system";
import css from "@styled-system/css";

export const ICON_NAMES = {
  "shopping-cart": "\\1f6d2",
  menu: "\\2261",
  "arrow-right": "\\276f",
  "arrow-up": "\\5e",
  "arrow-left": "\\276e",
  "arrow-down": "\\76",
  info: "\\69",
  plus: "\\1F6D3",
};

/**
 * Show an icon from the icon font
 *
 * supports any prop from these styled-systems mixins:
 * `border, color, layout, typography`
 */

const StyledIcon = styled.span(
  css({
    position: "relative",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",

    bg: "white",
    borderRadius: "circle",

    ":after": {
      fontFamily: "icon",
      lineHeight: 1,
    },
  }),
  (props) => ({
    ":after": {
      content: `"${ICON_NAMES[props.name]}"`,
      fontSize: props.variant === "teaser" ? 32 : 10,
    },
  }),
  compose(border, color, layout, position, space, typography),
  styledVariant({
    scale: "icons",
    // https://github.com/styled-system/styled-system/issues/1145#issuecomment-587389487
    variants: {
      primary: {},
      teaser: {},
    },
  })
);

export const Icon = ({ name, variant, className }) => (
  <StyledIcon className={className} name={name} variant={variant} />
);

Icon.propTypes = {
  /**
   * name from iconfont
   */
  name: PropTypes.oneOf([
    "shopping-cart",
    "menu",
    "arrow-up",
    "arrow-down",
    "arrow-left",
    "arrow-right",
    "info",
    "plus",
  ]).isRequired,

  /**
   * The design variant to use, omit for custom overrides
   */
  variant: PropTypes.oneOf(["teaser", "primary"]),
  className: PropTypes.string,
};

Icon.defaultProps = {
  variant: undefined,
  className: null,
};
