import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
const StyledButton = styled.button `
  align-self: end;
  margin-bottom: 7px;
  flex-basis: 10%;
  flex-grow: 0;
  margin-left: 10px;
  border: none;
  background: none;
  cursor: pointer;
`;
export const IconButton = ({ src, handleDelete, }) => {
    return (_jsx(StyledButton, { type: "button", onClick: handleDelete, children: _jsx("img", { src: src, alt: "delete icon" }) }));
};
