import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { Box, Surface } from "@mm/ui";
import { StandardPageTemplate } from "@/components/templates";
import { PageFooter, PageHeader } from "@/components/organisms";
import { extendURLParams } from "@/utils/fetchData";
const FlexSurface = styled(Surface) `
  display: flex;
  gap: 1.25rem;
  margin-top: 2rem;
  position: relative;
  flex-wrap: wrap;

  @media (max-width: 760px) {
    flex-wrap: wrap;
    margin-top: 0;
  }

  .page-not-found {
    min-height: 330px;

    &__wrapper {
      position: relative;
      flex-direction: row;

      &--mod {
        min-height: 300px;
        flex: 1;
      }
    }

    &__text-wrapper {
      padding: 20px 20px 0px 20px;

      &__title-big {
        font-size: 30px;
        font-style: normal;
        color: black;
        padding: 0;
        margin-bottom: 0;

        @media all and (min-width: 761px) {
          font-size: 50px;
          line-height: 50px;
        }
      }

      &__title-medium {
        font-size: 20px;
        font-style: normal;
        line-height: 42px;
        max-width: 90%;
        color: black;
        margin-bottom: 1em;
        margin-top: 0;

        @media all and (min-width: 761px) {
          font-size: 30px;
        }
      }

      &__text {
        margin-bottom: 1em;
        max-width: initial;
      }

      &__link {
        font-size: 1rem;
        color: #000;
        padding: 10px 12px;
        margin: 0;
        border-radius: 4px;
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
        background-color: #fff;
        border: 1px solid #fff;
        height: auto;
        display: block;
        width: 120px;
        text-align: center;

        @media all and (min-width: 761px) {
          margin-left: auto;
        }
      }
    }

    &__link-wrapper {
      padding: 0px 20px 0px 20px;

      &__link:hover {
        background: ${({ theme }) => theme.colors.grey[5]};
        color: ${({ theme }) => theme.colors.brand.primary};
        border: 1px solid ${({ theme }) => theme.colors.grey[2]};
      }
    }

    &__image {
      background-image: url("${({ cdnUrl }) => cdnUrl}/img/bg-404.png");
      background-repeat: no-repeat;
      background-position: 0 0;
      width: 288px;
      height: 266px;
      display: none;
      z-index: -1;

      @media all and (min-width: 761px) {
        display: block;
      }
    }
  }
`;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ErrorPage = ({ error }) => {
    const { cdnUrl, tenant } = extendURLParams();
    const tenantName = tenant === "mediamarkt" ? "MediaMarkt" : "Saturn";
    return (_jsx(Box, { children: _jsx(StandardPageTemplate, { header: _jsx(PageHeader, { itemCount: 1 }), sections: [
                _jsxs(FlexSurface, { variant: "narrow", cdnUrl: cdnUrl, children: [_jsxs("div", { className: "row bottom-xs  page-not-found__wrapper--mod", children: [_jsxs("div", { className: "col-xs-24 col-md-12 col-lg-16 page-not-found__text-wrapper", children: [_jsx("h1", { className: "page-not-found__text-wrapper__title-big", children: "Ups, hier geht's" }), _jsx("h2", { className: "page-not-found__text-wrapper__title-medium", children: "gerade nicht weiter." }), _jsxs("p", { className: "page-not-found__text-wrapper__text", children: ["Lieber ", tenantName, " Tarifwelt Kunde,"] }), _jsx("p", { className: "page-not-found__text-wrapper__text", children: "das tut uns leid... hier geht es gerade nicht weiter. Sie befinden sich auf einer Seite, die es aktuell nicht (mehr) gibt. Keine Panik! Klicken Sie doch einfach weiter und schauen Sie sich viele weitere Angebote in unserer Tarifwelt an." }), _jsxs("p", { className: "page-not-found__text-wrapper__text", children: ["Ihr ", tenantName, " Tarifwelt Team"] })] }), _jsx("div", { className: "col-xs-24 col-md-12 col-lg-8 page-not-found__link-wrapper", children: _jsx("a", { href: "/", className: "page-not-found__text-wrapper__link", children: "Zur Startseite" }) })] }), _jsx("div", { className: "row", children: _jsx("div", { className: "col-xs-2", children: _jsx("div", { className: "page-not-found__image" }) }) })] }, "page-not-found-surface"),
            ], footer: _jsx(PageFooter, {}) }) }));
};
