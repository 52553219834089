import React from "react";
// import ReactMarkdown from "react-markdown";
// import rehypeRaw from 'rehype-raw';
// import prepMarkdown from "utils/prepMarkdown";

import { Image } from "@mm/ui";

// TODO: getBreakpoint not available any more in the new system.
// as Image, Video etc.. not used, migrate it if neede to use the viewport in the store instead
// import { getBreakpoint } from '../../support/page-utils';

export const RichSEOTextImageSection = ({ image /* markdown */ }) => {
  // const breakpoint = getBreakpoint(mediaType);
  const breakpoint = {}; // TODO: see above
  const imageUrl =
    image && breakpoint && breakpoint in image ? image[breakpoint].path : "";

  return (
    <div className="text-image-container">
      <Image src={imageUrl} alt={""} />
      {/* <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                {prepMarkdown(markdown)}
            </ReactMarkdown> */}
    </div>
  );
};

export default RichSEOTextImageSection;
