import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useModal } from "@/utils/context/ModalContext";
import { CheckoutSection, CheckoutSectionHeadline, CheckoutSectionText, } from "../components";
export const WebIdentSection = ({ options }) => {
    const { dispatch } = useModal();
    const { content, title, } = options ?? {
        content: undefined,
        title: undefined,
    };
    return (_jsxs(CheckoutSection, { children: [_jsx(CheckoutSectionHeadline, { children: title ?? "Identifikation" }), typeof content === "function" ? (content(dispatch)) : (_jsx(CheckoutSectionText, { children: content }))] }));
};
