import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const ElemContainer = styled.div``;

const ElemHint = styled.div`
  font-size: 12px;
  text-align: center;
  color: ${(props) => props.theme.colors.shade[4]};
  margin-bottom: ${(props) => props.theme.space.xs}px;
`;

const ElemBar = styled.div`
  background-color: ${(props) => props.theme.colors.shade[2]};
  height: 4px;
  width: 100%;
  position: relative;
`;

const ElemActiveBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 4px;
  width: ${({ current, max }) => (current / max) * 100}%;
  background-color: ${(props) => props.theme.colors.brand.primary};
`;

const ProgressBar = ({ current, max, hint }) => (
  <ElemContainer>
    {hint && <ElemHint>{hint}</ElemHint>}
    <ElemBar>
      <ElemActiveBar current={current} max={max} />
    </ElemBar>
  </ElemContainer>
);

ProgressBar.defaultProps = {
  max: 100,
  hint: null,
};

ProgressBar.propTypes = {
  current: PropTypes.number.isRequired,
  max: PropTypes.number,
  hint: PropTypes.string,
};

export default ProgressBar;
