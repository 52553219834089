import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Text, Surface, Col, Row } from "@mm/ui";

const ErrorContainer = styled.div`
  border: 2px solid ${({ theme }) => theme.colors.feedback.error};
  padding: ${({ theme }) => theme.space.m}px;
`;

const ErrorComponent = ({ error }) => {
  if (window.APP_ENV === "production") {
    return null;
  }

  let message = JSON.stringify(error);
  if (error instanceof Error) {
    message = error.message;
  }

  return (
    <Surface variant="narrow">
      <Row>
        <Col xs={24}>
          <ErrorContainer>
            <Text>{message}</Text>
          </ErrorContainer>
        </Col>
      </Row>
    </Surface>
  );
};

ErrorComponent.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
    // could be more props here
  }).isRequired,
};

export default ErrorComponent;
