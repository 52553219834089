import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useReducer, } from "react";
export var ACTIONS;
(function (ACTIONS) {
    ACTIONS["SET_LOADING"] = "SET_LOADING";
})(ACTIONS || (ACTIONS = {}));
export function reducer(state, action) {
    switch (action.type) {
        case ACTIONS.SET_LOADING: {
            return {
                ...state,
                isLoading: action.payload,
            };
        }
        default:
            throw new Error("Reducer action not set");
    }
}
const initialState = {
    isLoading: false,
};
const ctx = createContext({
    state: initialState,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    dispatch: () => { },
});
export const LoadingStateProvider = ({ children, defaultState = initialState, }) => {
    const [state, dispatch] = useReducer(reducer, defaultState);
    return _jsx(ctx.Provider, { value: { state, dispatch }, children: children });
};
export const useLoadingState = () => useContext(ctx);
export const provideIsLoadingProp = () => {
    const { state: { isLoading }, } = useLoadingState();
    return {
        isLoading,
    };
};
