import { useLocation } from "react-router-dom";
import { useCheckoutState } from "../context/CheckoutContext";
/**
 * Returns the currentIndex and an isLastStep flag if called from a Checkout context.
 * Otherwise, returns undefined for both (f.e. on ProductConfigurationPage).
 *
 * @param config
 */
export const useCheckoutStep = () => {
    const { pathname } = useLocation();
    const { state } = useCheckoutState();
    const currentIndex = pathname.endsWith("/")
        ? pathname.split("/").at(-2)
        : pathname.split("/").at(-1);
    const isLastStep = state?.currentConfig
        ? state.currentConfig.steps.length === Number(currentIndex)
        : undefined;
    return {
        currentIndex: currentIndex ? Number(currentIndex) : undefined,
        isLastStep,
    };
};
