import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useCheckoutState } from "../../context/CheckoutContext";
import { useField } from "../../hooks";
import { useCheckout } from "../../hooks/api";
import { CheckoutSection, CheckoutSectionHeadline, CheckoutSectionText, } from "../../components";
import { Button } from "@mm/ui";
import { useInterval } from "@/utils/hooks";
import { extendURLParams } from "@/utils/fetchData";
import styled from "styled-components";
const Message = styled("div") `
  font-size: ${({ theme }) => theme.typography.size.s}px;
  line-height: 1;
  color: ${({ theme }) => theme.colors.grey[4]};
  margin-top: ${({ theme }) => theme.space.xs}px;
  margin-bottom: ${({ theme }) => theme.space.xs}px;

  ${({ layout, theme }) => {
    switch (layout) {
        case "error":
            return `
                    color: ${theme.colors.brand.primary};
                `;
        default:
            return ``;
    }
}}
`;
export const ContractSummarySection = ({ defaultValues, schema, }) => {
    const { state } = useCheckoutState();
    const { data, isFetching, isLoading } = useCheckout();
    const [idle, setIdle] = useState(false);
    const [startInterval, setStartInterval] = useState(false);
    const { clearErrors, errors, fieldState: downloaded, setFieldState: setDownloaded, } = useField("eeccDocumentsDownloaded", schema, Boolean(state?.eeccDocumentsDownloaded || defaultValues?.eeccDocumentsDownloaded));
    const { fieldState: maxRetriesReached, setFieldState: setMaxRetriesReached } = useField("eeccDocumentMaxRetriesReached", schema, Boolean(state?.eeccDocumentMaxRetriesReached ||
        defaultValues?.eeccDocumentMaxRetriesReached));
    const { tenant, environment, restUrl } = extendURLParams();
    useEffect(() => {
        if (downloaded) {
            clearErrors();
        }
    }, [downloaded]);
    useInterval(async () => {
        /**
         * We can not use refetch because of the rerendering of the page.
         * So we have to fetch the api endpoint on our own.
         */
        if (downloaded) {
            return;
        }
        try {
            const res = await fetch(`${restUrl}/v1/checkout?tenant=${tenant}&environment=${environment}`, {
                method: "GET",
                credentials: "include",
            });
            if (!res.ok) {
                throw new Error(`HTTP error! status: ${res.status}`);
            }
            const data = await res.json();
            if (data?.data && "eeccDocumentUris" in data.data) {
                downloadFile(data.data.eeccDocumentUris[0]);
                setDownloaded(true);
            }
        }
        catch (error) {
            // eslint-disable-next-line no-console
            console.error("Failed to fetch data:", error);
        }
    }, startInterval ? 2000 : null, 15000, [startInterval, downloaded], () => {
        if (!downloaded) {
            setIdle(false);
            setStartInterval(false);
            setMaxRetriesReached(true);
            clearErrors();
        }
    });
    const downloadFile = (url) => {
        const a = document.createElement("a");
        a.href = url;
        a.target = "_blank";
        a.download = url.split("/").pop() ?? url;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };
    const handleButtonClick = () => {
        // TODO: fix data type handling
        if (data &&
            data.data?.eeccDocumentUris &&
            data.data.eeccDocumentUris.length > 0) {
            downloadFile(data.data.eeccDocumentUris[0]);
            setDownloaded(true);
        }
        else {
            setIdle(true);
            setStartInterval(true);
        }
    };
    return (_jsxs(CheckoutSection, { children: [_jsx(CheckoutSectionHeadline, { children: "Vertragszusammenfassung" }), _jsx(CheckoutSectionText, { children: "Durch eine Gesetzes\u00E4nderung sind wir dazu verpflichtet, dir deine Vertragsdokumente zum Download zur Verf\u00FCgung zu stellen." }), !downloaded && !maxRetriesReached && (_jsx(CheckoutSectionText, { children: "Eine Zusammenfassung der Vertragsinformationen vor Bestellabschluss kannst du dir hier herunterladen." })), !maxRetriesReached && !downloaded ? (_jsx(Button, { type: "button", color: "primary", size: "fullWidth", loading: isLoading || isFetching || idle, onClick: () => handleButtonClick(), disabled: isLoading || isFetching || idle, children: "Vertragsdokumente herunterladen" })) : downloaded ? (_jsx(CheckoutSectionText, { children: "Vertragsdokumente heruntergeladen." })) : (_jsxs(_Fragment, { children: [_jsx(CheckoutSectionText, { children: _jsx("strong", { children: "Leider ist es im Moment technisch nicht m\u00F6glich die Vertragsdokumente anzuzeigen. Du erh\u00E4ltst nach Abschluss der Bestellung eine Mail, in der du die M\u00F6glichkeit hast, alle Vertragsdokumente herunterzuladen." }) }), _jsx(CheckoutSectionText, { children: "Erst nachdem du alle Vertragsunterlagen heruntergeladen hast, k\u00F6nnen wir deine Bestellung an 1&1 weiterleiten." })] })), errors?.length && errors.length > 0 && (_jsx(Message, { layout: "error", children: errors[0].message })), data?.summary?.tariff.pibUrl && (_jsxs(CheckoutSectionText, { children: ["Weitere Informationen findest du im", " ", _jsx("a", { href: data.summary.tariff.pibUrl, target: "_blank", rel: "noreferrer", children: "Produktinformationsblatt" }), "."] })), _jsx(CheckoutSectionText, { children: "Wenn du auf dieser Grundlage bestellen m\u00F6chtest, klicke auf \"Kostenpflichtig bestellen\"." })] }));
};
