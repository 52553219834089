import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledSubHeadline = styled.span`
  /*
     TODO: Line-Clamp fix
     * use vendor prefix due as line-clamp is not standardized:
     * https://caniuse.com/#feat=css-line-clamp
     */ /* stylelint-disable value-no-vendor-prefix, property-no-vendor-prefix */
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  /* stylelint-enable */
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;

  span {
    text-transform: uppercase;
  }
`;

const VariantHeadline = ({ className, children }) => (
  <StyledSubHeadline className={className}>{children}</StyledSubHeadline>
);

VariantHeadline.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

VariantHeadline.defaultProps = {
  className: "",
};

export default VariantHeadline;
