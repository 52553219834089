import { useQuery } from "react-query";
import { extendURLParams } from "@/utils/fetchData";
export const useCountries = (options) => {
    const { tenant, environment, restUrl } = extendURLParams();
    return useQuery(["useCountries", tenant, environment], () => fetch(`${restUrl}/v1/countries?tenant=${tenant}&environment=${environment}`, {
        method: "get",
        credentials: "include",
    }).then(async (res) => {
        return await res.json();
    }), {
        ...options,
        cacheTime: 0,
        refetchOnWindowFocus: false,
        enabled: false,
        retry: 0,
    });
};
