import React from "react";
import PropTypes from "prop-types";
import { map } from "lodash";
import { Dropdown } from "@mm/ui";

const getStorageSizes = (variants) =>
  map(variants, ({ variant: { storage } }, key) => ({
    label: `${storage} GB`,
    value: key,
    storage,
  }));

const VariantsStorageDropdown = ({
  className,
  variants,
  selected,
  onChange,
}) => {
  const options = getStorageSizes(variants);
  return (
    <Dropdown
      className={className}
      label="Speicherkapazität"
      options={options}
      onChange={onChange}
      selected={selected}
    />
  );
};

VariantsStorageDropdown.defaultProps = {
  className: "",
  variants: {},
  selected: null,
};

VariantsStorageDropdown.propTypes = {
  className: PropTypes.string,
  variants: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default VariantsStorageDropdown;
