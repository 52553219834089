import styled from "styled-components";
import { variant } from "styled-system";
import { Box, Surface, Button, Flex } from "@mm/ui";

export const StyledWrapper = styled(Surface)``;

export const StyledCol = styled(Surface)`
  display: ${(props) => (props.isLoading ? "block" : "flex")};
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.flexGrid.gap}px;
  padding: 0;
`;

export const StyledOfferTeaserEnergy = styled("div")`
  display: flex;
  flex-direction: column;
  flex-basis: calc(50% - ${({ theme }) => theme.flexGrid.gap / 2}px);
  flex-grow: 1;
  justify-content: space-between;
  background: white;
  padding: ${({ theme }) => theme.space.l}px;
  border: ${({ theme }) => theme.borders.small};
  border-radius: ${({ theme }) => theme.borders.radius};

  transition-duration: 0.15s;
  transition-timing-function: ease-in-out;
  transition-property: border-color, box-shadow, transform;

  :hover,
  :focus,
  :active {
    box-shadow: ${({ theme }) => theme.shadows.elevation2};
    border: 1px solid white;
  }
`;

export const BackgroundSurface = styled(Surface)`
  display: flex;
  align-items: center;
  background-image: url(${(props) => props.backgroundImg});
  background-position: center;
  background-size: cover;
  padding-top: ${({ theme }) => theme.space.m}px;
  padding-bottom: ${({ theme }) => theme.space.xxlhuge}px;
  min-height: 410px;
  border-radius: 0;
  box-shadow: ${({ theme }) => theme.shadows.elevation2};
  max-width: 1440px;
`;

export const InnerWrapper = styled("div")`
  margin: 0 auto;
  width: 100%;
  max-width: 1024px;
`;

export const ContentColor = styled("div")`
  h1 {
    color: ${(props) => {
      switch (props.variant) {
        case "white":
          return ({ theme }) => theme.colors.white;
        case "black":
          return ({ theme }) => theme.colors.black;
        case "brand":
          return ({ theme }) => theme.colors.brand.primary;
        default:
          return "white";
      }
    }};
  }

  p,
  span {
    color: ${(props) => {
      switch (props.variant) {
        case "white":
          return ({ theme }) => theme.colors.white;
        case "black":
          return ({ theme }) => theme.colors.black;
        case "brand":
          return ({ theme }) => theme.colors.brand.primary;
        default:
          return "white";
      }
    }};
  }

  p,
  span {
    display: block;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }

  img {
    display: block;
    max-width: 200px;
    margin: 20px 0 0 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpointsMap.sm.min}px) {
    p {
      margin-bottom: 0;
    }
  }

  ${variant({
    variants: {
      black: {
        color: "black",
      },
      white: {
        color: "white",
      },
    },
  })}
`;

export const ImageWrapper = styled(Box)`
  display: flex;
  align-items: flex-end;
  justify-content: end;

  .imageWrapper__seal {
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 150px;
    height: auto;
    padding: 15px;
    border-radius: ${({ theme }) => theme.borders.radius};
    margin: auto 0 0 auto;

    img {
      max-width: 100%;
      margin: 10px auto;
    }
  }

  .imageWrapper__text {
    position: relative;

    ::after {
      content: "";
      display: block;
      height: 1px;
      width: calc(100% + 30px);
      background-color: black;
      margin-left: -15px;
      margin-right: -15px;
    }

    p {
      font-size: 14px;
      font-weight: bold;
      padding: 0 0 10px 0;
      margin: 0;
      text-align: center;
    }
  }

  .imageWrapper__logo {
    img {
      display: block;
      width: 100%;
      max-width: 150px;
      height: auto;
      margin-top: 15px;
    }
  }
`;

export const FormSurface = styled(Surface)`
  form {
    background-color: white;
    border-radius: 4px;
    box-shadow: ${({ theme }) => theme.shadows.elevation2};
    padding: ${({ theme }) => theme.space.xxl}px
      ${({ theme }) => theme.space.s}px;
    width: auto;
    margin-top: -70px;

    @media (min-width: ${({ theme }) => theme.breakpointsMap.sm.min}px) {
      padding: ${({ theme }) => theme.space.xxl}px;
    }

    @media (min-width: ${({ theme }) => theme.breakpointsMap.lg.min}px) {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

export const StyledRadioWrapper = styled("div")`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${({ theme }) => theme.space.xxl}px;

  label {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: ${({ theme }) => theme.space.xl}px;
  }

  input[type="radio"] {
    position: relative;
    appearance: none;
    width: 24px;
    height: 24px;
    padding: 2px;
    border-radius: 50%;
    border: 1px solid grey;
    cursor: pointer;

    &:after {
      content: "";
      position: absolute;
      top: 5px;
      left: 5px;
      display: block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
    }

    &:focus,
    &:hover,
    &:checked {
      :after {
        background-color: ${({ theme }) => theme.colors.brand.primary};
      }
    }
  }

  p.error {
    flex-basis: 100%;
    font-size: 12px;
    line-height: 1;
    color: #4c4a4a;
    margin-top: 8px;
    margin-bottom: 8px;
    color: #df0000;
  }
`;

export const ConsumptionBtn = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  padding: 5px;
  border: 1px solid black;

  &:hover {
    border: 2px solid ${({ theme }) => theme.colors.brand.primary};
    text-decoration: none;
  }

  &.active {
    border: 2px solid ${({ theme }) => theme.colors.brand.primary};
    text-decoration: none;
  }
`;

export const GasIcon = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3px;

  .gas-icon__text {
    font-size: 14px;
  }
`;

export const StyledInputText = styled("div")`
  flex-basis: 50%;
  position: relative;

  ${variant({
    variants: {
      full: {
        flexBasis: "100%",
      },
      half: {
        flexBasis: "50%",
      },
    },
  })}
`;

export const FlexWrapper = styled(Flex)`
  gap: ${({ theme }) => theme.space.m}px;
`;

export const FlexContainer = styled(Flex)`
  gap: 0;
  justify-content: space-between;

  button[type="submit"] {
    width: 100%;
  }

  ${variant({
    variants: {
      noShrink: {
        flex: "0 0 auto",
      },
      shrink: {
        flex: "1 0 auto",
      },
      full: {
        flexWrap: "wrap",
      },
      half: {
        flexWrap: "nowrap",
      },
    },
  })}

  @media (min-width: ${({ theme }) => theme.breakpointsMap.sm.min}px) {
    gap: ${({ theme }) => theme.space.m}px;
  }
`;

export const OptionList = styled.ul`
  background: white;
  border-radius: 4px;
  border: 2px solid rgb(205, 202, 202);
  display: block;
  list-style: none;
  min-width: 100%;
  max-height: 190px;
  max-width: 200px;
  margin: 0;
  overflow-y: scroll;
  position: absolute;
  padding: 10px;
  z-index: 10;

  .option-list {
    &__item {
      padding: 5px 0;

      &:hover,
      &:focus {
        cursor: pointer;
      }

      &--text-right {
        text-align: right;
      }

      &.active {
        background-color: #c2c2c2;
      }

      &.hide {
        display: none;
      }
    }
  }
`;
