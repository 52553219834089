import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { Image, Box } from "@mm/ui";

import GeneralModalShell from "../../atoms/GeneralModalShell";
import ZoomImage from "../../atoms/ZoomImage";

const RegularImg = styled(Image)({
  height: "100%",
  display: "inline-block",
});

const ZoomedImg = styled(ZoomImage)({
  height: "100%",
  width: "180px",
  figure: {
    height: "100%",
    img: {
      width: "auto",
      height: "100%",
      margin: "0 auto",
    },
  },
});

export const sizes = ["S", "M", "L"];

const renderImage = (image, detailView) => {
  let img = <RegularImg src={image} alt="accessory" />;

  if (detailView === "zoom") {
    img = <ZoomedImg img={image} alt="accessory" zoomScale={2} />;
  }

  return img;
};

const AccessoryImage = ({ variantImage: image, detailView }) =>
  detailView === "modal" ? (
    <GeneralModalShell
      size="sm"
      dynamicHeight
      opener={renderImage(image, detailView)}
    >
      <Box textAlign="center">{renderImage(image, detailView)}</Box>
    </GeneralModalShell>
  ) : (
    renderImage(image, detailView)
  );

AccessoryImage.defaultProps = {
  detailView: "none",
  variantImage: "",
};

AccessoryImage.propTypes = {
  variantImage: PropTypes.string,
  detailView: PropTypes.oneOf(["none", "zoom", "modal"]),
};

export default AccessoryImage;
