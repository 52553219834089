import { map } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { variant as styledVariant } from "styled-system";

export const StyledTable = styled.table`
  width: 100%;
  /* drop space between tbody & tr */
  border-collapse: collapse !important;
`;

export const Tr = styled.tr(
  styledVariant({
    variants: {
      price: {
        borderBottom: "small",
        borderColor: ({ colors }) => colors.black,
      },
      details: {
        "&:hover": {
          backgroundColor: ({ colors }) => colors.shade[1],
        },
      },
    },
  })
);

export const Td = styled.td(
  // we cant access isLastCell prop in the variants object below so define it here with condition
  ({ isLastCell, variant }) =>
    isLastCell && variant === "price"
      ? `
        text-align: right;
    `
      : "",
  styledVariant({
    variants: {
      price: {
        paddingTop: ({ space }) => `${space.xxs}px`,
        paddingBottom: ({ space }) => `${space.xxs}px`,
        borderColor: ({ colors }) => colors.black,
        fontSize: ({ text }) => text.copy.fontSize,
      },
      details: {
        "&:first-of-type": {
          width: "30%",
        },
        padding: ({ space }) => `${space.l}px`,
        borderTop: ({ colors }) => `1px solid ${colors.shade[2]}`,
      },
      autoWidthDetails: {
        padding: ({ space }) => `${space.l}px`,
        borderTop: ({ colors }) => `1px solid ${colors.shade[2]}`,
      },
    },
  })
);

/**
 * A `<table>` with customized styles
 */
export const Table = ({ rows, variant }) => (
  <StyledTable variant={variant}>
    <tbody>
      {map(rows, (row, index) => (
        <Tr variant={variant} key={`Table-Tr-${index}`}>
          {map(row, (cell, idx) => {
            if (!cell) return null;
            const isLastCell = idx === row.length - 1;

            // if the cell is of type object (see proptypes) we use the key/content
            // from it otherwise lets use key/content based on the cell (string/number)
            const { key, content } =
              typeof cell === "object" ? cell : { key: cell, content: cell };

            return (
              <Td key={key} isLastCell={isLastCell} variant={variant}>
                {content}
              </Td>
            );
          })}
        </Tr>
      ))}
    </tbody>
  </StyledTable>
);

Table.propTypes = {
  // the price variant expexts the last cell in rows to be of type number
  // to correctly display price via Price component
  variant: PropTypes.oneOf(["default", "price", "details"]),

  /**
   * The data to render, supports any number of columns
   */
  rows: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        // for some special cases, where the cell is a node we allow setting an object of
        // key/content here this way the user can pass a node and define a key for the cell
        PropTypes.shape({
          key: PropTypes.string,
          content: PropTypes.node,
        }),
        PropTypes.string,
        PropTypes.number,
      ])
    )
  ).isRequired,
};

Table.defaultProps = { variant: "default" };

export default Table;
