import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Button = styled("button")`
  padding: 0;
  color: white;
  background: transparent;
  border: 0;
  cursor: pointer;
`;

const MobileNaviButton = ({ className, onClick, ariaLabel }) => (
  <Button
    aria-label={ariaLabel}
    className={`${className} menu icon-MM_iconfont_Menu`}
    onClick={onClick}
  />
);

MobileNaviButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  ariaLabel: PropTypes.string,
};

MobileNaviButton.defaultProps = {
  ariaLabel: "",
};

export default MobileNaviButton;
