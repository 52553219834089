import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styled from "styled-components";
import { transformNumberStringToCommaDelimitedString } from "@/utils/transformFloatStringToCommaDelimitedString";
const PriceDetails = styled.div `
  margin-top: 0.5em;
  display: flex;
  gap: 1em;
`;
const PriceDetailColumn = styled.div ``;
const Span = styled.span `
  color: inherit;
  display: block;
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
  margin-left: 0;
  text-align: left;
`;
const PriceSpan = styled(Span) `
  margin-top: 0.25em;
`;
const PriceFineprint = styled(Span) `
  margin-top: 1em;
  font-size: 10px;
  font-weight: 400;
`;
export const EnergyPriceDetails = ({ brutto_arbeitspreis, brutto_grundpreis, }) => (_jsxs(_Fragment, { children: [_jsxs(PriceDetails, { children: [_jsxs(PriceDetailColumn, { children: [_jsx(Span, { children: "Grundpreis" }), _jsxs(PriceSpan, { children: [transformNumberStringToCommaDelimitedString(brutto_grundpreis), " \u20AC / Jahr"] })] }), _jsxs(PriceDetailColumn, { children: [_jsx(Span, { children: "Arbeitspreis" }), _jsxs(PriceSpan, { children: [transformNumberStringToCommaDelimitedString(brutto_arbeitspreis), " ct / kWh"] })] })] }), _jsx(PriceFineprint, { children: "Gerundete Bruttopreise inkl. 19% Umsatzsteuer" })] }));
