import React, { useMemo } from "react";
import PropTypes from "prop-types";

import ColorRow from "components/atoms/ColorRow";
import { useOfferConfigurator, SET_ACTIVE_VARIANT } from "features/Offer";
import { findVariantById } from "utils/variantUtils";
import dataLayer from "helper/dataLayer";

import ColorDots from "./ColorDots";

const getColors = (variants, activeVariantId) => {
  const activeVariant = findVariantById(variants, activeVariantId);
  const retVal = [];
  variants.forEach((variant) => {
    if (activeVariant.variant.storage === variant.variant.storage) {
      const result = {
        label: (
          <ColorRow
            color={{
              name: variant.variant.color.name,
              value: variant.variant.color.hex,
            }}
            colorSize="small"
            hideCount
          />
        ),
        name: variant.variant.color.name,
        value: variant.variant.ebootisId,
        color: variant.variant.color.hex,
      };
      retVal.push(result);
    }
  });
  return retVal;
};

/**
 Wrapper Component, that connects to the configurator store and uses a hoc'ed ColorPicker Component
 */
const ColorPicker = (props) => {
  const { setInternalActiveVariant, activeVariantId } = props;
  const {
    state: { extendedVariants },
    dispatch,
  } = useOfferConfigurator();

  const options = useMemo(
    () => getColors(extendedVariants, activeVariantId),
    [extendedVariants, activeVariantId]
  );
  const selected = useMemo(
    () => options.find((opt) => opt.value === activeVariantId),
    [options]
  );

  const handleOnClick = (variantId, option) => {
    dispatch({
      type: SET_ACTIVE_VARIANT,
      payload: {
        activeVariantId: variantId,
        color: option.color,
      },
    });
  };

  return (
    <ColorDots
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      onClick={(variantId, option) => {
        if (option.name) {
          dataLayer({
            eventCategory: "Produktfilter",
            eventAction: "select",
            eventLabel: `Hardwarefarbe:${option.name}`,
          });
        }
        handleOnClick(variantId, option);
      }}
      setInternalActiveVariant={setInternalActiveVariant}
      activeVariantId={activeVariantId}
      extendedVariants={extendedVariants}
      options={options}
      selected={selected}
    />
  );
};

ColorPicker.defaultProps = {
  className: "",
};

ColorPicker.propTypes = {
  accessories: PropTypes.array,
  activeAccessoryVariantIds: PropTypes.object,
  className: PropTypes.string,
  activeVariantId: PropTypes.string.isRequired,
  setInternalActiveVariant: PropTypes.func.isRequired,
};

export default ColorPicker;
