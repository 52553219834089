/* eslint-disable func-names */
import React from "react";
import styled from "styled-components";

const StyledPillLabelList = styled.div`
  display: flex;
  align-self: flex-end;
  padding: 0;

  ul {
    padding: 0;
    list-style: none;
    margin-left: -6px;
    margin-right: -6px;
  }

  li {
    display: inline-block;
    padding-bottom: 2px;
    padding-top: 2px;
    padding-left: ${({ theme }) => theme.space.m}px;
    padding-right: ${({ theme }) => theme.space.m}px;
    margin-left: 6px;
    margin-right: 6px;
    margin-bottom: 6px;
    border-radius: ${({ theme }) => theme.pillLabelList.borderRadius};
    font-size: 12px;
    border: rgb(207, 203, 202) 1px solid;
    color: rgb(145, 142, 140);
  }

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    ul {
      max-width: 280px;
    }
  }
`;

function PillLabelList({ highlights, mobile }) {
  const mobileHighlights = highlights.slice(0, 2);

  return (
    <>
      <StyledPillLabelList>
        <ul>
          {!mobile
            ? highlights.map((item) => {
                return <li key={item}>{item}</li>;
              })
            : mobileHighlights.map((item) => {
                return <li key={item}>{item}</li>;
              })}
        </ul>
      </StyledPillLabelList>
    </>
  );
}

export default PillLabelList;
