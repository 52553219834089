import { padStart } from "lodash";

export const displayPrice = (price) =>
  Number.isInteger(price) ? `${price},-` : `${price}`;

export const determinePriceParts = (price, forceCentDisplay) => {
  const priceStr = price.toFixed(2).toString();
  let euro = priceStr.substring(0, priceStr.length - 3);
  let cent = priceStr.substring(priceStr.length - 2);

  if (priceStr.length === 2) {
    euro = 0;
  }

  if (cent.length === 1) {
    cent = padStart(cent || 0, 2, "0");
  }

  return {
    euro,
    cent: !forceCentDisplay && cent === "00" ? "-" : cent,
  };
};
