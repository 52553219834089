import React from "react";

import styled from "styled-components";

import { Box, Flex, Image, Surface, Col, Row } from "@mm/ui";

import { useViewport } from "@core/theme";

import { useOfferConfigurator } from "features/Offer";

import { Eyecatchers, BorderBox, TariffBadge, Price } from "components/atoms";
import {
  Loading,
  SelectedTariff,
  DeliveryInfoModal,
} from "components/molecules";

import { PriceSection } from "sections";

const TileBox = styled(BorderBox)`
  background: ${({ theme }) => theme.colors.white};

  @media (max-width: ${({ theme }) => theme.breakpointsMap.md.max}px) {
    margin-top: ${({ theme }) => `${theme.space.xxl}px`};
  }
`;

// Overwrite the default TariffBadge max-width and height
const StyledSurface = styled(Surface)`
  position: relative;
  margin-bottom: ${({ theme }) => `${theme.space[7]}px`};

  &:after {
    content: "";
    height: 95px;
    background: ${({ theme }) => theme.colors.brand.primary};
    position: absolute;
    z-index: -1;
    bottom: -30px;
    left: 0;
    right: 0;

    @media (max-width: ${({ theme }) => theme.breakpointsMap.md.max}px) {
      top: 320px;
      bottom: unset;
    }
  }

  [class*="TariffBadge__Img"] {
    height: 315px;
    max-width: unset;
    margin: auto 0 0 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpointsMap.md.max}px) {
    margin-bottom: 0;
  }
`;

const StyledFlex = styled(Flex)`
  position: relative;
`;

const CouponBG = styled(Image)`
  width: 100%;
`;

const MiniCouponWrapper = styled.div`
  position: absolute;
  bottom: 20px;
  left: 120px;
  display: inline-flex;
  width: 100%;
  max-width: 176px;
  margin-bottom: ${({ theme: { padding } }) => padding[1]};

  @media (max-width: ${({ theme }) => theme.breakpointsMap.sm.max}px) {
    left: 80px;
  }

  img {
    width: 100%;
  }
`;

const MiniCouponPrice = styled(Price).attrs({ size: "s" })`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const HeroTariff = () => {
  const { greaterThan, lessThan } = useViewport();

  const { state } = useOfferConfigurator();

  const { rootInfo } = state;

  if (!rootInfo) {
    return (
      <Surface variant="narrow">
        <Loading variant="hero" marginTop={4} />
      </Surface>
    );
  }

  const {
    tariff,
    stock,
    coupon,
    eyeCatchers,
    tariff: { serviceProvider, carrier, deliveryTime, deliveryPrice },
  } = rootInfo;

  const tariffUrl = `/tarife/${serviceProvider}`;

  const deliveryInfo = {
    deliveryTime,
    deliveryPrice,
  };

  return (
    <StyledSurface variant="narrow">
      <Row>
        <Col lg={12}>
          <StyledFlex
            justifyContent="space-between"
            flexWrap="wrap"
            height="100%"
          >
            {eyeCatchers && (
              <Box mb={7} width="100%">
                <Eyecatchers eyecatchers={eyeCatchers} />
              </Box>
            )}
            <TariffBadge tariff={{ carrier, serviceProvider }} sim />
            {coupon && (
              <MiniCouponWrapper>
                <CouponBG
                  // TODO: rename mm-neu directory / generally restructure images
                  src="/img/mm-neu/geschenk-coupon@3x.png"
                  srcSet="/img/mm-neu/geschenk-coupon@2x.png 2x,
                                /img/mm-neu/geschenk-coupon@3x.png 3x"
                />
                <MiniCouponPrice price={coupon} variant="secondary" />
              </MiniCouponWrapper>
            )}
          </StyledFlex>
        </Col>
        <Col lg={12}>
          <TileBox>
            <SelectedTariff
              tariff={tariff}
              tariffUrl={tariffUrl}
              serviceProvider={serviceProvider}
            />
            {greaterThan.md && (
              <Box mt={4}>
                <PriceSection
                  shippingTextVariant="smallCopy"
                  selectedAccessoryType="COUPON"
                  activeTariff={tariff}
                  stock={stock}
                  rootInfo={rootInfo}
                  priceShippingInfo={
                    <DeliveryInfoModal
                      deliveryPrice={deliveryInfo.deliveryPrice}
                    />
                  }
                />
              </Box>
            )}
          </TileBox>
          {lessThan.md && (
            <Box mt={4}>
              <PriceSection
                shippingTextVariant="s"
                activeTariff={tariff}
                selectedAccessoryType="COUPON"
                stock={stock}
                rootInfo={rootInfo}
                priceShippingInfo={
                  <DeliveryInfoModal
                    deliveryPrice={deliveryInfo.deliveryPrice}
                  />
                }
              />
            </Box>
          )}
        </Col>
      </Row>
    </StyledSurface>
  );
};

export default HeroTariff;
