/**
 * Transforms given strings of a certain pattern that are assumed to be dates
 * to a "dd.MM.yyyy" format.
 * - strips all chars that over-bound the available length of 8
 * - strips all non-digit chars
 * - injects dots behind "dd" and "MM" positions
 *
 * @param value
 */
export const transformDateString = (value) => {
    let transformedValue;
    // 1) strip all non-digit characters
    transformedValue = value.replace(/\D/g, "");
    // 2) trim to overall length of 8
    transformedValue = transformedValue.substring(0, 8);
    // 3) inject dot behind "dd"
    if (transformedValue.length >= 2) {
        transformedValue = `${transformedValue.substring(0, 2)}.${transformedValue.substring(2)}`;
    }
    // 4) inject dot behind "dd.MM"
    if (transformedValue.length >= 5) {
        transformedValue = `${transformedValue.substring(0, 5)}.${transformedValue.substring(5)}`;
    }
    return transformedValue;
};
