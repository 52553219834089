import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { Flex } from "@mm/ui";

import { textVariantsPropTypes } from "sharedPropTypes";
import { Price, PriceRow } from "components/atoms";
import { PriceDisplay, LegalNotes } from "components/molecules";

const PriceDisplayAligned = styled(PriceDisplay)`
  align-items: flex-end;
  justify-content: flex-end;
`;

const PriceEntry = ({
  highlight,
  price,
  priceStr,
  sub,
  tariffId,
  textVariant,
  legalNotes,
}) => {
  const displayAdditionalLegalNotes = tariffId && !highlight;
  return (
    <PriceRow
      text={priceStr}
      textVariant={textVariant}
      price={
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Flex alignItems="center">
          {highlight ? (
            // onetime price
            <>
              <PriceDisplayAligned
                size="s"
                price={price}
                tariffId={tariffId}
                tariffLegalNotes={legalNotes}
              />
            </>
          ) : (
            <Price size="s" price={price} preventStyling />
          )}
          {displayAdditionalLegalNotes && (
            // monthlyprice
            <LegalNotes
              tariffLegalNotes={legalNotes}
              tariffId={tariffId}
              stageStyle={false}
              pl={1}
            />
          )}
        </Flex>
      }
      textSubline={sub}
    />
  );
};

PriceEntry.defaultProps = {
  textVariant: "copy",
};

PriceEntry.propTypes = {
  highlight: PropTypes.bool.isRequired,
  price: PropTypes.number.isRequired,
  priceStr: PropTypes.node.isRequired,
  sub: PropTypes.node.isRequired,
  tariffId: PropTypes.string.isRequired,
  textVariant: textVariantsPropTypes,
};

PriceEntry.defaultProps = {};

export default PriceEntry;
