import React from "react";
import PropTypes from "prop-types";

import { tariffPropTypes } from "sharedPropTypes";

import { TariffMonthlyPriceLabel } from "components/molecules";
import { displayPrice } from "utils/price";

import PriceDisplay from ".";

const TariffPriceDisplay = ({
  tariff,
  tariff: {
    connectionFee,
    oneTimePriceFrom,
    id,
    legalNote,
    tariffId,
    commission,
    monthlyPrice,
  },
  averagePrice,
  direction,
  size,
  noPostText,
}) => {
  const tariffPrice = averagePrice || monthlyPrice;
  // change string to "Monatlicher Durchschnittspreis" on listing page
  const priceString = averagePrice < monthlyPrice;
  return (
    <PriceDisplay
      tariffLegalNotes={legalNote?.text}
      tariffId={tariffId || id}
      pretext={
        <TariffMonthlyPriceLabel
          tariff={tariff}
          commission={commission}
          priceString={priceString}
        />
      }
      price={tariffPrice}
      // FIXME use value from backend in Gerätepreis ab
      posttext={[
        !noPostText && `Einmaliger Anschlusspreis: ${connectionFee}`,
        oneTimePriceFrom &&
          `Einmaliger Gerätepreis ab ${displayPrice(oneTimePriceFrom)}`,
      ]}
      size={size}
      direction={direction}
    />
  );
};

TariffPriceDisplay.propTypes = {
  tariff: tariffPropTypes.isRequired,
  direction: PropTypes.oneOf(["row", "column"]),
  size: PropTypes.oneOf(["s", "m", "l"]).isRequired,
  noPostText: PropTypes.bool,
};

TariffPriceDisplay.defaultProps = {
  direction: "column",
  noPostText: false,
};

export default TariffPriceDisplay;
