import { CheckoutType } from "@/types";
import { config as internetConfig } from "./internet";
import { config as streamingConfig } from "./streaming";
import { config as simOnlyConfig } from "./simOnly";
export * from "./IOfferSummaryConfig";
export const getConfigByCheckoutType = (type) => {
    switch (type) {
        case CheckoutType.internet: {
            return internetConfig;
        }
        case CheckoutType.streaming: {
            return streamingConfig;
        }
        case CheckoutType.simOnly: {
            return simOnlyConfig;
        }
        default: {
            return undefined;
        }
    }
};
