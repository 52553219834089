import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CheckoutSection, CheckoutSectionHeadline, StyledInputText as InputText, } from "../components";
import styled from "styled-components";
import { useField, ValidateOnEvent } from "../hooks";
import { useCheckout } from "../hooks/api";
const InputWrapper = styled.div `
  width: 100%;
`;
export const PromotionCodeSection = ({ schema }) => {
    const { data, errors, fieldState, setFieldState, trigger } = useField("promotionCode", schema, "", {
        validateOn: ValidateOnEvent.Manual,
    });
    const { isFetching, isLoading } = useCheckout();
    return (_jsxs(CheckoutSection, { children: [_jsx(CheckoutSectionHeadline, { children: "Aktionscode" }), _jsx(InputWrapper, { children: _jsx(InputText, { id: "promotionCode", label: "Aktionscode", value: fieldState, message: errors?.[0]?.message || (data && "Aktionscode akzeptiert"), layout: (errors && "error") || (data && "success") || "normal", onChange: (value) => {
                        setFieldState(value);
                    }, onBlur: () => trigger(), disabled: isLoading || isFetching, autoComplete: "none" }) })] }));
};
