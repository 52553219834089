import React from "react";

import { ThemeProvider } from "@core/theme";

import { Surface } from "@mm/ui";

import { ContactInfo } from "components/molecules";

const ContactInfoSection = ({ data }) => (
  <ThemeProvider>
    <Surface variant="narrow" mt={[4, 4, 4, 6, 6]}>
      <ContactInfo title={data.title} content_md={data.content_md} />
    </Surface>
  </ThemeProvider>
);

export default ContactInfoSection;
