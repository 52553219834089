import React from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import styled from "styled-components";

import { breakpoints } from "@core/theme/src/theme/theme";
import dataLayer from "helper/dataLayer";

const PictureWrapper = styled.div`
  border: ${({ noBorder, theme }) =>
    noBorder ? "none" : `solid 1px ${theme.colors.grey[7]}`};
  border-radius: 4px;
  overflow: hidden;
  background-color: #ffffff;

  .linkWrapper {
    display: block;
  }

  img {
    display: block;
    width: 100%;
    margin: 0 auto;
  }
`;

const ImageOnlyAdTeaser = ({
  onClick,
  content: { images, linkUrl, type },
  className,
  qaName,
}) => {
  const url = window.location.href;
  const noBorder = url.includes("geburtstag-angebote");

  const handleClick = (onClick, url, component) => {
    dataLayer({
      eventCategory: "Teaser",
      eventAction: "click",
      eventLabel: `${component}:${url}`,
    });
    if (onClick) onClick(url);
  };

  if (Object.keys(images).length <= 0) {
    return <p>Es sind keine Bilderpfade angegeben</p>;
  }

  return (
    <PictureWrapper qaName={qaName} className={className} noBorder={noBorder}>
      {linkUrl ? (
        <a
          className="linkWrapper"
          href={linkUrl}
          target="_self"
          onClick={() => handleClick(onClick, linkUrl, type)}
        >
          <picture>
            <source
              srcSet={window.CDN_URL + images.xlUrl}
              media={`(min-width: ${breakpoints.xl})`}
            />
            <source
              srcSet={window.CDN_URL + images.lgUrl}
              media={`(min-width: ${breakpoints.lg})`}
            />
            <source
              srcSet={window.CDN_URL + images.mdUrl}
              media={`(min-width: ${breakpoints.md})`}
            />
            <source
              srcSet={window.CDN_URL + images.smUrl}
              media={`(min-width: ${breakpoints.sm})`}
            />
            <source
              srcSet={window.CDN_URL + images.xsUrl}
              media={`(min-width: 0px)`}
            />
            <img alt={""} src={window.CDN_URL + images.lgUrl} />
          </picture>
        </a>
      ) : (
        <picture>
          <source
            srcSet={window.CDN_URL + images.xlUrl}
            media={`(min-width: ${breakpoints.xl})`}
          />
          <source
            srcSet={window.CDN_URL + images.lgUrl}
            media={`(min-width: ${breakpoints.lg})`}
          />
          <source
            srcSet={window.CDN_URL + images.mdUrl}
            media={`(min-width: ${breakpoints.md})`}
          />
          <source
            srcSet={window.CDN_URL + images.smUrl}
            media={`(min-width: ${breakpoints.sm})`}
          />
          <source
            srcSet={window.CDN_URL + images.xsUrl}
            media={`(min-width: 0px)`}
          />
          <img alt={""} src={window.CDN_URL + images.lgUrl} />
        </picture>
      )}
    </PictureWrapper>
  );
};

ImageOnlyAdTeaser.propTypes = {
  onClick: PropTypes.func,
  content: PropTypes.shape({
    headlineText: PropTypes.string,
    images: PropTypes.shape({
      lgUrl: PropTypes.string,
      mdUrl: PropTypes.string,
      smUrl: PropTypes.string,
      xlUrl: PropTypes.string,
      xsUrl: PropTypes.string,
    }),
    linkUrl: PropTypes.string,
    type: PropTypes.string,
  }),
  qaName: PropTypes.string,
};

ImageOnlyAdTeaser.defaultProps = {
  onClick: noop,
  content: {},
  qaName: undefined,
};

export default ImageOnlyAdTeaser;
