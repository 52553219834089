import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
import { provideIsLoadingProp, } from "@/utils/context/LoadingContext";
const StyledInput = styled.input.attrs(provideIsLoadingProp) `
  height: 1.5rem;
  width: 1.5rem;
  appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
  margin: 0;

  border-radius: 50%;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.grey[7]};
  border: 0.3125rem solid ${({ theme }) => theme.colors.white};
  flex-shrink: 0;

  &:checked {
    background: ${({ theme }) => theme.colors.primary[0]};
  }
  &:focus {
    outline-color: ${({ theme }) => theme.colors.primary[0]};
  }

  ${({ isLoading, theme }) => isLoading &&
    `
  &:checked {
    background: ${theme.colors.grey[7]};
  }
  &:focus {
    outline-color: ${theme.colors.grey[7]};
  }
  `};
`;
export const RadioInput = ({ checked, disabled, defaultChecked, id, name, onChange, qaName, }) => (_jsx(StyledInput, { id: id, "aria-describedby": `${id}-description`, name: name, type: "radio", checked: checked, onChange: onChange, disabled: disabled, defaultChecked: defaultChecked, "data-qa": qaName }));
