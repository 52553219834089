import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Flex, Box } from "@mm/ui";

import PriceComp from "../../atoms/Price";
import LegalNotes from "../../molecules/LegalNotes";

const Container = styled.div`
  display: ${({ inline }) => (inline ? "inline-flex" : "flex")};
  align-items: ${({ direction }) =>
    direction !== "row" ? "flex-start" : "flex-end"};
  flex-direction: ${({ direction }) => direction || "column"};
  min-height: 132px;
  ${(props) => {
    switch (props.className) {
      case "quarterWidth":
        return `
                    display: block;
                    width: 58%;
                `;
      default:
        return null;
    }
  }}
`;

const StyledSpan = styled.span`
  color: ${(props) =>
    props.stageStyle ? props.theme.colors.white : "inherit"};
  display: block;
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
  margin-left: ${({ stageStyle }) => (stageStyle ? "auto" : 0)};
  text-align: ${(props) => (props.stageStyle ? "right" : "left")};
`;

const Pretext = styled(StyledSpan)`
  ${({ direction }) =>
    direction !== "row" ? "margin-bottom: 0.5em" : "margin-right: 0.5em"};
  margin-top: 10px;
`;

const Price = styled(PriceComp)({
  marginRight: ({ theme: { space } }) => `${space.xs}px`,
});

const SupLegalNotes = styled(LegalNotes)`
  svg {
    position: relative;
    top: -1px;
    left: 2px;
  }
`;

// this component is copied because i don't want to destroy the production PriceDisplay
function PriceDisplayAffOffer({
  AffOffer,
  className,
  direction,
  inline,
  oldPrice,
  oneTimePrice,
  price,
  priceNotice,
  stageStyle,
}) {
  return (
    <Container className={className} direction={direction} inline={inline}>
      <Pretext stageStyle={stageStyle} direction={direction}>
        {oneTimePrice ? "Einmalig" : "Monatlich"}
      </Pretext>
      <Flex>
        <Price
          size="m"
          price={parseFloat(price)}
          variant={stageStyle ? "secondary" : "primary"}
          forceCentDisplay
        />
        <LegalNotes legalNotes=" " AffOffer={AffOffer} />
      </Flex>
      <Box mt={2}>
        <Flex mt={1}>
          <StyledSpan>
            {oldPrice && (
              <>
                statt {oldPrice}€ {oneTimePrice ? "Einmalig" : "Monatlich"}
                <SupLegalNotes AffOffer={AffOffer} />
              </>
            )}
          </StyledSpan>
        </Flex>
      </Box>
      <Pretext>{priceNotice}</Pretext>
    </Container>
  );
}

PriceDisplayAffOffer.propTypes = {
  className: PropTypes.string,
  direction: PropTypes.oneOf(["row", "column"]),
  inline: PropTypes.bool,
  oldPrice: PropTypes.string,
  oneTimePrice: PropTypes.bool,
  priceNotice: PropTypes.string,
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  stageStyle: PropTypes.bool,
};

PriceDisplayAffOffer.defaultProps = {
  className: "",
  direction: "row",
  inline: false,
  oldPrice: "",
  oneTimePrice: false,
  priceNotice: "",
  stageStyle: false,
};

export default PriceDisplayAffOffer;
