import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  space,
  color,
  layout,
  typography,
  position,
  flexbox,
} from "styled-system";

const Box = styled.div(
  {
    boxSizing: "border-box",
    margin: 0,
    minWidth: 0,
  },
  position,
  space,
  color,
  layout,
  typography,
  flexbox
);

const BoxComponent = forwardRef(
  (
    // ...rest contains all (styling) props we can pass from outside, like textAlign="center"
    { children, ...rest },
    ref
  ) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Box ref={ref} {...rest}>
      {children}
    </Box>
  )
);

BoxComponent.propTypes = {
  className: PropTypes.string,
};

BoxComponent.defaultProps = {
  className: null,
};

BoxComponent.displayName = "BoxComponent";

export default BoxComponent;
