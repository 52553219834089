import React from "react";
import PropTypes from "prop-types";
import { Text } from "@mm/ui";

import { Price, StatusDotText } from "components/atoms";
import {
  accessoryPropTypes,
  textVariantsPropTypes,
  deliveryPricePropType,
  deliveryTimePropType,
} from "sharedPropTypes";

const ShippingHint = ({
  activeAccessoryVariantIds,
  deliveryInfo,
  priceShippingInfo,
  accessories,
  textVariant,
}) => {
  // hardware related prices
  const { deliveryPrice, deliveryTime: hWDeliveryTime } = deliveryInfo;

  if (!hWDeliveryTime) {
    return null;
  }

  const getAccessoryDeliveryTime = () => {
    if (!accessories) {
      return null;
    }
    const filteredItem = accessories?.map((e, index) => {
      const { ebootisId } = activeAccessoryVariantIds[index];
      if (!ebootisId) {
        return null;
      }
      return e.extendedVariants.filter(
        (item) => item.variant.ebootisId === ebootisId
      )[0];
    })[0];
    return filteredItem?.variant.deliveryTime;
  };

  const deliveryTime = () => {
    if (!getAccessoryDeliveryTime()) {
      return hWDeliveryTime;
    }
    if (getAccessoryDeliveryTime().days > hWDeliveryTime.days) {
      return getAccessoryDeliveryTime();
    }
    return hWDeliveryTime;
  };

  const { color, customText } = deliveryTime();

  const priceShipping = Math.min(
    ...Object.values(deliveryPrice).filter(
      // only consider numeric values
      (price) => typeof price === "number"
    )
  );

  return (
    <>
      <div data-codeception="deliveryTime">
        <Text variant={textVariant}>
          <StatusDotText color={color} align="right">
            {customText}
          </StatusDotText>
        </Text>
      </div>
      <Text variant={textVariant}>
        {priceShipping === 0 ? "zzgl. " : "zzgl. ab "}
        <span data-codeception="deliveryPrice">
          <Price price={priceShipping} preventStyling />
        </span>{" "}
        Versand
        {priceShippingInfo}
      </Text>
    </>
  );
};

ShippingHint.propTypes = {
  activeAccessoryVariantIds: PropTypes.shape({
    0: PropTypes.shape({
      hardwareId: PropTypes.string,
      ebootisId: PropTypes.string,
    }),
  }),
  deliveryInfo: PropTypes.shape({
    deliveryPrice: deliveryPricePropType,
    deliveryTime: deliveryTimePropType,
  }).isRequired,
  priceShippingInfo: PropTypes.node,
  accessories: PropTypes.arrayOf(accessoryPropTypes),
  textVariant: textVariantsPropTypes,
};

ShippingHint.defaultProps = {
  activeAccessoryVariantIds: { 0: {} },
  priceShippingInfo: null,
  accessories: null,
  textVariant: "copy",
};

export default ShippingHint;
