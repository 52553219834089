import React from "react";

import GoogleLogo from "@core/svgs/manufacturers/Logo-Google.svg";
import HonorLogo from "@core/svgs/manufacturers/Logo-Honor.svg";
import MotorolaLogo from "@core/svgs/manufacturers/Logo-Motorola.svg";
import TelekomLogo from "@core/svgs/manufacturers/Logo-Telekom.svg";
import XiaomiLogo from "@core/svgs/manufacturers/Logo-Xiaomi.svg";
import VivoLogo from "@core/svgs/manufacturers/Logo-Vivo.svg";
import AppleLogo from "@core/svgs/manufacturers/Logo-Apple.svg";
import HuaweiLogo from "@core/svgs/manufacturers/Logo-Huawei.svg";
import LgLogo from "@core/svgs/manufacturers/Logo-LG.svg";
import O2Logo from "@core/svgs/manufacturers/Logo-o2.svg";
import OppoLogo from "@core/svgs/manufacturers/Logo-Oppo.svg";
import SamsungLogo from "@core/svgs/manufacturers/Logo-Samsung.svg";
import VodafoneLogo from "@core/svgs/manufacturers/Logo-Vodafone.svg";
import SonyLogo from "@core/svgs/manufacturers/Logo-Sony.svg";
import OnePlusLogo from "@core/svgs/manufacturers/Logo-OnePlus.svg";
import RealmeLogo from "@core/svgs/manufacturers/Logo-Realme.svg";
import MdTelekomLogo from "@core/svgs/manufacturers/Logo-md-Telekom.svg";
import MdVodafoneLogo from "@core/svgs/manufacturers/Logo-md-Vodafone.svg";
import SoflowLogo from "@core/svgs/manufacturers/Logo-Soflow.svg";
import BabylissLogo from "@core/svgs/manufacturers/Logo-Babyliss.svg";
import BoseLogo from "@core/svgs/manufacturers/Logo-Bose.svg";
import FitbitLogo from "@core/svgs/manufacturers/Logo-Fitbit.svg";
import RyzeLogo from "@core/svgs/manufacturers/Logo-Ryze.svg";
import McAfee from "@core/svgs/manufacturers/Logo-Mcafee.svg";
import Nintendo from "@core/svgs/manufacturers/Logo-Nintendo.svg";
import Enders from "@core/svgs/manufacturers/Logo-Enders.svg";
import Realpower from "@core/svgs/manufacturers/Logo-Realpower.svg";
import Rollei from "@core/svgs/manufacturers/Logo-Rollei.svg";
import CornTechnology from "@core/svgs/manufacturers/Logo-Corn-Technology.svg";
import Pioneer from "@core/svgs/manufacturers/Logo-Pioneer.svg";
import Xplora from "@core/svgs/manufacturers/Logo-Xplora.svg";
import Rephone from "@core/svgs/manufacturers/Logo-Rephone.svg";
import Nothing from "@core/svgs/manufacturers/Logo-Nothing.svg";
import Kfa2 from "@core/svgs/manufacturers/Logo-KFA2.svg";
import Avm from "@core/svgs/manufacturers/Logo-AVM.svg";
import Renewd from "@core/svgs/manufacturers/Logo-Renewd.svg";
import MicrosoftLogo from "@core/svgs/manufacturers/Logo-Microsoft.svg";
import SuperSelect from "@core/svgs/manufacturers/Logo-SuperSelect.svg";
import Freenet from "@core/svgs/manufacturers/Logo-Freenet.svg";
import Blackberry from "@core/svgs/manufacturers/Logo-BlackBerry.svg";
import AcerLogo from "@core/svgs/manufacturers/Logo-Acer.svg";
import HtcLogo from "@core/svgs/manufacturers/Logo-HTC.svg";
import LenovoLogo from "@core/svgs/manufacturers/Logo-Lenovo.svg";
import LetsGoMobile from "@core/svgs/manufacturers/Logo-letsGoMobile.svg";
import PeaqLogo from "@core/svgs/manufacturers/Logo-Peaq.svg";
import ZteLogo from "@core/svgs/manufacturers/Logo-ZTE.svg";

const manufacturers = {
  acer: {
    logo: <AcerLogo />,
    link: "/smartphones/",
    name: "Acer",
    cdn: "https://storage.googleapis.com/content-ekontor24/mediamarkt/svgs/manufacturers/Logo-AVM.svg",
  },
  apple: {
    logo: <AppleLogo />,
    link: "/smartphones/apple/",
    name: "Apple",
    cdn: "https://storage.googleapis.com/content-ekontor24/mediamarkt/svgs/manufacturers/Logo-Apple.svg",
  },
  google: {
    logo: <GoogleLogo />,
    link: "/google-neuheiten/",
    name: "Google",
    cdn: "https://storage.googleapis.com/content-ekontor24/mediamarkt/svgs/manufacturers/Logo-Google.svg",
  },
  honor: {
    logo: <HonorLogo />,
    link: "/smartphones/honor/",
    name: "Honor",
    cdn: "https://storage.googleapis.com/content-ekontor24/mediamarkt/svgs/manufacturers/Logo-Honor.svg",
  },
  htc: {
    logo: <HtcLogo />,
    link: "/smartphones/",
    name: "HTC",
    cdn: "https://storage.googleapis.com/content-ekontor24/mediamarkt/svgs/manufacturers/Logo-HTC.svg",
  },
  huawei: {
    logo: <HuaweiLogo />,
    link: "/smartphones/huawei/",
    name: "Huawei",
    cdn: "https://storage.googleapis.com/content-ekontor24/mediamarkt/svgs/manufacturers/Logo-Huawei.svg",
  },
  lg: {
    logo: <LgLogo />,
    link: "/smartphones/lg/",
    name: "LG",
    cdn: "https://storage.googleapis.com/content-ekontor24/mediamarkt/svgs/manufacturers/Logo-LG.svg",
  },
  lenovo: {
    logo: <LenovoLogo />,
    link: "/smartphones/",
    name: "Lenovo",
    cdn: "https://storage.googleapis.com/content-ekontor24/mediamarkt/svgs/manufacturers/Logo-Lenovo.svg",
  },
  motorola: {
    logo: <MotorolaLogo />,
    link: "/smartphones/motorola/",
    name: "Motorola",
    cdn: "https://storage.googleapis.com/content-ekontor24/mediamarkt/svgs/manufacturers/Logo-Motorola.svg",
  },
  o2: {
    logo: <O2Logo />,
    link: "/tarife/o2/",
    name: "o2",
    cdn: "https://storage.googleapis.com/content-ekontor24/mediamarkt/svgs/manufacturers/Logo-o2.svg",
  },
  oppo: {
    logo: <OppoLogo />,
    link: "/smartphones/oppo/",
    name: "Oppo",
    cdn: "https://storage.googleapis.com/content-ekontor24/mediamarkt/svgs/manufacturers/Logo-AVM.svg",
  },
  peaq: {
    logo: <PeaqLogo />,
    link: "/smartphones/",
    name: "Peag",
    cdn: "https://storage.googleapis.com/content-ekontor24/mediamarkt/svgs/manufacturers/Logo-Oppo.svg",
  },
  samsung: {
    logo: <SamsungLogo />,
    link: "/smartphones/samsung/",
    name: "Samsung",
    cdn: "https://storage.googleapis.com/content-ekontor24/mediamarkt/svgs/manufacturers/Logo-Samsung.svg",
  },
  telekom: {
    logo: <TelekomLogo />,
    link: "/tarife/telekom/",
    name: "Telekom",
    cdn: "https://storage.googleapis.com/content-ekontor24/mediamarkt/svgs/manufacturers/Logo-Telekom.svg",
  },
  vodafone: {
    logo: <VodafoneLogo />,
    link: "/tarife/vodafone/",
    name: "Vodafone",
    cdn: "https://storage.googleapis.com/content-ekontor24/mediamarkt/svgs/manufacturers/Logo-Vodafone.svg",
  },
  xiaomi: {
    logo: <XiaomiLogo />,
    link: "/smartphones/xiaomi/",
    name: "Xiaomi",
    cdn: "https://storage.googleapis.com/content-ekontor24/mediamarkt/svgs/manufacturers/Logo-Xiaomi.svg",
  },
  xiaomilp: {
    logo: <XiaomiLogo />,
    link: "/xiaomi-angebote/",
    name: "Xiaomi",
    cdn: "https://storage.googleapis.com/content-ekontor24/mediamarkt/svgs/manufacturers/Logo-Xiaomi.svg",
  },
  vivo: {
    logo: <VivoLogo />,
    link: "/smartphones/vivo/",
    name: "Vivo",
    cdn: "https://storage.googleapis.com/content-ekontor24/mediamarkt/svgs/manufacturers/Logo-Vivo.svg",
  },
  sony: {
    logo: <SonyLogo />,
    link: "/smartphones/sony/",
    name: "Sony",
    cdn: "https://storage.googleapis.com/content-ekontor24/mediamarkt/svgs/manufacturers/Logo-Sony.svg",
  },
  oneplus: {
    logo: <OnePlusLogo />,
    link: "/smartphones/oneplus/",
    name: "OnePlus",
    cdn: "https://storage.googleapis.com/content-ekontor24/mediamarkt/svgs/manufacturers/Logo-OnePlus.svg",
  },
  realme: {
    logo: <RealmeLogo />,
    link: "/smartphones/realme/",
    name: "Realme",
    cdn: "https://storage.googleapis.com/content-ekontor24/mediamarkt/svgs/manufacturers/Logo-Realme.svg",
  },
  mdtelekom: {
    logo: <MdTelekomLogo />,
    link: "/tarife/telekom/",
    name: "Freenet Telekom",
    cdn: "https://storage.googleapis.com/content-ekontor24/mediamarkt/svgs/manufacturers/Logo-md-Telekom.svg",
  },
  mdvodafone: {
    logo: <MdVodafoneLogo />,
    link: "/tarife/vodafone/",
    name: "Freenet Vodafone",
    cdn: "https://storage.googleapis.com/content-ekontor24/mediamarkt/svgs/manufacturers/Logo-md-Vodafone.svg",
  },
  soflow: {
    logo: <SoflowLogo />,
    link: "/smartphones/soflow/",
    name: "Soflow",
    cdn: "https://storage.googleapis.com/content-ekontor24/mediamarkt/svgs/manufacturers/Logo-Softlow.svg",
  },
  babyliss: {
    logo: <BabylissLogo />,
    link: "smartphones/",
    name: "Babyliss",
    cdn: "https://storage.googleapis.com/content-ekontor24/mediamarkt/svgs/manufacturers/Logo-Babyliss.svg",
  },
  bose: {
    logo: <BoseLogo />,
    link: "smartphones/",
    name: "Bose",
    cdn: "https://storage.googleapis.com/content-ekontor24/mediamarkt/svgs/manufacturers/Logo-Bose.svg",
  },
  fitbit: {
    logo: <FitbitLogo />,
    link: "smartphones/",
    name: "Fitbit",
    cdn: "https://storage.googleapis.com/content-ekontor24/mediamarkt/svgs/manufacturers/Logo-Fitbit.svg",
  },
  ryze: {
    logo: <RyzeLogo />,
    link: "smartphones/",
    name: "Ryze",
    cdn: "https://storage.googleapis.com/content-ekontor24/mediamarkt/svgs/manufacturers/Logo-Ryze.svg",
  },
  mcafee: {
    logo: <McAfee />,
    link: "smartphones/",
    name: "McAfee",
    cdn: "https://storage.googleapis.com/content-ekontor24/mediamarkt/svgs/manufacturers/Logo-Macafee.svg",
  },
  nintendo: {
    logo: <Nintendo />,
    link: "smartphones/",
    name: "Nintendo",
    cdn: "https://storage.googleapis.com/content-ekontor24/mediamarkt/svgs/manufacturers/Logo-Nintendo.svg",
  },
  enders: {
    logo: <Enders />,
    link: "smartphones/",
    name: "Enders",
    cdn: "https://storage.googleapis.com/content-ekontor24/mediamarkt/svgs/manufacturers/Logo-Enders.svg",
  },
  realpower: {
    logo: <Realpower />,
    link: "smartphones/",
    name: "Realpower",
    cdn: "https://storage.googleapis.com/content-ekontor24/mediamarkt/svgs/manufacturers/Logo-Realpower.svg",
  },
  rollei: {
    logo: <Rollei />,
    link: "smartphones/",
    name: "Rollei",
    cdn: "https://storage.googleapis.com/content-ekontor24/mediamarkt/svgs/manufacturers/Logo-Rollei.svg",
  },
  corntechnology: {
    logo: <CornTechnology />,
    link: "smartphones/",
    name: "CORN Technology",
    cdn: "https://storage.googleapis.com/content-ekontor24/mediamarkt/svgs/manufacturers/Logo-Corn-Technology.svg",
  },
  pioneer: {
    logo: <Pioneer />,
    link: "smartphones/",
    name: "Pioneer",
    cdn: "https://storage.googleapis.com/content-ekontor24/mediamarkt/svgs/manufacturers/Logo-Pioneer.svg",
  },
  xplora: {
    logo: <Xplora />,
    link: "smartphones/",
    name: "Xplora",
    cdn: "https://storage.googleapis.com/content-ekontor24/mediamarkt/svgs/manufacturers/Logo-Xplora.svg",
  },
  rephone: {
    logo: <Rephone />,
    link: "smartphones/",
    name: "Rephone",
    cdn: "https://storage.googleapis.com/content-ekontor24/mediamarkt/svgs/manufacturers/Logo-Rephone.svg",
  },
  nothing: {
    logo: <Nothing />,
    link: "smartphones/",
    name: "Nothing",
    cdn: "https://storage.googleapis.com/content-ekontor24/mediamarkt/svgs/manufacturers/Logo-Nothing.svg",
  },
  kfa2: {
    logo: <Kfa2 />,
    link: "smartphones/",
    name: "Kfa2",
    cdn: "https://storage.googleapis.com/content-ekontor24/mediamarkt/svgs/manufacturers/Logo-KFA2.svg",
  },
  avm: {
    logo: <Avm />,
    link: "smartphones/",
    name: "AVM",
    cdn: "https://storage.googleapis.com/content-ekontor24/mediamarkt/svgs/manufacturers/Logo-AVM.svg",
  },
  renewd: {
    logo: <Renewd />,
    link: "smartphones/",
    name: "Renewd",
    cdn: "https://storage.googleapis.com/content-ekontor24/mediamarkt/svgs/manufacturers/Logo-Renewd.svg",
  },
  o2lp: {
    logo: <O2Logo />,
    link: "/o2-tarife/",
    name: "o2",
    cdn: "https://storage.googleapis.com/content-ekontor24/mediamarkt/svgs/manufacturers/Logo-o2.svg",
  },
  microsoft: {
    logo: <MicrosoftLogo />,
    link: "smartphones/",
    name: "Microsoft",
    cdn: "https://storage.googleapis.com/content-ekontor24/mediamarkt/svgs/manufacturers/Logo-Microsoft.svg",
  },
  superselect: {
    logo: <SuperSelect />,
    link: "/superselect/",
    name: "Superselect",
    cdn: "https://storage.googleapis.com/content-ekontor24/mediamarkt/svgs/manufacturers/Logo-SuperSelect.svg",
  },
  letsGoMobile: {
    logo: <LetsGoMobile />,
    link: "/letsgo-mobile/",
    name: "LetsGo Mobile",
    cdn: "https://storage.googleapis.com/content-ekontor24/mediamarkt/svgs/manufacturers/Logo-letsGoMobile.svg",
  },
  freenet: {
    logo: <Freenet />,
    link: "/freenet-tarife/",
    name: "Freenet",
    cdn: "https://storage.googleapis.com/content-ekontor24/mediamarkt/svgs/manufacturers/Logo-Freenet.svg",
  },
  blackberry: {
    logo: <Blackberry />,
    link: "/smartphones/",
    name: "Blackberry",
    cdn: "https://storage.googleapis.com/content-ekontor24/mediamarkt/svgs/manufacturers/Logo-BlackBerry.svg",
  },
  zte: {
    logo: <ZteLogo />,
    link: "/smartphones/",
    name: "ZTE",
    cdn: "https://storage.googleapis.com/content-ekontor24/mediamarkt/svgs/manufacturers/Logo-ZTE.svg",
  },
};

export default manufacturers;
