import React from "react";

import { DetailsAccordion } from "components/atoms";

const HardwareInfo = ({ hardware }) => {
  return (
    <>
      <DetailsAccordion details={hardware?.details} />
    </>
  );
};

export default HardwareInfo;
