import styled from "styled-components";
import { variant } from "styled-system";

export default styled("div")`
  position: relative;
  border-radius: 4px;
  padding: 0 16px 16px;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.grey[2]};
  box-shadow: ${({ hasShadow, theme: { shadows } }) =>
    hasShadow ? shadows.elevation4 : "none"};

  transition-duration: 0.15s;
  transition-timing-function: ease-in-out;
  transition-property: border-color, box-shadow, transform;

  &:active,
  &:focus,
  &:hover {
    border-color: rgba(0, 0, 0, 0);
    box-shadow: rgb(0 0 0 / 7%) 0px 8px 10px 1px,
      rgb(0 0 0 / 6%) 0px 3px 14px 2px, rgb(0 0 0 / 10%) 0px 5px 5px -3px;
  }

  ${variant({
    variants: {
      tile: {
        display: "flex !important",
        flexDirection: "column",
      },
      row: {
        display: "flex !important",
        alignItems: "flex-end",
      },
    },
  })}
`;
