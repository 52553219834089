import React from "react";
import PropTypes from "prop-types";

import { OfferProvider } from "features/Offer";

import PhoneHalfWidth from "./PhoneHalfWidth";

const OfferTeaserConfiguratorHardware = ({ data, voucherCode }) => {
  const {
    hardware: { extendedVariants },
  } = data;

  return (
    <OfferProvider rootInfoData={data} extendedVariantsData={extendedVariants}>
      <PhoneHalfWidth voucherCode={voucherCode} />
    </OfferProvider>
  );
};

OfferTeaserConfiguratorHardware.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]),
  voucherCode: PropTypes.string,
};

OfferTeaserConfiguratorHardware.defaultProps = {
  data: {},
  voucherCode: "",
};

export default OfferTeaserConfiguratorHardware;
