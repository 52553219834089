import React from "react";

import PropTypes from "prop-types";
import styled from "styled-components";

import { Overlay } from "components/atoms";

const Content = styled.span`
  padding: ${({ theme }) => `${theme.space.s}px ${theme.space.xxl}px`};
  background-color: ${({ theme }) => theme.colors.primary[0]};
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.typography.size.m};
  font-weight: ${({ theme }) => theme.typography.weight.highlighted};
`;

const SoldOverlay = ({ children }) => (
  <Overlay content={<Content>Ausverkauft</Content>}>{children}</Overlay>
);

SoldOverlay.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SoldOverlay;
