import React from "react";
import PropTypes from "prop-types";

import dataLayer, { sanitizeUrl } from "helper/dataLayer";

import "./styles.scss";

const CategoryBar = ({ data }) => (
  <ul className="msd-category-bar-wrapper">
    {Array.isArray(data) &&
      data.map(({ title, text, link, imagePath }) => (
        <li key={title}>
          <a
            href={link}
            className="msd-category-bar-link msd-hover--box-shadow"
            onClick={(url) =>
              dataLayer({
                eventCategory: "Kategorieteaser",
                eventAction: "click",
                eventLabel: `${title}:${sanitizeUrl(url)}`,
              })
            }
          >
            <img
              src={window.CDN_URL + imagePath}
              alt=""
              className="msd-category-bar-image"
            />
            {title && <span className="msd-category-bar-title">{title}</span>}
            {text && <span className="msd-category-bar-text">{text}</span>}
          </a>
        </li>
      ))}
  </ul>
);

CategoryBar.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      link: PropTypes.string,
      text: PropTypes.string,
      imagePath: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default CategoryBar;
