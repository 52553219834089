import React from "react";
import PropTypes from "prop-types";
import SlickCarousel from "react-slick";

import { Surface } from "@mm/ui";

import { ThemeProvider } from "@core/theme";

import { ThreeStageTeaser } from "components/organisms";

import { OfferProvider } from "features/Offer";

import { StyledTeaserCarousel, carouselSettings } from "./SlickCarouselSetting";

const ThreeStageTeaserSection = ({ data }) => {
  if (data.length === 0) {
    return (
      <ThemeProvider>
        <Surface variant="narrow">
          <div>Es können keine Angebote angezeigt werden</div>
        </Surface>
      </ThemeProvider>
    );
  }
  return (
    <ThemeProvider>
      <Surface variant="narrow">
        <StyledTeaserCarousel>
          <SlickCarousel {...carouselSettings}>
            {data.map((slide) => {
              return (
                <OfferProvider
                  rootInfoData={slide}
                  extendedVariantsData={slide.hardware?.extendedVariants}
                  key={slide.hardware?.id || slide.tariff?.id}
                >
                  <ThreeStageTeaser {...slide} key={slide.hardware?.id} />
                </OfferProvider>
              );
            })}
          </SlickCarousel>
        </StyledTeaserCarousel>
      </Surface>
    </ThemeProvider>
  );
};

ThreeStageTeaserSection.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.any.isRequired,
};

export default ThreeStageTeaserSection;
