import React from "react";
import PropTypes from "prop-types";

import { useOfferConfigurator } from "features/Offer";

import PriceDisplay from ".";

const HARDWARE_LEGAL_NOTES =
  "Einmaliger Preis der Hardware inklusive der derzeit gültigen Mehrwertsteuer. Das Angebot gilt nur bei Abschluss eines Mobilfunkvertrages mit einer Laufzeit von 24 Monaten, durch den weitere Kosten entstehen können. Einzelheiten entnehmen Sie bitte den Tarifdetails des jeweiligen Tarifs. Alle Angebote unverbindlich und nur solange Vorrat reicht.";

const HardwarePriceDisplay = ({ price, direction, size, onetimePrice }) => {
  const {
    state,
    state: { isListingPage },
  } = useOfferConfigurator();
  const tariffId = state?.activeTariff?._id || undefined;
  const showLegalNotes = isListingPage;
  return (
    <PriceDisplay
      tariffId={tariffId}
      pretext={onetimePrice ? "Einmaliger Gerätepreis" : "ab"}
      price={price || onetimePrice}
      size={size}
      direction={direction || onetimePrice ? "column" : "row"}
      legalNotes={showLegalNotes ? HARDWARE_LEGAL_NOTES : ""}
    />
  );
};

HardwarePriceDisplay.propTypes = {
  direction: PropTypes.oneOf(["row", "column"]),
  size: PropTypes.oneOf(["s", "m", "l"]).isRequired,
  price: PropTypes.number.isRequired,
  onetimePrice: PropTypes.number,
};

HardwarePriceDisplay.defaultProps = {
  onetimePrice: null,
  direction: "row",
};

export default HardwarePriceDisplay;
