import { extendURLParams } from "@/utils/fetchData";
/**
 * Signals to BE service that the current product configuration is finished and
 * to initialize a checkout with the given selection.
 */
export const addToCart = async (payload) => {
    const { tenant, environment, restUrl } = extendURLParams();
    return fetch(`${restUrl}/v1/addToCart?tenant=${tenant}&environment=${environment}`, {
        method: "post",
        credentials: "include",
        body: JSON.stringify({
            ...payload,
            force: true,
        }),
    })
        .then((res) => {
        return res.json();
    })
        .catch((error) => new Error(error));
};
