import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { Surface, Headline } from "@mm/ui";
import { ThemeProvider } from "@core/theme";
import { InternetOfferTeaserRegards, ImageOnlyTeaser, } from "@/components/organisms";
import { useInternetProvider } from "@/features/Internet";
import { Spinner } from "@/components/atoms";
const SpinnerWrapper = styled.div `
  display: flex;
  justify-content: center;
`;
const blackDealsImages = {
    images: {
        xlUrl: "/img/internet/blackdeals/headline/1104x236.png",
        lgUrl: "/img/internet/blackdeals/headline/1104x196.png",
        mdUrl: "/img/internet/blackdeals/headline/946x254.png",
        smUrl: "/img/internet/blackdeals/headline/700x251.png",
        xsUrl: "/img/internet/blackdeals/headline/478x249.png",
    },
};
const InternetTeaser = ({ layout }) => {
    const { state: { availabilityChecked, isLoadingOffers, promotionOffers, showRegards, }, } = useInternetProvider();
    if (showRegards && isLoadingOffers) {
        return (_jsx(Surface, { variant: "narrow", mt: "44px", children: _jsx(SpinnerWrapper, { children: _jsx(Spinner, { color: "ghost", size: "4rem" }) }) }));
    }
    if (promotionOffers.length < 1) {
        return null;
    }
    return (_jsx(ThemeProvider, { children: _jsxs(Surface, { variant: "narrow", children: [layout == "blackDeals" ? (_jsx(ImageOnlyTeaser, { content: blackDealsImages })) : (_jsxs(Headline, { variant: "h4", children: ["Unsere Empfehlung", availabilityChecked ? " für deine Adresse" : ""] })), _jsx(InternetOfferTeaserRegards, { availabilityChecked: availabilityChecked, data: promotionOffers })] }) }));
};
export default InternetTeaser;
