import { reduce } from "lodash";

import { getActiveAccessoryVariantStock } from "./variantUtils";

export const getStock = (
  rootInfo,
  extendedVariants,
  _, // just placeholder to have same function signature as in hardwareUtils
  activeAccessoryVariantIds
) => {
  if (!rootInfo.hasBookableHardware) {
    return rootInfo.stock;
  }
  return getActiveAccessoryVariantStock(
    [
      {
        extendedVariants,
      },
    ],
    activeAccessoryVariantIds
  );
};

/**
 * Do calculations for tariff details
 * @param {any[]} pricingIntervals
 * @param {number} commission
 * @param {number} contractTerm
 * @param {number|double} monthlyPrice
 */
const getTariffCalculations = (
  pricingIntervals,
  commission,
  contractTerm,
  monthlyPrice
) => {
  const totalAmount =
    pricingIntervals &&
    pricingIntervals.intervals &&
    pricingIntervals.intervals.length > 1
      ? pricingIntervals.intervals.reduce(
          (currentAmount, { startMonth, endMonth, price }) => {
            if (startMonth <= contractTerm) {
              // if endMonth == 0 we take contractTerm
              // In this case, the product management did not set the interval explicitely
              // so we take the full duration of the contract.
              const amountOfMonths =
                (endMonth < contractTerm && endMonth > 0
                  ? endMonth
                  : contractTerm) -
                (startMonth - 1);
              const intervalPrice = amountOfMonths * price;
              return currentAmount + intervalPrice;
            }

            return currentAmount;
          },
          0
        )
      : monthlyPrice * contractTerm; // Format in monthyPrice is sometimes 39.99 and sometimes 3999;
  const totalPrice = totalAmount - commission;

  return {
    totalAmount,
    totalPrice,
    // FIXME add comment here why we need both (one with and one without comission)
    averageMonthlyPrice: totalAmount / contractTerm,
    averageCalculatedMonthlyPrice: totalPrice / contractTerm,
  };
};

/**
 * Some additional calculations for each tariff
 * TODO: return object with { tariff: ..., calculations: ... } not { ...tariff, calculations: ... }
 * @param {any} tariff
 */
export const extendTariffDetails = (tariff) => {
  if (!tariff) {
    return null;
  }

  const calculations = getTariffCalculations(
    tariff.pricingIntervals,
    tariff.commission,
    tariff.contractTerm,
    tariff.monthlyPrice
  );

  return {
    ...tariff,
    calculations,
  };
};

/**
 * Add calculations for this legacy tariff (e.g. add-to-cart)
 * @param {any} legacyTariff
 */
export const extendLegacyTariffDetails = (legacyTariff) => {
  if (!legacyTariff) {
    return null;
  }

  const calculations = getTariffCalculations(
    legacyTariff.pricingIntervals,
    legacyTariff.promotionBonus,
    legacyTariff.promotionPeriod,
    legacyTariff.monthlyPrice
  );

  return {
    ...legacyTariff,
    calculations,
  };
};

/**
 * @param {string} text
 *
 * @returns {string} formatted. E.g. "**LTE**" -> "<strong>LTE</strong>"
 * */

const strongRegex = /\*\*([a-zA-Z0-9äöü?!\s-.,/]*)\*\*/g;
export const formatTariffText = (text) =>
  text.replace(strongRegex, "<strong>$1</strong>");

/**
 *
 * @param {*} variants
 * @param {*} color
 * @returns
 */

export const filterVariantsByColor = (variants, color) =>
  reduce(
    variants,
    (result, item) => {
      const key = item.variant.ebootisId;
      if (
        typeof result[key] === "undefined" &&
        (color === null || item.variant.colorGroup.hex === color)
      ) {
        return {
          ...result,
          [key]: item,
        };
      }
      return result;
    },
    {}
  );

/**
 * @param {array of extendedVariants} variants
 * @param {string of HW107088-1} val
 * @returns
 */
export const findSelectedTariffInExtendedVariants = (variants, val) => {
  const selectedTariff = variants.filter(
    (item) => item.variant.ebootisId === val
  )[0];

  return selectedTariff;
};
