import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CheckoutSection, CheckoutSectionHeadline, CheckoutSectionText, StyledInputText as InputText, } from "../../components";
import styled from "styled-components";
import { useField } from "../../hooks";
import { useCheckout } from "../../hooks/api";
const InputWrapper = styled.div `
  width: 100%;
`;
export const PersonalCustomerCodeSection = ({ schema }) => {
    const { errors, fieldState, setFieldState } = useField("personalCustomerCode", schema, "");
    const { isFetching, isLoading } = useCheckout();
    return (_jsxs(CheckoutSection, { children: [_jsx(CheckoutSectionHeadline, { children: "Pers\u00F6nliche Kundenkennzahl" }), _jsx(CheckoutSectionText, { children: "Bitte gib eine beliebige 4-stellige Zahl an, mit der du dich zuk\u00FCnftig als Kunde von o2 (Telef\u00F3nica Germany GmbH & Co. OHG) identifizierst." }), _jsx(InputWrapper, { children: _jsx(InputText, { id: "personalCustomerCode", label: "Pers\u00F6nliche Kundenkennzahl*", value: fieldState, message: errors?.[0]?.message, layout: errors && "error", onChange: (value) => {
                        setFieldState(value);
                    }, disabled: isLoading || isFetching, autoComplete: "none" }) }), _jsx(CheckoutSectionText, { children: "Schreibe dir die Ziffernfolge bitte auf, denn diese wird sowohl im Online-Portal bei der Vertragsverwaltung, als auch beim pers\u00F6nlichen und telefonischen Kundenservice ben\u00F6tigt." })] }));
};
