import styled from "styled-components";

import FakeLink from "../FakeLink";

const Index = styled(FakeLink)`
  font-size: ${({ theme }) => theme.legacyFontSize[2]};
  color: ${({ theme }) => theme.colors.grey[4]};
`;

Index.propTypes = FakeLink.propTypes;

export default Index;
