import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { variant } from "styled-system";

import { Icon, Surface } from "@mm/ui";

import TariffBadge from "../../atoms/TariffBadge";

import { tariffPropTypes } from "sharedPropTypes";

import { MiniCoupon } from "../DetailsCoupon";

const StyledTariffBadge = styled(TariffBadge)({
  width: "80px",
  margin: "0 auto",
});

const CenteredIcon = styled(Surface)`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-30px, -18px);
  z-index: 1;
  width: auto;
  border-radius: 50%;
  padding: 0;
  box-shadow: ${({ theme }) => theme.shadows.elevation3};
`;

const StyledIcon = styled(Icon)`
  width: 36px;
  height: 36px;
  :after {
    font-size: ${({ theme }) => theme.typography.size.s}px;
  }
`;

export const CouponWrapper = styled("div")(
  variant({
    variants: {
      tile: {
        position: "relative",
        marginLeft: "12%",
        bottom: "auto",
        right: "auto",
      },
      row: {
        position: "absolute",
        marginLeft: "12%",
        bottom: "40px",
        right: "0",
        "@media (max-width: 752px)": {
          right: "calc(50% - 108px)",
        },
      },
    },
  })
);

const TarifCouponDisplay = ({
  tariff,
  isRowMode,
  layoutVariant,
  sim,
  coupon,
}) => (
  <>
    <StyledTariffBadge tariff={tariff} sim={sim} framed={!isRowMode} />
    {(tariff.coupon?.value > 0 || coupon?.value) && (
      <CouponWrapper isRowMode={isRowMode} variant={layoutVariant}>
        <CenteredIcon level="2">
          <StyledIcon variant="teaser" name="plus" />
        </CenteredIcon>
        <MiniCoupon noMargin price={tariff.coupon?.value || coupon?.value} />
      </CouponWrapper>
    )}
  </>
);

TarifCouponDisplay.defaultProps = {
  isRowMode: false,
  layoutVariant: "tile",
  sim: true,
};

TarifCouponDisplay.propTypes = {
  tariff: tariffPropTypes.isRequired,
  isRowMode: PropTypes.bool,
  layoutVariant: PropTypes.oneOf(["tile", "row"]),
  sim: PropTypes.bool,
};

export default TarifCouponDisplay;
