import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { flexbox } from "styled-system";

import Box from "../Box";

const Flex = styled(Box)(
  {
    display: "flex",
  },
  flexbox
);

// ...rest contains all (styling) props we can pass from outside, like justifyContent="center"
const FlexComponent = ({ children, ...rest }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Flex {...rest}>{children}</Flex>
);

FlexComponent.propTypes = {
  className: PropTypes.string,
};

FlexComponent.defaultProps = {
  className: null,
};

export default FlexComponent;
