import { useQuery } from "react-query";
import { extendURLParams } from "@/utils/fetchData";
import { useValidation } from "..";
export const useIban = (iban, options) => {
    const { tenant, environment, restUrl } = extendURLParams();
    const { addError, clearErrors } = useValidation();
    return useQuery(["useIban", tenant, environment], () => fetch(`${restUrl}/v1/checkout/validate/iban?tenant=${tenant}&environment=${environment}`, {
        method: "post",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            iban,
        }),
    }).then(async (res) => {
        const data = await res.json();
        if (!res.ok && data.error) {
            addError("iban", data.error);
            return {};
        }
        clearErrors("iban");
        return data;
    }), {
        ...options,
        cacheTime: 0,
        refetchOnWindowFocus: false,
        enabled: false,
        retry: 0,
    });
};
