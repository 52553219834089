import React from "react";
import PropTypes from "prop-types";

import { Table, Box } from "@mm/ui";

import { useOfferConfigurator } from "features/Offer";
import { Price } from "components/atoms";

const StyledPrice = ({ price }) => (
  <Price preventStyling preventMargin forceCentDisplay price={price} />
);

StyledPrice.propTypes = {
  price: PropTypes.number.isRequired,
};

const buildListTable = ({
  commission,
  pricingIntervals: { intervals },
  contractTerm,
  calculations: { totalPrice, averageCalculatedMonthlyPrice },
}) => {
  let totalAmount = 0;
  const tableItems = [];

  intervals.forEach(({ startMonth, endMonth, price }, idx) => {
    // Prepare data for monthly cost table
    const monthStr = [];

    if (idx === intervals.length - 1) {
      monthStr.push("ab", "dem", `${startMonth}.`, "Monat");
    } else {
      monthStr.push("Monat", startMonth);
      if (endMonth) {
        monthStr.push("bis", endMonth);
      }
    }

    tableItems.push([
      `Monatlicher Grundpreis (${monthStr.join(" ")})`,
      `${price}€`,
    ]);

    if (startMonth <= contractTerm) {
      const amountOfMonths = endMonth - (startMonth - 1);
      totalAmount += amountOfMonths * price;
    }
  });

  // Now add the last static entries
  tableItems.push(
    // don't format with PriceComponent (third argument set to true)
    ["Tariflaufzeit in Monaten", contractTerm, true],
    [
      "Gesamtkosten über die erste Tariflaufzeit",
      `${Number(totalAmount).toFixed(2)}€`,
    ],
    ["Aktionsguthaben", `${Number(commission).toFixed(2)}€`],
    [
      "Gesamtkosten abzgl. Aktionsguthaben über die erste Tariflaufzeit",
      `${Number(totalPrice).toFixed(2)}€`,
    ],
    [
      "Monatlicher Durchschnittspreis über die erste Tariflaufzeit",
      `${Number(averageCalculatedMonthlyPrice).toFixed(2)}€`,
    ]
  );

  return tableItems;
};

const buildDetailTable = ({
  commission: tariffCommission,
  pricingIntervals: { intervals },
  contractTerm,
}) => {
  const tableItems = [];
  let commission = tariffCommission;

  // Build a price map for easier iteration
  const monthPriceMap = [];

  intervals.forEach(({ startMonth, endMonth, price }) => {
    for (let i = startMonth; i <= (endMonth || contractTerm + 1); i += 1) {
      monthPriceMap.push(price);
    }
  });

  let iterationPrice = -1;
  let iterationStartMonth = -1;
  monthPriceMap
    .map((monthPrice) => {
      // Map values and set to zero if covered by "Aktionsguthaben"
      let priceForMonth = monthPrice;

      if (commission >= monthPrice) {
        priceForMonth = `${Number(0).toFixed(2)}€`;

        commission -= monthPrice;
      } else if (commission > 0) {
        priceForMonth = `${Number(monthPrice - commission).toFixed(2)}€`;

        commission = 0;
      }

      return priceForMonth;
    })
    .forEach((monthPrice, idx) => {
      if (idx < monthPriceMap.length - 2) {
        // Not last month and not the month before
        if (idx !== 0) {
          if (monthPrice !== iterationPrice) {
            tableItems.push([
              iterationStartMonth + 1 === idx
                ? `Monat ${iterationStartMonth + 1}`
                : `Monat ${iterationStartMonth + 1} bis Monat ${idx}`,
              iterationPrice,
            ]);

            iterationStartMonth = idx;
          }
        } else {
          iterationStartMonth = idx;
        }

        iterationPrice = monthPrice;
      } else if (idx === monthPriceMap.length - 2) {
        // the last regular month
        tableItems.push([
          `Monat ${iterationStartMonth + 1} bis Monat ${idx + 1}`,
          `${monthPrice}€`,
        ]);
      } else {
        // You know it - last month ;)
        tableItems.push([`ab Monat ${idx + 1}`, `${monthPrice}€`]);
      }
    });

  return tableItems;
};

const ExampleCalculations = () => {
  const {
    state: { activeTariff },
  } = useOfferConfigurator();

  if (!activeTariff) {
    return null;
  }

  return (
    <>
      <Table
        variant="price"
        rows={buildListTable(activeTariff).map((rowItem) => [
          rowItem[0],
          rowItem[1],
        ])}
      />
      <Box mt={2} mb={2}>
        Das Aktionsguthaben wird mit den ersten Monatsrechnungen voll
        angerechnet. Daraus ergeben sich die folgenden monatlichen
        Rechnungsbeträge:
      </Box>
      <Table variant="price" rows={buildDetailTable(activeTariff)} />
    </>
  );
};

ExampleCalculations.propTypes = {};
ExampleCalculations.defaultProps = {};

export default ExampleCalculations;
