import React from "react";
import PropTypes from "prop-types";

import { Text, Flex, Box } from "@mm/ui";

import { textVariantsPropTypes } from "sharedPropTypes";

const PriceRow = ({ text, textSubline, price, textVariant }) => (
  <Box>
    <Flex justifyContent="space-between" width="100%">
      <Text variant={textVariant}>
        <b>{text}</b>
      </Text>
      <Text variant={textVariant}>
        <b>{price}</b>
      </Text>
    </Flex>
    {!!textSubline && (
      <Box>
        <Text variant={textVariant}>{textSubline}</Text>
      </Box>
    )}
  </Box>
);

PriceRow.propTypes = {
  text: PropTypes.node.isRequired,
  textSubline: PropTypes.node,
  price: PropTypes.node.isRequired,
  textVariant: textVariantsPropTypes,
};

PriceRow.defaultProps = {
  textSubline: null,
  textVariant: "copy",
};

export default PriceRow;
