import { extendURLParams } from "@/utils/fetchData";
/**
 * Signals to BE service that the current checkout is complete and
 * to initialize an order /w the data of the current session
 */
export const submitOrder = async () => {
    const { tenant, environment, restUrl } = extendURLParams();
    return fetch(`${restUrl}/v1/checkout/order?tenant=${tenant}&environment=${environment}`, {
        method: "post",
        credentials: "include",
    })
        .then((res) => {
        return res.json();
    })
        .catch((error) => new Error(error));
};
