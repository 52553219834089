import { useViewport } from "@core/theme";

/**
 * Build image Url after viewport width
 *@param {object} images
 */
export const buildImageUrl = (images) => {
  let imageUrl = null;

  const viewport = useViewport();

  if (typeof images.smUrl !== "undefined") {
    imageUrl = images[`${viewport.mediaType}Url`];
  } else {
    // TODO refactor data to use new notation (xsUrl etc..)
    // eslint-disable-next-line no-lonely-if
    if (viewport.lessThan.sm) {
      // eslint-disable-next-line react/prop-types
      imageUrl = images.smallUrl;
    } else if (viewport.lessThan.xl) {
      // eslint-disable-next-line react/prop-types
      imageUrl = images.mediumUrl;
    } else {
      // eslint-disable-next-line react/prop-types
      imageUrl = images.largeUrl;
    }
  }

  return imageUrl;
};
