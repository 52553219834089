/* eslint-disable guard-for-in */
import React from "react";
import styled from "styled-components";
import { Box, Headline, Text, Surface, Col, Row } from "@mm/ui";

import { useViewport } from "@core/theme";

import dataLayer from "helper/dataLayer";

import {
  useOfferConfigurator,
  SET_ACTIVE_VARIANT,
  SET_ACTIVE_ACCESSORY_VARIANT,
  SET_ACTIVE_TARIFF,
} from "features/Offer";

import {
  extendTariffDetails,
  filterVariantsByColor,
  findSelectedTariffInExtendedVariants,
} from "utils/tariffUtils";

import {
  Eyecatchers,
  BorderBox,
  SmallInlineLink,
  GeneralModalShell,
  VariantsStorageDropdown,
} from "components/atoms";

import {
  SelectedTariff,
  HardwareInfo,
  DeliveryInfoModal,
  ProductAccessoryDisplay,
  VariantsColorDropdown,
  PriceDisplay,
  Loading,
} from "components/molecules";

import { SelectedAccessories } from "components/organisms";

import { PriceSection } from "sections";

import { ProductHeadline } from "../../HardwareDetailsSection/ProductHeadline";

const TileBox = styled(BorderBox)`
  background: ${({ theme }) => theme.colors.white};

  @media (max-width: ${({ theme }) => theme.breakpointsMap.md.max}px) {
    margin-top: ${({ theme }) => `${theme.space.xxxl}px`};
  }

  @media (max-width: ${({ theme }) => theme.breakpointsMap.lg.max}px) {
    margin-top: ${({ theme }) => `${theme.space.huge}px`};
  }

  @media (max-width: ${({ theme }) => theme.breakpointsMap.sm.max}px) {
    margin-top: ${({ theme }) => `${theme.space.m}px`};
  }
`;

const StyledSurface = styled(Surface)`
  position: relative;
  padding-bottom: ${({ theme }) => `${theme.space[7]}px`};

  &:after {
    content: "";
    height: 95px;
    background: ${({ theme }) => theme.colors.brand.primary};
    position: absolute;
    z-index: -1;
    bottom: 0;
    left: 0;
    right: 0;

    @media (max-width: ${({ theme }) => theme.breakpointsMap.md.max}px) {
      height: 75px;
      bottom: 50%;
    }

    @media (max-width: ${({ theme }) => theme.breakpointsMap.sm.max}px) {
      height: 100px;
      top: 360px;
      bottom: unset;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpointsMap.md.max}px) {
    margin-bottom: 0;
  }
`;

const StyledPrice = styled(Box)`
  position: absolute;
  left: 25%;
  bottom: 10px;
  z-index: 1;

  span {
    color: white;
  }

  @media (max-width: ${({ theme }) => theme.breakpointsMap.md.max}px) {
    left: 28%;
    bottom: 52%;
  }

  @media (max-width: ${({ theme }) => theme.breakpointsMap.sm.max}px) {
    left: 58%;
    top: 375px;
    bottom: unset;
  }
`;

const HeroHardware = () => {
  const { greaterThan, lessThan, viewport } = useViewport();

  const { state, dispatch } = useOfferConfigurator();

  const {
    accessories,
    activeAccessoryVariantIds,
    activeVariantId,
    extendedVariant,
    extendedVariants,
    stock,
    rootInfo,
    isSoldOut,
  } = state;

  if (!extendedVariant) {
    return (
      <Surface variant="narrow">
        <Loading variant="hero" marginTop={4} />
      </Surface>
    );
  }

  const { eyeCatchers, tariff } = extendedVariant;

  // Für die Speichervarianten
  const availableVariants = filterVariantsByColor(
    extendedVariants,
    extendedVariant?.variant.colorGroup.hex
  );

  const extendedTariff = extendTariffDetails(tariff);

  const { serviceProvider, carrier } = extendedTariff;

  const { manufacturer, name } = rootInfo?.hardware || {};

  // first letter in product headline should be uppercase. https://skon.atlassian.net/browse/MMSD-4484
  const upperCaseManufacturer =
    manufacturer?.charAt(0).toUpperCase() + manufacturer.slice(1);

  const tariffUrl = `/tarife/${serviceProvider}`;

  const handleVariantChange = (val) => {
    dispatch({
      type: SET_ACTIVE_VARIANT,
      payload: {
        activeVariantId: val,
      },
    });
    dispatch({
      type: SET_ACTIVE_TARIFF,
      payload: {
        calculateExtendTariff: findSelectedTariffInExtendedVariants(
          extendedVariants,
          val
        ),
      },
    });
  };

  return (
    <StyledSurface variant="narrow">
      <Row className="relative">
        <Col lg={14}>
          <Row>
            <Col mb={3}>
              <ProductHeadline
                variant="h2"
                hardware={{
                  manufacturer: upperCaseManufacturer,
                  name,
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={14}>
              <Box mb={6}>
                <Eyecatchers eyecatchers={eyeCatchers} mx={0} />
              </Box>
              <ProductAccessoryDisplay
                isSoldOut={isSoldOut}
                name={manufacturer}
                isHeroHardware
                image={extendedVariant?.variant.images[0]}
                imageHeight="300px"
                manufacturer={manufacturer}
                accessories={greaterThan.md ? accessories : []}
              />
              <StyledPrice>
                <PriceDisplay
                  price={extendedVariant.variant.price}
                  tariffId={extendedVariant.tariff.id}
                  size="s"
                  stageStyle
                />
                <Box mt={1}>
                  <Text variant="xs">Einmaliger Gerätepreis</Text>
                </Box>
              </StyledPrice>
            </Col>
            <Col md={10}>
              <Box mt={lessThan.md ? 10 : 0}>
                <VariantsColorDropdown
                  viewport={viewport}
                  extendedVariants={extendedVariants}
                  activeVariantId={activeVariantId}
                  onChange={handleVariantChange}
                />
              </Box>
              <Box mt={3}>
                <VariantsStorageDropdown
                  viewport={viewport}
                  selected={activeVariantId}
                  variants={availableVariants}
                  onChange={handleVariantChange}
                />
              </Box>
              {greaterThan.md &&
                accessories.map((accessory, index) => {
                  const variantId =
                    activeAccessoryVariantIds?.[index].ebootisId ||
                    accessory.extendedVariants[0].variant.ebootisId;
                  return (
                    <Box mt={3} key={variantId}>
                      <VariantsColorDropdown
                        extendedVariants={accessory.extendedVariants}
                        label={`Zubehörfarbe ${accessory.name}`}
                        activeVariantId={variantId}
                        viewport={viewport}
                        onChange={(activeAccessoryVariantId) => {
                          dispatch({
                            type: SET_ACTIVE_ACCESSORY_VARIANT,
                            payload: {
                              activeAccessoryVariantId,
                              activeAccessoryHardwareId: accessory._id,
                              index,
                            },
                          });
                        }}
                      />
                    </Box>
                  );
                })}
              <Box mt={3}>
                <GeneralModalShell
                  opener={
                    <SmallInlineLink
                      href="#"
                      onClick={(evt) => {
                        evt.preventDefault();
                        dataLayer({
                          eventCategory: "Hardwaredetails",
                          eventAction: "click",
                          eventLabel:
                            "Geräte-Details:undefined:undefined:OfferHeroHardware",
                        });
                      }}
                    >
                      Gerätedetails
                    </SmallInlineLink>
                  }
                >
                  <Headline variant="h2">{rootInfo?.hardware.name}</Headline>
                  <HardwareInfo hardware={rootInfo?.hardware} />
                  {accessories &&
                    accessories.map((item) => {
                      return (
                        <Box mt={4} key={item.name}>
                          <Headline variant="h2">{item.name}</Headline>
                          <HardwareInfo hardware={item} />
                        </Box>
                      );
                    })}
                </GeneralModalShell>
              </Box>
            </Col>
          </Row>
        </Col>
        <Col lg={10}>
          <TileBox>
            <SelectedTariff
              tariff={extendedTariff}
              serviceProvider={carrier}
              tariffUrl={tariffUrl}
            />

            {greaterThan.md && (
              <Box mt={4}>
                <PriceSection
                  shippingTextVariant="smallCopy"
                  selectedAccessoryType="HARDWARE"
                  accessories={accessories}
                  activeAccessoryVariantIds={activeAccessoryVariantIds}
                  extendedVariant={extendedVariant}
                  stock={stock}
                  rootInfo={rootInfo}
                  showOnePriceRow={false}
                  priceShippingInfo={
                    <DeliveryInfoModal
                      deliveryPrice={extendedVariant.variant.deliveryPrice}
                    />
                  }
                />
              </Box>
            )}
          </TileBox>
          {lessThan.md && (
            <>
              {accessories && (
                <Box mt={4}>
                  {accessories.map((accessory, index) => {
                    const ebootisId =
                      activeAccessoryVariantIds?.[index].ebootisId ||
                      accessory.extendedVariants[0].variant.ebootisId;

                    return (
                      <SelectedAccessories
                        key={accessory.name}
                        index={index}
                        ebootisId={ebootisId}
                        accessory={accessory}
                        activeAccessoryVariantIds={activeAccessoryVariantIds}
                      />
                    );
                  })}
                </Box>
              )}
              <Box mt={4}>
                <PriceSection
                  shippingTextVariant="s"
                  accessories={accessories}
                  activeAccessoryVariantIds={activeAccessoryVariantIds}
                  extendedVariant={extendedVariant}
                  stock={stock}
                  rootInfo={rootInfo}
                  showOnePriceRow={false}
                  priceShippingInfo={
                    <DeliveryInfoModal
                      deliveryPrice={extendedVariant.variant.deliveryPrice}
                    />
                  }
                />
              </Box>
            </>
          )}
        </Col>
      </Row>
    </StyledSurface>
  );
};

export default HeroHardware;
