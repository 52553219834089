import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState, } from "react";
import styled from "styled-components";
import { ContractPartnerLabel, Checkbox, MetaLink, RadioInput, } from "@/components/atoms";
import { Box } from "@mm/ui";
import { provideIsLoadingProp, } from "@/utils/context/LoadingContext";
import { ACTIONS, useModal } from "@/utils/context/ModalContext";
import { THEME } from "@core/theme";
import dataLayer from "@/helper/dataLayer";
const { skeletonBase } = THEME;
const StyledArticleSelectList = styled("ul").attrs(provideIsLoadingProp) `
  border-radius: 4px;
  list-style: none;
  padding: 0;
  position: relative;
  ${({ disabled }) => disabled && "opacity: 0.5"};
`;
const ArticleSelectListItem = ({ children, 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
isLoading, ...props }) => (_jsx("li", { ...props, children: children }));
const StyledArticleSelectListItem = styled(ArticleSelectListItem).attrs(provideIsLoadingProp) `
  background-color: white;
  border: 1px solid
    ${({ selected, theme }) => selected ? theme.colors.primary[0] : theme.colors.grey[7]};

  ${({ isLoading, selected, theme }) => selected &&
    "box-shadow: 0 -4px 0 -2px " +
        theme.colors.white +
        ", inset 0 0 0 2px " +
        (!isLoading ? theme.colors.brand.primary : theme.colors.grey[7]) +
        ";"}

  padding: ${({ selected }) => selected
    ? "calc(0.875rem + 1px) calc(1.25rem + 1px) calc(1.5rem + 1px)"
    : "0.875rem 1.25rem 1.5rem"};
  position: relative;

  &:first-of-type {
    ${({ selected }) => {
    if (selected) {
        return "padding: calc(0.875rem + 1px) calc(1.25rem + 1px) 1.5rem;";
    }
    return "border-bottom: 0;";
}}
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    ${({ isLoading, selected, theme }) => selected &&
    "box-shadow: inset 0 0 0 2px " +
        (!isLoading ? theme.colors.brand.primary : theme.colors.grey[7]) +
        ";"}
  }
  &:last-of-type {
    ${({ length, selected }) => {
    if (length > 2) {
        if (selected) {
            return "padding: 0.875rem calc(1.25rem + 1px) calc(1.5rem + 1px);";
        }
        return "border-top: 0;";
    }
    // return selected && "margin-bottom: -1px;";
}}
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;
const Header = styled.header `
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media (max-width: 880px) {
    flex-direction: column;
  }
`;
const EyeCatcherList = styled.ul `
  flex: 1 0 50%;
  display: flex;
  flex-direction: row;
  list-style: none;
  padding: 0;
  gap: 1rem;
  margin-bottom: 1rem;

  @media (max-width: 880px) {
    flex-direction: column;
    gap: 0.5rem;
    flex: 0;
    align-items: flex-start;
  }
`;
const EyeCatcher = styled.li.attrs(provideIsLoadingProp) `
  color: ${({ theme }) => theme.colors.brand.primary};
  font-size: 0.75rem;
  font-weight: bold;

  ${({ isLoading }) => isLoading && skeletonBase + "height: auto;"}
`;
const EyeCatcherLabel = styled.span.attrs(provideIsLoadingProp) `
  position: relative;

  align-items: center;
  background-color: #fff;
  border-top: 1px solid ${({ theme }) => theme.colors.brand.primary};
  border-bottom: 1px solid ${({ theme }) => theme.colors.brand.primary};
  display: flex;
  height: 1.625rem;
  padding: 0.125rem 0.5rem 0.125rem 0.75rem;
  white-space: nowrap;

  ${({ isLoading }) => isLoading &&
    "background: none; height: 1.625rem; border-color: transparent;"}

  &:before {
    position: absolute;
    top: -1px;
    left: -0.125rem;

    content: "";
    height: 1.5rem;
    width: 0.75rem;
    background-color: #fff;
    border-radius: 4px 0 0 4px;
    border-top: 1px solid ${({ theme }) => theme.colors.brand.primary};
    border-bottom: 1px solid ${({ theme }) => theme.colors.brand.primary};
    border-left: 1px solid ${({ theme }) => theme.colors.brand.primary};

    ${({ isLoading }) => isLoading && "background: none; border-color: transparent;"}
  }

  &:after {
    position: absolute;
    top: -1px;
    right: -0.25rem;

    content: "";
    height: 1.5rem;
    width: 0.5rem;
    background-color: #fff;
    border-radius: 0 4px 4px 0;
    border-top: 1px solid ${({ theme }) => theme.colors.brand.primary};
    border-bottom: 1px solid ${({ theme }) => theme.colors.brand.primary};
    border-right: 1px solid ${({ theme }) => theme.colors.brand.primary};
    transform: skew(-10deg);

    ${({ isLoading }) => isLoading && "background: none; border-color: transparent;"}
  }
`;
const HeadlineBlock = styled.div.attrs(provideIsLoadingProp) `
  display: flex;
  flex-direction: column;
  ${({ isLoading }) => isLoading && skeletonBase + "height: 1.5rem;"}
`;
const Headline = styled.span `
  font-size: 1.25rem;
  font-weight: bold;
  display: flex;
  align-items: center;
`;
const ContractPartnerLabelWrapper = styled.div.attrs(provideIsLoadingProp) `
  flex: 1 0 50%;
  display: flex;
  justify-content: flex-end;

  ${({ hasEyeCatchers }) => !hasEyeCatchers && "order: 1;"};

  @media (max-width: 880px) {
    ${({ hasEyeCatchers }) => !hasEyeCatchers && "order: -1;"};

    margin-bottom: 1rem;
    justify-content: flex-start;
  }
`;
const CheckboxWrapper = styled.div `
  margin-right: 1rem;
`;
const Subline = styled.span `
  font-size: 1rem;
  margin-top: 0.25rem;
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;
const BulletsList = styled.ul.attrs(provideIsLoadingProp) `
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  ${({ isLoading }) => isLoading &&
    `
        padding: 0;
        padding-right: 2rem;
        margin: 0;
    `}
  @media (max-width: 511px) {
    padding: 0.5rem 0rem 0rem 1rem;
  }
`;
const BulletListItem = styled.li.attrs(provideIsLoadingProp) `
  list-style-type: disc;
  ${({ isLoading }) => isLoading && skeletonBase + "height: 1.25rem; align-self: flex-start"}
`;
const MetaLinks = styled.div `
  margin-top: auto;
  padding-top: 0.5rem;
  display: flex;
  gap: 0.5rem;
`;
const ImageWrapper = styled.div.attrs(provideIsLoadingProp) `
  flex: 0 0 9rem;
  width: 9rem;
  max-height: 9rem;
  margin-right: 2rem;

  ${({ isLoading }) => isLoading &&
    skeletonBase + "width: 9rem; height: 8rem; & > img { display: none; }"};

  @media (max-width: 760px) {
    flex: 0 0 8rem;
    width: 8rem;
    max-height: 8rem;
  }
  @media (max-width: 511px) {
    margin-right: 1rem;
  }
`;
const Image = styled.img `
  display: block;
  width: 100%;
  height: auto;
  max-height: 9rem;
  object-fit: cover;
  object-position: center;

  @media (max-width: 760px) {
    width: 100%;
    max-height: 8rem;
  }
`;
const Main = styled.main `
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;

  @media (max-width: 760px) {
    flex-direction: column;
  }
`;
const Details = styled.div `
  display: flex;
  @media (max-width: 410px) {
    flex-wrap: wrap;
  }
`;
const Description = styled.div `
  display: flex;
  flex-direction: column;
`;
const PriceBox = styled.div `
  margin-left: auto;
  margin-top: auto;
  text-align: right;
  display: flex;
  flex-direction: column;
  white-space: nowrap;

  @media (max-width: 760px) {
    margin-top: 1rem;
  }
`;
const Price = styled.span.attrs(provideIsLoadingProp) `
  font-size: 1.25rem;
  font-weight: bold;
  display: flex;

  ${({ isLoading }) => isLoading && skeletonBase + "height: 1.5rem;"};

  svg {
    ${({ isLoading }) => isLoading && "display: none;"};
  }
`;
const NextPriceInterval = styled.span.attrs(provideIsLoadingProp) `
  ${({ isLoading }) => isLoading && skeletonBase + "height: 1.25rem; margin-top: 1px;"};
`;
const SublineInfoIcon = styled.i `
  cursor: pointer;
`;
export const ArticleSelectList = ({ articles, disabled, metaLinks, multiselect, onChange, value, }) => {
    const normalizedValue = value
        ? Array.isArray(value)
            ? value
            : [value]
        : undefined;
    // TOOD: same for saturn?
    const imageUriPrefix = "https://content.ekontor24.net/mediamarkt";
    const [selected, setSelected] = useState(normalizedValue ?? []);
    const [touched, setTouched] = useState(false);
    const { dispatch } = useModal();
    useEffect(() => {
        if (touched) {
            onChange(articles.filter(({ id }) => selected.includes(id)));
        }
    }, [selected, touched]);
    const isSelected = (article) => {
        return Boolean(selected.includes(article.id));
    };
    const handleItemClick = (article) => {
        if (!disabled) {
            setTouched(true);
            if (isSelected(article)) {
                if (multiselect) {
                    setSelected(selected?.filter((id) => article.id !== id));
                }
            }
            else {
                if (multiselect) {
                    setSelected([...selected, article.id]);
                }
                else {
                    setSelected([article.id]);
                }
            }
        }
        dataLayer({
            eventCategory: "PKS:SelectArticle",
            eventAction: "input",
            eventLabel: `PKS:Action:SelectArticle:${article.name}`,
        });
    };
    return (_jsx(StyledArticleSelectList, { disabled: disabled, children: articles.map((article) => (_jsxs(StyledArticleSelectListItem, { length: articles.length, onClick: () => handleItemClick(article), selected: article.isSelected ?? isSelected(article), "data-testid": `ArticleSelectListItem-${article.id}`, children: [_jsxs(Header, { children: [article.eyeCatchers && article.eyeCatchers.length > 0 && (_jsx(EyeCatcherList, { children: article.eyeCatchers.map((eyeCatcher) => (_jsx(EyeCatcher, { children: _jsx(EyeCatcherLabel, { children: eyeCatcher }) }, eyeCatcher))) })), article.contractPartner && (_jsx(ContractPartnerLabelWrapper, { hasEyeCatchers: article.eyeCatchers && article.eyeCatchers.length > 0, children: _jsx(ContractPartnerLabel, { contractPartner: article.contractPartner, onClick: () => dispatch({
                                    type: ACTIONS.SET_MODAL,
                                    payload: {
                                        type: "contractPartner",
                                        title: "Details zum Vertragspartner",
                                        content: article.contractPartnerInfo ? (_jsx("div", { dangerouslySetInnerHTML: {
                                                __html: article.contractPartnerInfo,
                                            } })) : undefined,
                                    },
                                }) }) })), _jsxs(HeadlineBlock, { children: [_jsxs(Headline, { children: [multiselect && (_jsxs(_Fragment, { children: [_jsx(CheckboxWrapper, { children: _jsx(Checkbox, { checked: isSelected(article) }) }), article.name] })), !multiselect && (_jsxs(_Fragment, { children: [_jsx(RadioInput, { disabled: disabled, id: article.id, name: article.name, checked: article.isSelected || isSelected(article), onChange: () => article.id }), _jsx(Box, { ml: "2", children: article.name })] }))] }), article.subline && (_jsxs(Subline, { children: [article.subline, _jsx(SublineInfoIcon, { className: "icon-MM_iconfont_Info", onClick: () => dispatch({
                                                type: ACTIONS.SET_MODAL,
                                                payload: {
                                                    type: "generic",
                                                    title: article.subline ?? "",
                                                    content: article.sublineInfo ? (_jsx("div", { dangerouslySetInnerHTML: {
                                                            __html: article.sublineInfo,
                                                        } })) : undefined,
                                                },
                                            }) })] }))] })] }), _jsxs(Main, { children: [_jsxs(Details, { children: [article.image && (_jsx(ImageWrapper, { children: _jsx(Image, { src: `${imageUriPrefix}${article.image.webp?.medium ?? article.image.png?.medium}`, alt: article.name }) })), _jsxs(Description, { children: [_jsx(BulletsList, { children: article.bullets.map((bullet, index) => (_jsx(BulletListItem, { children: bullet }, `highlight-${index}`))) }), metaLinks && (_jsx(MetaLinks, { children: metaLinks.map(({ urlKey, onClick, ...props }, index) => {
                                                const url = urlKey
                                                    ? article[urlKey]
                                                    : props.url;
                                                return (_jsx(MetaLink, { ...props, onClick: (details, content) => {
                                                        onClick && onClick(details, content);
                                                        dataLayer({
                                                            event: "gaEvent",
                                                            eventCategory: "Details",
                                                            eventAction: "click",
                                                            eventLabel: `${article.name}:${props.text}`,
                                                        });
                                                    }, details: article.details, modalContent: article.modalContent, url: url }, `metaLinks-${index}`));
                                            }) }))] })] }), _jsxs(PriceBox, { children: [_jsx(Price, { children: article.price }), article.nextPriceInterval && (_jsx(NextPriceInterval, { children: article.nextPriceInterval }))] })] })] }, article.id))) }));
};
