import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from "react";
import dataLayer from "@/helper/dataLayer";
import { usePage } from "@/utils/fetchData";
import { SimOnlyProvider } from "@/features/SimOnly";
import { Box, Surface } from "@mm/ui";
import { StandardPageTemplate } from "@/components/templates";
import { SimOnlyFilter, SimOnlyOfferTeaser, SimOnlyPromotionOfferTeaser, } from "@/sections";
import { Loading } from "@/components/molecules";
import { LoadingOverlay, PageHeader, PageFooter } from "@/components/organisms";
import PageSections from "@/pages/PageSections";
const ListingPageSimonly = ({ queryParams }) => {
    const { data: page } = usePage();
    useEffect(() => {
        if (page) {
            dataLayer({
                pageType: "Simonly",
                category: "Tarife",
            });
        }
    }, [page]);
    if (!page)
        return _jsx(Loading, { variant: "home" });
    return (_jsx(SimOnlyProvider, { page: page, children: _jsx(StandardPageTemplate, { header: _jsx(PageHeader, { itemCount: 1 }), sections: [
                _jsx(Surface, { variant: "narrow", children: _jsx("h2", { children: "Unsere besten Handytarife" }) }, "Unsere besten Angebote"),
                _jsx(SimOnlyPromotionOfferTeaser, { queryParams: queryParams }, "SimOnlyPromotionOfferTeaser"),
                _jsx(SimOnlyFilter, {}, "SimOnlyFilter"),
                _jsx(SimOnlyOfferTeaser, { queryParams: queryParams }, "SimOnlyOfferTeaser"),
                _jsx(Box, { mb: 4 }, "Box"),
                _jsx(PageSections, { sections: page.sections, variant: page.siteType }, "PageSections"),
                _jsx(LoadingOverlay, {}, "LoadingOverlay"),
            ], footer: _jsx(PageFooter, {}) }) }));
};
export default ListingPageSimonly;
