import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useIntl } from "@core/text";
import { Button } from "@mm/ui";

const Header = styled.p`
  color: ${(props) => props.theme.colors.grey[2]};
  font-size: 12px;
  line-height: 1.33;
  padding: 0;
  margin: 0 0 16px;
`;

const NewsletterForm = ({ header }) => {
  const intl = useIntl();
  return (
    <>
      {header && <Header>Newsletter</Header>}
      <Button
        size="small"
        color="primary"
        type="button"
        onClick={(e) => {
          e.preventDefault();
          window.open(
            intl.formatMessage({ id: "url.footer.newsletter" }),
            "_blank"
          );
        }}
      >
        Zur Newsletter Anmeldung
      </Button>
    </>
  );
};

NewsletterForm.propTypes = {
  header: PropTypes.bool.isRequired,
};
export default NewsletterForm;
