import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Box, Text, Row, Col } from "@mm/ui";

import { Bulletlist, Eyecatchers, TariffBadge } from "components/atoms";

import { TariffDetailsLinks } from "components/molecules";

import { eyecatcherPropTypes, tariffPropTypes } from "sharedPropTypes";

const StyledRow = styled(Row)`
  flex-wrap: wrap;
  align-items: flex-start;
`;

const StyledCol = styled(Col)`
  position: relative;
`;

const Container = styled.div`
  li {
    font-size: ${({ theme }) => theme.legacyFontSize[6]}!important;

    @media (min-width: ${({ theme }) => theme.breakpointsMap.xl.min}px) {
      font-size: ${({ theme }) => theme.legacyFontSize[1]}!important;
    }
  }
`;

const TariffDetails = ({
  eyeCatchers,
  tariff,
  tariff: { bullets, contractTerm },
  headline,
}) => (
  <>
    <StyledRow>
      <StyledCol xs={24} md={12} lg={10}>
        <Eyecatchers eyecatchers={eyeCatchers} mb={12} mx={0} />
        <Box textAlign="center" height="250px">
          <TariffBadge tariff={tariff} sim />
        </Box>
      </StyledCol>
      <Col xs={24} md={12} lg={14}>
        <Container>
          {headline}
          <Bulletlist list={bullets} />
          <TariffDetailsLinks
            tariff={tariff}
            analytics={{ context: "TariffDetails" }}
          />
          {contractTerm && (
            <Text variant="s" display="block" mt={4}>
              {`Mindestvertragslaufzeit: ${contractTerm} Monate`}
            </Text>
          )}
        </Container>
      </Col>
    </StyledRow>
  </>
);

TariffDetails.defaultProps = {
  eyeCatchers: [],
  headline: null,
};

TariffDetails.propTypes = {
  tariff: tariffPropTypes.isRequired,
  eyeCatchers: eyecatcherPropTypes,
  headline: PropTypes.node,
};

export default TariffDetails;
