import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import dataLayer from "helper/dataLayer";
import { hashString } from "utils/hashString";

import { ProviderQuittingTile } from "components/atoms";
import { Loading } from "components/molecules";

const StyledCol = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const ProviderQuittingTileContainer = ({ providers }) => {
  if (!providers) return <Loading variant="manufacturer" />;
  return (
    <StyledCol>
      {providers.map(({ link, provider }, index) => (
        <ProviderQuittingTile
          link={link}
          onClick={() =>
            dataLayer({
              eventCategory: "Markenlogo",
              eventAction: "click",
              eventLabel: `${provider}:${index}`,
            })
          }
          key={hashString(provider, ["ProviderQuittingTileContainer"])}
          provider={provider}
        />
      ))}
    </StyledCol>
  );
};

ProviderQuittingTileContainer.propTypes = {
  providers: PropTypes.arrayOf(PropTypes.object).isRequired,
};
export default React.memo(ProviderQuittingTileContainer);
