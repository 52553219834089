/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
import SlickCarousel from "react-slick";

import { OfferProvider } from "features/Offer";

import PhoneQuarterWidth from "./Phone";
import TariffQuarterWidth from "./Tariff";

import { StyledTeaserCarousel, carouselSettings } from "./SlickCarouselSetting";

const OfferTeaserFourColumns = ({ data }) => {
  if (data.length === 0) {
    return <div>Es können keine Angebote angezeigt werden</div>;
  }
  return (
    <StyledTeaserCarousel>
      <SlickCarousel {...carouselSettings}>
        {data.map((slide) => {
          return slide.hardware ? (
            <OfferProvider
              rootInfoData={slide}
              extendedVariantsData={slide.hardware.extendedVariants}
              key={`Provider-${slide.hardware.id}`}
            >
              <PhoneQuarterWidth {...slide} key={slide.hardware.id} />
            </OfferProvider>
          ) : (
            <TariffQuarterWidth {...slide} key={slide.tariff.id} />
          );
        })}
      </SlickCarousel>
    </StyledTeaserCarousel>
  );
};

OfferTeaserFourColumns.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
};

OfferTeaserFourColumns.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default OfferTeaserFourColumns;
