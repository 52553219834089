import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { ACTIONS as CONTEXT_ACTIONS, useCheckoutState, } from "../../context/CheckoutContext";
import { useField, useValidation, } from "../../hooks";
import { useCheckout } from "../../hooks/api";
import { DatePicker, RadioList, CheckoutSection, CheckoutSectionHeadline, } from "../../components";
import { addDays, parse, startOfDay } from "date-fns";
import { ACTIONS, useModal } from "@/utils/context/ModalContext";
const Row = styled.div `
  display: flex;
  width: 100%;
  gap: 1.5rem;
`;
const InputWrapper = styled.div `
  flex: 1;
`;
export const ConnectionActivationSection = ({ defaultValues, schema, options, }) => {
    const { state, dispatch: dispatchContext } = useCheckoutState();
    const { selectableStartInDays, selectableEndInDays } = options ?? {
        selectableStartInDays: 0,
        selectableEndInDays: 365,
    };
    const startDate = startOfDay(addDays(new Date(), Number(selectableStartInDays)));
    const endDate = startOfDay(addDays(new Date(), Number(selectableEndInDays)));
    const { errors: activationDateSelectionErrors, fieldState: activationDateSelection, setFieldState: setActivationDateSelection, } = useField("activationDateSelection", schema, Boolean(defaultValues?.activationDateSelection));
    const { errors: activationDateErrors, fieldState: activationDate, setFieldState: setActivationDate, } = useField("activationDate", schema, String(defaultValues?.activationDate));
    const { clearErrors, } = useValidation();
    const { isFetching, isLoading } = useCheckout();
    const { dispatch } = useModal();
    return (_jsxs(CheckoutSection, { children: [_jsx(CheckoutSectionHeadline, { children: "Zu wann w\u00FCnscht du die Aktivierung des Anschlusses?" }), _jsx(RadioList, { id: "activationDateSelection", checked: activationDateSelection
                    ? "activationDate--manual"
                    : "activationDate--asap", items: [
                    {
                        key: "activationDate--asap",
                        label: "Schnellstmöglich",
                        onClickInfo: () => {
                            dispatch({
                                type: ACTIONS.SET_MODAL,
                                payload: {
                                    type: "generic",
                                    title: "Schnellstmögliche Schaltung",
                                    content: (_jsx("p", { children: "Dein 1&1 Anschluss wird schnellstm\u00F6glich geschaltet. Bei Auswahl \u201Cschnellstm\u00F6glich\u201D beginnen wir umgehend mit der Ausf\u00FChrung deines Auftrages." })),
                                },
                            });
                        },
                    },
                    {
                        key: "activationDate--manual",
                        label: "Schaltung zum Wunschtermin",
                        onClickInfo: () => {
                            dispatch({
                                type: ACTIONS.SET_MODAL,
                                payload: {
                                    type: "generic",
                                    title: "Schaltung zum Wunschtermin",
                                    content: (_jsx("p", { children: "Du kannst einen Wunschtermin zwischen 28 und 118 Tagen in der Zukunft w\u00E4hlen. Wunschtermine an Wochenenden und Feiertagen sind nicht m\u00F6glich." })),
                                },
                            });
                        },
                    },
                ], onChange: (key) => {
                    if (!isLoading && !isFetching) {
                        clearErrors(["activationDate"]);
                        if (key === "activationDate--asap") {
                            dispatchContext({
                                type: CONTEXT_ACTIONS.RESET_FIELDS,
                                payload: "activationDate",
                            });
                        }
                        setActivationDateSelection(key === "activationDate--manual");
                    }
                }, error: activationDateSelectionErrors?.[0]?.message }), state.providerChange && !activationDateSelection && (_jsx("p", { children: "Dein neuer 1&1 Anschluss wird unterbrechungsfrei exakt zum Ende deines alten Vertrages aktiviert." })), activationDateSelection && (_jsx(_Fragment, { children: _jsx(Row, { children: _jsx(InputWrapper, { children: _jsx(DatePicker, { id: "activationDate", label: "Aktivierungsdatum*", selectableStart: startDate, selectableEnd: endDate, selected: activationDate
                                ? parse(activationDate, "dd.MM.yyyy", new Date())
                                : undefined, openToDate: startDate, onChange: (date) => setActivationDate(date?.toLocaleString("de-DE", {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                            }) ?? ""), layout: activationDateErrors && "error", message: activationDateErrors?.[0]?.message }) }) }) }))] }));
};
