import React from "react";
import PropTypes from "prop-types";

import { Flex, Box, Surface, Col } from "@mm/ui";

import { useOfferConfigurator } from "features/Offer";

import { PriceRow, StickyElement, ToTopBtn } from "components/atoms";

import {
  CartButton,
  PriceDisplay,
  TariffMonthlyPriceLabel,
} from "components/molecules";

const createPriceCol = (text, price) => (
  <Col xs={24} lg={12}>
    <PriceRow text={text} price={price} />
  </Col>
);

const BottomPriceBanner = ({ priceOnce, tariffId, basicPrice }) => {
  const {
    state: { activeTariff, stock, isSoldOut: soldOut },
  } = useOfferConfigurator();

  const { commission } = activeTariff;

  const isSoldOut = stock <= 0 || soldOut;

  return (
    <StickyElement position="bottomleft" fullWidth>
      <Box textAlign="right">
        <ToTopBtn />

        <Box
          color="grey.6"
          backgroundColor="shade.1"
          width="100%"
          height="auto"
          textAlign="left"
          padding={3}
        >
          <Surface variant="narrow">
            <Flex flexDirection={["column", "column", "column", "row", "row"]}>
              <Col xs={24} md={12} lg={16}>
                <Flex
                  height="100%"
                  alignItems={["auto", "auto", "auto", "auto", "center"]}
                  flexDirection={[
                    "column",
                    "column",
                    "column",
                    "column",
                    "row",
                  ]}
                >
                  {createPriceCol(
                    <TariffMonthlyPriceLabel commission={commission} />,
                    <PriceDisplay
                      className="noMargin"
                      price={basicPrice}
                      size="xs"
                      preventStyling={!!priceOnce}
                      tariffId={tariffId}
                      inline
                    />
                  )}
                  {!!priceOnce && (
                    <>
                      <Col xs={2} lg={0}>
                        <Box height={[10, 10, 10, 10, 0]} />
                      </Col>
                      {createPriceCol(
                        "Einmaliger Gerätepreis",
                        <PriceDisplay
                          className="noMargin"
                          price={priceOnce}
                          size="xs"
                          tariffId={tariffId}
                          inline
                        />
                      )}
                    </>
                  )}
                </Flex>
              </Col>
              <Col xs={0} lg={2} />
              <Col xs={2} md={0}>
                <Box height={[10, 10, 10, 0]} />
              </Col>
              <Col xs={24} md={12} lg={6}>
                <Flex
                  height="100%"
                  alignSelf="center"
                  justifyContent="center"
                  flexDirection="column"
                >
                  <CartButton isDisabled={isSoldOut} />
                </Flex>
              </Col>
            </Flex>
          </Surface>
        </Box>
      </Box>
    </StickyElement>
  );
};

BottomPriceBanner.propTypes = {
  priceOnce: PropTypes.number,
  tariffId: PropTypes.string.isRequired,
};

BottomPriceBanner.defaultProps = {
  priceOnce: 0,
};

export default BottomPriceBanner;
