import { jsx as _jsx } from "react/jsx-runtime";
import { Surface } from "@mm/ui";
import { Loading } from "@/components/molecules";
import { Countdown } from "@/components/organisms";
import styled from "styled-components";
const AllWidthSurface = styled(Surface) `
  padding: unset;
  margin: auto;
`;
const CountdownSection = ({ data }) => {
    if (!data)
        return _jsx(Loading, { variant: "manufacturer" });
    return (_jsx(AllWidthSurface, { variant: "ultra", mt: data.marginTop ? [4, 4, 4, 6, 6] : 0, children: _jsx(Countdown, { date: data.date, headline: data.headline, headlinePostCountDown: data.headlinePostCountDown, image: data.image, subHeadline: data.subHeadline, subHeadlinePostCountDown: data.subHeadlinePostCountDown }) }));
};
export default CountdownSection;
