import React from "react";
import PropTypes from "prop-types";

import { Surface } from "@mm/ui";

import { ThemeProvider } from "@core/theme";

import { OfferTeaser } from "components/organisms";

const OfferTeaserSection = ({ data, voucherCode }) => (
  <ThemeProvider>
    <Surface variant="narrow">
      <OfferTeaser data={data} voucherCode={voucherCode} />
    </Surface>
  </ThemeProvider>
);

OfferTeaserSection.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.any.isRequired,
  voucherCode: PropTypes.string,
};

OfferTeaserSection.defaultProps = {
  voucherCode: "",
};

export default OfferTeaserSection;
