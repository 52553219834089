import styled from "styled-components";
import { Button } from "@mm/ui";

export const AffiliateOfferContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 12px;
`;

export const AffiliateOfferItem = styled.div`
  position: relative;
  margin: 20px 0;

  @media (min-width: ${({ theme }) => theme.breakpointsMap.sm.min}px) {
    max-width: calc(33.33% - 12px);
  }
`;

export const AffiliateButton = styled(Button)`
  width: 100%;
`;

export const StyledOfferHeadline = styled.h4`
  font-weight: 400;
  min-height: 48px;
  line-height: 1.5;
  margin: 16px 0;

  strong {
    font-weight: 600;
    text-transform: uppercase;
  }
`;
