import PDP_CONFIG_MM from "./pdp.banner.data.mm";
import PDP_CONFIG_SAT from "./pdp.banner.data.sat";

export const pdpBannerData = {
  MEDIAMARKT: PDP_CONFIG_MM,
  SATURN: PDP_CONFIG_SAT,
}[TENANT];

export default {
  pdpBannerData,
};
