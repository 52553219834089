import styled from "styled-components";

const FakeLink = styled.span`
  text-decoration: underline;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.grey[6]};
`;

FakeLink.propTypes = {};

export default FakeLink;
