import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { LegalNoteAsterisk } from "@/components/atoms";
import { ArticleSelectList, } from "@/components/molecules";
import { formatNumberAsCurrencyString } from "@/utils/price/formatNumberAsCurrencyString";
import { ACTIONS as ModalActions, useModal, } from "@/utils/context/ModalContext";
const contractPartner = "S-KON eKontor 24 GmbH";
export const AccessorySelectList = ({ accessories, disabled, legalNote, onChange, }) => {
    const { dispatch } = useModal();
    const transformAccessory = (accessory) => ({
        ...accessory,
        contractPartner,
        contractPartnerInfo: accessory.info,
        image: accessory.extendedVariants?.[0].variant.images?.[0],
        modalContent: accessory.conditions,
        price: (_jsxs(_Fragment, { children: [formatNumberAsCurrencyString(accessory.extendedVariants?.[0].variant.price), " ", legalNote && _jsx(LegalNoteAsterisk, { legalNote: legalNote }), "einmalig"] })),
    });
    const aggregatedAccessories = accessories.map(transformAccessory);
    return (_jsx(ArticleSelectList, { articles: aggregatedAccessories, disabled: disabled, metaLinks: [
            {
                text: "Einlösebedingungen",
                onClick: (details, content) => dispatch({
                    type: ModalActions.SET_MODAL,
                    payload: {
                        title: "Einlösebedingungen",
                        type: "redemptionConditions",
                        content: content ? (_jsx("div", { dangerouslySetInnerHTML: { __html: content } })) : undefined,
                    },
                }),
            },
            {
                text: "Prämiendetails",
                onClick: (details) => dispatch({
                    type: ModalActions.SET_MODAL,
                    payload: {
                        details,
                        title: "Prämiendetails",
                        type: "accessoryDetails",
                    },
                }),
            },
        ], onChange: (selectedArticles) => {
            if (!disabled) {
                onChange(selectedArticles[0]);
            }
        } }));
};
