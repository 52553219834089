import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import XIcon from "@core/svgs/icon-x.svg";
const maxHeightOffSet = {
    md: "4rem",
    lg: "8rem",
};
const StyledModal = styled.div `
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  background: #fff;
  z-index: 9999;
  margin: auto;
  padding: 1rem;
  height: 100%;
  width: 100%;
  max-height: 100%;
  overflow-y: auto;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    max-height: calc(100vh - ${maxHeightOffSet.md});
    top: 2rem;
    width: auto;
    min-width: 40rem;
    border-radius: 4px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    max-height: calc(100vh - ${maxHeightOffSet.lg});
    top: 4rem;
  }
`;
const ButtonWrapper = styled.div `
  display: flex;
  justify-content: flex-end;
`;
const CloseButton = styled.div `
  cursor: pointer;
  height: 1rem;
  width: 1rem;
`;
export const Modal = ({ children, onClose }) => (_jsxs(StyledModal, { children: [_jsx(ButtonWrapper, { children: _jsx(CloseButton, { onClick: onClose, role: "button", children: _jsx(XIcon, {}) }) }), children] }));
