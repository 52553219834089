import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { useViewport } from "@core/theme";
import { Image } from "@mm/ui";

const FigureComp = styled("figure")(
  {
    backgroundRepeat: "no-repeat",
    backgroundImage: "none",
    backgroundSize: ({ zoomScale }) => `${zoomScale * 100}% auto`,
    padding: 0,
    backgroundOrigin: "content-box",
    margin: 0,

    img: {
      margin: ({ innerPadding }) => (innerPadding ? `-${innerPadding}px` : 0),
    },
  },
  ({ isTouchDevice, backgroundImageUrl, innerPadding }) => {
    if (isTouchDevice) {
      // Simply block zoom functionality for touch-devices
      return {};
    }

    return {
      "&:hover": {
        backgroundImage: `url(${backgroundImageUrl})`,
        padding: innerPadding ? `${innerPadding}px` : 0,

        img: {
          opacity: 0,
        },
      },
    };
  }
);

const ImgComp = styled(Image)({
  display: "block",
  width: "100%",
  height: "100%",
  pointerEvents: "none",
});

export const ZoomImage = ({ img, alt, className, zoomScale, innerPadding }) => {
  const { touchDevice: isTouchDevice } = useViewport();
  const [backgroundPosition, setBackgroundPosition] = useState("0% 0%");
  const imageType = Object.keys(img)[0];
  return (
    <div className={className}>
      <FigureComp
        onMouseMove={(e) => {
          if (isTouchDevice) {
            // Simply block zoom functionality for touch-devices
            return;
          }

          const { left, top, width, height } = e.target.getBoundingClientRect();
          const x = ((e.pageX - left) / width) * 100;
          const y = ((e.pageY - top) / height) * 100;

          setBackgroundPosition(`${x}% ${y}%`);
        }}
        backgroundImageUrl={window.CDN_URL + img[imageType].large}
        zoomScale={zoomScale}
        isTouchDevice={isTouchDevice}
        innerPadding={innerPadding}
        style={{
          backgroundPosition,
        }}
      >
        <ImgComp srcSet={img} alt={alt} />
      </FigureComp>
    </div>
  );
};

ZoomImage.defaultProps = {
  className: "",
  alt: "",
  zoomScale: 1.5,
  innerPadding: 15,
};

ZoomImage.propTypes = {
  className: PropTypes.string,
  img: PropTypes.object.isRequired,
  alt: PropTypes.string,
  zoomScale: PropTypes.number,
  innerPadding: PropTypes.number,
};

export default ZoomImage;
