import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { z } from "zod";
import styled from "styled-components";
import { Transition } from "@headlessui/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useMutation } from "react-query";
import { Box, Surface } from "@mm/ui";
import { PageFooter, PageHeader } from "@/components/organisms";
import { StandardPageTemplate } from "@/components/templates";
import { Spinner } from "@/components/atoms";
import { Loading, OfferSummary } from "@/components/molecules";
import { CheckoutStep } from "./steps";
import { StepNavigation } from "./components";
import { CheckoutStateProvider, initialState, useCheckoutState, } from "./context/CheckoutContext";
import { getDefaultValues, getFirstStepWithField, getSchema, mergeDataWithDefaultValues, } from "./util";
import { ACTIONS as LoadingStateActions, useLoadingState, } from "@/utils/context/LoadingContext";
import { mutateCheckout, submitOrder, useCheckout } from "./hooks/api";
import dataLayer from "@/helper/dataLayer";
import { CheckoutValidationContextProvider, useCheckoutStep, useValidation, } from "./hooks";
import { getConfigByCheckoutType } from "./config";
import { checkoutBasePaths } from "@/router/checkoutBasePaths";
const FlexSurface = styled(Surface) `
  display: flex;
  gap: 1.25rem;
  margin-top: 2rem;
  position: relative;
  flex-wrap: wrap;

  @media (max-width: 760px) {
    flex-wrap: wrap;
    margin-top: 0;
  }
`;
const ConfigurationContainer = styled.form `
  flex: 1 0 34rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @media (max-width: 760px) {
    flex: 1 0 100%;
  }
`;
const SummaryContainer = styled.aside `
  flex: 0 0 26rem;
  position: sticky;
  top: 2rem;
  align-self: flex-start;

  @media (max-width: 760px) {
    flex: 1 0 100%;
    background: #fff;
    margin: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-top: 1rem;
  }
`;
const LoadingOverlay = styled.div `
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9998;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: center;
  padding-top: 33vh;
  padding-bottom: 50vh;

  &.enter {
    transition: opacity 0.2s;
  }
  &.leave {
    transition: opacity 0.4s;
  }
  &.enterFrom,
  &.leaveTo {
    opacity: 0;
  }
  &.enterTo,
  &.leaveFrom {
    opacity: 0.5;
  }
`;
const SpinnerWrapper = styled.div `
  position: sticky;
  top: 33vh;
  bottom: 50vh;
  height: 4rem;
  width: 4rem;
`;
const StyledStepNavigation = styled(StepNavigation) `
  flex: 1 0 100%;
  width: 100%;
  margin: 0 auto 2rem;

  @media (max-width: 760px) {
    margin: 2rem auto 1rem;
  }
`;
const stepSchema = z.coerce.number();
const getNextStepIndex = (step, config) => (step !== config.steps.length ? step + 1 : -1);
const CheckoutLayout = ({ step, type }) => {
    const { state } = useCheckoutState();
    const { currentConfig, connectionType, onlineOnly, serviceProvider } = state;
    const { data, isFetching, isLoading, error } = useCheckout();
    const { dispatch } = useLoadingState();
    const navigate = useNavigate();
    const { pathname, state: navigationState } = useLocation();
    const [submitIsLoading, setSubmitIsLoading] = useState(false);
    const { clearErrors, isValid, setErrors, validate, addError } = useValidation();
    const basePath = checkoutBasePaths[type];
    const currentStep = currentConfig?.steps[Number(step) - 1];
    const { currentIndex, isLastStep } = useCheckoutStep();
    /**
     * Post the field values to the server
     */
    const mutation = useMutation({
        mutationFn: mutateCheckout,
        onError: (error, variables, context) => {
            // An error happened!
            // eslint-disable-next-line no-console
            console.error("checkout mutation:", error, variables, context);
        },
        onMutate: () => {
            setSubmitIsLoading(true);
        },
        onSuccess: (data) => {
            if (data.validationErrors) {
                Object.entries(data?.validationErrors ?? []).forEach(([path, message]) => {
                    addError(path, message);
                });
            }
            if (!data.validationErrors && currentConfig && currentStep) {
                const nextStep = getNextStepIndex(Number(step), currentConfig);
                if (nextStep > 0) {
                    navigate(`${basePath}/checkout/step/${nextStep}`);
                }
                else {
                    submitOrder()
                        .then((data) => {
                        if (data.success) {
                            if (data.tracking) {
                                dataLayer(data.tracking);
                            }
                            navigate(`${basePath}/checkout/danke`, {
                                state: {
                                    ...history.state,
                                    checkoutType: type,
                                    email: data.data.contactAddressEmail,
                                    orderNo: data.orderNo,
                                    summary: data.summary,
                                    links: data.links,
                                    webIdent: data.webIdent,
                                },
                                replace: true,
                            });
                        }
                        else {
                            if (data.error) {
                                Promise.reject(new Error(data.code ?? data.error));
                            }
                            // eslint-disable-next-line no-console
                            console.error(data.validationErrors);
                            const firstStepWithErrors = getFirstStepWithField(Object.keys(data.validationErrors), options.currentConfig, options, state);
                            if (firstStepWithErrors.index && firstStepWithErrors.step) {
                                // TODO: move to hook internals
                                setErrors({
                                    formErrors: [],
                                    fieldErrors: data.validationErrors
                                        ? Object.entries(data.validationErrors).reduce((errors, [path, message]) => {
                                            errors[path] = [
                                                {
                                                    message: message,
                                                },
                                            ];
                                            return errors;
                                        }, {})
                                        : {},
                                });
                                navigate(`${basePath}/checkout/step/${firstStepWithErrors.index}/`, {
                                    replace: true,
                                    state: {
                                        keepErrors: true,
                                    },
                                });
                            }
                        }
                    })
                        .catch((error) => {
                        throw error;
                    });
                }
            }
            else {
                setSubmitIsLoading(false);
            }
        },
    });
    if (error) {
        throw error;
    }
    useEffect(() => {
        dispatch({
            type: LoadingStateActions.SET_LOADING,
            payload: isFetching,
        });
    }, [isFetching]);
    useEffect(() => {
        if (!navigationState || !navigationState.keepErrors) {
            clearErrors();
        }
        setSubmitIsLoading(false);
    }, [pathname, navigationState]);
    if (!currentConfig || !currentStep) {
        return _jsx(Loading, { variant: "home" }, "checkout-loading-state");
    }
    const options = {
        connectionType,
        currentConfig,
        onlineOnly,
        serviceProvider,
    };
    return (_jsx(Box, { children: _jsx(StandardPageTemplate, { header: _jsx(PageHeader, { itemCount: 1, reducedHeader: true }), sections: [
                !data || isLoading ? (_jsx(Loading, { variant: "home" }, "checkout-loading-state")) : (_jsxs(FlexSurface, { variant: "narrow", children: [_jsx(StyledStepNavigation, { basePath: basePath }), _jsx(ConfigurationContainer, { autoComplete: "off", children: _jsx(CheckoutStep, { sections: currentStep.sections, options: options }) }), _jsxs(SummaryContainer, { children: [_jsx(OfferSummary, { currentIndex: currentIndex, isLastStep: isLastStep, legalNote: currentStep.legalNote, coupon: data?.summary?.coupon, onSubmit: () => {
                                        if (currentConfig && currentStep) {
                                            validate(mergeDataWithDefaultValues(state, getDefaultValues(currentStep)), getSchema(currentStep, options, state)).then((success) => {
                                                if (success) {
                                                    mutation.mutate(state);
                                                }
                                            });
                                        }
                                    }, showSummaryOnMobile: currentStep.showSummaryOnMobile, submitDisabled: !isValid || submitIsLoading, submitLabel: currentStep.submitLabel, summary: data?.summary, type: type }), _jsx(Transition, { as: LoadingOverlay, show: isFetching ?? false, enter: "enter", enterFrom: "enterFrom", enterTo: "enterTo", leave: "leave", leaveFrom: "leaveFrom", leaveTo: "leaveTo", children: _jsx(SpinnerWrapper, { children: _jsx(Spinner, { color: "ghost", size: "4rem" }) }) })] })] })),
            ], footer: _jsx(PageFooter, {}) }) }));
};
export const CheckoutPage = ({ type }) => {
    const { pathname, state: navigationState } = useLocation();
    const { step } = useParams();
    const navigate = useNavigate();
    useEffect(() => {
        if (!step || !stepSchema.safeParse(step).success) {
            navigate(`${checkoutBasePaths[type]}/checkout/step/1`);
        }
    }, [step]);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    const currentConfig = getConfigByCheckoutType(type)?.find((config) => config.key === navigationState?.serviceProvider);
    const options = {
        connectionType: navigationState?.connectionType,
        currentConfig,
        onlineOnly: navigationState?.onlineOnly,
        serviceProvider: navigationState?.serviceProvider,
    };
    useEffect(() => {
        if (currentConfig) {
            dataLayer({
                pageType: type,
                category: "checkout",
            });
        }
    }, [currentConfig]);
    return (_jsx(CheckoutStateProvider, { defaultState: {
            ...initialState,
            ...options,
        }, type: type, children: _jsx(CheckoutValidationContextProvider, { schemas: currentConfig ? getSchema(currentConfig, options) : [], children: _jsx(CheckoutLayout, { step: step, type: type }) }) }));
};
