import React from "react";
import PropTypes from "prop-types";

import { addTrackFunction } from "../helper";
import ImageOnlyTeaser from "../ImageOnlyTeaser";

import "./GridTeaserSection.scss";

const GridTeaserSection = ({ contents, type }) => {
  return (
    <div className="msd-gridWrapper">
      {contents.map((content, idx) => (
        <ImageOnlyTeaser
          key={content.linkUrl}
          type={content.type}
          content={content}
          onClick={addTrackFunction(content, type, 1)}
          qaName={`teaser${idx}`}
        />
      ))}
    </div>
  );
};

GridTeaserSection.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  contents: PropTypes.any,
  type: PropTypes.string.isRequired,
};

GridTeaserSection.defaultProps = {
  contents: [],
};

export default GridTeaserSection;
