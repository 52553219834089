import React from "react";
import PropTypes from "prop-types";
import { Flex, Box } from "@mm/ui";

import { useViewport } from "@core/theme";

import {
  TariffBadge,
  Eyecatchers,
  PillLabelList,
  Price,
  ManufacturerLogo,
} from "components/atoms";

import {
  eyecatcherPropTypes,
  hardwarePropTypes,
  tariffPropTypes,
} from "sharedPropTypes";

import {
  Background,
  DynamicStageImage,
  StyledTariffBadge,
  StyledPriceDisplayBackground,
  PriceInfoText,
  MobileConnectImage,
  StyledManufacturerLogo,
  StyledSubHeadline,
  StyledStageHeadline,
  ColoredStageHeadline,
  StyledTileAnchor,
  StyledPrice,
  StyledText,
  StyledPlus,
  StyledFlexbox,
} from "./DynamicStage.styled";

// TODO: Rename the whole Component DynamicStage = Flexhero.
const DynamicStageConfig = {
  PriceDisplayBackground: [1 / 1, 1 / 1, 1 / 1, 1 / 1, 2 / 3],
  ProductImage: [1 / 5, 1 / 5, 1 / 5, 1 / 5, 1 / 4],
  SingleProductImage: [1 / 5, 1 / 4, "130px", 1 / 5, 1 / 4],
  AccessoryImage: [1 / 4, 1 / 4, 1 / 4, 1 / 5, 1 / 5],
  WrapperBox: [1 / 1, 1 / 1, 1 / 1, 1 / 1, 1 / 3],
  DynamicStageBox: [1 / 1, 1 / 1, 1 / 1, 1 / 1, 2 / 3],
  PriceBadgeBox: [1 / 5, 1 / 2, 1 / 2, 1 / 2, 1 / 2],
  // Unfortunately Apple Product Images are a bit wider then other Product Images
  AppleProductImage: ["100px", "120px", "140px", "210px"],
};

const FlexHero = ({
  differentProductLink,
  tariff,
  tariff: { monthlyPrice },
  eyeCatchers,
  headline,
  subHeadline,
  hardware,
  hardware: { manufacturer, price, link, name, image },
  accessories,
  idx,
  qaName,
}) => {
  const DynamicStageConfigHandler = (prop) => {
    if (accessories.length > 0) {
      if (prop === "Apple") {
        return DynamicStageConfig.AppleProductImage;
      }
      return DynamicStageConfig.ProductImage;
    }
    if (prop === "Apple") {
      return DynamicStageConfig.AppleProductImage;
    }
    return DynamicStageConfig.SingleProductImage;
  };

  const loadedBenefits = [
    `${tariff.properties.tariff_inclusive_benefit1}`,
    `${tariff.properties.tariff_inclusive_benefit2}`,
    `${tariff.properties.tariff_inclusive_benefit3}`,
  ];

  const preparedBenefits = [];

  const getBenefits = (x) => {
    if (x.length >= 0) {
      for (let i = 0; i < x.length; i += 1) {
        const element = x[i];
        if (element === "undefined") {
          // eslint-disable-next-line no-continue
          continue;
        } else {
          preparedBenefits.push(element);
        }
      }
    }
    return preparedBenefits;
  };
  const viewport = useViewport();
  return (
    <StyledTileAnchor
      href={differentProductLink ? differentProductLink : link}
      idx={idx}
      qaName={qaName}
      position="relative"
    >
      {viewport.lessThan.sm && (
        <Eyecatchers variant="dynamicStage" eyecatchers={eyeCatchers} />
      )}
      <Flex
        flexDirection={[
          "column-reverse",
          "column-reverse",
          "column-reverse",
          "column-reverse",
          "row",
        ]}
        maxWidth={1104}
        position="relative"
        backgroundColor="white"
        justifyContent="space-between"
      >
        <Box width={DynamicStageConfig.WrapperBox} position="relative">
          {viewport.greaterThan.sm && (
            <Eyecatchers eyecatchers={eyeCatchers} mx={0} />
          )}
          <Box ml={6} mr={6} minHeight={220}>
            <StyledFlexbox flexDirection="column">
              {headline ? (
                <ColoredStageHeadline>{headline}</ColoredStageHeadline>
              ) : (
                <StyledStageHeadline
                  hardware={hardware}
                  accessories={accessories}
                />
              )}
              {subHeadline && (
                <StyledSubHeadline>{subHeadline}</StyledSubHeadline>
              )}
              <Flex justifyContent="space-between" marginTop={2}>
                <PillLabelList
                  highlights={getBenefits(loadedBenefits)}
                  mobile={!viewport.greaterThan.sm}
                />
                {tariff.carrier === "o2" && (
                  <MobileConnectImage
                    src="/img/heroslider/connect/auszeichnungen-testberichte-connect-netztest-2023.webp"
                    alt={"connect Siegel sehr gut"}
                  />
                )}
              </Flex>
            </StyledFlexbox>
          </Box>
        </Box>
        {/* DynamicStage Start */}
        <Box
          width={DynamicStageConfig.DynamicStageBox}
          position="relative"
          mb={[1, 4, 4, 4, 0]}
          pr={3}
        >
          <Background />
          <Flex alignItems="center" height={[250, 250, 300, 400, 400]}>
            {/* PriceBadge */}
            <Box width={DynamicStageConfig.PriceBadgeBox}>
              <StyledPriceDisplayBackground>
                <StyledTariffBadge>
                  <TariffBadge tariff={tariff} />
                </StyledTariffBadge>
                <StyledManufacturerLogo>
                  <ManufacturerLogo manufacturer={manufacturer} size="s" />
                </StyledManufacturerLogo>
                {viewport.lessThan.sm && (
                  <>
                    {/* eslint-disable-next-line */}
                    <PriceInfoText>einm.</PriceInfoText>
                  </>
                )}
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  flexWrap="noWrap"
                  width="100%"
                >
                  {viewport.greaterThan.sm && (
                    <>
                      {/* eslint-disable-next-line */}
                      <PriceInfoText>einm.</PriceInfoText>
                    </>
                  )}
                  <StyledPrice price={price} size="s" />
                </Flex>
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  flexWrap="noWrap"
                  width="100%"
                >
                  <StyledText variant="s" marginRight={4} highlighted>
                    <b>
                      {viewport.lessThan.sm ? (
                        <>
                          {/* eslint-disable-next-line */}
                          <>mtl. </>
                        </>
                      ) : (
                        <>
                          {/* eslint-disable-next-line */}
                          <>monatlich </>
                        </>
                      )}
                      <Price size="s" price={monthlyPrice} preventStyling />
                    </b>
                  </StyledText>
                </Flex>
              </StyledPriceDisplayBackground>
            </Box>
            {/* Product Image */}
            <Box width={DynamicStageConfigHandler(manufacturer)} p={0} pl={0}>
              <DynamicStageImage src={image} alt={`${manufacturer} ${name}`} />
            </Box>
            {accessories.length > 0 && (
              <Box width={DynamicStageConfig.AccessoryImage} pl={0}>
                <StyledPlus />
                {accessories.map((accessory) => (
                  <DynamicStageImage
                    detailView="zoom"
                    key={accessory.name}
                    length={accessories.length}
                    src={accessory.image}
                    alt={`${accessory.manufacturer} ${accessory.name}`}
                  />
                ))}
              </Box>
            )}
          </Flex>
        </Box>
      </Flex>
    </StyledTileAnchor>
  );
};

FlexHero.propTypes = {
  accessories: PropTypes.arrayOf(hardwarePropTypes),
  eyeCatchers: eyecatcherPropTypes,
  hardware: hardwarePropTypes.isRequired,
  tariff: tariffPropTypes.isRequired,
  headline: PropTypes.string.isRequired,
  subHeadline: PropTypes.string,
  idx: PropTypes.number,
  qaName: PropTypes.string,
};

FlexHero.defaultProps = {
  subHeadline: null,
  accessories: [],
  eyeCatchers: [],
  idx: 0,
  qaName: undefined,
};

export default FlexHero;
