export const hashString = (str, prefixStrs) => {
  const preparedStr =
    !str && str !== 0 ? Math.random().toString(36).substring(12) : `${str}`;

  let hash = 0;
  let i;
  let chr;
  let len;

  for (i = 0, len = preparedStr.length; i < len; i += 1) {
    chr = preparedStr.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hash = (hash << 5) - hash + chr;
    // eslint-disable-next-line no-bitwise
    hash |= 0; // Convert to 32bit integer
  }

  return [
    prefixStrs.length ? "_".concat(prefixStrs.join("_")) : "",
    `${hash}`,
  ].join(); // Cast hash to string and add prefix
};

export default hashString;
