import React from "react";

import { useViewport } from "@core/theme";
import { Button } from "@mm/ui";

const CartModalItemBtn = ({ onClick }) => {
  const {
    greaterThan: { sm },
  } = useViewport();

  return (
    <Button color="secondary" onClick={onClick}>
      {sm ? "Dieses Produkt bestellen" : "bestellen"}
    </Button>
  );
};

export default CartModalItemBtn;
