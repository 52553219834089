import React from "react";
import styled from "styled-components";

const StyledSection = styled.section`
  margin-bottom: 56px;
`;

const PageSection = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <StyledSection {...props} />
);

export default PageSection;
