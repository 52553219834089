import React from "react";
import PropTypes from "prop-types";

import ContentAdTeaser from "./ContentAdTeaser";
import ImageOnlyAdTeaser from "./ImageOnlyAdTeaser";

const teaser = {
  ContentTeaser: ContentAdTeaser,
  ImageOnlyTeaser: ImageOnlyAdTeaser,
  ImageOnlyAdTeaser,
  ContentAdTeaser,
};
const AdTeaser = ({ type, ...props }) => {
  if (Object.prototype.hasOwnProperty.call(teaser, type)) {
    return React.createElement(teaser[type], props);
  }
  return null;
};

AdTeaser.propTypes = {
  type: PropTypes.oneOf([
    "ContentTeaser",
    "ImageOnlyTeaser",
    "ImageOnlyAdTeaser",
    "ContentAdTeaser",
  ]).isRequired,
};

export default AdTeaser;
