import React from "react";

import styled from "styled-components";

import { useViewport } from "@core/theme";

import { Box, Flex, Text, Row, Col } from "@mm/ui";

import dataLayer from "helper/dataLayer";

import { useOfferConfigurator } from "features/Offer";

import {
  Bulletlist,
  Eyecatchers,
  OfferHeadline,
  TileBox,
  SmallInlineLink,
  TariffBadge,
  Price,
  GeneralModalShell,
} from "components/atoms";

import {
  Loading,
  DeliveryInfoModal,
  TariffDetailsLinks,
} from "components/molecules";

import { labels } from "components/molecules/DetailsCoupon";

import TariffPriceSection from "./TariffPriceSection";

export const BoxWithBorderTop = styled(Box)`
  border-top: 1px solid ${({ theme }) => theme.colors.shade.xs};
`;

const RelativeBox = styled(Box)`
  position: relative;
`;

const MiniCouponPrice = styled(Price).attrs({ size: "tiny" })`
  display: block;
  position: absolute;
  top: 39%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 19px;
`;

const TariffBadgeCol = styled(Col)`
  height: 250px;
`;

const TariffHalfWidth = () => {
  const viewport = useViewport();

  const { state } = useOfferConfigurator();

  if (!state.rootInfo) return <Loading variant="teaser" />;

  const priceCheckoutSectionTextVariant = viewport.lessThan.md
    ? "copy"
    : "smallCopy";

  const priceSectionMarginTop = viewport.lessThan.md ? 6 : 0;

  const {
    tariff,
    coupon,
    eyeCatchers,
    accessories,
    tariff: { bullets, carrier, name, deliveryTime, deliveryPrice },
  } = state.rootInfo;

  const deliveryInfo = {
    deliveryTime,
    deliveryPrice,
  };

  return (
    <TileBox>
      {eyeCatchers && (
        <Eyecatchers eyecatchers={eyeCatchers} variant="offerTeaser" />
      )}

      <Box mb={6}>
        <OfferHeadline
          additionalData={accessories || []}
          text={name}
          prefix={carrier}
          lines={2}
        />
        <Row>
          <TariffBadgeCol md={12}>
            <TariffBadge tariff={tariff} sim />
          </TariffBadgeCol>
          <Col md={12}>
            <Bulletlist
              variant="checkmark"
              list={bullets.slice(0, 3)} // maximal 3 bullets
              size="m"
            />
            <Box mt={16}>
              <TariffDetailsLinks
                tariff={tariff}
                analytics={{
                  context: "TariffOfferListItem",
                }}
              />
            </Box>
          </Col>
        </Row>
      </Box>
      <BoxWithBorderTop pt={6}>
        {coupon && (
          <Row px={3} mb={24}>
            <Flex alignItems="center">
              <RelativeBox height="100%" mr={4}>
                <img
                  src={`${window.CDN_URL}/img/mm-neu/geschenk-coupon.png`}
                  height="50px"
                  alt={"Geschenk Coupon"}
                />
                <MiniCouponPrice price={coupon.value} variant="secondary" />
              </RelativeBox>
              <Text>
                Geschenk-Coupon{" "}
                <Price
                  price={coupon.value}
                  size="s"
                  variant="primary"
                  preventStyling
                />
              </Text>
            </Flex>
          </Row>
        )}
        <Row mt={18}>
          <Col md={12}>
            {coupon && (
              <>
                <Bulletlist list={labels.terms} />
                <GeneralModalShell
                  opener={
                    <SmallInlineLink
                      href="#"
                      small
                      onClick={() => {
                        dataLayer({
                          eventAction: "click",
                          eventCategory: "Link",
                          eventLabel: "Coupondetails",
                        });
                      }}
                    >
                      {labels.detailsToggle}
                    </SmallInlineLink>
                  }
                >
                  {labels.details}
                </GeneralModalShell>
              </>
            )}
          </Col>
          <Col md={12}>
            <Box mt={priceSectionMarginTop}>
              <TariffPriceSection
                priceTextVariant={priceCheckoutSectionTextVariant}
                shippingTextVariant="s"
                deliveryInfo={deliveryInfo}
                priceShippingInfo={
                  <DeliveryInfoModal
                    deliveryPrice={deliveryInfo.deliveryPrice}
                  />
                }
              />
            </Box>
          </Col>
        </Row>
      </BoxWithBorderTop>
    </TileBox>
  );
};

export default TariffHalfWidth;
