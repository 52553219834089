import React, { useReducer, useEffect, createContext, useContext } from "react";

import PropTypes from "prop-types";

import { noop, orderBy, head } from "lodash";

import {
  getSelectedExtendedVariant,
  findVariantById,
} from "utils/variantUtils";

import { extendTariffDetails } from "utils/tariffUtils";

import changeURLParams, {
  buildDefaultAccessoryVariants,
  calculateStock,
  sanitize,
} from "./utils.js";

/* Reducer Actions */
/**
 * Merge new values in the configurator with old ones
 * @private
 * @constant
 * @type {string}
 */
export const SYNC = "SYNC";

/**
 * Set active variant
 * @private
 * @constant
 * @type {string}
 */
export const SET_ACTIVE_VARIANT = "SET_ACTIVE_VARIANT";

/**
 * Set active tariff
 * @private
 * @constant
 * @type {string}
 */
export const SET_ACTIVE_TARIFF = "SET_ACTIVE_TARIFF";

/**
 * Set active smartphone
 * @private
 * @constant
 * @type {string}
 */
export const SET_ACTIVE_SMARTPHONE = "SET_ACTIVE_SMARTPHONE";

/**
 * Set active accessory variant
 * @private
 * @constant
 * @type {string}
 */
export const SET_ACTIVE_ACCESSORY_VARIANT = "SET_ACTIVE_ACCESSORY_VARIANT";

/**
 * Select coupon or hardware (on tariff details page)
 * @private
 * @constant
 * @type {string}
 */
export const SET_SELECTED_ACCESSORY_TYPE = "SET_SELECTED_ACCESSORY_TYPE";

export const COUPON = "COUPON";
export const HARDWARE = "HARDWARE";
export const SIMONLY = "SIMONLY";

export const getExtendedAccessories = (rootInfo) =>
  rootInfo?.accessories?.map((acc) => ({
    ...acc,
    image: rootInfo.accessories[0].extendedVariants[0].variant.images,
  }));

export const reducer = (state, action) => {
  switch (action.type) {
    case SYNC: {
      const { extendedVariants: variants, rootInfo } = action.payload;
      if (!rootInfo) return state;
      const {
        isTariff,
        activeVariantId: activeId,
        selectedAccessoryType,
      } = state;

      const { hardware } = rootInfo;

      let extend = {};

      if (variants && hardware) {
        const activeExtendedVariant = activeId
          ? findVariantById(variants, activeId)
          : getSelectedExtendedVariant(variants);

        const { variant, tariff } = activeExtendedVariant;
        extend = {
          // TODO drop all activeVariantId and use activeVariantIds
          activeVariantId: variant.ebootisId,
          activeTariff: extendTariffDetails(tariff),
          extendedVariant: activeExtendedVariant || variants?.[0],
        };
      } else {
        extend = {
          activeTariff: extendTariffDetails(rootInfo.tariff),
        };
      }

      const activeAccessoryVariantIds = buildDefaultAccessoryVariants(rootInfo);

      const { coupon, hasBookableHardware } = rootInfo || {};
      const hasCoupon = coupon > 0;
      const { activeVariantId } = extend;

      // Add image field to accessories
      const accessories = getExtendedAccessories(rootInfo);

      const stock = calculateStock(
        isTariff,
        selectedAccessoryType,
        rootInfo,
        variants,
        activeVariantId,
        activeAccessoryVariantIds
      );

      // Trigger the isSoldOut Banner, Button get disabled in PriceSection via Stock.
      const isSoldOut = stock <= 0;

      let accessoryType = HARDWARE;
      if (selectedAccessoryType !== HARDWARE && hasCoupon) {
        accessoryType = COUPON;
      } else if (hasBookableHardware === false) {
        accessoryType = SIMONLY;
      }

      return {
        ...state,
        ...extend,
        extendedVariants: variants,
        isSoldOut,
        activeAccessoryVariantIds,
        stock,
        rootInfo,
        hasCoupon,
        selectedAccessoryType: accessoryType,
        accessories,
      };
    }
    case SET_ACTIVE_VARIANT: {
      /**
       * DO ONLY PERFORM CHANGES TO THE HARDWARE!!
       */
      const {
        extendedVariants: variants,
        rootInfo,
        activeAccessoryVariantIds,
        isTariff,
        selectedAccessoryType,
        isProductDetailInterface,
      } = state;

      const { activeVariantId } = action.payload;

      const extendedVariant = findVariantById(variants, activeVariantId);

      const stock = calculateStock(
        isTariff,
        selectedAccessoryType,
        rootInfo,
        variants,
        activeVariantId,
        activeAccessoryVariantIds
      );

      const isSoldOut = stock <= 0;

      const {
        variant: {
          storage,
          color: { name },
        },
      } = extendedVariant;

      /**
       * If a color is sold out on the new storage, we are switching to an available color
       * We are performing this step only on a PDP with isProductDetailInterface
       */
      if (stock === 0 && isProductDetailInterface) {
        /**
         * Filter variants by storage
         */
        const filteredVariantsByStorage = variants.filter((item) => {
          if (item.variant.storage === storage) {
            return item;
          }
          return null;
        });

        /**
         * Order array by stock and return the item with the highest stock
         */
        const orderedVariant = head(
          orderBy(filteredVariantsByStorage, ["stock"], ["desc"])
        );

        /**
         * Get the new url parameters
         */
        const {
          variant: {
            storage: variantColorStorage,
            color: { name: variantColorName },
          },
        } = orderedVariant;

        changeURLParams([
          {
            param: "farbe",
            value: sanitize(variantColorName),
          },
          {
            param: "speicher",
            value: variantColorStorage,
          },
        ]);

        const stock = calculateStock(
          isTariff,
          selectedAccessoryType,
          rootInfo,
          variants,
          activeVariantId,
          activeAccessoryVariantIds
        );

        const isSoldOut = stock <= 0;

        return {
          ...state,
          extendedVariant: orderedVariant,
          activeVariantId: orderedVariant.variant.ebootisId,
          stock,
          isSoldOut,
        };
      }

      if (isProductDetailInterface) {
        changeURLParams([
          {
            param: "farbe",
            value: sanitize(name),
          },
          {
            param: "speicher",
            value: storage,
          },
        ]);
      }

      return {
        ...state,
        extendedVariant,
        activeVariantId,
        stock,
        isSoldOut,
      };
    }
    case SET_ACTIVE_ACCESSORY_VARIANT: {
      const {
        activeAccessoryVariantIds,
        rootInfo,
        extendedVariants: variants,
        activeVariantId,
        isTariff,
        selectedAccessoryType,
      } = state;

      const { activeAccessoryVariantId, index } = action.payload;

      if (!activeAccessoryVariantId) return state;

      const newActiveAccessoryVariantIds = {
        ...activeAccessoryVariantIds,
        [index]: {
          hardwareId: activeAccessoryVariantIds[index].hardwareId,
          ebootisId: activeAccessoryVariantId,
        },
      };

      // Add image field to accessories
      const accessories = getExtendedAccessories(rootInfo);

      const stock = calculateStock(
        isTariff,
        selectedAccessoryType,
        rootInfo,
        variants,
        activeVariantId,
        newActiveAccessoryVariantIds
      );

      // Trigger the isSoldOut Banner, Button get disabled in PriceSection via Stock.
      const isSoldOut = stock <= 0;

      return {
        ...state,
        activeAccessoryVariantIds: newActiveAccessoryVariantIds,
        stock,
        accessories,
        isSoldOut,
      };
    }
    case SET_ACTIVE_TARIFF: {
      /**
       * DO ONLY PERFORM CHANGES TO THE TARIFF!!
       */
      const {
        payload: {
          calculateExtendTariff: { tariff },
        },
      } = action;

      const { urlName, url, carrier } = tariff;

      const { isProductDetailInterface } = state;

      if (isProductDetailInterface) {
        changeURLParams([
          {
            param: "tarif",
            value: sanitize(urlName || url),
          },
          {
            param: "carrier",
            value: sanitize(carrier),
          },
        ]);
      }

      return {
        ...state,
        activeTariff: tariff,
      };
    }
    case SET_SELECTED_ACCESSORY_TYPE: {
      const {
        isTariff,
        rootInfo,
        extendedVariants,
        activeVariantId,
        activeAccessoryVariantIds,
      } = state;
      const { selectedAccessoryType } = action.payload;

      const stock = calculateStock(
        isTariff,
        selectedAccessoryType,
        rootInfo,
        extendedVariants,
        activeVariantId,
        activeAccessoryVariantIds
      );

      return {
        ...state,
        selectedAccessoryType,
        stock,
      };
    }
    case SET_ACTIVE_SMARTPHONE: {
      const {
        payload: {
          manufacturer,
          hardwareName,
          name: legacyName,
          variants,
          defaultVariant,
        },
      } = action;
      const {
        activeAccessoryVariantIds,
        extendedVariants,
        isTariff,
        rootInfo,
        isProductDetailInterface,
        refetch,
      } = state;

      // FIXME remove me as soon as backend provided a consolidated api
      const name = hardwareName || legacyName;

      const {
        color: { name: colorName },
        storageSize,
      } = variants[defaultVariant];

      if (isProductDetailInterface) {
        changeURLParams([
          {
            param: "hardware",
            value: sanitize(`${manufacturer}-${name}`),
          },
          {
            param: "speicher",
            value: storageSize,
          },
          {
            param: "farbe",
            value: sanitize(colorName),
          },
        ]);
        // it fires a rerender, to get the extenderVariant from the parent component
        refetch();
      }

      // find the extendedVariant corresponding to the payload defaultVariant
      const extendedVariant = findVariantById(extendedVariants, defaultVariant);

      const activeVariantId =
        extendedVariant?.variant.ebootisId || defaultVariant;

      const selectedAccessoryType = HARDWARE;

      const stock = calculateStock(
        isTariff,
        selectedAccessoryType,
        rootInfo,
        extendedVariants,
        activeVariantId,
        activeAccessoryVariantIds
      );
      return {
        ...state,
        selectedAccessoryType,
        activeVariantId,
        extendedVariant,
        stock,
      };
    }
    default:
      throw new Error(`Reducer action not set: ${action.type}`);
  }
};

const ctx = createContext(null);

export const OfferProvider = ({
  queryParams,
  children,
  isTariff: isTariffProp,
  isListingPage,
  isProductDetailInterface,
  rootInfoData,
  extendedVariantsData,
  refetch,
}) => {
  const isTariff = isTariffProp || queryParams?.offerType === "tarife";
  const [state, dispatch] = useReducer(reducer, {
    extendedVariants: extendedVariantsData,
    rootInfo: rootInfoData,
    isTariff,
    offerGroupUrl: queryParams?.offerGroupUrl || rootInfoData?.link,
    isListingPage,
    isProductDetailInterface,
    refetch,
  });

  useEffect(() => {
    dispatch({
      type: SYNC,
      payload: {
        extendedVariants: extendedVariantsData,
        rootInfo: rootInfoData,
      },
    });
  }, [extendedVariantsData, rootInfoData]);

  return <ctx.Provider value={{ state, dispatch }}>{children}</ctx.Provider>;
};

OfferProvider.propTypes = {
  queryParams: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
  isTariff: PropTypes.bool,
  isListingPage: PropTypes.bool,
  isProductDetailInterface: PropTypes.bool,
  extendedVariantsData: PropTypes.arrayOf(PropTypes.shape({})),
  rootInfoData: PropTypes.shape({}),
  refetch: PropTypes.func,
};

OfferProvider.defaultProps = {
  queryParams: null,
  isTariff: false,
  isListingPage: false,
  isProductDetailInterface: false,
  extendedVariantsData: undefined,
  rootInfoData: undefined,
  refetch: noop,
};
export const useOfferConfigurator = () => useContext(ctx);
