import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { compose, space } from "styled-system";

const StyledDiv = styled.div`
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.grey[2]};
  ${(props) => !props.noPadding && "padding: 24px;"}
  ${compose(space)}
`;

const BorderBox = (props) => {
  const { noPadding, children, qaName, className, onClick } = props;
  return (
    <StyledDiv
      data-codeception={qaName}
      className={className}
      onClick={onClick}
      noPadding={noPadding}
      {...props}
    >
      {children}
    </StyledDiv>
  );
};

BorderBox.propTypes = {
  /**
   * disable the default padding of 24px
   */
  noPadding: PropTypes.bool,
  children: PropTypes.node.isRequired,
  qaName: PropTypes.string,
  className: PropTypes.string,
};

BorderBox.defaultProps = {
  noPadding: undefined,
  qaName: undefined,
  className: "",
};

export default BorderBox;
