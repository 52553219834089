import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { variant } from "styled-system";

import { dataLayerPropTypes } from "sharedPropTypes";
import dataLayer from "helper/dataLayer";
import conditionalPropType from "utils/conditionalPropType";

const TileAnchorStyle = styled("a")(
  {
    display: "block",
    color: (props) => props.theme.colors.grey[0],
    textDecoration: "none",
    width: "100%",
    marginBottom: "auto",

    "&:hover": {
      color: (props) => props.theme.colors.grey[0],
    },
  },
  variant({
    variants: {
      tile: {
        width: "100%",
      },
      row: {
        width: "70%",
      },
    },
  })
);

const TileAnchor = ({
  children,
  component,
  parent,
  href,
  dataLayerObj,
  layout,
  className,
  qaName,
}) => (
  <TileAnchorStyle
    variant={layout}
    href={href}
    onClick={() =>
      dataLayer(
        dataLayerObj || {
          eventCategory: parent,
          eventAction: "click",
          eventLabel: `${component}:${href}`,
        }
      )
    }
    className={className}
    data-codeception={qaName}
  >
    {children}
  </TileAnchorStyle>
);

TileAnchor.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string,
  dataLayerObj: dataLayerPropTypes,
  component: PropTypes.string,
  parent: PropTypes.string,
  idx: conditionalPropType(
    (props) => !props.dataLayerObj && typeof props.idx !== "number",
    "'idx' must be set if 'dataLayerObj' isn't set"
  ),
  className: PropTypes.string,
  qaName: PropTypes.string,
};

TileAnchor.defaultProps = {
  dataLayerObj: null,
  href: "#",
  idx: null,
  component: "",
  parent: "",
  className: "",
  qaName: undefined,
};

export default TileAnchor;
