import styled from "styled-components";

export const Component = styled.div`
  position: absolute;
  top: 10%;
  left: 10%;
  width: 80%;
  height: 80%;
  z-index: 1;
  background-image: linear-gradient(
    to bottom right,
    transparent 47%,
    ${({ theme }) => theme.colors.primary[0]} 50%,
    transparent 54%
  );
`;

export default Component;
