import React from "react";
import styled from "styled-components";

import { Text } from "@mm/ui";

import PhoneSvg from "@core/svgs/phone.svg";
import FaqSvg from "@core/svgs/faq.svg";
import ContactSvg from "@core/svgs/contact.svg";

import dataLayer from "helper/dataLayer";
import { viewportPropTypes } from "sharedPropTypes";

import { HOTLINE_NUMBER, HOTLINE_NUMBER_CLICKABLE } from "helper/globals";

import Link from "../../../atoms/Link/index";

const Ul = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const Li = styled.li`
  align-items: center;
  color: ${(props) => props.theme.colors.grey[2]};
  display: flex;
  font-size: 14px;
  line-height: 2;
  margin-bottom: 16px;

  svg g use {
    fill: ${(props) => props.theme.colors.white};
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  margin: 0 0 0 16px;
`;

const A = styled(Link)`
  color: ${(props) => props.theme.colors.grey[2]};
  cursor: pointer;
  text-decoration: none;
  :after {
    color: ${(props) => props.theme.colors.grey[2]};
  }
  :before {
    background-color: ${(props) => props.theme.colors.grey[2]};
  }
`;

const ContactList = ({ viewport }) => (
  <Ul>
    <Li>
      <PhoneSvg width={16} height={16} />
      <TextWrapper>
        {viewport.lessThan.lg ? (
          <Text variant="smallCopy" highlighted>
            Servicehotline
          </Text>
        ) : (
          <Text variant="smallCopy" highlighted>
            Servicehotline: {HOTLINE_NUMBER}
          </Text>
        )}
        {viewport.lessThan.lg ? (
          <span>
            <A
              href={`tel:${HOTLINE_NUMBER_CLICKABLE}`}
              onClick={() =>
                dataLayer({
                  eventCategory: "Footer",
                  eventAction: "click",
                  eventLabel: "Kontakt:Servicehotline:tel:*",
                })
              }
              small
            >
              {HOTLINE_NUMBER} (Mo.-Fr.: 8-20 Uhr, Sa. 10-18 Uhr)
            </A>
          </span>
        ) : (
          <Text variant="smallCopy" highlighted>
            (Mo.-Fr.: 8-20 Uhr, Sa. 10-18 Uhr)
          </Text>
        )}
      </TextWrapper>
    </Li>
    <Li>
      <FaqSvg width={16} height={16} />
      <TextWrapper>
        <Text variant="smallCopy" highlighted>
          Antworten auf alle Fragen
        </Text>
        <A
          href="/faq/"
          onClick={() =>
            dataLayer({
              eventCategory: "Footer",
              eventAction: "click",
              eventLabel: "Kontakt:Service:/faq/",
            })
          }
          small
        >
          Service-Bereich
        </A>
      </TextWrapper>
    </Li>
    <Li>
      <ContactSvg width={16} height={16} />
      <TextWrapper>
        <Text variant="smallCopy" highlighted>
          Schreiben Sie uns
        </Text>
        <A
          href="/kontakt/"
          onClick={() =>
            dataLayer({
              eventCategory: "Footer",
              eventAction: "click",
              eventLabel: "Kontakt:Kontakt:/kontakt/",
            })
          }
          small
        >
          Kontaktformular
        </A>
      </TextWrapper>
    </Li>
  </Ul>
);

ContactList.propTypes = {
  viewport: viewportPropTypes.isRequired,
};

export default ContactList;
