import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import styled from "styled-components";
import { Surface } from "@mm/ui";
import ClearSvg from "@core/svgs/reset-filter.svg";
import dataLayer from "@/helper/dataLayer";
import { useSimOnlyProvider } from "@/features/SimOnly";
import { ACTIONS } from "@/features/SimOnly/context";
import { OrderSettings } from "@/types";
const StyledClearSvg = styled(ClearSvg) `
  position: relative;
  top: 2px;
  transform: scale(0.7);
`;
const SortFilterWrapper = styled("div") `
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const TabsNavigation = styled("div") `
  display: grid;
  grid-template-columns: 1fr;

  .tabsNavigationItem {
    color: #000000;
    font-size: 16px;
    text-align: center;
    padding: 10px 25px;
    border: 0;
    border-bottom: 2px solid #c2c2c2;
    background: white;
    cursor: pointer;

    &.active {
      font-weight: 600;
      border-bottom: 2px solid #df0000;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpointsMap.md.min}px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;
const TabsContent = styled("div") `
  display: none;
  margin: 24px 0 0;

  &.active {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
  }
`;
const FilterPill = styled("button") `
  color: #000000;
  display: block;
  font-size: 18px;
  font-weight: 600;
  padding: 12px 24px;
  background: white;
  border: 1px solid #979797;
  border-radius: 4px;
  cursor: pointer;

  &:hover,
  &.active {
    border: 1px solid #df0000;
  }
  &.fixedWidth {
    min-width: 8.5rem;
  }

  .font-weight-400 {
    font-weight: 400;
    font-size: 14px;
  }
`;
const ActiveFilterSection = styled("div") `
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  margin: 24px 0 0;

  .filterResetButton {
    color: #000000;
    display: block;
    font-size: 16px;
    padding: 12px 24px;
    background: #f4f4f4;
    border: 1px solid #cdcaca;
    border-radius: 4px;
    cursor: pointer;

    &:first-letter {
      text-transform: uppercase;
    }

    &:hover,
    &.active {
      border: 1px solid #df0000;
    }
  }

  .filterDeleteButton {
    color: #000000;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    padding: 5px 0;
    background: transparent;
    border: 1px solid transparent;
    cursor: pointer;

    &__icon-red {
      > .icon-MM_iconfont_ArrowR {
        color: red;
      }
    }
  }
`;
const SimOnlyFilter = () => {
    const { state: { allOffers = [], availableProviders = [], availableContractTerms = [], activeFilter: { award, contractTerm, dataVolume, order, provider, status, }, filterOptions: { volume }, }, dispatch, } = useSimOnlyProvider();
    const [activeTab, setActiveTab] = useState(1);
    const [valueForOrderSelectField, setValueForOrderSelectField] = useState("priceAscending");
    const types = [
        {
            value: true,
            label: "ja",
        },
        {
            value: false,
            label: "Nein",
        },
    ];
    const mapToOrderSetting = (value) => {
        switch (value) {
            case "default":
                return OrderSettings.default;
            case "priceAscending":
                return OrderSettings.priceAscending;
            case "priceDescending":
                return OrderSettings.priceDescending;
            case "dataVolumeAscending":
                return OrderSettings.dataVolumeAscending;
            case "dataVolumeDescending":
                return OrderSettings.dataVolumeDescending;
            default:
                return OrderSettings.priceAscending;
        }
    };
    const mapToStateOrder = (value) => {
        switch (value) {
            case 0:
                return "default";
            case 1:
                return "priceAscending";
            case 2:
                return "priceDescending";
            case 3:
                return "dataVolumeAscending";
            case 4:
                return "dataVolumeDescending";
            default:
                return "priceAscending";
        }
    };
    const changeOrderFilter = (order) => {
        dispatch({
            type: ACTIONS.SET_ORDER_FILTER,
            payload: mapToOrderSetting(order),
        });
    };
    useEffect(() => {
        setValueForOrderSelectField(mapToStateOrder(order));
    }, [order]);
    if (allOffers.length < 1) {
        return null;
    }
    return (_jsxs(_Fragment, { children: [_jsx(Surface, { variant: "narrow", children: _jsxs(SortFilterWrapper, { children: [_jsx("h2", { children: "Alle Tarife" }), _jsxs("div", { children: [_jsx("span", { children: "Sortieren nach |" }), " ", _jsxs("select", { onChange: (e) => changeOrderFilter(e.target.value), value: valueForOrderSelectField, children: [_jsx("option", { value: "priceAscending", children: "Preis aufsteigend" }), _jsx("option", { value: "priceDescending", children: "Preis absteigend" }), _jsx("option", { value: "dataVolumeAscending", children: "GB aufsteigend" }), _jsx("option", { value: "dataVolumeDescending", children: "GB absteigend" })] })] })] }) }, "Alle Tarife"), _jsxs(Surface, { variant: "narrow", shape: "roundedSmall", mt: 6, children: [_jsxs(TabsNavigation, { children: [_jsx("button", { type: "button", className: activeTab === 1
                                    ? "tabsNavigationItem active"
                                    : "tabsNavigationItem inActive", onClick: () => {
                                    setActiveTab(1);
                                    dataLayer({
                                        eventCategory: "Internet:Filter",
                                        eventAction: "ActiveTab",
                                        eventLabel: "Internet:Action:Filter:ActiveTabDownloadSpeed",
                                    });
                                }, "data-qa": "filterdatavolume", children: "Datenvolumen" }), _jsx("button", { type: "button", className: activeTab === 2
                                    ? "tabsNavigationItem active"
                                    : "tabsNavigationItem inActive", onClick: () => {
                                    setActiveTab(2);
                                    dataLayer({
                                        eventCategory: "Internet:Filter",
                                        eventAction: "ActiveTab",
                                        eventLabel: "Internet:Action:Filter:ActiveProvider",
                                    });
                                }, "data-qa": "filterProvider", children: "Netz/Anbieter" }), _jsx("button", { type: "button", className: activeTab === 3
                                    ? "tabsNavigationItem active"
                                    : "tabsNavigationItem inActive", onClick: () => {
                                    setActiveTab(3);
                                    dataLayer({
                                        eventCategory: "Internet:Filter",
                                        eventAction: "ActiveTab",
                                        eventLabel: "Internet:Action:Filter:ActiveTabMonthlyPrice",
                                    });
                                }, "data-qa": "filterTerm", children: "Laufzeit" }), _jsx("button", { type: "button", className: activeTab === 4
                                    ? "tabsNavigationItem active"
                                    : "tabsNavigationItem inActive", onClick: () => {
                                    setActiveTab(4);
                                    dataLayer({
                                        eventCategory: "Internet:Filter",
                                        eventAction: "ActiveTab",
                                        eventLabel: "Internet:Action:Filter:ActiveTabTechnology",
                                    });
                                }, "data-qa": "filterBonus", children: "Pr\u00E4mie" })] }), _jsxs(TabsContent, { className: activeTab === 1 ? "active" : "inActive", children: [_jsx(FilterPill, { className: dataVolume.length === 0 ? "active" : "inActive", "data-qa": "filter-default", type: "button", onClick: () => dispatch({
                                    type: ACTIONS.CLEAR_DATA_VOLUME_FILTER,
                                }), children: "Alle" }), volume &&
                                volume.map(({ volume, price, label }) => (_jsxs(FilterPill, { className: `${dataVolume.includes(volume) ? "active" : "inActive"} fixedWidth`, type: "button", "data-qa": `speed-${volume}`, onClick: () => {
                                        dispatch({
                                            type: ACTIONS.CLEAR_DATA_VOLUME_FILTER,
                                        });
                                        dispatch({
                                            type: ACTIONS.SET_DATA_VOLUME_FILTER,
                                            payload: volume,
                                        });
                                        dataLayer({
                                            eventCategory: "SimOnly:Filter",
                                            eventAction: "SetVolume",
                                            eventLabel: "SimOnly:Action:Filter:SetVolume",
                                        });
                                    }, children: [label, price && (_jsxs(_Fragment, { children: [_jsx("br", {}), _jsxs("span", { className: "font-weight-400", children: ["ab ", price, "\u20AC"] })] }))] }, `speed-${volume}`)))] }), _jsxs(TabsContent, { className: activeTab === 2 ? "active" : "inActive", children: [_jsx(FilterPill, { className: provider.length === 0 ? "active" : "inActive", "data-qa": "filter-default", type: "button", onClick: () => dispatch({
                                    type: ACTIONS.CLEAR_PROVIDER_FILTER,
                                }), children: "Alle" }), availableProviders.map((providerName) => {
                                const displayProviderName = providerName === "Telefonica" ? "Telefónica" : providerName;
                                return (_jsx(FilterPill, { className: provider.includes(providerName) ? "active" : "inActive", type: "button", "data-qa": `filter-${providerName}`, onClick: () => {
                                        dispatch({
                                            type: ACTIONS.SET_PROVIDER_FILTER,
                                            payload: providerName,
                                        });
                                        dataLayer({
                                            eventCategory: "Internet:Filter",
                                            eventAction: "SetProvider",
                                            eventLabel: "Internet:Action:Filter:SetProvider",
                                        });
                                    }, children: displayProviderName }, `provider-${providerName}`));
                            })] }), _jsxs(TabsContent, { className: activeTab === 3 ? "active" : "inActive", children: [_jsx(FilterPill, { className: contractTerm.length === 0 ? "active" : "inActive", "data-qa": "filter-default", type: "button", onClick: () => dispatch({
                                    type: ACTIONS.CLEAR_CONTRACT_TERM_FILTER,
                                }), children: "Alle" }), availableContractTerms.map((contract) => (_jsx(FilterPill, { className: contractTerm.includes(contract) ? "active" : "inActive", type: "button", "data-qa": `filter-${contract}`, onClick: () => {
                                    dispatch({
                                        type: ACTIONS.SET_CONTRACT_TERM_FILTER,
                                        payload: contract,
                                    });
                                    dataLayer({
                                        eventCategory: "Internet:Filter",
                                        eventAction: "SetProvider",
                                        eventLabel: "Internet:Action:Filter:SetProvider",
                                    });
                                }, children: contract <= 1 ? `${contract} Monat` : `${contract} Monate` }, `provider-${contract}`)))] }), _jsxs(TabsContent, { className: activeTab === 4 ? "active" : "inActive", children: [_jsx(FilterPill, { className: award === undefined ? "active" : "inActive", "data-qa": "filter-default", type: "button", onClick: () => dispatch({
                                    type: ACTIONS.CLEAR_AWARD_FILTER,
                                }), children: "Alle" }), types && (_jsxs(_Fragment, { children: [_jsx(FilterPill, { className: award == true ? "active" : "inActive", type: "button", "data-qa": "award-ja", onClick: () => {
                                            dispatch({
                                                type: ACTIONS.SET_AWARD_FILTER,
                                                payload: true,
                                            });
                                            dataLayer({
                                                eventCategory: "Internet:Filter",
                                                eventAction: "SetConnectionType",
                                                eventLabel: "Internet:Action:Filter:SetConnectionType",
                                            });
                                        }, children: "Ja" }, "award-ja"), _jsx(FilterPill, { className: award == false ? "active" : "inActive", type: "button", "data-qa": "award-nein", onClick: () => {
                                            dispatch({
                                                type: ACTIONS.SET_AWARD_FILTER,
                                                payload: false,
                                            });
                                            dataLayer({
                                                eventCategory: "Internet:Filter",
                                                eventAction: "SetConnectionType",
                                                eventLabel: "Internet:Action:Filter:SetConnectionType",
                                            });
                                        }, children: "Nein" }, "award-nein")] }))] }), status && (_jsxs(ActiveFilterSection, { children: [dataVolume.map((volume) => (_jsxs("button", { type: "button", className: "filterResetButton", onClick: () => {
                                    dispatch({
                                        type: ACTIONS.RESET_DATA_VOLUME_FILTER,
                                        payload: volume,
                                    });
                                    dataLayer({
                                        eventCategory: "SimOnly:Filter",
                                        eventAction: "ResetVolume",
                                        eventLabel: "SimOnly:Action:Filter:ResetVolume",
                                    });
                                }, children: [volume == 9999 ? "unbegrenzt" : `${volume}GB`, " ", _jsx(StyledClearSvg, {})] }, `data-${volume}`))), provider.map((item) => {
                                const displayItem = item === "Telefonica" ? "Telefónica" : item;
                                return (_jsxs("button", { type: "button", className: "filterResetButton", onClick: () => {
                                        dispatch({
                                            type: ACTIONS.RESET_PROVIDER_FILTER,
                                            payload: item,
                                        });
                                        dataLayer({
                                            eventCategory: "Internet:Filter",
                                            eventAction: "ResetProvider",
                                            eventLabel: "Internet:Action:Filter:ResetProvider",
                                        });
                                    }, children: [displayItem, " ", _jsx(StyledClearSvg, {})] }, `provider-${item}`));
                            }), contractTerm.map((item) => (_jsxs("button", { type: "button", className: "filterResetButton", onClick: () => {
                                    dispatch({
                                        type: ACTIONS.RESET_CONTRACT_TERM_FILTER,
                                        payload: item,
                                    });
                                    dataLayer({
                                        eventCategory: "Internet:Filter",
                                        eventAction: "ResetContractTerm",
                                        eventLabel: "Internet:Action:Filter:ResetContractTerm",
                                    });
                                }, children: [item >= 1 ? `${item} Monate` : `${item} Monat`, " ", _jsx(StyledClearSvg, {})] }, `contractTerm-${item}`))), award == true && (_jsxs("button", { type: "button", className: "filterResetButton", onClick: () => {
                                    dispatch({
                                        type: ACTIONS.RESET_AWARD_FILTER,
                                    });
                                    dataLayer({
                                        eventCategory: "Internet:Filter",
                                        eventAction: "ResetProvider",
                                        eventLabel: "Internet:Action:Filter:ResetProvider",
                                    });
                                }, children: [`${award ? "Ja" : "Nein"}`, " ", _jsx(StyledClearSvg, {})] }, `provider-${award ? "ja" : "nein"}`)), award == false && (_jsxs("button", { type: "button", className: "filterResetButton", onClick: () => {
                                    dispatch({
                                        type: ACTIONS.RESET_AWARD_FILTER,
                                    });
                                    dataLayer({
                                        eventCategory: "Internet:Filter",
                                        eventAction: "ResetProvider",
                                        eventLabel: "Internet:Action:Filter:ResetProvider",
                                    });
                                }, children: [`${award ? "Ja" : "Nein"}`, " ", _jsx(StyledClearSvg, {})] }, `provider-${award ? "ja" : "nein"}`)), status && (_jsxs("button", { type: "button", className: "filterDeleteButton filterDeleteButton__icon-red", onClick: () => {
                                    dispatch({
                                        type: ACTIONS.RESET_ALL_FILTER,
                                    });
                                    dataLayer({
                                        eventCategory: "Internet:Filter",
                                        eventAction: "ResetAllFilter",
                                        eventLabel: "Internet:Action:Filter:ResetAllFilter",
                                    });
                                }, children: [_jsx("i", { className: "icon-MM_iconfont_ArrowR" }), " Auswahl zur\u00FCcksetzen"] }))] }))] })] }));
};
export default SimOnlyFilter;
