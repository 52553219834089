import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useViewport } from "@core/theme";

import { Image } from "@mm/ui";

import Copy from "../Copy";

const Container = styled.div`
  display: grid;
  position: relative;
  grid-template-columns: 1fr;
  gap: 24px;

  .image {
    border-radius: 4px;
    width: 100%;
    height: auto;
  }

  @media (min-width: ${({ theme }) => theme.breakpointsMap.md.min}px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const CopyImg = ({
  title,
  subtitle,
  content,
  fatheadline,
  image,
  imgPosition,
}) => {
  const viewport = useViewport();
  return (
    <Container>
      {viewport.greaterThan.xs && imgPosition === "rechts" ? (
        <>
          <Copy
            title={title}
            subtitle={subtitle}
            content={content}
            fatheadline={fatheadline}
            imgPosition={imgPosition}
          />
          <Image src={image} alt={title} className="image" />
        </>
      ) : (
        <>
          <Image src={image} alt={title} className="image" />
          <Copy
            title={title}
            subtitle={subtitle}
            content={content}
            fatheadline={fatheadline}
            imgPosition={imgPosition}
          />
        </>
      )}
    </Container>
  );
};

CopyImg.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  content: PropTypes.node,
  fatheadline: PropTypes.bool,
  image: PropTypes.string,
  imgPosition: PropTypes.oneOf(["links", "rechts"]),
};

CopyImg.defaultProps = {
  title: "",
  subtitle: "",
  content: "",
  fatheadline: false,
  image: "",
  imgPosition: "",
};

export default CopyImg;
