import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const StatusContainer = styled.ul`
  list-style: none;
  width: 100%;
  padding: 0;
  margin: 0;

  &::after {
    content: ".";
    clear: both;
    display: block;
    visibility: hidden;
    height: 0px;
  }
`;

const StatusText = styled.li`
  position: relative;
  float: ${({ align }) => align};
  font-weight: bold;

  --length: 0.65em;
  &::before {
    content: "";
    background-color: ${({ color }) => color};
    display: inline-block;
    border-radius: var(--length);
    width: var(--length);
    height: var(--length);
    font-weight: bold;
    margin-right: ${({ theme }) => theme.space.xs}px;
  }
`;

const StatusDotText = ({ children, color, align }) => (
  <StatusContainer>
    <StatusText color={color} align={align}>
      {children}
    </StatusText>
  </StatusContainer>
);

StatusDotText.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string.isRequired,
  align: PropTypes.oneOf(["left", "right"]),
};

StatusDotText.defaultProps = {
  align: "left",
};

export default StatusDotText;
