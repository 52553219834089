import React from "react";

import { ThemeProvider } from "@core/theme";

import { Surface } from "@mm/ui";

import { StageSlider } from "components/organisms";

const WithThemeProvider = (props) => (
  <ThemeProvider>
    <Surface variant="narrow">
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <StageSlider {...props} />
    </Surface>
  </ThemeProvider>
);

export default WithThemeProvider;
