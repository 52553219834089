import { getServiceProviderLabel } from "@/types";
import { ACTIONS as ModalActions } from "@/utils/context/ModalContext";
import dataLayer from "@/helper/dataLayer";
export const config = {
    tariffHeadline: "Dein Tarif",
    contractPartner: (tariff) => tariff?.serviceProviderLabel
        ? tariff.serviceProviderLabel
        : tariff?.serviceProvider
            ? getServiceProviderLabel(tariff.serviceProvider)
            : "",
    contractPartnerModalContent: (tariff) => tariff?.info,
    showConnectionFee: true,
    hardwareHeadline: "Dein Router",
    showTotalPrice: true,
    metaLinks: (dispatch) => [
        {
            text: "Tarifdetails",
            onClick: (details) => {
                dispatch({
                    type: ModalActions.SET_MODAL,
                    payload: {
                        details,
                        title: "Tarifdetails",
                        type: "tariffDetails",
                    },
                });
                dataLayer({
                    event: "gaEvent",
                    eventCategory: "Details",
                    eventAction: "click",
                    eventLabel: "Summary:Tarifdetails",
                });
            },
        },
        {
            onClick: () => {
                dataLayer({
                    event: "gaEvent",
                    eventCategory: "Details",
                    eventAction: "click",
                    eventLabel: "Summary:PIB",
                });
            },
            text: "Produktinformationsblatt",
            target: "_blank",
            urlKey: "pibUrl",
        },
    ],
};
