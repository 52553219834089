import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { Box } from "@mm/ui";

const PriceRowBox = styled.div`
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const PriceBox = ({
  monthlyPriceRow,
  oncePriceRow,
  cartBtn,
  devicesHint,
  shippingHint,
}) => (
  <PriceRowBox>
    {oncePriceRow}
    <Box mt="2" minHeight="38px">
      {monthlyPriceRow}
    </Box>
    {cartBtn}
    {devicesHint && <Box ml="auto">{devicesHint}</Box>}
    {shippingHint && <Box textAlign="right">{shippingHint}</Box>}
  </PriceRowBox>
);

PriceBox.propTypes = {
  monthlyPriceRow: PropTypes.node,
  oncePriceRow: PropTypes.node,
  cartBtn: PropTypes.node,
  devicesHint: PropTypes.node,
  shippingHint: PropTypes.node,
};

// TODO: Cleanup, lol?
PriceBox.defaultProps = {
  monthlyPriceRow: <></>,
  oncePriceRow: <></>,
  cartBtn: <></>,
  devicesHint: <></>,
  shippingHint: <></>,
};

export default PriceBox;
