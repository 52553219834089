import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ACTIONS as ModalActions, useModal, } from "@/utils/context/ModalContext";
import { Headline } from "@mm/ui";
import { DetailsAccordion, Modal } from "@/components/atoms";
export const TariffDetailsModal = ({ details, title, }) => {
    const { dispatch } = useModal();
    return (_jsxs(Modal, { onClose: () => dispatch({
            type: ModalActions.CLEAR_MODAL,
        }), children: [_jsx(Headline, { variant: "h2", children: title }), _jsx(DetailsAccordion, { details: details, autoWidth: true })] }));
};
