import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { space, grid } from "styled-system";

const Grid = styled.div(
  {
    display: "grid",
    boxSizing: "border-box",
  },
  space,
  grid
);

const GridComponent = forwardRef(
  (
    // ...rest contains all (styling) props we can pass from outside, like textAlign="center"
    { children, ...rest },
    ref
  ) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Grid ref={ref} {...rest}>
      {children}
    </Grid>
  )
);

GridComponent.propTypes = {
  className: PropTypes.string,
};

GridComponent.defaultProps = {
  className: null,
};

GridComponent.displayName = "GridComponent";

export default GridComponent;
