import {
  findVariantById,
  getActiveAccessoryVariantStock,
} from "./variantUtils";

export const getStock = (
  rootInfo,
  extendedVariants,
  activeVariantId,
  activeAccessoryVariantIds
) => {
  const activeExtendedVariant = findVariantById(
    extendedVariants,
    activeVariantId
  );

  if (!activeExtendedVariant) {
    return undefined;
  }

  const accessoryStock = getActiveAccessoryVariantStock(
    rootInfo?.accessories,
    activeAccessoryVariantIds
  );

  return Math.min(accessoryStock, activeExtendedVariant.stock);
};

export const getUniqueItemWithStorageId = (item) => {
  const defaultVariant = item.defaultVariant
    ? item.variants[item.defaultVariant]
    : null;

  return `${item.id || item._id}${
    defaultVariant && (defaultVariant.storageSize || defaultVariant.storage)
      ? `_${defaultVariant.storageSize || defaultVariant.storage}GB`
      : ""
  }`;
};
