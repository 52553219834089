import React from "react";
import styled from "styled-components";
import { variant } from "styled-system";
import {
  Surface,
  HeadlineSourceSansPro,
  Text,
  Flex,
  Box,
  Button,
} from "@mm/ui";

import NewsletterSvg from "@core/svgs/newsletter.svg";
import FeedbackSvg from "@core/svgs/feedback.svg";

import { AlternativeOfferTeaser } from "components/molecules";

import { useInternetProvider } from "features/Internet";

import dataLayer from "helper/dataLayer";

const GridWrapper = styled("div")`
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
  margin-bottom: 60px;

  @media (min-width: ${({ theme }) => theme.breakpointsMap.md.min}px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  ${variant({
    variants: {
      small: {
        maxWidth: "900px",
        margin: "0 auto 60px",
      },
    },
  })}
`;

const FlexWrapper = styled("div")`
  display: flex;
  align-items: flex-start;
  gap: 24px;

  .svgIcon {
    flex-shrink: 0;
    width: 40px;
  }
`;

const MobileCenter = styled("div")`
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpointsMap.md.min}px) {
    text-align: left;
  }
`;

const BorderedBox = styled("div")`
  position: relative;
  border-radius: 4px;
  padding: 24px;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.grey[2]};

  .moveOnInfo {
    position: relative;
    padding-left: 40px;
    padding-bottom: 20px;

    &::before {
      content: "";
      display: block;
      position: absolute;
      left: 10px;
      top: 0;
      background-color: black;
      width: 3px;
      height: 100%;
    }

    &::after {
      content: "1";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      font-size: 14px;
      font-weight: 600;
      color: white;
      text-align: center;
      line-height: 1.71;
      border-radius: 50%;
      background-color: black;
      width: 24px;
      height: 24px;
    }

    &:last-child {
      padding-bottom: 0;
      &::before {
        display: none;
      }
      &::after {
        content: "2";
      }
    }
  }
`;

const TextCenter = styled("p")`
  text-align: center;
  margin: 0;
  padding: 0;
`;

const ThankYouSection = () => {
  const {
    state: { tenant },
  } = useInternetProvider();

  const feedBackLinkMediaMarkt =
    "https://feedback.mediamarkt.com/cgi-bin/qwebcorporate.dll";
  const feedBackLinkSaturn =
    "https://feedback.saturn.eu/cgi-bin/qwebcorporate.dll";

  const newsletterLinkMediaMarkt =
    "https://www.mediamarkt.de/de/myaccount/guest-newsletter";

  const newsletterLinkSaturn =
    "https://www.saturn.de/de/myaccount/guest-newsletter";

  return (
    <Surface variant="narrow" shape="roundedSmall" mt={[6, 6, 6, 0, 0]}>
      <Flex flexDirection="column" alignItems="center" alignContent="center">
        <MobileCenter>
          <HeadlineSourceSansPro
            size="28px"
            weight="600"
            type="h1"
            text="Vielen Dank für Deine Bestellung"
            mt={10}
            mb={8}
          />
        </MobileCenter>
        <span>ICON</span>
        <HeadlineSourceSansPro
          size="20px"
          weight="600"
          type="h2"
          text="Bestellung 239571230957"
          mb={4}
        />
        <TextCenter>
          Deine Bestellung ist bei uns eingegangen und wird von uns geprüft.
          <br /> Du erhaltst in Kürze eine Bestellbestätigung per E-Mail.
        </TextCenter>
        <Button
          type="button"
          onClick={() =>
            dataLayer({
              eventCategory: "Shop:ThankYou",
              eventAction: "ThankYouPage",
              eventLabel: "Shop:Action:ThankYouPage:Button",
            })
          }
          mt={8}
          mb={10}
        >
          Weiter einkaufen
        </Button>
      </Flex>

      <GridWrapper mb={10} variant="small">
        <MobileCenter>
          <span>
            <b>Bestellnummer</b>
          </span>
          <br />
          <Text>
            <b>239571230957</b> (Bitte geben Sie diese Nummer bei allen
            Rückfragen an)
          </Text>
        </MobileCenter>
        <MobileCenter>
          <span>
            <b>Artikel</b>
          </span>
          <br />
          <Text>GigaZuhause 500 Kabel mit Vodafone Station</Text>
        </MobileCenter>
        <MobileCenter>
          <span>
            <b>Adresse</b>
          </span>
          <br />
          <Text>
            Toni Weißwieschnee
            <br /> Gasstraße 4c
            <br /> 22761 Hamburg
            <br /> toni.weißwieschnee@skon.de
          </Text>
        </MobileCenter>
      </GridWrapper>

      <BorderedBox>
        <HeadlineSourceSansPro
          size="20px"
          weight="600"
          type="h2"
          text="Und so geht es weiter"
          mb={4}
        />
        <div className="moveOnInfo">
          <span>
            <b>Übermittlung der Bestellung</b>
          </span>
          <br />
          <Text>
            Sie haben ein Angebot von Vodafone Kabel Deutschaldn erworben. Wir
            werden Ihre Bestellung an Vodafone Kabel Deutschland weiterleiten.
            Die erfolgreiche Übermittlung wird Ihnen in einer E-Mail bestätigt.
          </Text>
        </div>
        <div className="moveOnInfo">
          <span>
            <b>Weiterer Verlauf</b>
          </span>
          <br />
          <Text>
            Nach erfolgreicher Übermittlung Ihrer Bestellung ist Vodafone Kabel
            Deutschland Ihr Ansprechpartner zu allen Fragen rund um Ihre
            Bestellung.
          </Text>
        </div>
      </BorderedBox>

      <Box mt={8} />

      <GridWrapper>
        <BorderedBox>
          <HeadlineSourceSansPro
            size="20px"
            weight="600"
            type="h2"
            text="Feedback?"
            mb={4}
          />
          <FlexWrapper>
            <FeedbackSvg className="svgIcon" />
            <Text>
              Du bist mit unserem Bestellprozess zufrieden? Wir versuchen uns
              kontinuierlich zu verbessern. Daher ist uns deine Meinung sehr
              wichtig. Wir freuen uns auf dein{" "}
              <a
                href={
                  tenant === "mediamarkt"
                    ? feedBackLinkMediaMarkt
                    : feedBackLinkSaturn
                }
                target="_blank"
                rel="noreferrer"
              >
                Feedback
              </a>
              !
            </Text>
          </FlexWrapper>
        </BorderedBox>
        <BorderedBox>
          <HeadlineSourceSansPro
            size="20px"
            weight="600"
            type="h2"
            text="Newsletter Anmeldung"
            mb={4}
          />
          <FlexWrapper>
            <NewsletterSvg className="svgIcon" />
            <Text>
              Alle MediaMarkt Angebote, Top-Aktionen & Gewinnspiele - nichts
              mehr verpassen!{" "}
              <a
                href={
                  tenant === "mediamarkt"
                    ? newsletterLinkMediaMarkt
                    : newsletterLinkSaturn
                }
                target="_blank"
                rel="noreferrer"
              >
                Hier anmelden
              </a>
            </Text>
          </FlexWrapper>
        </BorderedBox>
        <AlternativeOfferTeaser checkoutType="internet" />
      </GridWrapper>
    </Surface>
  );
};

export default ThankYouSection;
