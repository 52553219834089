import React from "react";
import PropTypes from "prop-types";

import { ThemeProvider } from "@core/theme";

import { Surface } from "@mm/ui";

import { CategoryBar } from "components/molecules";

const CategoryBarSection = ({ data }) => (
  <ThemeProvider>
    <Surface variant="narrow" pt={[4, 4, 4, 8, 8]} pb={[4, 4, 4, 8, 8]}>
      <CategoryBar data={data} />
    </Surface>
  </ThemeProvider>
);

CategoryBarSection.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      link: PropTypes.string,
      imagePath: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default CategoryBarSection;
