import React from "react";
import styled from "styled-components";
import { useViewport } from "@core/theme";
import { useIntl } from "@core/text";
import { Accordion, Surface, Row, Col } from "@mm/ui";

import PaypalSvg from "@core/svgs/paypal.svg";
import VorkasseSvg from "@core/svgs/vorkasse.svg";
import DhlSvg from "@core/svgs/dhl-logo.svg";

import NewsletterForm from "../NewsletterForm/index";

import IconList from "./IconList/index";
import LinkList from "./LinkList/index";
import ContactList from "./ContactList/index";
import ColumnHeader from "./ColumnHeader/index";

const StyledWrapper = styled.div`
  color: ${({ theme }) => theme.colors.grey[2]};
  a:hover {
    color: ${({ theme }) => theme.colors.grey[2]};
  }
  background-color: ${({ theme }) => theme.colors.grey[4]};
  padding: 32px 0;

  @media (min-width: ${({ theme }) => theme.breakpointsMap.md.min}px) {
    padding-bottom: 48px;
    padding-top: 48px;
  }

  @media (min-width: ${({ theme }) => theme.breakpointsMap.xl.min}px) {
    padding-bottom: 64px;
    padding-top: 64px;
  }
`;

const IconArea = styled.div`
  margin-bottom: 48px;

  @media (min-width: ${({ theme }) => theme.breakpointsMap.xl.min}px) {
    margin-bottom: 64px;
  }
`;

const TopCategories = [
  {
    url: "/smartphones/",
    text: "Handy mit Vertrag",
  },
  {
    url: "/elektronik/",
    text: "Elektronik",
  },
  {
    url: "/tarife/",
    text: "Tarife",
  },
  {
    url: "/neuheiten-und-aktionen/",
    text: "Neuheiten & Aktionen",
  },
  {
    url: "/strom/",
    text: "Strom",
  },
  {
    url: "/gas/",
    text: "Gas",
  },
  {
    url: "/dsl-und-internet/",
    text: "DSL & Internet",
  },
];

const TopProducts = () => [
  {
    url: "/smartphones/apple/iphone-15-pro/",
    text: "Apple iPhone 15 Pro",
  },
  {
    url: "/smartphones/samsung/galaxy-s24/",
    text: "Samsung Galaxy S24 5G",
  },
  {
    url: "/smartphones/google/pixel-8/",
    text: "Google Pixel 8",
  },
  {
    url: "/smartphones/xiaomi/14-5g/",
    text: "Xiaomi 14 5G",
  },
  {
    url: "/smartphones/honor/magic-6-pro/",
    text: "Honor Magic 6 Pro",
  },
];

const Services = (intl) =>
  [
    {
      url: "/bestellstatus/",
      text: "Bestellstatus prüfen",
    },
    /* removed for launch 19.02.2020
  {
    url: '/versandinformationen/',
    text: 'Versandinformationen',
  },
     */
    {
      url: "/faq/",
      text: " Hilfe & Service",
    },
    {
      url: "/kontakt/",
      text: "Kontaktformular",
    },
    {
      url: "/widerruf/",
      text: "Widerrufsformular",
    },
    {
      url: intl.formatMessage({
        id: "url.footer.affiliate",
      }),
      text: "Affiliate Partnerprogramm",
    },
    {
      url: intl.formatMessage({
        id: "url.footer.superSelect.customerCenter",
        defaultMessage: null,
      }),
      text: "SuperSelect-Kundencenter",
    },
    {
      url: "/kuendigung/",
      text: "Verträge hier kündigen",
    },
  ].filter(({ url }) => url.includes("/")); // Filter entries that are not URLs

const Linklists = () => {
  const viewport = useViewport();
  const intl = useIntl();
  return (
    <StyledWrapper>
      <Surface variant="wide">
        <IconArea>
          <Row>
            <Col xs={12} md={6}>
              <IconList
                header="Ganz einfach bezahlen"
                // eslint-disable-next-line react/jsx-key
                icons={[<PaypalSvg />, <VorkasseSvg />]}
              />
            </Col>
            <Col xs={12} md={6}>
              <IconList
                header="Versandpartner"
                icons={[
                  // eslint-disable-next-line react/jsx-key
                  <DhlSvg />,
                ]}
              />
            </Col>
            {viewport.greaterThan.md && (
              <Col xs={12} md={6}>
                <NewsletterForm header={viewport.greaterThan.md} />
              </Col>
            )}
          </Row>
        </IconArea>
        {viewport.lessThan.lg && (
          <Row>
            <Col xs={24}>
              <Accordion
                entryList={[
                  {
                    header: "Top Kategorien",
                    content: (
                      <LinkList header="Top Kategorien" links={TopCategories} />
                    ),
                  },
                  {
                    header: "Top Produkte",
                    content: (
                      <LinkList
                        header="Top Produkte"
                        links={TopProducts(intl)}
                      />
                    ),
                  },
                  {
                    header: "Kontakt",
                    content: <ContactList viewport={viewport} />,
                  },
                  {
                    header: "Services",
                    content: (
                      <LinkList header="Service" links={Services(intl)} />
                    ),
                  },
                  {
                    header: "Newsletter",
                    content: <NewsletterForm header={false} />,
                  },
                ]}
                variant="footer"
              />
            </Col>
          </Row>
        )}
        {viewport.greaterThan.md && (
          <Row mt={[4, 4, 4, 4, 0]}>
            <Col xs={6}>
              <ColumnHeader>Kontakt</ColumnHeader>
              <ContactList viewport={viewport} />
            </Col>
            <Col xs={6}>
              <ColumnHeader>Top Kategorien</ColumnHeader>
              <LinkList header="Top Kategorien" links={TopCategories} />
            </Col>
            <Col xs={6}>
              <ColumnHeader>Top Produkte</ColumnHeader>
              <LinkList header="Top Produkte" links={TopProducts(intl)} />
            </Col>
            <Col xs={6}>
              <ColumnHeader>Service</ColumnHeader>
              <LinkList header="Service" links={Services(intl)} />
            </Col>
          </Row>
        )}
      </Surface>
    </StyledWrapper>
  );
};

Linklists.propTypes = {};

export default Linklists;
