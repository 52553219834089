import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { useField } from "../../hooks";
import { useCheckout } from "../../hooks/api";
import { RadioList, CheckoutSection, CheckoutSectionHeadline, CheckoutSectionText, } from "../../components";
import { ItemisedBilling } from "../../types";
const InputWrapper = styled.div `
  width: 100%;
`;
// TODO: refactor - move
export const getItemisedBillingLabel = (itemisedBilling) => {
    switch (itemisedBilling) {
        case ItemisedBilling.None: {
            return "Nein, ich wünsche keinen Einzelverbindungsnachweis.";
        }
        case ItemisedBilling.Partial: {
            return "Ja, ich wünsche einen um die letzten drei Rufnummern gekürzten Einzelverbindungsnachweis.";
        }
        case ItemisedBilling.Full: {
            return "Ja, ich wünsche einen vollständigen Einzelverbindungsnachweis.";
        }
        default: {
            throw new Error(`ItemisedBilling ${itemisedBilling} not defined`);
        }
    }
};
export const ItemisedBillingSection = ({ schema }) => {
    const { errors, fieldState, setFieldState } = useField("itemisedBilling", schema, ItemisedBilling.None);
    const { isFetching, isLoading } = useCheckout();
    return (_jsxs(CheckoutSection, { children: [_jsx(CheckoutSectionHeadline, { children: "Einzelverbindungsnachweis" }), _jsx(CheckoutSectionText, { children: "Mit Auswahl eines Einzelverbindungsnachweises best\u00E4tigst du, dass alle zum Haushalt geh\u00F6renden Mitbenutzer hier\u00FCber informiert sind oder werden." }), _jsx(InputWrapper, { children: _jsx(RadioList, { checked: fieldState ?? ItemisedBilling.None, id: "itemisedBilling", items: Object.values(ItemisedBilling).map((itemisedBilling) => ({
                        key: itemisedBilling,
                        label: getItemisedBillingLabel(itemisedBilling),
                    })), onChange: (key) => {
                        setFieldState(key);
                    }, disabled: isLoading || isFetching, error: errors?.[0]?.message }) })] }));
};
