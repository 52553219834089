import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import styled from "styled-components";
import { noop } from "lodash";

const Overlay = styled.div`
  position: fixed;
  background-color: rgb(0, 0, 0, 0.4);
  height: 100%;
  inset: 0;
  width: 100%;
  z-index: 100;
`;

const PageOverlay = ({ onClick }) =>
  ReactDOM.createPortal(
    <Overlay onClick={onClick} />,
    document.getElementById("fixed-body")
  );

PageOverlay.propType = {
  onClick: PropTypes.func,
};

PageOverlay.defaultProps = {
  onClick: noop,
};

export default PageOverlay;
