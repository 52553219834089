import React from "react";
import PropTypes from "prop-types";

import { Surface } from "@mm/ui";

import { ThemeProvider } from "@core/theme";

import { ProviderQuittingTileContainer } from "components/molecules";

const WithThemeProvider = ({ data }) => (
  <ThemeProvider>
    <Surface variant="narrow">
      <ProviderQuittingTileContainer providers={data} />
    </Surface>
  </ThemeProvider>
);

WithThemeProvider.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default WithThemeProvider;
