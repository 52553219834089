/* eslint-disable no-console */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";

import dataLayer from "helper/dataLayer";
import { viewportPropTypes } from "sharedPropTypes";

const Wrapper = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: ${(props) => (props.viewport.lessThan.lg ? "40px" : "56px")};
  width: ${(props) => (props.viewport.lessThan.lg ? "40px" : "56px")};
  font-size: ${(props) => (props.viewport.lessThan.lg ? "13px" : "20px")};
  text-decoration: none;
  color: ${(props) => props.theme.colors.grey[0]};
  background: ${(props) => props.theme.colors.white};
  border-radius: 50%;
  cursor: pointer;
`;

const Counter = styled("span")`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: ${(props) => (props.viewport.lessThan.lg ? "-4px" : "0")};
  right: ${(props) => (props.viewport.lessThan.lg ? "-4px" : "0")};
  height: 16px;
  width: 16px;
  font-size: 12px;
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.grey[0]};
  border-radius: 50%;
`;

const Cart = ({ className, viewport }) => {
  const [showCartIcon, updateIconVisibilityStatus] = useState(false);

  const handleClick = () => {
    dataLayer({
      eventCategory: "Basket Button",
      eventAction: "click",
      eventLabel: "buy:Header",
    });

    axios
      .get("/checkout/continue/")
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          window.location.href = "/checkout/step/1/";
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    axios
      .get("/helper/show-cart-icon/")
      .then((response) => {
        if (
          response.status === 200 &&
          window.location.href.indexOf("checkout") === -1
        ) {
          // only fire impression event when cart is really showing
          updateIconVisibilityStatus(response.data.showCartIcon);

          if (response.data.showCartIcon) {
            dataLayer({
              eventCategory: "Basket Button",
              eventAction: "Impression",
              eventLabel: "buy:Header",
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  if (!showCartIcon) {
    return null;
  }

  return (
    <Wrapper
      className={className}
      onClick={handleClick}
      viewport={viewport}
      title="Warenkorb"
    >
      <i className="icon-MM_iconfont_ShoppingCart" />
      <Counter viewport={viewport}>1</Counter>
    </Wrapper>
  );
};

Cart.propTypes = {
  viewport: viewportPropTypes.isRequired,
};

export default Cart;
