import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { variant } from "styled-system";

import { Image } from "@mm/ui";

import dataLayer from "helper/dataLayer";

const Link = styled.a`
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex: 1 1 auto;
  position: relative;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.primary[0]};
  ${variant({
    variants: {
      navDesktop: {
        backgroundColor: "transparent",
        flexDirection: "column",
        justifyContent: "center",
      },
    },
  })}
`;

const handleClick = (onClick, url, idx, component, parent) => {
  dataLayer({
    eventCategory: parent,
    eventAction: "click",
    eventLabel: `${component}:${idx}:${url}`,
  });
  if (onClick) onClick(url);
};

const LinkedImageContainer = ({
  onClick,
  alt,
  imageUrl,
  linkUrl,
  idx,
  parent,
  type,
  qaName,
  variant,
}) => (
  <Link
    href={linkUrl}
    onClick={() => handleClick(onClick, linkUrl, idx, type, parent)}
    data-codeception={qaName}
    variant={variant}
  >
    <Image src={imageUrl} alt={alt} height="100%" width="100%" />
  </Link>
);

LinkedImageContainer.propTypes = {
  onClick: PropTypes.func,
  alt: PropTypes.string,
  imageUrl: PropTypes.string.isRequired,
  linkUrl: PropTypes.string,
  idx: PropTypes.number,
  parent: PropTypes.string,
  type: PropTypes.string,
  qaName: PropTypes.string,
};

LinkedImageContainer.defaultProps = {
  onClick: null,
  type: "full",
  idx: 0,
  parent: "",
  qaName: undefined,
  alt: "",
  linkUrl: "/",
};

export default LinkedImageContainer;
