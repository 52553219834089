import { extendURLParams } from "@/utils/fetchData";
/**
 * Signals to BE service that the current product configuration is finished and
 * to initialize a checkout with the given selection.
 *
 * @param url
 */
export const addToCart = async (url) => {
    const { tenant, environment, restUrl } = extendURLParams();
    return fetch(`${restUrl}/v1/pks/${url}/addToCart?tenant=${tenant}&environment=${environment}`, {
        method: "post",
        credentials: "include",
    })
        .then((res) => {
        return res.json();
    })
        .catch((error) => new Error(error));
};
