import { useQuery } from "react-query";
import { extendURLParams } from "@/utils/fetchData";
export const useOrderValidation = (payload, options) => {
    const { tenant, environment, restUrl } = extendURLParams();
    return useQuery([`useOrderValidation`, tenant, environment], () => fetch(`${restUrl}/v1/sepa?tenant=${tenant}&environment=${environment}`, {
        method: "post",
        credentials: "include",
        body: JSON.stringify(payload),
    })
        .then((res) => {
        return res.json();
    })
        .catch((error) => new Error(error)), {
        ...options,
        cacheTime: 5 * 60 * 1000,
        refetchOnWindowFocus: false,
    });
};
