/**
 * Formats a given date as a date string matching "dd.MM.yyyy"
 *
 * @param date
 */
export const formatDate = (date) => date.toLocaleString("de-DE", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
});
