import React from "react";
import { Skeleton, Row, Col } from "@mm/ui";

// Return object with height/width/etc., array with this objects or nodes
// Take a look at LoadingElem.propTypes
export default {
  headline: { width: "50%", height: "24px" },
  text: { width: "100%", height: "16px" },
  textBlock: [
    {
      key: "row1",
      width: "100%",
      height: "16px",
      marginBottom: 2,
    },
    {
      key: "row2",
      width: "100%",
      height: "16px",
      marginBottom: 2,
    },
    {
      key: "row3",
      width: "100%",
      height: "16px",
      marginBottom: 2,
    },
    {
      key: "row4",
      width: "100%",
      height: "16px",
      marginBottom: 2,
    },
    {
      key: "row5",
      width: "100%",
      height: "16px",
      marginBottom: 2,
    },
  ],
  breadcrumb: { width: "70%", height: "30px" },
  headerBar: ({ lessThan: { sm } }) => ({
    height: sm ? 110 : 200,
  }),
  home: ({ lessThan: { sm } }) => [
    {
      key: "headerBar",
      height: sm ? 110 : 200,
      marginBottom: 2,
    },
    {
      key: "mainContent",
      height: sm ? 200 : 600,
      marginBottom: 2,
    },
    {
      key: "footer",
      height: sm ? 900 : 750,
    },
  ],
  filter: (
    <Row>
      <Col xs={24} md={8}>
        <Skeleton height={300} />
      </Col>
      <Col xs={24} md={16}>
        <Skeleton height={300} />
      </Col>
    </Row>
  ),
  filterContent: {
    height: "450px",
  },
  energyContent: {
    height: "370px",
  },
  // eslint-disable-next-line react/display-name
  details: ({ lessThan: { lg } }) => (
    <Row>
      {/* Product Slider */}
      <Col xs={2} md={0} />
      <Col xs={20} md={12} lg={6}>
        <Skeleton width="100%" height={650} marginBottom={2} />
      </Col>
      <Col xs={2} md={0} />
      {/* Variant picker */}
      <Col xs={24} md={12} lg={6}>
        {lg && <Skeleton width="30%" height={150} marginBottom={2} />}
        <Skeleton width="100%" height={100} />
      </Col>
      {/* Tariff, Accessory & Price */}
      <Col xs={24} lg={12}>
        <Row>
          <Skeleton width="100%" height={550} />
        </Row>
      </Col>
    </Row>
  ),
  listing: (
    <Row>
      <Col xs={24} md={10} lg={6}>
        <Skeleton width="100%" height={650} marginBottom={2} />
      </Col>
      <Col xs={24} md={14} lg={18}>
        <Skeleton width="100%" height={650} marginBottom={2} />
      </Col>
    </Row>
  ),
  hero: (
    <>
      <Row>
        <Col>
          <Skeleton width="100%" height={50} marginBottom={4} />
        </Col>
      </Row>
      <Row>
        <Col xs={24} md={24} lg={15}>
          <Skeleton width="100%" height={500} marginBottom={2} />
        </Col>
        <Col xs={24} md={24} lg={9}>
          <Skeleton width="100%" height={500} marginBottom={2} />
        </Col>
      </Row>
    </>
  ),
  teaser: {
    height: "650px",
  },
  manufacturer: {
    height: "110px",
  },
};
