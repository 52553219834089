import React, { useState } from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";

import { Box, Button, Headline, InputText, Text } from "@mm/ui";

const VerificationForm = ({
  headline,
  errorMessage,
  onKeyPress,
  onClick,
  buttonText,
  disclaimerText,
  validationRule,
  maxLength,
  label,
}) => {
  const [value, setValue] = useState("");
  const [isValid, setIsValid] = useState(true);
  const handleChange = (val) => {
    setValue(val);
  };
  const handleClick = () => {
    if (value.match(validationRule)) {
      onClick();
    } else {
      setIsValid(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleClick();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Headline variant="h2">{headline}</Headline>
      <InputText
        id={label}
        width="50%"
        label={label}
        maxLength={maxLength}
        onKeyPress={onKeyPress}
        onChange={handleChange}
        layout={isValid ? "normal" : "error"}
        message={!isValid ? errorMessage : ""}
        value={value}
      />
      <Button size="small" mt={4} onClick={handleClick} type="sumbit">
        {buttonText}
      </Button>
      <Box mt={4}>
        <Text>{disclaimerText}</Text>
      </Box>
    </form>
  );
};

VerificationForm.defaultProps = {
  disclaimerText: <></>,
  buttonText: "",
  label: "",
  maxLength: undefined,
  errorMessage: <></>,
  validationRule: undefined,
  headline: "",
  onKeyPress: noop,
  onClick: noop,
};

VerificationForm.propTypes = {
  disclaimerText: PropTypes.node,
  buttonText: PropTypes.string,
  label: PropTypes.string,
  maxLength: PropTypes.string,
  errorMessage: PropTypes.node,
  validationRule: PropTypes.instanceOf(RegExp),
  headline: PropTypes.string,
  onKeyPress: PropTypes.func,
  onClick: PropTypes.func,
};

export default VerificationForm;
