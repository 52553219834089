import React from "react";
import { FormattedMessage } from "react-intl";
import { Headline } from "@mm/ui";

/**
 * Default texts for the `DetailsCoupon` component
 *
 * @type {{terms: [string, string, string], details: *, detailsToggle: string, headline: string}}
 */
export default {
  headline: "Geschenk-Coupon €",
  terms: [
    // eslint-disable-next-line react/jsx-key
    <FormattedMessage id="app.coupon.bullet1" />,
    // eslint-disable-next-line react/jsx-key
    <FormattedMessage id="app.coupon.bullet2" />,
    // eslint-disable-next-line react/jsx-key
    <FormattedMessage id="app.coupon.bullet3" />,
  ],
  detailsToggle: "Coupondetails",
  details: (
    <aside>
      <Headline variant="h3">
        <FormattedMessage id="app.coupon.details1.headline" />
      </Headline>
      <p>
        <FormattedMessage id="app.coupon.details1.p1" />
      </p>
      <p>
        <FormattedMessage id="app.coupon.details1.p2" />
      </p>
      <Headline variant="h3">
        <FormattedMessage id="app.coupon.details2.headline" />
      </Headline>
      <p>
        <FormattedMessage id="app.coupon.details2.p1" />
      </p>
    </aside>
  ),
};
