import styled from "styled-components";
import { InputSelect } from "@mm/ui";
import { THEME } from "@core/theme";
import { provideIsLoadingProp, } from "@/utils/context/LoadingContext";
const { skeletonBase } = THEME;
export const StyledInputSelect = styled(InputSelect).attrs(provideIsLoadingProp) `
  ${({ isLoading }) => isLoading &&
    skeletonBase +
        `
      height: 2rem;
      border-bottom-color: transparent;
      label, select, option, svg {
        color: transparent;
      }
      svg {
        fill: transparent;
      }
  `};
`;
