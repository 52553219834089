import React from "react";

import PropTypes from "prop-types";

import { TariffBadge, Price } from "components/atoms";

// import { CartModalItem } from "components/molecules";

// FIXME refactor components (dependency cycle)
import CartModalItem from "../CartModalItem";

const CartModalItemForTariff = ({
  tariff: { serviceProvider, carrier, name: tariffName, commission },
  couponValue,
  price,
  buttonAction,
}) => (
  <CartModalItem
    tariffCommission={commission}
    itemType="tariff"
    itemName={`${carrier || ""} ${tariffName}`}
    itemDetails={
      couponValue ? (
        <>
          mit <Price preventMargin preventStyling price={couponValue} />{" "}
          Geschenk-Coupon
        </>
      ) : null
    }
    itemImage={
      <TariffBadge
        tariff={{
          serviceProvider,
          carrier,
        }}
        sim
      />
    }
    monthlyPrice={price}
    button={buttonAction}
  />
);

CartModalItemForTariff.propTypes = {
  tariff: PropTypes.shape({
    serviceProvider: PropTypes.string,
    name: PropTypes.string,
    carrier: PropTypes.string,
    commission: PropTypes.number,
  }).isRequired,
  price: PropTypes.string.isRequired,
  couponValue: PropTypes.string.isRequired,
  buttonAction: PropTypes.elementType.isRequired,
};

export default CartModalItemForTariff;
