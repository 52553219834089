import React from "react";
import PropTypes from "prop-types";
import { Button } from "@mm/ui";

import styled from "styled-components";

import SinglePersonSvg from "@core/svgs/single-person.svg";
import CoupleSvg from "@core/svgs/couple.svg";
import GroupSvg from "@core/svgs/group.svg";
import GroupLargeSvg from "@core/svgs/group-large.svg";
import HouseSvg from "@core/svgs/house.svg";

import { GasIcon } from "./styledComponents";

const StyledBtn = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  padding: 5px;
  border: 1px solid black;

  &:hover {
    border: 2px solid ${({ theme }) => theme.colors.brand.primary};
    text-decoration: none;
  }

  &:focus {
    outline: auto;
  }

  &.active {
    border: 2px solid ${({ theme }) => theme.colors.brand.primary};
    text-decoration: none;
  }
`;

const ConsumptionBtn = (props) => {
  const {
    energyType,
    minValue,
    maxValue,
    selectedValue,
    onClickHandler,
    position,
  } = props;

  const floorSize = [50, 100, 150, 200];
  const amountOfPeople = [
    // eslint-disable-next-line react/jsx-key
    <SinglePersonSvg />,
    // eslint-disable-next-line react/jsx-key
    <CoupleSvg />,
    // eslint-disable-next-line react/jsx-key
    <GroupSvg />,
    // eslint-disable-next-line react/jsx-key
    <GroupLargeSvg />,
  ];

  return (
    <StyledBtn
      type="button"
      color="ghost"
      onClick={onClickHandler}
      className={
        selectedValue >= minValue && selectedValue <= maxValue ? "active" : null
      }
    >
      {energyType === "strom" ? (
        <>{amountOfPeople[position]}</>
      ) : (
        <GasIcon>
          <HouseSvg />
          <span className="gas-icon__text">{floorSize[position]}m²</span>
        </GasIcon>
      )}
    </StyledBtn>
  );
};

ConsumptionBtn.defaultProps = {
  energyType: "strom",
  minValue: 0,
  maxValue: 9999,
  selectedValue: 1500,
  onClickHandler: () => null,
  position: 1,
};

ConsumptionBtn.propTypes = {
  energyType: PropTypes.string,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  selectedValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onClickHandler: PropTypes.func,
  position: PropTypes.number,
};

export default ConsumptionBtn;
