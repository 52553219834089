import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { variant as styledVariant } from "styled-system";

import Disabled from "components/atoms/Disabled";

const Div = styled.div(
  styledVariant({
    prop: "variant",
    variants: {
      default: {
        width: "1.4em",
        height: "1.4em",
      },
      small: {
        width: "1em",
        height: "1em",
      },
    },
  }),
  ({ theme: { colors }, selected, value }) => `
        flex-shrink: 0;
        position: relative;
        border-radius: 2em;
        border: 1px ${colors.grey[2]} solid;
        background-color: ${value};
        ${
          selected
            ? `
            border: 2px ${colors.primary[0]} solid;
        `
            : ""
        }
    `
);

const ColorCircle = ({
  className,
  selected,
  soldout,
  value,
  variant,
  onClick,
  onMouseEnter,
  onMouseLeave,
  sequentialNumer,
}) => (
  <Div
    selected={selected}
    value={value}
    variant={variant}
    className={className}
    onClick={onClick}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    title={soldout ? "ausverkauft" : ""}
    id="colorDot"
    data-codeception={sequentialNumer}
  >
    {soldout && <Disabled />}
  </Div>
);

ColorCircle.defaultProps = {
  selected: false,
  variant: "default",
  soldout: false,
  className: "",
  onClick: () => null,
  onMouseEnter: () => null,
  onMouseLeave: () => null,
  sequentialNumer: 0,
};

ColorCircle.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(["small", "default"]),
  selected: PropTypes.bool,
  value: PropTypes.string.isRequired,
  soldout: PropTypes.bool,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  sequentialNumer: PropTypes.number,
};

export default ColorCircle;
