import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import { Surface } from "@mm/ui";
import { PageFooter, PageHeader } from "@/components/organisms";
import { StandardPageTemplate } from "@/components/templates";
import { AvailabilityCheck, Loading, OfferSummary, } from "@/components/molecules";
import { AccessorySelectList, HardwareSelectList, TariffSelectList, } from "@/components/organisms";
import styled from "styled-components";
import { addToCart, useProductConfiguration, } from "@/features/ProductConfiguration";
import { ACTIONS as LoadingStateActions, useLoadingState, } from "@/utils/context/LoadingContext";
import { Spinner } from "@/components/atoms";
import { useLocalStorage } from "@/utils/hooks";
import dataLayer from "@/helper/dataLayer";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CheckoutType } from "@/types/";
import { checkoutBasePaths } from "@/router/checkoutBasePaths";
const FlexSurface = styled(Surface) `
  display: flex;
  flex-wrap: wrap;
  gap: 1.25rem;
  margin-top: 2rem;
  position: relative;

  @media (max-width: 760px) {
    flex-wrap: wrap;
    margin-top: 0;
  }
`;
const ConfigurationContainer = styled.main `
  flex: 1 0 34rem;
  overflow-x: hidden;

  @media (max-width: 760px) {
    flex: 1 0 100%;
  }
`;
const SummaryContainer = styled.aside `
  flex: 0 0 26rem;
  position: sticky;
  top: 2rem;
  align-self: flex-start;

  @media (max-width: 760px) {
    flex: 1 0 100%;
    background: #fff;
    margin: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-top: 1rem;
  }
`;
const LoadingOverlay = styled.div `
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9998;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: center;
  padding-top: 33vh;
  padding-bottom: 50vh;

  &.enter {
    transition: opacity 0.2s;
  }
  &.leave {
    transition: opacity 0.4s;
  }
  &.enterFrom,
  &.leaveTo {
    opacity: 0;
  }
  &.enterTo,
  &.leaveFrom {
    opacity: 0.5;
  }
`;
const SpinnerWrapper = styled.div `
  position: sticky;
  top: 33vh;
  bottom: 50vh;
  height: 4rem;
  width: 4rem;
`;
const H1 = styled.h1 `
  margin-top: 1rem;
`;
export const ProductConfigurationPage = ({ type }) => {
    const { offerGroupUrl } = useParams();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const basePath = checkoutBasePaths[type];
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    if (!offerGroupUrl) {
        throw new Error("OfferGroupUrl not found");
    }
    const queryParams = new URLSearchParams(window.location.search);
    const clearAvailability = queryParams.get("clearAvailability");
    const [availabilityCheckedAddress, setAvailabilityCheckedAddress] = useLocalStorage("availabilityCheckedAddress", undefined);
    const [selection, setSelection] = useState({
        accessories: [],
        address: availabilityCheckedAddress,
        options: [],
        pkAccessories: [],
        reset: Boolean(clearAvailability),
    });
    const { data, isLoading, isFetching, refetch } = useProductConfiguration(offerGroupUrl, selection, {
        refetchOnWindowFocus: false,
    });
    const { dispatch } = useLoadingState();
    useEffect(() => {
        if (clearAvailability) {
            setAvailabilityCheckedAddress(undefined);
            setSelection({
                ...selection,
                address: undefined,
            });
        }
    }, [clearAvailability]);
    useEffect(() => {
        refetch();
    }, [selection]);
    useEffect(() => {
        dispatch({
            type: LoadingStateActions.SET_LOADING,
            payload: isFetching,
        });
    }, [isFetching]);
    useEffect(() => {
        if (data && data.url === "") {
            throw new Error("Could not find OfferGroup");
        }
        if (data) {
            if (data.error) {
                throw new Error(data.error);
            }
            if (data.address) {
                setAvailabilityCheckedAddress(undefined);
            }
            dataLayer({
                pageType: "Productdetail",
                category: "DSL",
                producttype: "DSL",
                dimension9: data?.summary?.tariff.serviceProvider,
            });
        }
    }, [data]);
    const accessories = data?.accessories?.filter(({ type }) => type === "bonus") ?? [];
    const pkAccessories = data?.accessories?.filter(({ type }) => type === "pk") ?? [];
    const configurationSections = [
        {
            title: "Verfügbarkeit",
            content: (_jsx(AvailabilityCheck, { alternativeOffers: (data?.summary?.onlineOnly &&
                    data?.alternatives &&
                    data.alternativeOffers) ||
                    [], available: data?.offersAvailable, basePath: basePath, checked: data?.availabilityChecked, data: {
                    ...(data?.address && { ...data.address }),
                    promoCode: data?.promotionCode,
                    marketCode: data?.address?.marketCode || "",
                }, isLoading: isFetching, onSubmit: (address) => {
                    if (address) {
                        setSelection({
                            ...selection,
                            address,
                        });
                    }
                }, clearAvailability: Boolean(clearAvailability), onlineOnly: data?.summary?.onlineOnly })),
        },
        {
            title: "Deine Tarifauswahl",
            content: (_jsx(TariffSelectList, { disabled: data?.offersAvailable === false, onChange: (selectedTariff) => {
                    if (!selectedTariff.isSelected) {
                        setSelection({
                            ...selection,
                            tariff: selectedTariff.id,
                        });
                    }
                }, tariffs: data?.tariffs || [] })),
        },
        {
            title: "Dein Router",
            content: (_jsx(HardwareSelectList, { disabled: data?.offersAvailable === false, hardwares: data?.hardwares ?? [], onChange: (selectedHardware) => {
                    if (!selectedHardware.isSelected) {
                        setSelection({
                            ...selection,
                            hardware: {
                                hardware: selectedHardware.id,
                                variant: selectedHardware.extendedVariants?.[0].variant.ebootisId ??
                                    "",
                            },
                        });
                    }
                } })),
        },
        ...(pkAccessories?.length > 0
            ? [
                {
                    title: `Deine ${pkAccessories.length < 2 ? "Prämie" : "Prämien"}`,
                    content: (_jsx(AccessorySelectList, { accessories: pkAccessories || [], disabled: data?.offersAvailable === false, onChange: (selectedPkAccessory) => {
                            if (!selectedPkAccessory.isSelected) {
                                setSelection({
                                    ...selection,
                                    pkAccessories: [
                                        {
                                            hardware: selectedPkAccessory.id,
                                            variant: selectedPkAccessory.extendedVariants?.[0].variant
                                                .ebootisId ?? "",
                                        },
                                    ],
                                });
                            }
                        } })),
                },
            ]
            : []),
        ...(accessories?.length > 0
            ? [
                {
                    title: `Deine ${accessories.length < 2 ? "Zugabe" : "Zugaben"}`,
                    content: (_jsx(AccessorySelectList, { accessories: accessories || [], disabled: data?.offersAvailable === false, onChange: (selectedAccessory) => {
                            setSelection({
                                ...selection,
                                accessories: [
                                    {
                                        hardware: selectedAccessory.id,
                                        variant: selectedAccessory.extendedVariants?.[0].variant
                                            .ebootisId ?? "",
                                    },
                                ],
                            });
                        } })),
                },
            ]
            : []),
    ];
    return (_jsx(StandardPageTemplate, { header: _jsx(PageHeader, { itemCount: 1, reducedHeader: true }), sections: [
            !data || isLoading || data.url === "" ? (_jsx(Loading, { variant: "home" }, "loading-wrapper")) : (_jsxs(FlexSurface, { variant: "narrow", children: [_jsxs(ConfigurationContainer, { children: [_jsx(H1, { children: data?.name ?? data?.summary?.tariff.name }), configurationSections.map(({ title, content }, index) => (_jsxs(Fragment, { children: [_jsxs("h2", { children: [index + 1, ". ", title] }), content] }, `product-configuration-page-section-${title}-${index}`)))] }), _jsx(SummaryContainer, { children: _jsx(OfferSummary, { coupon: data?.summary?.coupon, 
                            // TODO: get loading state for addToCart
                            disabled: data?.offersAvailable === false, mode: "price", onSubmit: () => {
                                // TODO: error handling
                                addToCart(offerGroupUrl).then(() => {
                                    navigate(`${basePath}/checkout/step/1`, {
                                        state: {
                                            ...history.state,
                                            connectionType: data?.summary?.tariff.connectionType,
                                            serviceProvider: data?.summary?.tariff.serviceProvider,
                                            onlineOnly: data?.summary?.onlineOnly,
                                        },
                                    });
                                });
                                dataLayer({
                                    eventCategory: "BasketButton",
                                    eventAction: "click",
                                    eventLabel: "buy",
                                });
                            }, showSummaryOnMobile: true, summary: data?.summary, submitDisabled: data?.availabilityChecked === false ||
                                data?.offersAvailable === false, submitLabel: "Jetzt bestellen", type: CheckoutType.internet }) }), _jsx(Transition, { as: LoadingOverlay, show: isFetching, enter: "enter", enterFrom: "enterFrom", enterTo: "enterTo", leave: "leave", leaveFrom: "leaveFrom", leaveTo: "leaveTo", children: _jsx(SpinnerWrapper, { children: _jsx(Spinner, { color: "ghost", size: "4rem" }) }) })] }, "product-configuration-page-surface")),
        ], footer: _jsx(PageFooter, {}) }));
};
export default ProductConfigurationPage;
