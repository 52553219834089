import React from "react";
import PropTypes from "prop-types";

import { namePropTypes } from "sharedPropTypes";

import StageHeadline from "./StageHeadline";

const StageOfferHeadline = ({ accessories, className, hardware }) => (
  <StageHeadline className={className}>
    {hardware.name}
    {accessories.length > 0 ? " inkl." : ""}
    {accessories.map(
      (accessory, idx) => `${idx > 0 ? " und " : " "} ${accessory.name}`
    )}
  </StageHeadline>
);

StageOfferHeadline.propTypes = {
  accessories: PropTypes.arrayOf(namePropTypes).isRequired,
  className: PropTypes.string,
  hardware: namePropTypes.isRequired,
};

StageOfferHeadline.defaultProps = {
  className: "",
};

export default StageOfferHeadline;
