import PropTypes from "prop-types";
import styled from "styled-components";

const Anchor = styled.a`
  cursor: pointer;
  color: ${(props) => props.theme.colors.grey[6]};
  font-size: 14px;
  line-height: 20px;
  padding: 0.75rem 0.5rem;
  text-decoration: none;
  width: 100%;
  display: flex;
  flex-direction: row;
  :active {
    background-color: ${(props) => props.theme.colors.grey[1]};
  }
  ${"svg"} {
    padding: auto;
    &.arrowRight {
      width: 1.25rem;
      transform: scale(0.7) rotate(90deg);
      margin-left: auto;
      height: auto;
    }
    &.arrowLeft {
      margin-left: 0;
      margin-right: 0.75rem;
      transform: scale(0.7) rotate(270deg);
      height: 1.25rem;
      width: auto;
    }
  }
`;

Anchor.propTypes = {
  href: PropTypes.string.isRequired,
};

export default Anchor;
