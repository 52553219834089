import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styled from "styled-components";
import { Image } from "@mm/ui";
import { getSelectedExtendedVariant } from "@/utils/variantUtils";
import { Bulletlist, Eyecatchers, GeneralModalShell } from "@/components/atoms";
import Header from "./Header/index";
import Footer from "./Footer/index";
const ImageWrapper = styled("div") `
  position: relative;
`;
const SvgWrapper = styled.span `
  position: relative;
  z-index: 1;

  svg {
    display: block;
    path {
      fill: ${({ theme }) => theme.colors.grey[4]};
    }
  }
`;
const PkCouponValue = styled("div") `
  color: ${({ theme }) => theme.colors.brand.primary};
  font-family: ${({ theme }) => theme.fonts.headlineBig};
  font-size: 25px;
  position: absolute;
  right: 35px;
  top: 20px;
`;
const ContentWrapper = styled("div") `
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  gap: ${({ theme }) => theme.space.s}px;
  margin-bottom: ${({ theme }) => theme.space.xxlLong}px;

  img {
    width: 220px;
  }

  @media (min-width: ${({ theme }) => theme.breakpointsMap.md.max}px) {
    flex-wrap: nowrap;
  }
`;
const DetailEyeCatcher = styled.div `
  display: inline-block;
  position: relative;

  .detailEyeCatcher-text {
    display: inline-block;
    margin-right: 5px;
  }

  .detailEyeCatcher-info {
    display: inline-block;
  }
`;
const RenderOfferAccessoriesImages = ({ accessories, }) => {
    return (_jsx(_Fragment, { children: accessories.map((accessory) => {
            if (!accessory.extendedVariants)
                return null;
            const { images } = getSelectedExtendedVariant(accessory.extendedVariants).variant;
            const { pkCouponValue } = getSelectedExtendedVariant(accessory.extendedVariants).variant;
            return (_jsxs(ImageWrapper, { children: [images.map((image) => (_jsx(Image, { length: accessories.length, srcSet: image, alt: `${accessory.manufacturer} ${accessory.name}` }, accessory.name))), pkCouponValue > 0 && (_jsxs(PkCouponValue, { children: [pkCouponValue, "\u20AC"] }))] }, accessory.name));
        }) }));
};
const OfferTeaserInternet = ({ accessories, availabilityChecked, coupon, eyeCatchers, detailEyeCatcher, tariff, name, offerGroupUrl, accessoriesAlternativeImage, accessoriesAlternativePrice, }) => {
    if (!tariff) {
        return null;
    }
    const { serviceProvider, bullets } = tariff;
    const validateEyeCatcher = () => {
        return eyeCatchers
            ? Object.values(eyeCatchers).some((item) => item.length >= 1)
            : false;
    };
    return (_jsxs(_Fragment, { children: [validateEyeCatcher() && (_jsx(Eyecatchers, { eyecatchers: eyeCatchers, variant: "affOffer" })), _jsx(Header, { headline: name, provider: serviceProvider, accessories: accessories }), _jsxs(ContentWrapper, { children: [_jsxs("div", { className: "flex-left-item", children: [_jsx(Bulletlist, { list: bullets, variant: "checkmark" }), detailEyeCatcher?.eyeCatcher && (_jsxs(DetailEyeCatcher, { children: [detailEyeCatcher?.eyeCatcher && (_jsx("span", { className: "detailEyeCatcher-text", children: _jsx("b", { children: detailEyeCatcher.eyeCatcher }) })), _jsx("div", { className: "detailEyeCatcher-info", children: _jsx(GeneralModalShell, { opener: _jsx(SvgWrapper, { "aria-label": "Mehr Informationen", role: "dialog", "aria-modal": "true", children: _jsx("i", { className: "icon-MM_iconfont_Info" }) }), children: _jsx("div", { children: detailEyeCatcher?.eyeCatcherInfo }) }) })] }))] }), _jsxs("div", { className: "flex-right-item", children: [coupon?.value && coupon.value > 0 && (_jsx(Image, { src: `/img/coupon/coupon-${coupon?.value}.png`, alt: "Geschenk Coupon" })), !accessoriesAlternativeImage && accessories && (_jsx(RenderOfferAccessoriesImages, { accessories: accessories })), accessoriesAlternativeImage && (_jsx(Image, { src: accessoriesAlternativeImage, alt: "Verschiedene Pr\u00E4mienzugaben" }))] })] }), _jsx(Footer, { accessories: accessories, availabilityChecked: availabilityChecked, tariff: tariff, offerGroupUrl: offerGroupUrl, accessoriesAlternativePrice: accessoriesAlternativePrice })] }));
};
export default OfferTeaserInternet;
