import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Headline, Row, Col } from "@mm/ui";

const StyledServiceTile = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.grey[2]};
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  flex-direction: column;
  height: calc(100% - ${(props) => props.theme.gutterWidths.xs}px);
  justify-content: space-between;
  line-height: 20px;
  margin-bottom: ${(props) => props.theme.gutterWidths.xs}px;
  padding: 24px 16px;

  @media (min-width: ${(props) => props.theme.breakpointsMap.md.min}px) {
    font-size: 16px;
    height: calc(100% - ${(props) => props.theme.gutterWidths.md}px);
    line-height: 24px;
    margin-bottom: ${(props) => props.theme.gutterWidths.md}px;
  }

  &.secondary {
    background-color: ${(props) => props.theme.colors.primary[0]};
    color: ${(props) => props.theme.colors.white};
  }
`;

const Header = styled.header`
  height: 64px;
  margin-bottom: 8px;
`;

const StyledHeadline = styled(Headline)`
  color: ${(props) =>
    props.className.includes("secondary")
      ? props.theme.colors.white
      : props.theme.colors.primary[0]};
  font-family: ${({ theme }) => theme.fonts.headlineBig};
  font-size: 24px;
  line-height: 32px;
  margin: 0;
  ${(props) =>
    props.className.includes("secondary") &&
    `
    @media (min-width: ${props.theme.breakpointsMap.lg.min}px) {
      font-size: 40px;
      line-height: 48px;
    }
  `};
`;

const Text = styled.p`
  margin: 0 0 2em;
  white-space: pre-line;
  @media (min-width: ${(props) =>
      props.theme.breakpointsMap.md.min}px) and (max-width: ${(props) =>
      props.theme.breakpointsMap.md.max}px) {
    white-space: normal;
  }
`;

const BottomLine = styled.div`
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  position: relative;

  svg {
    height: 69px;
    width: 69px;

    ${(props) =>
      props.className.includes("secondary") &&
      `
    @media (min-width: ${props.theme.breakpointsMap.lg.min}px) {
      position: absolute;
      right: 0;
      bottom: 0;
      height: 185px;
      width: 185px;
    }
  `};
  }
`;

const Span = styled.span`
  display: block;
  :before {
    content: ">";
    color: ${(props) =>
      props.className.includes("secondary")
        ? props.theme.colors.white
        : props.theme.colors.primary[0]};
    display: inline-block;
    margin-right: 8px;
  }
`;

const ServiceTile = (props) => {
  const { headline, text, link, icon, className } = props;

  const tileClick = () => {
    window.location = link;
  };

  return (
    <StyledServiceTile className={className} onClick={tileClick}>
      <div>
        <Row>
          <Col xs={24}>
            <Header className={className}>
              <StyledHeadline variant="h3" className={className}>
                {headline}
              </StyledHeadline>
            </Header>
          </Col>
          <Col xs={24}>
            <Text>{text}</Text>
          </Col>
        </Row>
      </div>
      <div>
        <Row>
          <Col xs={24}>
            <BottomLine className={className}>
              <Span className={className}>Mehr erfahren</Span>
              {icon}
            </BottomLine>
          </Col>
        </Row>
      </div>
    </StyledServiceTile>
  );
};

ServiceTile.propTypes = {
  className: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  link: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default ServiceTile;
