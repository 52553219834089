import React from "react";
import styled from "styled-components";

import dataLayer from "helper/dataLayer";

const ServiceProviderWrapper = styled("div")`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;

  .ServiceProviderItem {
    flex-basis: 100%;
    height: 120px;
    text-align: center;
  }

  .ServiceProviderItem:nth-child(1) {
    flex: 2 0;
  }

  .ServiceProviderItem:nth-child(2) {
    flex: 2 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpointsMap.sm.min}px) {
    .ServiceProviderItem,
    .ServiceProviderItem:nth-child(1),
    .ServiceProviderItem:nth-child(2) {
      flex: 1 0;
    }
  }
`;

const ServiceProviderItem = styled("div")`
  width: 100%;
  height: 100%;
  background-image: url(${(props) => props.background});
  background-position: center;
  background-size: cover;
  border-radius: 4px;
`;

const InternetServiceProvider = ({ availableProviders, url }) => {
  return (
    <ServiceProviderWrapper>
      {availableProviders.map(({ providerName, tileImage }) => (
        <a
          href={`/${url}/${providerName}`} // TODO: Maybe after web.php is gone: const navigate = useNavigate(); navigate(`/${url}/${providerName}`)
          className="ServiceProviderItem"
          key={providerName}
          onClick={() => {
            dataLayer({
              eventCategory: "Internet:Filter",
              eventAction: "ProviderCard",
              eventLabel: `Internet:Action:ProviderCard:${providerName}`,
            });
          }}
        >
          <ServiceProviderItem
            background={`https://content.ekontor24.net/mediamarkt${tileImage}`}
          />
        </a>
      ))}
    </ServiceProviderWrapper>
  );
};

export default InternetServiceProvider;
