import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Image } from "@mm/ui";

import Link from "../../atoms/Link";
import ShadowBox from "../../atoms/ShadowBox";
import TileAnchor from "../../atoms/TileAnchor";

const TileBox = styled(ShadowBox)`
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding-bottom: 24px;
`;

const ImageContainer = styled.div`
  overflow: hidden;
  position: relative;

  img {
    border-radius: 4px;
    display: block;
    height: auto;
    width: 100%;
  }

  :after {
    background-color: ${(props) => props.theme.colors.white};
    bottom: -51px;
    content: "";
    display: block;
    height: 50px;
    left: -10%;
    position: absolute;
    transform: rotate(-1.5deg);
    transform-origin: left top;
    width: 120%;
  }
`;

const Text = styled.div`
  margin: 0 16px;
`;

const Headline = styled.h3`
  color: ${(props) => props.theme.colors.grey[5]};
  display: flex;
  font-family: ${({ theme }) => theme.fonts.headlineBig};
  font-size: 24px;
  height: calc(2.4 * 1.33 * 20px);
  line-height: 1.33;
  margin-bottom: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  flex-direction: column;
  line-clamp: 2;
`;

const Copy = styled.p`
  display: flex;
  font-size: 14px;
  line-height: 1.43;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  flex-direction: column;
  line-clamp: 3;

  @media (min-width: ${(props) => props.theme.breakpointsMap.md.min}px) {
    font-size: 16px;
    line-height: 1.5;
  }
`;

const StyledLink = styled(Link)`
  font-size: 16px;
  line-height: 1.5;
  margin: 32px 0 0 16px;
`;

const EditorialTile = ({ copy, headline, href, image, linktext }) => (
  <TileAnchor href={href}>
    <TileBox>
      <div>
        <ImageContainer>
          <Image src={image} alt={headline} />
        </ImageContainer>
        <Text>
          <Headline>{headline}</Headline>
          <Copy>{copy}</Copy>
        </Text>
      </div>
      <StyledLink>{linktext}</StyledLink>
    </TileBox>
  </TileAnchor>
);

EditorialTile.propTypes = {
  copy: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  linktext: PropTypes.string.isRequired,
};
export default EditorialTile;
