import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Button, Surface } from "@mm/ui";
import { ThemeProvider } from "@core/theme";
import { Spinner } from "@/components/atoms";
import { OfferTeaserSimOnly } from "@/components/organisms";
import { OfferProvider } from "@/features/Offer";
import { useSimOnlyProvider } from "@/features/SimOnly";
import styled from "styled-components";
const SpinnerWrapper = styled.div `
  display: flex;
  justify-content: center;
`;
const ButtonWrapper = styled.div `
  display: flex;
  justify-content: center;
  margin-top: 44px;

  .buttonLoadTeasers {
    background: black;
    border-color: black;
    color: white;
  }
`;
const TeaserWrapper = styled("div") `
  display: grid;
  grid-template-columns: 1fr;
  gap: 44px 24px;

  @media (min-width: ${({ theme }) => theme.breakpointsMap.sm.min}px) {
    grid-template-columns: repeat(2, calc(50% - 12px));
  }

  @media (min-width: ${({ theme }) => theme.breakpointsMap.md.min}px) {
    grid-template-columns: repeat(2, calc(50% - 12px));
  }

  @media (min-width: ${({ theme }) => theme.breakpointsMap.lg.min}px) {
    grid-template-columns: repeat(4, calc(25% - 18px));
  }
`;
const SimOnlyOfferTeaser = ({ queryParams }) => {
    const { state } = useSimOnlyProvider();
    const { offers, showOffers, isLoadingOffers } = state;
    const [teaserToShow, setTeaserToShow] = useState(12);
    if (showOffers && isLoadingOffers) {
        return (_jsx(Surface, { variant: "narrow", mt: "44px", children: _jsx(SpinnerWrapper, { children: _jsx(Spinner, { color: "ghost", size: "4rem" }) }) }));
    }
    if (offers.length < 1) {
        return (_jsx(Surface, { variant: "narrow", mt: "44px", children: _jsx("b", { children: "Es k\u00F6nnen keine Angebote angezeigt werden." }) }));
    }
    return (_jsx(ThemeProvider, { children: _jsxs(Surface, { variant: "narrow", mt: 10, children: [_jsx(TeaserWrapper, { children: offers.slice(0, teaserToShow).map((offer, index) => {
                        const { accessories, coupon, eyeCatchers, tariff, newCheckout } = offer;
                        return (_jsx(OfferProvider, { queryParams: queryParams, isListingPage: true, isTariff: true, children: _jsx(OfferTeaserSimOnly, { accessories: accessories, coupon: coupon, eyeCatchers: eyeCatchers, tariff: tariff, name: tariff.name, offerGroupUrl: tariff.link, cartButtonQaName: `offer${index}`, newCheckout: newCheckout, offer: offer }) }, `${offer.offerId}-${index}`));
                    }) }), offers.length > 12 && teaserToShow < offers.length && (_jsx(ButtonWrapper, { children: _jsx(Button, { type: "button", onClick: () => setTeaserToShow(teaserToShow + 12), className: "buttonLoadTeasers", children: "Weitere Tarife anzeigen" }) }))] }) }));
};
export default SimOnlyOfferTeaser;
