import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { throttle } from "lodash";
import styled from "styled-components";
import { variant } from "styled-system";

const Sticky = styled("div")(
  {
    position: "fixed",
    width: ({ fullWidth }) => (fullWidth ? "100%" : "auto"),
    height: ({ fullHeight }) => (fullHeight ? "100%" : "auto"),
    transition: "all 0.5s ease-in-out",
    zIndex: "10",
  },
  variant({
    prop: "vertical",
    variants: {
      top: {
        top: 0,
      },
      bottom: {
        bottom: 0,
      },
    },
  }),
  variant({
    prop: "horizontal",
    variants: {
      left: {
        left: 0,
      },
      right: {
        right: 0,
      },
    },
  }),
  variant({
    prop: "displayType",
    variants: {
      "fade-show": {
        display: "block",
        opacity: 1,
      },
      "fade-transition": {
        display: "block",
        opacity: 0,
      },
      "fade-hide": {
        display: "none",
        opacity: 0,
      },
      "slide-top-show": {
        display: "block",
        transform: "translateY(0%)",
      },
      "slide-top-transition": {
        display: "block",
        transform: "translateY(-100%)",
      },
      "slide-top-hide": {
        display: "none",
        transform: "translateY(-100%)",
      },
      "slide-right-show": {
        display: "block",
        transform: "translateX(0%)",
      },
      "slide-right-transition": {
        display: "block",
        transform: "translateX(100%)",
      },
      "slide-right-hide": {
        display: "none",
        transform: "translateX(100%)",
      },
      "slide-bottom-show": {
        display: "block",
        transform: "translateY(0%)",
      },
      "slide-bottom-transition": {
        display: "block",
        transform: "translateY(100%)",
      },
      "slide-bottom-hide": {
        display: "none",
        transform: "translateY(100%)",
      },
      "slide-left-show": {
        display: "block",
        transform: "translateX(0%)",
      },
      "slide-left-transition": {
        display: "block",
        transform: "translateX(-100%)",
      },
      "slide-left-hide": {
        display: "none",
        transform: "translateX(-100%)",
      },
    },
  })
);

export const StickyElement = ({
  children,
  position,
  fullWidth,
  fullHeight,
  transitionStyle,
  showAfterScroll,
}) => {
  const [elementStatus, setElementStatus] = useState(
    showAfterScroll === 0 || window.scrollY >= showAfterScroll ? "show" : "hide"
  );

  useEffect(() => {
    if (elementStatus.startsWith("transition")) {
      const targetAction = elementStatus.substr("transition-to-".length);
      window.setTimeout(
        () => {
          setElementStatus(targetAction);
        },
        targetAction === "show" ? 0 : 500
      );
    }
  }, [elementStatus, setElementStatus]);

  const handleScrolling = useCallback(
    throttle(() => {
      // Show item after scrolling given amount of pixels
      if (showAfterScroll === 0) {
        return false;
      }

      if (elementStatus === "hide" && window.scrollY >= showAfterScroll) {
        setElementStatus("transition-to-show");
      } else if (elementStatus === "show" && window.scrollY < showAfterScroll) {
        setElementStatus("transition-to-hide");
      }

      return null;
    }, 25),
    [elementStatus, setElementStatus, showAfterScroll]
  );

  useEffect(() => {
    window.addEventListener("scroll", handleScrolling);

    return () => {
      window.removeEventListener("scroll", handleScrolling);
    };
  }, [handleScrolling]);

  return (
    <Sticky
      vertical={position.indexOf("top") !== -1 ? "top" : "bottom"}
      horizontal={position.indexOf("left") !== -1 ? "left" : "right"}
      fullHeight={fullHeight}
      fullWidth={fullWidth}
      displayType={`${transitionStyle}-${
        elementStatus.startsWith("transition") ? "transition" : elementStatus
      }`}
    >
      {children}
    </Sticky>
  );
};

StickyElement.propTypes = {
  children: PropTypes.node.isRequired,
  position: PropTypes.oneOf([
    "topleft",
    "bottomleft",
    "topright",
    "bottomright",
  ]).isRequired,
  fullWidth: PropTypes.bool,
  fullHeight: PropTypes.bool,
  transitionStyle: PropTypes.oneOf([
    "slide-top",
    "slide-right",
    "slide-bottom",
    "slide-left",
    "fade",
  ]),
  showAfterScroll: PropTypes.number,
};

StickyElement.defaultProps = {
  showAfterScroll: 0,
  fullWidth: false,
  fullHeight: false,
  transitionStyle: "fade",
};

export default StickyElement;
