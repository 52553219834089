import PropTypes from "prop-types";
import styled from "styled-components";
import { compose, space } from "styled-system";

/*
 * The props in PropTypes are being used in a loop,
 * eslint does not detect this correctly, so we disable these rules:
 */
/* eslint-disable react/no-unused-prop-types */

/**
 * Create responsive columns inside of a `Surface` or `Row`
 */
const Col = styled("div")(compose(space), (props) => {
  let preparedStyles = {
    flexGrow: props.flexGrow,
    flexShrink: props.flexShrink,
  };

  let padding = 0;
  const lastSetPadding = padding;
  const breakpoints = Object.keys(props.theme.breakpointsMap);

  breakpoints.forEach((bp) => {
    // If e.g. xs is 0 then hide
    if (props[bp] === 0) {
      preparedStyles[
        `@media (min-width: ${props.theme.breakpointsMap[bp].min}px)`
      ] = {
        display: "none",
      };
    } else {
      // Prepare vars
      const width = (props[bp] / props.theme.maxcols) * 100;
      padding = props.theme.gutterWidths[bp] / 2;
      const breakpointProps = {};

      if (props[bp]) {
        if (props[bp] === "auto") {
          breakpointProps.flexBasis = "auto";
        } else {
          breakpointProps.flexBasis = `${width}%`;
          breakpointProps.maxWidth = `${width}%`;
        }
        breakpointProps.display = "block";
      }

      if (lastSetPadding !== padding) {
        breakpointProps.paddingLeft = `${padding}px`;
        breakpointProps.paddingRight = `${padding}px`;
      }

      // If not xs put media query around
      if (bp !== "xs") {
        preparedStyles[
          `@media (min-width: ${props.theme.breakpointsMap[bp].min}px)`
        ] = breakpointProps;
      } else {
        preparedStyles = { ...preparedStyles, ...breakpointProps };
      }
    }
  });

  return preparedStyles;
});

Col.propTypes = {
  children: PropTypes.node,
  xs: PropTypes.oneOfType([PropTypes.oneOf(["auto"]), PropTypes.number]),
  sm: PropTypes.oneOfType([PropTypes.oneOf(["auto"]), PropTypes.number]),
  md: PropTypes.oneOfType([PropTypes.oneOf(["auto"]), PropTypes.number]),
  lg: PropTypes.oneOfType([PropTypes.oneOf(["auto"]), PropTypes.number]),
  xl: PropTypes.oneOfType([PropTypes.oneOf(["auto"]), PropTypes.number]),
  flexGrow: PropTypes.number,
  flexShrink: PropTypes.number,
};

Col.defaultProps = {
  children: null,
  xs: 24,
  sm: undefined,
  md: undefined,
  lg: undefined,
  xl: undefined,
  flexGrow: 0,
  flexShrink: 0,
};

export default Col;
