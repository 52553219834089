import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
const StyledSection = styled.section `
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grey[7]};
  padding: 0.875rem 1.25rem 1.5rem;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;

  p {
    margin: 0;
  }
`;
export const CheckoutSection = (props) => (_jsx(StyledSection, { ...props }));
