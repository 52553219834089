import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useState } from "react";
import { z, } from "zod";
export const checkoutValidationDefaultValues = {
    errors: {
        formErrors: [],
        fieldErrors: {},
    },
    isValid: true,
    schemas: [z.object({})],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-empty-function
    setErrors: (errors) => { },
};
export const CheckoutValidationContext = createContext(checkoutValidationDefaultValues);
export const CheckoutValidationContextProvider = ({ children, schemas, }) => {
    const [errors, setErrors] = useState({
        formErrors: [],
        fieldErrors: {},
    });
    const hasError = Object.values(errors.fieldErrors).some((value) => Boolean(value));
    return (_jsx(CheckoutValidationContext.Provider, { value: { errors, isValid: !hasError, schemas, setErrors }, children: children }));
};
/**
 * Provides form errors and methods to modify the error state
 * for the current `CheckoutValidationContext`.
 *
 * Allows to `validate` data to either a given zod schema or to `validateAt`
 * a given `path` string that corresponds to an attribute in a zod schema.
 */
export const useValidation = () => {
    const { errors, isValid, setErrors, schemas } = useContext(CheckoutValidationContext);
    const addError = (path, message) => {
        setErrors({
            ...errors,
            fieldErrors: Array.isArray(path)
                ? {
                    ...errors.fieldErrors,
                    ...path.reduce((obj, path) => {
                        obj[path] = [
                            ...(errors.fieldErrors[path] ?? []),
                            {
                                message,
                                ref: document.getElementById(String(path)) ?? undefined,
                            },
                        ];
                        return obj;
                    }, {}),
                }
                : {
                    ...errors.fieldErrors,
                    [path]: [
                        ...(errors.fieldErrors[path] ?? []),
                        {
                            message,
                            ref: document.getElementById(String(path)) ?? undefined,
                        },
                    ],
                },
        });
    };
    const clearErrors = (path) => {
        if (path) {
            if (Array.isArray(path)) {
                setErrors({
                    ...errors,
                    fieldErrors: {
                        ...errors.fieldErrors,
                        ...path.reduce((obj, path) => {
                            obj[path] = undefined;
                            return obj;
                        }, {}),
                    },
                });
            }
            else {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { [path]: _, ...fieldErrors } = errors.fieldErrors;
                setErrors({
                    ...errors,
                    fieldErrors,
                });
            }
        }
        else {
            setErrors({ formErrors: [], fieldErrors: {} });
        }
    };
    const validate = (data, schemaOverwrite, params) => {
        clearErrors();
        return Promise.all((schemaOverwrite ?? schemas).map((schema) => schema.safeParseAsync(data, params))).then((results) => {
            const err = results
                .map((result) => {
                if (!result.success) {
                    if (result.error.issues[0].code === "invalid_union") {
                        // eslint-disable-next-line no-console
                        console.error("invalid inputs given for union: ", result.error.issues[0].unionErrors);
                    }
                    return result.error.flatten(({ message, path }) => {
                        return {
                            message,
                            ref: document.getElementById(String(path)) ?? undefined,
                        };
                    });
                }
            })
                // reduce all section errors to one flattened error object
                .reduce((err, result) => {
                return {
                    fieldErrors: {
                        ...err.fieldErrors,
                        ...(result && result.fieldErrors),
                    },
                    formErrors: { ...err.formErrors, ...(result && result.formErrors) },
                };
            }, {});
            const { fieldErrors, formErrors } = err;
            setErrors({
                fieldErrors: {
                    ...errors.fieldErrors,
                    ...fieldErrors,
                },
                formErrors: { ...errors.formErrors, ...formErrors },
            });
            if (err.fieldErrors || err.formErrors?.length > 0) {
                // eslint-disable-next-line no-console
                console.error(err.fieldErrors, err.formErrors);
            }
            scrollToFirstFieldWithError(err);
            return results.every(({ success }) => success);
        });
    };
    const validateAt = (path, schema, data, params) => {
        clearErrors(path);
        let partialSchema;
        // schema is an object
        if ("shape" in schema) {
            partialSchema = schema.shape[path];
        }
        // schema is a union
        if ("options" in schema) {
            const optionsWithField = schema.options.filter((option) => option.shape[path]);
            if (optionsWithField.length > 0) {
                if (optionsWithField.length === 1) {
                    partialSchema = optionsWithField[0].shape[path];
                }
                else {
                    return Promise.all(optionsWithField.map((option) => option.shape[path].safeParseAsync(data[path], params))).then((results) => {
                        if (results.some(({ success }) => success)) {
                            // early return since we have validated to at least one option
                            return true;
                        }
                        // we were not able to validate to any given option,
                        // so we accumulate errors for both options
                        const error = {
                            message: results
                                .map((result) => {
                                if (!result.success) {
                                    return result.error.flatten(({ message }) => ({ message }))
                                        .formErrors[0];
                                }
                            })
                                .filter((error) => error)
                                .reduce((message, result, index) => {
                                if (index === 0) {
                                    // we filtered for existence 3 lines before
                                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                    return result.message;
                                }
                                return message ?? result?.message ?? "";
                            }, ""),
                        };
                        setErrors({
                            ...errors,
                            fieldErrors: {
                                ...errors.fieldErrors,
                                [path]: [error],
                            },
                        });
                        return false;
                    });
                }
            }
        }
        if (!partialSchema) {
            // eslint-disable-next-line no-console
            console.error("PATH not found", path);
            return Promise.reject("path not found on schema or something is wrong with the schema");
        }
        return partialSchema
            .safeParseAsync(data[path], params)
            .then((result) => {
            if (!result.success) {
                setErrors({
                    ...errors,
                    fieldErrors: {
                        ...errors.fieldErrors,
                        [path]: result.error.flatten(({ message }) => ({ message }))
                            .formErrors,
                    },
                });
            }
            return result.success;
        });
    };
    const getFirstFieldWithErrorInDOM = (errors) => {
        if (!errors ||
            !errors.fieldErrors ||
            Object.keys(errors.fieldErrors).length < 1) {
            return undefined;
        }
        const elements = Object.values(errors.fieldErrors).map((errorsByPath) => errorsByPath?.[0].ref);
        const scrollHeights = elements.map((el) => Number(el?.scrollHeight));
        return elements.find((el) => el?.scrollHeight === Math.min(...scrollHeights));
    };
    const scrollToFirstFieldWithError = (errors) => {
        const el = getFirstFieldWithErrorInDOM(errors);
        if (el) {
            el.scrollIntoView({ behavior: "smooth" });
            el.focus();
        }
    };
    return {
        addError,
        clearErrors,
        errors,
        isValid,
        setErrors,
        validate,
        validateAt,
    };
};
