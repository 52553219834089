import React from "react";

import { Surface } from "@mm/ui";

import { OfferProvider } from "features/Offer";

import HeroHardware from "./layout/hardware";

import HeroTariff from "./layout/tariff";

const OfferHero = ({ data }) => {
  if (data.length === 0) {
    return (
      <Surface variant="narrow">
        <div>Es können keine Angebote angezeigt werden</div>
      </Surface>
    );
  }
  const firstItem = data[0];
  const isTariff = !firstItem.hardware;

  const Container = ({ children }) => (
    <OfferProvider
      rootInfoData={firstItem}
      extendedVariantsData={firstItem.hardware?.extendedVariants || null}
      isTariff={isTariff}
    >
      {children}
    </OfferProvider>
  );

  const children = isTariff ? <HeroTariff /> : <HeroHardware />;

  return <Container>{children}</Container>;
};

export default OfferHero;
