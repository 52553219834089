import React from "react";
import PropTypes from "prop-types";

import { Surface } from "@mm/ui";

import { ThemeProvider } from "@core/theme";

import { GridTeaserSection } from "../../components/organisms/Teaser";

const GridTeaser = ({ data, ...props }) => (
  <ThemeProvider>
    <Surface variant="narrow">
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <GridTeaserSection contents={data} {...props} />
    </Surface>
  </ThemeProvider>
);

GridTeaser.propTypes = {
  data: PropTypes.oneOfType([PropTypes.any]),
};

GridTeaser.defaultProps = {
  data: <></>,
};

export default GridTeaser;
