import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { variant as themeVariant } from "styled-system";

import { determinePriceParts } from "utils/price";

const StyledPrice = styled.mark(
  {
    display: "inline-flex",
    backgroundColor: "transparent",
    fontFamily: ({ theme }) => `${theme.fonts.price}`,
    fontSize: ({ size }) => {
      switch (size) {
        case "tiny":
          return "20px";
        case "xxs":
          return "22px";
        case "xs":
          return "26px";
        case "s":
          return "40px";
        case "m":
          return "52px";
        case "l":
        default:
          return "64px";
      }
    },
    letterSpacing: "0.07em",
    lineHeight: 1,
    paddingLeft: 5,
    marginRight: ({ preventMargin }) => (preventMargin ? 0 : "10px"),
    width: "100%",
    textAlign: "center",
  },
  themeVariant({
    scale: "priceDisplay",
    // https://github.com/styled-system/styled-system/issues/1145#issuecomment-587389487
    variants: {
      primary: {},
      secondary: {},
    },
  })
);

const Sup = styled.sup`
  position: relative;
  vertical-align: super;
  font-size: ${({ theme }) => theme.fontSizes[6]}px;
  top: ${({ theme }) => theme.space.xxs}px;
`;

const NoLineBreaks = styled.span`
  white-space: nowrap;
`;

const Price = ({
  size,
  price,
  className,
  forceCentDisplay,
  preventStyling,
  preventMargin,
  directPrice,
  variant,
}) => {
  let { euro, cent } = determinePriceParts(price, forceCentDisplay);

  if (Number.isInteger(price)) {
    euro = price;
    cent = "-";
  }

  if (preventStyling) {
    return (
      <NoLineBreaks data-codeception="price">
        € {euro || 0},{cent}
      </NoLineBreaks>
    );
  }

  if (directPrice) {
    return (
      <StyledPrice
        size={size}
        className={className}
        preventMargin={preventMargin}
        variant={variant}
        data-codeception="price"
      >
        {price}
      </StyledPrice>
    );
  }

  return preventStyling ? (
    <NoLineBreaks data-codeception="price">€ {price}</NoLineBreaks>
  ) : (
    <StyledPrice
      size={size}
      className={className}
      preventMargin={preventMargin}
      variant={variant}
      data-codeception="price"
    >
      {(euro || 0) + ","}
      <Sup>{cent}</Sup>
    </StyledPrice>
  );
};

Price.propTypes = {
  /**
   * Size of the price display
   */
  size: PropTypes.oneOf(["tiny", "xxs", "xs", "s", "m", "l"]),
  variant: PropTypes.oneOf(["primary", "secondary"]),
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  preventStyling: PropTypes.bool,
  preventMargin: PropTypes.bool,
  forceCentDisplay: PropTypes.bool,
  className: PropTypes.string,
};

Price.defaultProps = {
  size: "m",
  variant: "primary",
  className: undefined,
  preventStyling: false,
  preventMargin: false,
  forceCentDisplay: false,
};

export default Price;
