/* eslint-disable react/no-danger */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Headline } from "@mm/ui";

import StarSvg from "@core/svgs/sternchen.svg";

import { useLegalNote } from "utils/fetchData";

import GeneralModalShell from "../../atoms/GeneralModalShell";

const SvgWrapper = styled.span`
  position: relative;
  z-index: 1;

  svg {
    display: block;
    path {
      fill: ${({ stageStyle, theme }) =>
        stageStyle ? theme.colors.white : theme.colors.grey[4]};
    }
  }
`;

// we sometimes pass legalNotes directly. e.g. Hardware Legal Notes in HardwarePriceDisplay
// for all other cases we just use the tariffId
// AffOffer = components/organisms/AffiliateOffer
const LegalNotesContent = ({
  tariffId,
  legalNotes,
  tariffLegalNotes,
  AffOffer,
}) => {
  // TODO: Refactor useLegalNote, we want to use OfferConfigurator.
  const { data } = useLegalNote(tariffId, { enabled: !legalNotes });
  return (
    <>
      <Headline variant="h2">Rechtliche Hinweise</Headline>
      <>
        {tariffLegalNotes ? (
          <div>
            <div
              dangerouslySetInnerHTML={{
                __html: tariffLegalNotes,
              }}
            />
          </div>
        ) : (
          <div>
            <div
              dangerouslySetInnerHTML={{
                __html: legalNotes || data?.legal_note,
              }}
            />
          </div>
        )}
        {AffOffer && (
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: `${AffOffer}`,
            }}
          />
        )}{" "}
      </>
    </>
  );
};

LegalNotesContent.propTypes = {
  tariffLegalNotes: PropTypes.string,
  tariffId: PropTypes.string,
  legalNotes: PropTypes.string,
  AffOffer: PropTypes.string,
};

LegalNotesContent.defaultProps = {
  tariffLegalNotes: null,
  tariffId: null,
  legalNotes: null,
  AffOffer: "",
};

const LegalNotes = ({
  stageStyle,
  tariffId,
  tariffLegalNotes,
  legalNotes,
  AffOffer,
  ...rest
}) => {
  if (!tariffId && !legalNotes) {
    return null;
  }

  return (
    <GeneralModalShell
      aria-label="Rechtliche Tarif Details anzeigen."
      role="dialog"
      aria-modal="true"
      // use rest here in order to populate styled system props (compose, space etc..)
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      opener={
        <SvgWrapper
          stageStyle={stageStyle}
          aria-label="Rechtliche Tarif Details anzeigen"
          role="dialog"
          aria-modal="true"
        >
          <StarSvg width={10} height={10} />
        </SvgWrapper>
      }
    >
      <LegalNotesContent
        tariffId={tariffId}
        legalNotes={legalNotes}
        tariffLegalNotes={tariffLegalNotes}
        AffOffer={AffOffer}
      />
    </GeneralModalShell>
  );
};

// tariffLegalNotes from acp "Rechtliche Hinweise (Fußnote), controlled via offer alias bundle"
LegalNotes.propTypes = {
  stageStyle: PropTypes.bool,
  tariffId: PropTypes.string,
  tariffLegalNotes: PropTypes.string,
  legalNotes: PropTypes.string,
  AffOffer: PropTypes.string,
};

LegalNotes.defaultProps = {
  stageStyle: false,
  tariffLegalNotes: null,
  AffOffer: null,
  tariffId: null,
  legalNotes: null,
};

export default LegalNotes;
