import React, { useRef } from "react";
import PropTypes from "prop-types";
import { useBtnPosition, Box } from "@mm/ui";

import { ShippingHint, PriceEntry } from "sections/index";

import { HARDWARE, useOfferConfigurator } from "features/Offer";

import { Price, PriceBox, StickyElement } from "components/atoms";

import { CartButton, TariffMonthlyPriceLabel } from "components/molecules";

import { BottomPriceBanner } from "components/organisms";

import {
  extendedVariantPropTypes,
  textVariantsPropTypes,
} from "sharedPropTypes";

const TariffPriceSection = ({
  priceShippingInfo,
  customExtendedVariant,
  sticky,
  priceTextVariant,
  shippingTextVariant,
  deliveryInfo,
  ...props
}) => {
  const cartBtnRef = useRef(null);
  const cartBtnPosition = useBtnPosition(cartBtnRef);

  const {
    state: {
      extendedVariant: storeExtendedVariant,
      activeTariff,
      selectedAccessoryType,
      stock,
      isSoldOut,
    },
  } = useOfferConfigurator();

  const extendedVariant = customExtendedVariant || storeExtendedVariant;

  if (!activeTariff) return null;

  // tariff related prices
  const {
    monthlyPrice,
    pricingIntervals: { intervals },
    calculations: { averageCalculatedMonthlyPrice },
    commission,
  } = activeTariff;

  const priceMonthlySub = intervals[1] && (
    <span>
      Ab dem {intervals[1].startMonth}. Monat{" "}
      <Price price={intervals[1].price} preventStyling preventMargin />
    </span>
  );

  return (
    <Box
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <PriceBox
        monthlyPriceRow={
          monthlyPrice ? (
            <PriceEntry
              textVariant={priceTextVariant}
              highlight={false}
              price={commission ? averageCalculatedMonthlyPrice : monthlyPrice}
              priceStr={<TariffMonthlyPriceLabel commission={commission} />}
              sub={priceMonthlySub || ""}
              tariffId={activeTariff.id}
            />
          ) : (
            <></>
          )
        }
        cartBtn={
          <>
            <CartButton isDisabled={isSoldOut} />
            {/* avoid to render div with margin top and
                        bottom taking unnecessary space */}
            <Box m={0} ref={cartBtnRef} />
          </>
        }
        shippingHint={
          stock !== 0 && (
            <ShippingHint
              textVariant={shippingTextVariant}
              deliveryInfo={deliveryInfo}
              priceShippingInfo={priceShippingInfo}
            />
          )
        }
      />
      {cartBtnPosition !== 0 && sticky && (
        <StickyElement position="bottomleft" showAfterScroll={cartBtnPosition}>
          <BottomPriceBanner
            priceMonthly={activeTariff.monthlyPrice}
            priceOnce={
              customExtendedVariant?.variant.price ||
              (selectedAccessoryType === HARDWARE &&
                extendedVariant?.variant.price) ||
              0
            }
            tariffId={activeTariff.id}
          />
        </StickyElement>
      )}
    </Box>
  );
};

TariffPriceSection.propTypes = {
  priceOnceSub: PropTypes.string,
  priceShippingInfo: PropTypes.node,
  customExtendedVariant: extendedVariantPropTypes,
  sticky: PropTypes.bool,
  showOnePriceRow: PropTypes.bool,
  priceTextVariant: textVariantsPropTypes,
  shippingTextVariant: textVariantsPropTypes,
};

TariffPriceSection.defaultProps = {
  priceOnceSub: "",
  priceShippingInfo: null,
  customExtendedVariant: null,
  sticky: false,
  showOnePriceRow: true,
  priceTextVariant: "copy",
  shippingTextVariant: "copy",
};

export default TariffPriceSection;
