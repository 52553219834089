import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Icon } from "@mm/ui";

import { giftcardPropTypes } from "sharedPropTypes";
import GiftcardDisplay from "../../atoms/GiftcardDisplay";
import TariffBadge from "../../atoms/TariffBadge";

const TariffDisplayContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const GiftcardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 6px;
`;

const StyledGiftcardDisplay = styled(GiftcardDisplay)`
  width: 110px;
  margin-left: -14px;
  line-height: 0;
`;

const TariffDisplay = ({ tariff, giftcard, framed }) => (
  <TariffDisplayContainer>
    <TariffBadge tariff={tariff} framed={framed} />
    {giftcard && (
      <GiftcardContainer>
        <Icon name="plus" variant="teaser" />
        <StyledGiftcardDisplay giftcard={giftcard} size="xs" />
      </GiftcardContainer>
    )}
  </TariffDisplayContainer>
);

TariffDisplay.propTypes = {
  tariff: TariffBadge.propTypes.tariff,
  giftcard: giftcardPropTypes,
  framed: PropTypes.bool,
};

TariffDisplay.defaultProps = {
  giftcard: undefined,
  tariff: null,
  framed: true,
};

export default TariffDisplay;
