import React from "react";
import styled from "styled-components";

const InfoText = styled("p")`
  font-size: ${({ theme }) => theme.fontSizes[3]}px;
  font-weight: ${({ theme }) => theme.typography.weight.normal};

  @media (min-width: ${({ theme }) => theme.breakpointsMap.md.min}px) {
    font-size: ${({ theme }) => theme.fontSizes[5]}px;
    font-weight: ${({ theme }) => theme.typography.weight.highlighted};
  }
`;

const HardwareInfoText = ({ children }) => <InfoText>{children}</InfoText>;

export default HardwareInfoText;
