import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCheckoutState } from "../../context/CheckoutContext";
import { useCheckout } from "../../hooks/api";
import { CheckoutSection, OverviewGrid } from "../../components";
import { getProviderNameByCode } from "../../providers";
import { ConnectionType } from "@/types";
import { getBuildingAlignmentLabel, getBuildingFloorLabel, getBuildingTypeLabel, getInstallationTimeWindowLabel, InstallationTimeWindow, PhoneNumberAmount, } from "../../types";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ConnectionOverviewSection = (props) => {
    const { state } = useCheckoutState();
    const { serviceProvider } = state;
    const { data } = useCheckout();
    return (_jsx(CheckoutSection, { children: _jsx(OverviewGrid, { items: [
                {
                    title: "Anschlussadresse",
                    content: (_jsx(_Fragment, { children: _jsxs("p", { children: [data?.availability.address.street, " ", data?.availability.address.streetNumber, _jsx("br", {}), data?.availability.address.zip, " ", data?.availability.address.city] }) })),
                },
                {
                    title: "Anzahl Rufnummern",
                    hide: !data?.data.phoneNumberAmount,
                    content: data?.data.phoneNumberAmount === PhoneNumberAmount.Single
                        ? "1 Rufnummer (analoger Anschluss) 0,00€/mtl."
                        : "bis zu 10 Rufnummern (ISDN Anschluss) 2,99€/mtl.",
                },
                {
                    title: "Gebäude",
                    hide: !data?.data.buildingType,
                    content: (_jsxs(_Fragment, { children: [_jsx("p", { children: data?.data.buildingType &&
                                    getBuildingTypeLabel(data.data.buildingType) }), data?.data.buildingFloor && (_jsx("p", { children: data?.data.buildingFloor &&
                                    getBuildingFloorLabel(data.data.buildingFloor) })), data?.data.buildingAlignment && (_jsx("p", { children: data?.data.buildingAlignment &&
                                    getBuildingAlignmentLabel(data.data.buildingAlignment) }))] })),
                },
                {
                    title: "Anbieterwechsel",
                    content: (_jsxs(_Fragment, { children: [_jsx("p", { children: data?.data.providerChange ? "Ja" : "Nein" }), data?.data.providerChange && data?.data.formerProvider && (_jsxs("p", { children: ["Bisheriger Anbieter:", " ", getProviderNameByCode(data.data.formerProvider, serviceProvider)] }))] })),
                },
                {
                    title: "Rufnummernmitnahme",
                    content: data?.data.numberPorting ? (_jsxs(_Fragment, { children: [_jsx("p", { children: "Ja" }), _jsxs("p", { children: ["Rufnummer: ", data?.data.currentProviderNumberPrefix, " ", data?.data.currentProviderNumberExtension] })] })) : ("Keine Rufnummernmitnahme"),
                },
                {
                    title: "Bisherige Rufnummer",
                    content: `${data?.data.currentProviderNumberPrefix} ${data?.data.currentProviderNumberExtension}`,
                    hide: Boolean(data?.data.numberPorting),
                },
                {
                    title: "Alleiniger Anschlussinhaber",
                    content: data?.data.solePhoneSubscriber ? ("Ja") : (_jsxs(_Fragment, { children: [_jsx("p", { children: "Nein" }), _jsxs("p", { children: ["Weiterer Anschlussinhaber:", " ", data?.data.phoneSubscriberFirstName, " ", data?.data.phoneSubscriberLastName] })] })),
                    hide: data?.summary.tariff.connectionType !== ConnectionType.dsl,
                },
                {
                    title: "Aktivierungszeitpunkt",
                    content: data?.data.activationDate,
                },
                {
                    title: "Wunschzeitfenster für die Installation",
                    content: data?.data.installationTimeWindow ===
                        InstallationTimeWindow.NotSpecified
                        ? getInstallationTimeWindowLabel(InstallationTimeWindow.NotSpecified)
                        : data?.data.installationTimeWindow
                            ? getInstallationTimeWindowLabel(data.data.installationTimeWindow)
                            : undefined,
                    hide: data?.summary.tariff.connectionType !== ConnectionType.dsl,
                },
            ] }) }));
};
