import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Flex } from "@mm/ui";

const SMARTPHONE_URL = "/smartphones";
const INTERNET_URL = "/dsl-und-internet";

const AlternativeOfferWrapper = styled.div`
  background: ${(props) => `url(${props.backgroundUrl}) no-repeat`};
  position: relative;
  color: white;
  width: 100%;
  height: 100%;
  border-radius: 4px;
`;

const StyledAlternativeOffer = styled.div`
  padding: 1em;
  width: 100%;
  height: 100%;
  display: block;
`;

const StyledHeadline = styled.h2`
  margin-bottom: 15px;
`;

const StyledDescription = styled.p``;

const StyledLink = styled.a`
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  color: #ffffff;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    display: block;
    bottom: -2px;
    height: 1px;
    left: 0px;
    width: calc(100%);
    background-color: white;
    transform-origin: right center;
    transform: scale(0, 1);
    transition: transform 0.25s cubic-bezier(0.37, 0.31, 0.2, 0.85) 0s;
  }
  &:hover {
    color: #ffffff;
  }
  &:hover::after {
    transform-origin: left center;
    transform: scale(1, 1);
  }
`;
const StyledSpan = styled.span`
  display: block;
`;

const StyledImage = styled.img`
  display: block;
  width: 135px;
`;

const phoneContent = {
  headline: "Bei uns gibt es auch DSL und Internet",
  description:
    "Ganz einfach Verfügbarkeit prüfen und Highspeed Internet sichern.",
  linkText: "Verfügbarkeit prüfen",
  linkTarget: `${INTERNET_URL}`,
  imageSrc: `${window.CDN_URL}/img/orderconfirmation/router.png`,
  imageAlt: "Router Bild",
  trackingEvent: "AlternativeOffer Internet",
};

const internetContent = {
  headline: "Entdecke unsere Mobilfunk-Vielfalt",
  description:
    "Bei uns gibt es auch eine attraktive Vielfalt an Smartphones mit Tarifen.",
  linkText: "Jetzt entdecken",
  linkTarget: `${SMARTPHONE_URL}`,
  imageSrc: `${window.CDN_URL}/img/orderconfirmation/smartphones.png`,
  imageAlt: "Smartphone Bild",
  trackingEvent: "AlternativeOffer Mobilfunk",
};

const handleTracking = (text) => {
  window.dataLayer.push({
    event: "gaEvent",
    eventCategory: "Button",
    eventAction: "click",
    eventLabel: `${text}`,
  });
};

const AlternativeOfferTeaser = ({ checkoutType }) => {
  const currentContent =
    checkoutType === "default" ? phoneContent : internetContent;
  return (
    <>
      <AlternativeOfferWrapper
        backgroundUrl={`${window.CDN_URL}/img/orderconfirmation/background.png`}
      >
        <StyledAlternativeOffer>
          <StyledHeadline>{currentContent?.headline}</StyledHeadline>
          <Flex alignItems="flex-end" justifyContent="space-between">
            <div>
              <StyledDescription>
                {currentContent?.description}
              </StyledDescription>
              <StyledLink
                target="_blank"
                onClick={() => handleTracking(currentContent?.trackingEvent)}
                href={currentContent?.linkTarget}
              >
                <StyledSpan>{currentContent?.linkText}</StyledSpan>
              </StyledLink>
            </div>
            <div>
              <StyledImage
                src={currentContent?.imageSrc}
                alt={currentContent?.imageAlt}
              />
            </div>
          </Flex>
        </StyledAlternativeOffer>
      </AlternativeOfferWrapper>
    </>
  );
};

AlternativeOfferTeaser.defaultProps = {
  checkoutType: "default",
};

AlternativeOfferTeaser.propTypes = {
  checkoutType: PropTypes.string,
};

export default AlternativeOfferTeaser;
