export const formatNumberAsCurrencyString = (value, hideCurrencySymbol = false) => {
    if (value === undefined) {
        return undefined;
    }
    if (hideCurrencySymbol) {
        return Intl.NumberFormat("de-DE", {
            style: "currency",
            currency: "EUR",
            currencyDisplay: "code",
        })
            .format(value)
            .replace("EUR", "")
            .trim();
    }
    return Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
    }).format(value);
};
