/* eslint-disable prettier/prettier */
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Headline, Box, Text, Row, Col, Image } from "@mm/ui";

import { Price, HardwareInfoText } from "components/atoms";

import TariffMonthlyPriceLabel from "../TariffMonthlyPriceLabel";

const ImageContainer = styled("div")`
  position: relative;
  text-align: center;

  img {
    box-sizing: border-box;
    max-height: 200px;
    max-width: 200px;
  }
`;

const ProviderImage = styled("div")`
  position: absolute;
  right: 25%;
  bottom: 25%;
`;

const CartModalItem = ({
  itemName,
  isDslHardware,
  itemDetails,
  itemImage,
  tariffImage,
  tariffName,
  tariffCommission,
  monthlyPrice,
  oncePrice,
  button,
}) => (
  <Row height="100%">
    <Col>
      <Headline variant="h3">{itemName}</Headline>
    </Col>
    <Col>
      <HardwareInfoText>
        {!isDslHardware && itemDetails}
        {tariffName && (isDslHardware ? tariffName : ` mit ${tariffName}`)}
      </HardwareInfoText>
    </Col>
    <Col>
      <ImageContainer>
        {typeof itemImage[0] == "object" ? (
          <Image srcSet={itemImage[0]} alt={""} />
        ) : (
          itemImage
        )}
        {tariffImage && <ProviderImage>{tariffImage}</ProviderImage>}
      </ImageContainer>
    </Col>
    {monthlyPrice && (
      <Col>
        <Row flexDirection="row" justifyContent="space-between" my={3}>
          <Col xs="auto">
            <TariffMonthlyPriceLabel commission={tariffCommission} />
          </Col>
          <Col xs="auto">
            <Price preventStyling preventMargin price={monthlyPrice} />*
          </Col>
        </Row>
      </Col>
    )}
    {oncePrice && (
      <Col>
        <Row flexDirection="row" justifyContent="space-between" my={3}>
          <Col xs="auto">
            <Box mb={[4, 4, 4, 0]}>
              <Text>Einmaliger Gerätepreis</Text>
            </Box>
          </Col>
          <Col xs="auto">
            <Price price={oncePrice} size="s" />
          </Col>
        </Row>
      </Col>
    )}
    <Col>
      <Row
        flexDirection="row"
        justifyContent="center"
        alignItems="flex-end"
        height="100%"
      >
        {button}
      </Row>
    </Col>
  </Row>
);

CartModalItem.defaultProps = {
  button: null,
  tariffCommission: 0,
  isDslHardware: false,
  oncePrice: null,
  itemDetails: null,
  tariffImage: null,
  tariffName: null,
};

CartModalItem.propTypes = {
  itemName: PropTypes.string.isRequired,
  itemDetails: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  itemImage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.node,
  ]).isRequired,
  tariffImage: PropTypes.node,
  isDslHardware: PropTypes.bool,
  tariffName: PropTypes.string,
  tariffCommission: PropTypes.number,
  monthlyPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  oncePrice: PropTypes.number,
  button: PropTypes.node,
};

export default CartModalItem;
