import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
const Grid = styled.div `
  display: grid;
  ${({ multiple }) => multiple && "grid-template-columns: repeat(2, minmax(0, 1fr));"};
  gap: 1rem;
  @media (max-width: 760px) {
    grid-template-columns: auto;
  }
`;
const GridItem = styled.div ``;
// TODO: loading states
export const OverviewGrid = ({ items }) => (_jsx(Grid, { multiple: items.length > 1, "data-tk-cobrowse-hidden": "true", children: items.map(({ title, content, hide }) => {
        return !hide ? (_jsxs(GridItem, { children: [_jsx("h3", { children: title }), typeof content === "string" ? _jsx("p", { children: content }) : content] }, title)) : null;
    }) }));
