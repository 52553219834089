import React from "react";
import PropTypes from "prop-types";

import { Headline } from "@mm/ui";

export const ProductHeadline = ({ variant, tariff: { carrier, name } }) => {
  let carrierString = carrier;

  switch (carrier) {
    case "Vodafone klarmobil":
      carrierString = "klarmobil";
      break;

    case "Telekom klarmobil":
      carrierString = "klarmobil";
      break;

    default:
      break;
  }

  return (
    <Headline variant={variant}>
      {carrierString} {name.replace("Super Select", "")}
    </Headline>
  );
};

ProductHeadline.propTypes = {
  variant: PropTypes.string.isRequired,
  tariff: PropTypes.shape({
    carrier: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default ProductHeadline;
