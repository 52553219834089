import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Wrapper = styled.div`
  align-items: center;
  border: solid 1px ${(props) => props.theme.colors.grey[3]};
  border-radius: 4px;
  display: flex;
  height: 32px;
  justify-content: center;
  width: 48px;

  svg {
    height: auto;
    max-height: 70%;
    width: 70%;
    path {
      fill: ${(props) => props.theme.colors.grey[2]};
    }
  }

  @media (min-width: ${(props) => props.theme.breakpointsMap.md.min}px) {
    height: 38px;
    width: 54px;
  }

  @media (min-width: ${(props) => props.theme.breakpointsMap.lg.min}px) {
    height: 48px;
    width: 80px;
  }
`;

const IconButton = ({ svg }) => <Wrapper>{svg}</Wrapper>;

IconButton.propTypes = {
  svg: PropTypes.node.isRequired,
};
export default IconButton;
