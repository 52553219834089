import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { useViewport } from "@core/theme";
import { teaserContentPropTypes } from "sharedPropTypes";

import { Slider } from "components/molecules";

import { addTrackFunction } from "../helper";
import AdTeaser from "../../AdTeaser";

const Container = styled.div`
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 24px;
  height: 256px;

  .teaser0 {
    grid-column: span 2;
  }

  .teaser1 {
    grid-column: span 1;

    img {
      height: 100%;
      width: auto;
    }
  }
`;

const TwoToOneTeaserSection = ({ contents, type }) => {
  const viewport = useViewport();
  return (
    <>
      {viewport.lessThan.md ? (
        <Slider>
          {contents.map((teaser, idx) => (
            <AdTeaser
              className={`teaser${idx}`}
              content={teaser}
              key={`teaser${idx}`}
              onClick={addTrackFunction(teaser.linkUrl, type.type, idx)}
              qaName={`teaser${idx}`}
              type={teaser.type}
              viewport={viewport}
            />
          ))}
        </Slider>
      ) : (
        <Container>
          {contents.map((teaser, idx) => (
            <AdTeaser
              className={`teaser${idx}`}
              content={teaser}
              key={`teaser${idx}`}
              onClick={addTrackFunction(teaser.linkUrl, type.type, idx)}
              qaName={`teaser${idx}`}
              type={teaser.type}
              viewport={viewport}
            />
          ))}
        </Container>
      )}
    </>
  );
};

TwoToOneTeaserSection.propTypes = {
  contents: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      props: PropTypes.shape({
        content: teaserContentPropTypes.isRequired,
      }),
    })
  ).isRequired,
  type: PropTypes.string.isRequired,
};

export default TwoToOneTeaserSection;
