import React from "react";

import PropTypes from "prop-types";

import { TariffBadge } from "components/atoms";

// FIXME refactor components (dependency cycle)
import CartModalItem from "../CartModalItem";

const CartModalItemForSmartphone = ({
  hardware: { manufacturer, name: hardwareName },
  variant: { storage, color },
  tariff: { serviceProvider, carrier, name: tariffName, commission },
  prices: { once, monthly },
  accessories,
  image,
  buttonAction,
}) => {
  let preparedName = `${manufacturer} ${hardwareName}`;
  if (accessories && accessories.length) {
    const mappedAccessories = accessories.map(
      ({ manufacturer: accessoryManufacturer, name }) =>
        `${accessoryManufacturer} ${name}`
    );
    preparedName += ` + ${mappedAccessories.join(" & ")}`;
  }

  return (
    <CartModalItem
      itemName={preparedName}
      itemDetails={`${storage}GB, ${color}`}
      itemImage={image}
      tariffCommission={commission}
      tariffImage={
        <TariffBadge
          tariff={{
            serviceProvider,
            carrier,
          }}
        />
      }
      tariffName={`${serviceProvider} ${tariffName}`}
      monthlyPrice={
        // the old backend (add-to-cart) returns price in float -
        // to not mess up with decimal precision we Math.round it
        monthly
      }
      oncePrice={once}
      button={buttonAction}
    />
  );
};

CartModalItemForSmartphone.propTypes = {
  hardware: PropTypes.shape({
    manufacturer: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  variant: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    storage: PropTypes.any,
    color: PropTypes.string,
  }).isRequired,
  tariff: PropTypes.shape({
    serviceProvider: PropTypes.string,
    name: PropTypes.string,
    carrier: PropTypes.string,
    commission: PropTypes.number,
  }).isRequired,
  prices: PropTypes.shape({
    once: PropTypes.number,
    monthly: PropTypes.number,
  }).isRequired,
  accessories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      manufacturer: PropTypes.string,
    })
  ).isRequired,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  buttonAction: PropTypes.element.isRequired,
};

export default CartModalItemForSmartphone;
