import React from "react";

/**
 *
 * PAGES
 *
 * */

const SmartphonePDP = React.lazy(() => import("./HardwareProductDetailPage"));
const TariffPDP = React.lazy(() => import("./TariffProductDetailPage"));
const Homepage = React.lazy(() => import("./Homepage"));
const HardwareListingPage = React.lazy(() => import("./HardwareListingPage"));
const CheckoutPage = React.lazy(() => import("./CheckoutPage"));
const TariffListingPage = React.lazy(() => import("./TariffListingPage"));

const Pages = {
  SmartphonePDP,
  TariffPDP,
  Homepage,
  // eslint-disable-next-line react/display-name
  HardwareListingPage: (type) => (
    <HardwareListingPage
      queryParams={{
        type,
      }}
    />
  ),
  // eslint-disable-next-line react/display-name
  CheckoutPage: (type) => <CheckoutPage type={type} />,
  TariffListingPage,
};

export default Pages;
