export default function conditionalPropType(condition, message) {
  if (typeof condition !== "function") {
    throw new Error(
      "Wrong argument type 'condition' supplied to 'conditionalPropType'"
    );
  }

  return (props, propName, componentName) => {
    if (condition(props, propName, componentName)) {
      return new Error(
        `Invalid prop '${propName}' '${props[propName]}' supplied to '${componentName}'. ${message}`
      );
    }

    return null;
  };
}
