import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Flex, Box } from "@mm/ui";

import PriceComp from "../../atoms/Price";

import LegalNotes from "../LegalNotes";

const Container = styled.div`
  display: ${({ inline }) => (inline ? "inline-flex" : "flex")};
  align-items: ${({ direction }) =>
    direction !== "row" ? "flex-start" : "flex-end"};
  flex-direction: ${({ direction }) => direction || "column"};

  ${(props) => {
    switch (props.className) {
      case "quarterWidth":
        return `
                    display: block;
                    width: 58%;
                `;
      default:
        return null;
    }
  }}
`;

const Span = styled.span`
  color: ${(props) =>
    props.stageStyle ? props.theme.colors.white : "inherit"};
  display: block;
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
  margin-left: ${({ stageStyle }) => (stageStyle ? "auto" : 0)};
  text-align: ${(props) => (props.stageStyle ? "right" : "left")};
`;

const Pretext = styled(Span)`
  ${({ direction }) =>
    direction !== "row" ? "margin-bottom: 0.5em" : "margin-right: 0.5em"};
`;

const FlexRow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
`;

const Buttontext = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  border: solid 1px ${(props) => props.theme.colors.grey[2]};
  border-radius: 4px;
  display: inline-block;
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 6px;
  padding: 0 3px;
`;

const Price = styled(PriceComp)({
  marginRight: ({ theme: { space } }) => `${space.xs}px`,
});

const SupLegalNotes = styled(LegalNotes)`
  svg {
    position: relative;
    top: -1px;
    left: 2px;
  }
`;

const PriceDisplay = ({
  buttontext,
  className,
  tariffLegalNotes,
  stageStyle,
  tariffId,
  posttext,
  pretext,
  price,
  size,
  direction,
  inline,
}) => {
  const posttexts = Array.isArray(posttext) ? posttext : [posttext];
  return (
    <Container className={className} direction={direction} inline={inline}>
      {buttontext ? (
        <FlexRow>
          <Buttontext>{buttontext}</Buttontext>
        </FlexRow>
      ) : (
        ""
      )}
      {pretext && (
        <Pretext size={size} stageStyle={stageStyle} direction={direction}>
          {pretext}
        </Pretext>
      )}
      <Flex>
        <Price
          size={size}
          price={price}
          variant={stageStyle ? "secondary" : "primary"}
        />
        <LegalNotes
          tariffLegalNotes={tariffLegalNotes}
          tariffId={tariffId}
          stageStyle={stageStyle}
        />
      </Flex>
      {posttexts.length > 0 && (
        <Box mt={2}>
          {posttexts.map(
            (text) =>
              text && (
                <Flex mt={1} key={text}>
                  <Span size={size}>
                    {text}
                    <SupLegalNotes
                      tariffLegalNotes={tariffLegalNotes?.text}
                      tariffId={tariffId}
                      stageStyle={stageStyle}
                    />
                  </Span>
                </Flex>
              )
          )}
        </Box>
      )}
    </Container>
  );
};

PriceDisplay.propTypes = {
  direction: PropTypes.oneOf(["row", "column"]),
  buttontext: PropTypes.string,
  className: PropTypes.string,
  stageStyle: PropTypes.bool,
  tariffId: PropTypes.string,
  posttext: PropTypes.node,
  pretext: PropTypes.node,
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  size: PropTypes.oneOf(["tiny", "xs", "s", "m", "l"]).isRequired,
  inline: PropTypes.bool,
};

PriceDisplay.defaultProps = {
  buttontext: "",
  posttext: "",
  pretext: "",
  direction: "row",
  className: "",
  stageStyle: false,
  tariffId: null,
  inline: false,
};

export default PriceDisplay;
