import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import { LegalNoteAsterisk } from "@/components/atoms";
import { ArticleSelectList, } from "@/components/molecules";
import { ServiceProvider } from "@/types";
import { formatNumberAsCurrencyString } from "@/utils/price/formatNumberAsCurrencyString";
import { ACTIONS as ModalActions, useModal, } from "@/utils/context/ModalContext";
export const TariffSelectList = ({ disabled, onChange, tariffs, }) => {
    const { dispatch } = useModal();
    const transformTariff = (tariff) => ({
        ...tariff,
        eyeCatchers: [
            tariff.eyeCatchers?.small1 ?? "",
            tariff.eyeCatchers?.small2 ?? "",
        ].filter((eyeCatcher) => eyeCatcher),
        ...(tariff.serviceProvider && {
            contractPartner: ServiceProvider[tariff.serviceProvider],
            contractPartnerInfo: tariff.info,
        }),
        price: (_jsxs(_Fragment, { children: ["nur ", formatNumberAsCurrencyString(tariff.monthlyPrice), " ", tariff.legalNote && _jsx(LegalNoteAsterisk, { legalNote: tariff.legalNote }), "monatlich"] })),
        nextPriceInterval: tariff.pricingIntervals.intervals[1]
            ? `ab dem ${tariff.pricingIntervals.intervals[1].startMonth}. Monat ${formatNumberAsCurrencyString(tariff.pricingIntervals.intervals[1].price)}`
            : undefined,
        ...(tariff.internetDetail?.eyeCatcher && {
            subline: tariff.internetDetail.eyeCatcher,
            sublineInfo: tariff.internetDetail.eyeCatcherInfo,
        }),
        isSelected: tariffs.length == 1 ? true : tariff.isSelected,
    });
    const [aggregatedTariffs, setAggregatedTariffs] = useState(tariffs.map(transformTariff));
    // const aggregatedTariffs: IArticleSelectListItem[] =
    //   tariffs.map(transformTariff);
    useEffect(() => {
        setAggregatedTariffs(tariffs.map(transformTariff));
    }, [tariffs]);
    return (_jsx(ArticleSelectList, { articles: aggregatedTariffs, disabled: disabled, metaLinks: [
            {
                text: "Tarifdetails",
                onClick: (details) => dispatch({
                    type: ModalActions.SET_MODAL,
                    payload: {
                        details,
                        title: "Tarifdetails",
                        type: "tariffDetails",
                    },
                }),
            },
            {
                text: "Produktdatenblatt",
                target: "_blank",
                urlKey: "pibUrl",
            },
        ], onChange: (selectedArticles) => {
            if (!disabled) {
                onChange(selectedArticles[0]);
            }
        } }));
};
