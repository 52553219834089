import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { noop } from "lodash";

import Arrow from "@core/svgs/arrow.svg";

const StyledButton = styled.button`
  position: relative;
  height: 32px;
  width: 32px;
  padding: 0;
  border-radius: 4px;
  background-color: ${(props) =>
    props.disabled ? props.theme.colors.grey[3] : props.theme.colors.grey[0]};
  border: solid 2px
    ${(props) =>
      props.disabled ? props.theme.colors.grey[3] : props.theme.colors.grey[0]};
  user-select: none;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

  :hover,
  :active {
    background-color: ${(props) =>
      props.disabled ? props.theme.colors.grey[3] : props.theme.colors.grey[4]};
    border: solid 2px
      ${(props) =>
        props.disabled
          ? props.theme.colors.grey[3]
          : props.theme.colors.grey[4]};
    box-shadow: ${(props) =>
      props.disabled
        ? "none"
        : "0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 5px 0 rgba(0, 0, 0, 0.15)"};
  }

  svg {
    position: absolute;
    top: calc(50% - 6px);
    left: ${(props) => (props.right ? "calc(50% - 5px)" : "calc(50% - 7px)")};
    transform: ${(props) => (props.right ? "rotate(90deg)" : "rotate(-90deg)")};
    fill: ${(props) => props.theme.colors.white};
  }

  &.inside {
    ${(props) =>
      props.right
        ? "border-top-right-radius: 0; border-bottom-right-radius: 0;"
        : "border-top-left-radius: 0; border-bottom-left-radius: 0;"};
  }

  &.outside {
    ${(props) =>
      props.right
        ? "border-top-left-radius: 0; border-bottom-left-radius: 0;"
        : "border-top-right-radius: 0; border-bottom-right-radius: 0;"};
  }

  &.round {
    width: 48px;
    height: 48px;
    border-radius: 50%;
  }

  &.contrast {
    background-color: ${(props) =>
      props.disabled ? props.theme.colors.grey[2] : props.theme.colors.white};
    border: solid 2px
      ${(props) =>
        props.disabled ? props.theme.colors.grey[2] : props.theme.colors.white};

    :hover,
    :active {
      background-color: ${(props) =>
        props.disabled
          ? props.theme.colors.grey[2]
          : props.theme.colors.grey[2]};
      border: solid 2px
        ${(props) =>
          props.disabled
            ? props.theme.colors.grey[2]
            : props.theme.colors.grey[2]};
      box-shadow: ${(props) =>
        props.disabled
          ? "none"
          : "0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 5px 0 rgba(0, 0, 0, 0.15)"};
    }

    svg {
      fill: ${(props) => props.theme.colors.grey[0]};
    }
  }

  &.ghost {
    background-color: transparent;
    border: none;

    svg {
      fill: ${(props) =>
        props.disabled
          ? props.theme.colors.grey[2]
          : props.theme.colors.grey[0]};
    }

    &:hover,
    &:active {
      background-color: transparent;
      box-shadow: none;
      border: none;
      text-decoration: underline;

      svg {
        fill: ${(props) =>
          props.disabled
            ? props.theme.colors.grey[2]
            : props.theme.colors.grey[4]};
      }
    }

    &.contrast {
      svg {
        fill: ${(props) =>
          props.disabled
            ? props.theme.colors.grey[2]
            : props.theme.colors.white};
      }

      &:hover,
      &:active {
        svg {
          fill: ${(props) => props.theme.colors.grey[2]};
        }
      }
    }
  }
`;

const NavigationButton = (props) => {
  const { className, disabled, onClick, right, ariaLabel } = props;

  const clickHandler = (ev) => {
    if (!disabled && onClick) {
      onClick(ev);
    }
  };

  return (
    <StyledButton
      className={className}
      disabled={disabled}
      right={right}
      onClick={clickHandler}
      aria-label={ariaLabel}
    >
      <Arrow aria-hidden="true" />
    </StyledButton>
  );
};

NavigationButton.propTypes = {
  disabled: PropTypes.bool,
  right: PropTypes.bool,
  className: PropTypes.string,
  ariaLabel: PropTypes.string,
  onClick: PropTypes.func,
};

NavigationButton.defaultProps = {
  disabled: false,
  right: false,
  ariaLabel: "",
  className: "",
  onClick: noop,
};

export default NavigationButton;
