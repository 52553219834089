import { useEffect, useRef } from "react";
// Custom Hook: useInterval
export function useInterval(callback, delay, timeout = null, deps = [], onTimeout = null) {
    const savedCallback = useRef();
    // Remember the latest callback if it changes.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);
    // Set up the interval.
    useEffect(() => {
        function tick() {
            if (savedCallback.current) {
                savedCallback.current();
            }
        }
        if (delay !== null) {
            const id = setInterval(tick, delay);
            // Set up the timeout if specified.
            if (timeout !== null) {
                const timeoutId = setTimeout(() => {
                    clearInterval(id);
                    if (onTimeout) {
                        onTimeout();
                    }
                }, timeout);
                return () => {
                    clearInterval(id);
                    clearTimeout(timeoutId);
                };
            }
            return () => clearInterval(id);
        }
    }, [delay, timeout, onTimeout, ...deps]);
}
