import React from "react";
import PropTypes from "prop-types";
import { THEME } from "@core/theme";
import { Text } from "@mm/ui";

import { StatusDotText } from "components/atoms";

const {
  colors: { orange },
} = THEME;

const STOCK_WARNING_AMOUNT = 10;

const DevicesHint = ({ stock, textVariant }) =>
  stock > 0 &&
  stock <= STOCK_WARNING_AMOUNT && (
    <Text variant={textVariant}>
      <StatusDotText color={orange}>
        Nur noch {`${stock} ${stock === 1 ? "Gerät " : "Geräte "}`}
        bestellbar
      </StatusDotText>
    </Text>
  );

DevicesHint.propTypes = {
  stock: PropTypes.number.isRequired,
  textVariant: PropTypes.string,
};

DevicesHint.defaultProps = {
  textVariant: "copy",
};

export default DevicesHint;
