import styled from "styled-components";

export const StyledOutSign = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 10;
  translate: 37.5% 275%;
  background-color: ${({ theme }) => theme.colors.brand.primary};
  color: ${({ theme }) => theme.colors.white};
  font-size: 18px;
  width: 170px;
  height: 40px;
`;

export default StyledOutSign;
