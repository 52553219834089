/* eslint-disable max-len */
import React, { useLayoutEffect } from "react";
import { Headline } from "@mm/ui";

const Datasecurity = () => {
  useLayoutEffect(() => {
    const scriptVendorList = document.createElement("script");
    const scriptCookieInfo = document.createElement("script");

    scriptVendorList.src = `https://consentmanager.mgr.consensu.org/delivery/vendorlist.php?id=${window.cmp_id}&l=de`;
    scriptCookieInfo.src = `https://consentmanager.mgr.consensu.org/delivery/cookieinfo.php?id=${window.cmp_id}&l=de`;
    scriptVendorList.async = true;
    scriptCookieInfo.async = true;

    document.body.appendChild(scriptVendorList);
    document.body.appendChild(scriptCookieInfo);

    return () => {
      document.body.removeChild(scriptVendorList);
      document.body.removeChild(scriptCookieInfo);
    };
  }, []);
  return (
    <>
      <Headline variant="h1">Datenschutzerkl&auml;rung</Headline>
      <p>
        Soweit nachstehend keine anderen Angaben gemacht werden, ist die
        Bereitstellung Ihrer personenbezogenen Daten weder gesetzlich oder
        vertraglich vorgeschrieben, noch f&uuml;r einen Vertragsabschluss
        erforderlich. Sie sind zur Bereitstellung der Daten nicht verpflichtet.
        Eine Nichtbereitstellung hat keine Folgen. Dies gilt nur soweit bei den
        nachfolgenden Verarbeitungsvorg&auml;ngen keine anderweitige Angabe
        gemacht wird.
        <br />
        &quot;Personenbezogene Daten&quot; sind alle Informationen, die sich auf
        eine identifizierte oder identifizierbare nat&uuml;rliche Person
        beziehen.
      </p>
      <Headline variant="h2">Server-Logfiles</Headline>
      <p>
        Sie k&ouml;nnen unsere Webseiten besuchen, ohne Angaben zu Ihrer Person
        zu machen.&nbsp;
      </p>
      <p>
        Bei jedem Zugriff auf unsere Website werden an uns oder unseren
        Webhoster / IT-Dienstleister Nutzungsdaten durch Ihren Internet Browser
        &uuml;bermittelt und in Protokolldaten (sog. Server-Logfiles)
        gespeichert. Zu diesen gespeicherten Daten geh&ouml;ren z.B. der Name
        der aufgerufenen Seite, Datum und Uhrzeit des Abrufs, die IP-Adresse,
        die &uuml;bertragene Datenmenge und der anfragende Provider. Die
        Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1 lit. f DSGVO aus
        unserem &uuml;berwiegenden berechtigten Interesse an der
        Gew&auml;hrleistung eines st&ouml;rungsfreien Betriebs unserer Website
        sowie zur Verbesserung unseres Angebotes.&nbsp;
      </p>
      <Headline variant="h2">Kontakt</Headline>
      <Headline variant="h4">
        Verantwortlicher / Datenschutzbeauftragter
      </Headline>
      <p>
        Kontaktieren Sie uns auf Wunsch. Die Kontaktdaten des f&uuml;r die
        Datenverarbeitung Verantwortlichen finden Sie in unserem Impressum.
      </p>
      <p>
        Unseren Datenschutzbeauftragten erreichen Sie direkt unter:{" "}
        <a href="mailto:datenschutz@ekontor24.net">datenschutz@ekontor24.net</a>
      </p>
      <Headline variant="h4">
        Initiativ-Kontaktaufnahme des Kunden per E-Mail
      </Headline>
      <p>
        Wenn Sie per E-Mail initiativ mit uns in Gesch&auml;ftskontakt treten,
        erheben wir Ihre personenbezogenen Daten (Name, E-Mail-Adresse,
        Nachrichtentext) nur in dem von Ihnen zur Verf&uuml;gung gestellten
        Umfang. Die Datenverarbeitung dient der Bearbeitung und Beantwortung
        Ihrer Kontaktanfrage. Wenn die Kontaktaufnahme der Durchf&uuml;hrung
        vorvertraglichen Ma&szlig;nahmen (bspw. Beratung bei Kaufinteresse,
        Angebotserstellung) dient oder einen bereits zwischen Ihnen und uns
        geschlossenen Vertrag betrifft, erfolgt diese Datenverarbeitung auf
        Grundlage des Art. 6 Abs. 1 lit. b DSGVO. Erfolgt die Kontaktaufnahme
        aus anderen Gr&uuml;nden erfolgt diese Datenverarbeitung auf Grundlage
        des Art. 6 Abs. 1 lit. f DSGVO aus unserem &uuml;berwiegenden
        berechtigten Interesse an der Bearbeitung und Beantwortung Ihrer
        Anfrage.
        <em>
          <strong>
            In diesem Fall haben Sie das Recht, aus Gr&uuml;nden, die sich aus
            Ihrer besonderen Situation ergeben, jederzeit dieser auf Art. 6 Abs.
            1 lit. f DSGVO beruhenden Verarbeitungen Sie betreffender
            personenbezogener Daten zu widersprechen.
          </strong>
        </em>
        Ihre E-Mail-Adresse nutzen wir nur zur Bearbeitung Ihrer Anfrage. Ihre
        Daten werden anschlie&szlig;end unter Beachtung gesetzlicher
        Aufbewahrungsfristen gel&ouml;scht, sofern Sie der weitergehenden
        Verarbeitung und Nutzung nicht zugestimmt haben.
      </p>
      <Headline variant="h4">
        Erhebung und Verarbeitung bei Nutzung des Kontaktformulars{" "}
      </Headline>
      <p>
        Bei der Nutzung des Kontaktformulars erheben wir Ihre personenbezogenen
        Daten (Name, E-Mail-Adresse, Nachrichtentext) nur in dem von Ihnen zur
        Verf&uuml;gung gestellten Umfang. Die Datenverarbeitung dient dem Zweck
        der Kontaktaufnahme.
      </p>
      <p>
        Wenn die Kontaktaufnahme der Durchf&uuml;hrung vorvertraglichen
        Ma&szlig;nahmen (bspw. Beratung bei Kaufinteresse, Angebotserstellung)
        dient oder einen bereits zwischen Ihnen und uns geschlossenen Vertrag
        betrifft, erfolgt diese Datenverarbeitung auf Grundlage des Art. 6 Abs.
        1 lit. b DSGVO.
      </p>
      <p>
        Erfolgt die Kontaktaufnahme aus anderen Gr&uuml;nden erfolgt diese
        Datenverarbeitung auf Grundlage des Art. 6 Abs. 1 lit. f DSGVO aus
        unserem &uuml;berwiegenden berechtigten Interesse an der Bearbeitung und
        Beantwortung Ihrer Anfrage.&nbsp;
      </p>
      <em>
        <strong>
          In diesem Fall haben Sie das Recht, aus Gr&uuml;nden, die sich aus
          Ihrer besonderen Situation ergeben, jederzeit dieser auf Art. 6 Abs. 1
          lit. f DSGVO beruhenden Verarbeitungen Sie betreffender
          personenbezogener Daten zu widersprechen.
        </strong>
      </em>
      <p>
        Ihre E-Mail-Adresse nutzen wir nur zur Bearbeitung Ihrer Anfrage. Ihre
        Daten werden anschlie&szlig;end unter Beachtung gesetzlicher
        Aufbewahrungsfristen gel&ouml;scht, sofern Sie der weitergehenden
        Verarbeitung und Nutzung nicht zugestimmt haben.
      </p>
      <Headline variant="h2">Bestellungen</Headline>
      <Headline variant="h4">
        Erhebung, Verarbeitung und Weitergabe personenbezogener Daten bei
        Bestellungen
      </Headline>
      <p>
        Bei der Bestellung erheben und verarbeiten wir Ihre personenbezogenen
        Daten nur, soweit dies zur Erf&uuml;llung und Abwicklung Ihrer
        Bestellung sowie zur Bearbeitung Ihrer Anfragen erforderlich ist. Die
        Bereitstellung der Daten ist f&uuml;r den Vertragsschluss erforderlich.
        Eine Nichtbereitstellung hat zur Folge, dass kein Vertrag geschlossen
        werden kann. Die Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1
        lit. b DSGVO und ist f&uuml;r die Erf&uuml;llung eines Vertrags mit
        Ihnen erforderlich.&nbsp;
      </p>
      <p>
        Eine Weitergabe Ihrer Daten erfolgt dabei beispielsweise an die von
        Ihnen gew&auml;hlten Versandunternehmen und Dropshipping Anbieter,
        Zahlungsdienstleister, Diensteanbieter f&uuml;r die Bestellabwicklung
        und IT-Dienstleister. In allen F&auml;llen beachten wir strikt die
        gesetzlichen Vorgaben. Der Umfang der Daten&uuml;bermittlung
        beschr&auml;nkt sich auf ein Mindestma&szlig;.
      </p>
      <Headline variant="h2">Drittanbieter und Cookies</Headline>
      <p>
        Unsere Website verwendet Cookies. Cookies sind kleine Textdateien, die
        im Internetbrowser bzw. vom Internetbrowser auf dem Computersystem eines
        Nutzers gespeichert werden. Ruft ein Nutzer eine Website auf, so kann
        ein Cookie auf dem Betriebssystem des Nutzers gespeichert werden. Dieser
        Cookie enth&auml;lt eine charakteristische Zeichenfolge, die eine
        eindeutige Identifizierung des Browsers beim erneuten Aufrufen der
        Website erm&ouml;glicht.
      </p>
      <p>
        Cookies werden auf Ihrem Rechner gespeichert. Daher haben Sie die volle
        Kontrolle &uuml;ber die Verwendung von Cookies. Durch die Auswahl
        entsprechender technischer Einstellungen in Ihrem Internetbrowser
        k&ouml;nnen Sie vor dem Setzen von Cookies benachrichtigt werden und
        &uuml;ber die Annahme einzeln entscheiden sowie die Speicherung der
        Cookies und &Uuml;bermittlung der enthaltenen Daten verhindern. Bereits
        gespeicherte Cookies k&ouml;nnen jederzeit gel&ouml;scht werden. Wir
        weisen Sie jedoch darauf hin, dass Sie dann gegebenenfalls nicht
        s&auml;mtliche Funktionen dieser Website vollumf&auml;nglich werden
        nutzen k&ouml;nnen.
      </p>
      <p>
        Unter den nachstehenden Links k&ouml;nnen Sie sich informieren, wie Sie
        die Cookies bei den wichtigsten Browsern verwalten (u.a. auch
        deaktivieren) k&ouml;nnen:
      </p>
      <p>
        Chrome:
        <a
          href="https://support.google.com/accounts/answer/61416?hl=de"
          target="_blank"
          rel="noreferrer"
        >
          https://support.google.com/accounts/answer/61416?hl=de
        </a>
      </p>
      <p>
        Internet Explorer:
        <a
          href="https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies"
          target="_blank"
          rel="noreferrer"
        >
          https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies
        </a>
      </p>
      <p>
        Mozilla Firefox:
        <a
          href="https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen"
          target="_blank"
          rel="noreferrer"
        >
          https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen
        </a>
      </p>
      <p>
        Safari:
        <a
          href="https://support.apple.com/de-de/guide/safari/manage-cookies-and-website-data-sfri11471/mac"
          target="_blank"
          rel="noreferrer"
        >
          https://support.apple.com/de-de/guide/safari/manage-cookies-and-website-data-sfri11471/mac
        </a>
      </p>
      <Headline variant="h4">Technisch notwendige Cookies</Headline>
      <p>
        Soweit nachstehend in der Datenschutzerkl&auml;rung keine anderen
        Angaben gemacht werden setzen wir nur diese technisch notwendigen
        Cookies zu dem Zweck ein, unser Angebot nutzerfreundlicher, effektiver
        und sicherer zu machen. Des Weiteren erm&ouml;glichen Cookies unseren
        Systemen, Ihren Browser auch nach einem Seitenwechsel zu erkennen und
        Ihnen Services anzubieten. Einige Funktionen unserer Internetseite
        k&ouml;nnen ohne den Einsatz von Cookies nicht angeboten werden.
        F&uuml;r diese ist es erforderlich, dass der Browser auch nach einem
        Seitenwechsel wiedererkannt wird.
      </p>
      <p>
        Die Nutzung von Cookies oder vergleichbarer Technologien erfolgt auf
        Grundlage des &sect; 15 Abs. 3 S. 1 TMG. Die Verarbeitung Ihrer
        personenbezogenen Daten erfolgt auf Grundlage des Art. 6 Abs. 1 lit. f
        DSGVO aus unserem &uuml;berwiegenden berechtigten Interesse&nbsp;an der
        Gew&auml;hrleistung der optimalen Funktionalit&auml;t der Website sowie
        einer nutzerfreundlichen und effektiven Gestaltung unseres Angebots.
      </p>
      <p>
        <em>
          <strong>
            Sie haben das Recht aus Gr&uuml;nden, die sich aus Ihrer besonderen
            Situation ergeben, jederzeit dieser Verarbeitungen Sie betreffender
            personenbezogener Daten zu widersprechen.
          </strong>
        </em>
      </p>
      <br />
      <Headline variant="h4">Verwendung von Consentmanager</Headline>
      <p>
        Wir verwenden auf unserer Website das Consent-Management-Tool
        Consentmanager der&nbsp;Consentmanager AB&nbsp;(H&aring;ltegelv&auml;gen
        1b, 72348 V&auml;ster&aring;s, Schweden; &quot;Consentmanager&quot;).
        <br />
        Das Tool erm&ouml;glicht es Ihnen, Einwilligungen in Datenverarbeitungen
        &uuml;ber die Website, insbesondere das Setzen von Cookies, zu erteilen
        sowie von Ihrem Widerrufsrecht f&uuml;r bereits erteilte Einwilligungen
        Gebrauch zu machen.
        <br />
        Die Datenverarbeitung dient dem Zweck, erforderliche Einwilligungen in
        Datenverarbeitungen einzuholen sowie zu dokumentieren und damit
        gesetzliche Verpflichtungen einzuhalten.
        <br />
        Hierzu k&ouml;nnen Cookies eingesetzt werden. Dabei k&ouml;nnen u. a.
        folgende Informationen erhoben und an Consentmanager &uuml;bermittelt
        werden: Datum und Uhrzeit des Seitenaufrufs, Informationen zu dem von
        Ihnen verwendeten Browser und das von Ihnen genutzte Ger&auml;t,
        anonymisierte IP-Adresse, Opt-in- und Opt-out-Daten. Eine Weitergabe
        dieser Daten an sonstige Dritte erfolgt nicht.
        <br />
        Die Datenverarbeitung erfolgt zur Erf&uuml;llung einer rechtlichen
        Verpflichtung auf Grundlage des Art. 6 Abs. 1 lit. c DSGVO.
        <br />
        N&auml;here Informationen zum Datenschutz bei Consentmanager finden Sie
        unter:
        <a
          href="https://www.consentmanager.net/privacy.php"
          target="_blank"
          rel="noreferrer"
        >
          https://www.consentmanager.net/privacy.php
        </a>
      </p>
      <Headline variant="h4">Drittanbieter</Headline>
      <div id="cmpvendorlist" />
      <br />
      <br />
      <Headline variant="h4">Cookie</Headline>
      <div id="cmpcookieinfo" />
      <br />
      <br />
      <Headline variant="h2">Analyse&nbsp;Werbetracking</Headline>
      <Headline variant="h4">Verwendung von Google Analytics</Headline>
      <p>
        Wir verwenden auf unserer Website den Webanalysedienst Google Analytics
        der Google LLC. (1600 Amphitheatre Parkway, Mountain View, CA 94043,
        USA; &bdquo;Google&ldquo;). Soweit Sie Ihren gew&ouml;hnlichen
        Aufenthalt im Europ&auml;ischen Wirtschaftsraum oder der Schweiz haben,
        ist Google Ireland Limited (Gordon House, Barrow Street, Dublin 4,
        Irland) der f&uuml;r Ihre Daten zust&auml;ndige Verantwortliche. Google
        Ireland Limited ist demnach das mit Google verbundene Unternehmen,
        welches f&uuml;r die Verarbeitung Ihrer Daten und die Einhaltung der
        anwendbaren Datenschutzgesetze verantwortlich ist.
        <br />
        Die Datenverarbeitung dient dem Zweck der Analyse dieser Website und
        ihrer Besucher sowie f&uuml;r Marketing- und Werbezwecke. Dazu wird
        Google im Auftrag des Betreibers dieser Website die gewonnenen
        Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um
        Reports &uuml;ber die Websiteaktivit&auml;ten zusammenzustellen und um
        weitere, mit der Websitenutzung und der Internetnutzung verbundene
        Dienstleistungen gegen&uuml;ber dem Websitebetreiber zu erbringen. Dabei
        k&ouml;nnen u.a. folgende Informationen erhoben werden: IP-Adresse,
        Datum und Uhrzeit des Seitenaufrufs, Klickpfad, Informationen &uuml;ber
        den von Ihnen verwendeten Browser und das von Ihnen verwendete Device
        (Ger&auml;t), besuchte Seiten, Referrer-URL (Webseite, &uuml;ber die Sie
        unsere Webseite aufgerufen haben), Standortdaten, Kaufaktivit&auml;ten.
        Die im Rahmen von Google Analytics von Ihrem Browser &uuml;bermittelte
        IP-Adresse wird nicht mit anderen Daten von Google zusammengef&uuml;hrt.
        <br />
        Google Analytics verwendet Technologien wie Cookies, Webspeicher im
        Browser und Z&auml;hlpixel, die eine Analyse der Benutzung der Website
        durch Sie erm&ouml;glichen. Die dadurch erzeugten Informationen
        &uuml;ber Ihre Benutzung dieser Website werden in der Regel an einen
        Server von Google in den USA &uuml;bertragen und dort
        gespeichert.&nbsp;F&uuml;r die USA ist kein Angemessenheitsbeschluss der
        EU-Kommission vorhanden. Die Daten&uuml;bermittlung erfolgt u.a auf
        Grundlage von Standardvertragsklauseln&nbsp;als geeignete Garantien
        f&uuml;r den Schutz der personenbezogenen Daten, einsehbar unter:
        <a
          href="https://policies.google.com/privacy/frameworks"
          target="_blank"
          rel="noreferrer"
        >
          https://policies.google.com/privacy/frameworks
        </a>{" "}
        . Sowohl Google als auch staatliche US-Beh&ouml;rden haben Zugriff auf
        Ihre Daten. Ihre Daten k&ouml;nnen von Google mit anderen Daten, wie
        beispielsweise Ihrem Suchverlauf, Ihren pers&ouml;nlichen Accounts,
        Ihren Nutzungsdaten anderer Ger&auml;te und allen anderen Daten, die
        Google zu Ihnen vorliegen hat, verkn&uuml;pft werden.
        <br />
        Auf dieser Website ist die IP-Anonymisierung aktiviert. Dadurch wird
        Ihre IP-Adresse von Google innerhalb von Mitgliedstaaten der
        Europ&auml;ischen Union oder in anderen Vertragsstaaten des Abkommens
        &uuml;ber den Europ&auml;ischen Wirtschaftsraum zuvor gek&uuml;rzt. Nur
        in Ausnahmef&auml;llen wird die volle IP-Adresse an einen Server von
        Google in den USA &uuml;bertragen und dort gek&uuml;rzt.
        <br />
        Die Nutzung von Cookies oder vergleichbarer Technologien erfolgt mit
        Ihrer Einwilligung auf Grundlage des &sect; 15 Abs. 3 S. 1 TMG i.V.m.
        Art. 6 Abs. 1 lit. a DSGVO. Die Verarbeitung Ihrer personenbezogenen
        Daten erfolgt mit Ihrer Einwilligung auf Grundlage des Art. 6 Abs. 1
        lit. a DSGVO. Sie k&ouml;nnen die Einwilligung jederzeit widerrufen,
        ohne dass die Rechtm&auml;&szlig;igkeit der aufgrund der Einwilligung
        bis zum Widerruf erfolgten Verarbeitung ber&uuml;hrt wird.
        <br />
        N&auml;here Informationen zu Nutzungsbedingungen und Datenschutz finden
        Sie unter
        <a
          href="https://www.google.com/analytics/terms/de.html"
          target="_blank"
          rel="noreferrer"
        >
          https://www.google.com/analytics/terms/de.html
        </a>{" "}
        bzw. unter{" "}
        <a
          href="https://www.google.de/intl/de/policies/"
          target="_blank"
          rel="noreferrer"
        >
          https://www.google.de/intl/de/policies/
        </a>{" "}
        sowie unter
        <a
          href="https://policies.google.com/technologies/cookies?hl=de"
          target="_blank"
          rel="noreferrer"
        >
          https://policies.google.com/technologies/cookies?hl=de
        </a>
        .
      </p>
      <Headline variant="h4">
        Verwendung von Google Ads Conversion-Tracking
      </Headline>
      <p>
        Wir verwenden auf unserer Website das Online-Werbeprogramm &bdquo;Google
        Ads&ldquo; und in diesem Rahmen Conversion-Tracking
        (Besuchsaktionsauswertung). Das Google Conversion Tracking ist ein
        Analysedienst der Google LLC (1600 Amphitheatre Parkway, Mountain View,
        CA 94043, USA; &bdquo;Google&ldquo;). Soweit Sie Ihren gew&ouml;hnlichen
        Aufenthalt im Europ&auml;ischen Wirtschaftsraum oder der Schweiz haben,
        ist Google Ireland Limited (Gordon House, Barrow Street, Dublin 4,
        Irland) der f&uuml;r Ihre Daten zust&auml;ndige Verantwortliche. Google
        Ireland Limited ist demnach das mit Google verbundene Unternehmen,
        welches f&uuml;r die Verarbeitung Ihrer Daten und die Einhaltung der
        anwendbaren Datenschutzgesetze verantwortlich ist.
        <br />
        Wenn Sie auf eine von Google geschaltete Anzeige klicken, wird ein
        Cookie f&uuml;r das Conversion-Tracking auf Ihrem Rechner abgelegt.
        Diese Cookies haben eine begrenzte G&uuml;ltigkeit, enthalten keine
        personenbezogenen Daten und dienen somit nicht der pers&ouml;nlichen
        Identifizierung. Wenn Sie bestimmte Seiten unserer Website besuchen und
        das Cookie noch nicht abgelaufen ist, k&ouml;nnen Google und wir
        erkennen, dass Sie auf die Anzeige geklickt haben und zu dieser Seite
        weitergeleitet wurden. Jeder Google Ads-Kunde erh&auml;lt ein anderes
        Cookie. Somit besteht keine M&ouml;glichkeit, dass Cookies &uuml;ber die
        Websites von Ads-Kunden nachverfolgt werden k&ouml;nnen.
        <br />
        Die Informationen, die mit Hilfe des Conversion-Cookie eingeholt werden,
        dienen dem Zweck Conversion-Statistiken zu erstellen. Hierbei erfahren
        wir die Gesamtanzahl der Nutzer, die auf eine unserer Anzeigen geklickt
        haben und zu einer mit einem Conversion-Tracking-Tag versehenen Seite
        weitergeleitet wurden. Wir erhalten jedoch keine Informationen, mit
        denen sich Nutzer pers&ouml;nlich identifizieren lassen.
        <br />
        Ihre Daten werden gegebenenfalls in die USA
        &uuml;bermittelt.&nbsp;F&uuml;r die USA ist kein
        Angemessenheitsbeschluss der EU-Kommission vorhanden. Die
        Daten&uuml;bermittlung erfolgt u.a auf Grundlage von
        Standardvertragsklauseln als geeignete Garantien f&uuml;r den Schutz der
        personenbezogenen Daten, einsehbar unter:
        <a
          href="https://policies.google.com/privacy/frameworks"
          target="_blank"
          rel="noreferrer"
        >
          https://policies.google.com/privacy/frameworks
        </a>
        .<br />
        Die Nutzung von Cookies oder vergleichbarer Technologien erfolgt mit
        Ihrer Einwilligung auf Grundlage des &sect; 15 Abs. 3 S. 1 TMG i.V.m.
        Art. 6 Abs. 1 lit. a DSGVO. Die Verarbeitung Ihrer personenbezogenen
        Daten erfolgt mit Ihrer Einwilligung auf Grundlage des Art. 6 Abs. 1
        lit. a DSGVO. Sie k&ouml;nnen die Einwilligung jederzeit widerrufen,
        ohne dass die Rechtm&auml;&szlig;igkeit der aufgrund der Einwilligung
        bis zum Widerruf erfolgten Verarbeitung ber&uuml;hrt wird.
        <br />
        N&auml;here Informationen sowie die Datenschutzerkl&auml;rung von Google
        finden Sie unter:
        <a
          href="https://www.google.de/policies/privacy/"
          target="_blank"
          rel="noreferrer"
        >
          https://www.google.de/policies/privacy/
        </a>{" "}
      </p>
      <Headline variant="h4">
        Verwendung der Remarketing- oder &quot;&Auml;hnliche
        Zielgruppen&quot;-Funktion der Google Inc.
      </Headline>
      <p>
        Wir verwenden auf unserer Website die Remarketing- oder
        &quot;&Auml;hnliche Zielgruppen&quot;- Funktion der Google LLC (1600
        Amphitheatre Parkway, Mountain View, CA 94043, USA;
        &bdquo;Google&ldquo;). Soweit Sie Ihren gew&ouml;hnlichen Aufenthalt im
        Europ&auml;ischen Wirtschaftsraum oder der Schweiz haben, ist Google
        Ireland Limited (Gordon House, Barrow Street, Dublin 4, Irland) der
        f&uuml;r Ihre Daten zust&auml;ndige Verantwortliche. Google Ireland
        Limited ist demnach das mit Google verbundene Unternehmen, welches
        f&uuml;r die Verarbeitung Ihrer Daten und die Einhaltung der anwendbaren
        Datenschutzgesetze verantwortlich ist.
        <br />
        Die Anwendung dient dem Zweck der Analyse des Besucherverhaltens und der
        Besucherinteressen. Zur Durchf&uuml;hrung der Analyse der
        Websiten-Nutzung, welche die Grundlage f&uuml;r die Erstellung der
        interessenbezogenen Werbeanzeigen bildet, setzt Google Cookies ein.
        &Uuml;ber die Cookies werden die Besuche der Website sowie anonymisierte
        Daten &uuml;ber die Nutzung der Website erfasst. Es erfolgt keine
        Speicherung von personenbezogenen Daten der Besucher der Website.
        Besuchen Sie nachfolgend eine andere Website im Google Display-Netzwerk
        werden Ihnen Werbeeinblendungen angezeigt, die mit hoher
        Wahrscheinlichkeit zuvor aufgerufene Produkt- und Informationsbereiche
        ber&uuml;cksichtigen.
        <br />
        Ihre Daten werden gegebenenfalls in die USA
        &uuml;bermittelt.&nbsp;F&uuml;r die USA ist kein
        Angemessenheitsbeschluss der EU-Kommission vorhanden. Die
        Daten&uuml;bermittlung erfolgt u.a auf Grundlage von
        Standardvertragsklauseln als geeignete Garantien f&uuml;r den Schutz der
        personenbezogenen Daten, einsehbar unter:
        <a
          href="https://policies.google.com/privacy/frameworks"
          target="_blank"
          rel="noreferrer"
        >
          https://policies.google.com/privacy/frameworks
        </a>{" "}
        .<br />
        Die Nutzung von Cookies oder vergleichbarer Technologien erfolgt mit
        Ihrer Einwilligung auf Grundlage des &sect; 15 Abs. 3 S. 1 TMG i.V.m.
        Art. 6 Abs. 1 lit. a DSGVO. Die Verarbeitung Ihrer personenbezogenen
        Daten erfolgt mit Ihrer Einwilligung auf Grundlage des Art. 6 Abs. 1
        lit. a DSGVO. Sie k&ouml;nnen die Einwilligung jederzeit widerrufen,
        ohne dass die Rechtm&auml;&szlig;igkeit der aufgrund der Einwilligung
        bis zum Widerruf erfolgten Verarbeitung ber&uuml;hrt wird.
        <br />
        N&auml;here Informationen zu Google Remarketing sowie die
        dazugeh&ouml;rige Datenschutzerkl&auml;rung finden Sie unter:
        <a
          href="https://www.google.com/privacy/ads/"
          target="_blank"
          rel="noreferrer"
        >
          https://www.google.com/privacy/ads/
        </a>{" "}
      </p>
      <br />
      <Headline variant="h2">Plug-ins und Sonstiges</Headline>
      <Headline variant="h4">Verwendung von Sentry.io</Headline>
      <p>
        Wir verwenden den Dienst Sentry (Sentry, 1501 Mariposa St #408, San
        Francisco, CA 94107, USA), um die technische Stabilität unseres Dienstes
        durch Überwachung der Systemstabilität und Ermittlung von Codefehlern zu
        verbessern. Sentry dient alleine diesen Zielen und wertet keine Daten zu
        Werbezwecken aus. Die Daten der Nutzer, wie z.B. Angaben zum Gerät oder
        Fehlerzeitpunkt werden anonym erhoben und nicht personenbezogen genutzt
        sowie anschließend gelöscht.
      </p>
      <p>
        Weitere Informationen hierzu finden Sie in der Datenschutzerklärung von
        Sentry:{" "}
        <a href="https://sentry.io/privacy/" target="_blank" rel="noreferrer">
          https://sentry.io/privacy/
        </a>
        .
      </p>
      <Headline variant="h4">Verwendung des Google Tag Managers</Headline>
      <p>
        Wir verwenden auf unserer Website den Google Tag Manager der Google LLC.
        (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA;
        &bdquo;Google&ldquo;). Soweit Sie Ihren gew&ouml;hnlichen Aufenthalt im
        Europ&auml;ischen Wirtschaftsraum oder der Schweiz haben, ist Google
        Ireland Limited (Gordon House, Barrow Street, Dublin 4, Irland) der
        f&uuml;r Ihre Daten zust&auml;ndige Verantwortliche. Google Ireland
        Limited ist demnach das mit Google verbundene Unternehmen, welches
        f&uuml;r die Verarbeitung Ihrer Daten und die Einhaltung der anwendbaren
        Datenschutzgesetze verantwortlich ist. Mit dieser Anwendung werden
        JavaScript-Tags und HTML-Tags verwaltet, die zur Implementierung
        insbesondere von Tracking- und Analyse-Tools verwendet werden. Die
        Datenverarbeitung dient dem Zweck der bedarfsgerechten Gestaltung und
        der Optimierung unserer Website. Der Google Tag Manager selbst speichert
        weder Cookies noch werden hierdurch personenbezogene Daten verarbeitet.
        Er erm&ouml;glicht jedoch die Ausl&ouml;sung weiterer Tags, die
        personenbezogene Daten erheben und verarbeiten k&ouml;nnen. N&auml;here
        Informationen zu Nutzungsbedingungen und Datenschutz finden Sie
        <a
          href="https://www.google.com/intl/de/tagmanager/use-policy.html"
          target="_blank"
          rel="noreferrer"
        >
          hier
        </a>{" "}
      </p>
      <Headline variant="h4">Verwendung von Google Optimize</Headline>
      <p>
        Wir verwenden auf unserer Website den Webanalysedienst Google Optimize
        der Google LLC. (1600 Amphitheatre Parkway, Mountain View, CA 94043,
        USA; „Google“). Soweit Sie Ihren gewöhnlichen Aufenthalt im Europäischen
        Wirtschaftsraum oder der Schweiz haben, ist Google Ireland Limited
        (Gordon House, Barrow Street, Dublin 4, Irland) der für Ihre Daten
        zuständige Verantwortliche. Google Ireland Limited ist demnach das mit
        Google verbundene Unternehmen, welches für die Verarbeitung Ihrer Daten
        und die Einhaltung der anwendbaren Datenschutzgesetze verantwortlich
        ist.
      </p>
      <p>
        Die Datenverarbeitung dient dem Zweck uns bei der Gestaltung unserer
        Website zu unterstützen. Google Optimize bietet uns die Möglichkeit,
        unserer Webseiten zu testen und zu sehen, wie sie im Hinblick auf
        bestimmte Ziele abschneiden. Wir können so neue Designs, Layouts und
        Inhalte mit einem Teil der Webseitenbesucher testen. Bei Google Optimize
        handelt es sich um einen Unterstützungsdienst von Google Analytics. Dies
        bedeutet, dass Google Optimize auf Google Analytics zugreift, um die
        oben beschrienen Tests auszuwerten. Zudem können gezielt mit bestimmten
        Nutzergruppen, die in Google Analytics als Zielgruppen festgelegt
        wurden, Tests durchgeführt werden. Bei der Verarbeitung können u.a.
        folgende Informationen erhoben werden: Online-Kennzeichnungen und
        IP-Adresse. Weitere Informationen zu den verarbeiteten Daten finden Sie
        unter
        <a
          href="https://www.google.com/intl/de/policies/privacy/#infocollect"
          target="_blank"
          rel="noreferrer"
        >
          https://www.google.com/intl/de/policies/privacy/#infocollect
        </a>{" "}
        und unter
        <a
          href="https://privacy.google.com/businesses/adsservices/"
          target="_blank"
          rel="noreferrer"
        >
          https://privacy.google.com/businesses/adsservices/
        </a>{" "}
      </p>
      <p>
        Google Optimze verwendet Technologien wie Cookies, Webspeicher im
        Browser und Zählpixel, die eine Analyse der Benutzung der Website durch
        Sie ermöglichen. Die dadurch erzeugten Informationen über Ihre Benutzung
        dieser Website werden in der Regel an einen Server von Google in den USA
        übertragen und dort gespeichert. Für die USA ist kein
        Angemessenheitsbeschluss der EU-Kommission vorhanden. Die
        Datenübermittlung erfolgt u.a auf Grundlage von Standardvertragsklauseln
        als geeignete Garantien für den Schutz der personenbezogenen Daten,
        einsehbar unter:
      </p>
      <a
        href="https://policies.google.com/privacy/frameworks"
        target="_blank"
        rel="noreferrer"
      >
        https://policies.google.com/privacy/frameworks
      </a>{" "}
      <p>
        Sowohl Google als auch staatliche US-Behörden haben Zugriff auf Ihre
        Daten. Ihre Daten können von Google mit anderen Daten, wie
        beispielsweise Ihrem Suchverlauf, Ihren persönlichen Accounts, Ihren
        Nutzungsdaten anderer Geräte und allen anderen Daten, die Google zu
        Ihnen vorliegen hat, verknüpft werden.
      </p>
      <p>
        Auf dieser Website ist die IP-Anonymisierung aktiviert. Dadurch wird
        Ihre IP-Adresse von Google innerhalb von Mitgliedstaaten der
        Europäischen Union oder in anderen Vertragsstaaten des Abkommens über
        den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen
        wird die volle IP-Adresse an einen Server von Google in den USA
        übertragen und dort gekürzt.
      </p>
      <p>
        Die Nutzung von Cookies oder vergleichbarer Technologien erfolgt mit
        Ihrer Einwilligung auf Grundlage des § 15 Abs. 3 S. 1 TMG i.V.m. Art. 6
        Abs. 1 lit. a DSGVO. Die Verarbeitung Ihrer personenbezogenen Daten
        erfolgt mit Ihrer Einwilligung auf Grundlage des Art. 6 Abs. 1 lit. a
        DSGVO. Sie können die Einwilligung jederzeit widerrufen, ohne dass die
        Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten
        Verarbeitung berührt wird.
      </p>
      <p>
        Nähere Informationen zu Nutzungsbedingungen und Datenschutz finden Sie
        unter{" "}
        <a
          href="https://policies.google.com/privacy"
          target="_blank"
          rel="noreferrer"
        >
          https://policies.google.com/privacy
        </a>{" "}
        bzw. unter{" "}
        <a
          href="https://www.google.de/intl/de/policies/"
          target="_blank"
          rel="noreferrer"
        >
          https://www.google.de/intl/de/policies/
        </a>{" "}
      </p>
      <Headline variant="h4">Talkative Engage</Headline>
      <p>
        Talkative Engage ist eine Software zur digitalen Beratung der Talkative
        Ltd, Innovation Centre, Coldra Woods, Newport, NP18 2YB, United Kingdom
        (&bdquo;Talkative&ldquo;). Wir nutzen Talkative Engage f&uuml;r
        Kundenberatungen mittels Chat, Videoanrufen sowie Co-Browsing in unserem
        Buchungsportal. Bei der Nutzung von Talkative Engage wird von Talkative
        eine ID vergeben. Dies dient unter anderem der Nachvollziehbarkeit von
        wiederholten Kontakten. Hierbei werden auch Informationen &uuml;ber Ihr
        Surfverhalten in unserem Onlineshop sowie Ihre IP-Adresse an einen
        Server von Talkative in der EU &uuml;bertragen und dort gespeichert.
      </p>
      <Headline variant="h2">Betroffenenrechte und Speicherdauer</Headline>
      <Headline variant="h4">Dauer der Speicherung</Headline>
      <p>
        Nach vollst&auml;ndiger Vertragsabwicklung werden die Daten
        zun&auml;chst f&uuml;r die Dauer der Gew&auml;hrleistungsfrist, danach
        unter Ber&uuml;cksichtigung gesetzlicher, insbesondere steuer- und
        handelsrechtlicher Aufbewahrungsfristen gespeichert und dann nach
        Fristablauf gel&ouml;scht, sofern Sie der weitergehenden Verarbeitung
        und Nutzung nicht zugestimmt haben.
      </p>
      <Headline variant="h4">Rechte der betroffenen Person</Headline>
      <p>
        Ihnen stehen bei Vorliegen der gesetzlichen Voraussetzungen folgende
        Rechte nach Art. 15 bis 20 DSGVO zu: Recht auf Auskunft, auf
        Berichtigung, auf L&ouml;schung, auf Einschr&auml;nkung der
        Verarbeitung, auf Daten&uuml;bertragbarkeit.
        <br />
        Au&szlig;erdem steht Ihnen nach Art. 21 Abs. 1 DSGVO ein
        Widerspruchsrecht gegen die Verarbeitungen zu, die auf Art. 6 Abs. 1 f
        DSGVO beruhen, sowie gegen die Verarbeitung zum Zwecke von
        Direktwerbung.
      </p>
      <Headline variant="h4">
        Beschwerderecht bei der Aufsichtsbeh&ouml;rde
      </Headline>
      <p>
        Sie haben gem&auml;&szlig; Art. 77 DSGVO das Recht, sich bei der
        Aufsichtsbeh&ouml;rde zu beschweren, wenn Sie der Ansicht sind, dass die
        Verarbeitung Ihrer personenbezogenen Daten nicht rechtm&auml;&szlig;ig
        erfolgt.
      </p>
      <Headline variant="h4">Widerspruchsrecht</Headline>
      <p>
        Beruhen die hier aufgef&uuml;hrten personenbezogenen Datenverarbeitungen
        auf Grundlage unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. f
        DSGVO, haben Sie das Recht aus Gr&uuml;nden, die sich aus Ihrer
        besonderen Situation ergeben, jederzeit diesen Verarbeitungen mit
        Wirkung f&uuml;r die Zukunft zu widersprechen.
        <br />
        Nach erfolgtem Widerspruch wird die Verarbeitung der betroffenen Daten
        beendet, es sei denn, wir k&ouml;nnen zwingende schutzw&uuml;rdige
        Gr&uuml;nde f&uuml;r die Verarbeitung nachweisen, die Ihren Interessen,
        Rechten und Freiheiten &uuml;berwiegen, oder wenn die Verarbeitung der
        Geltendmachung, Aus&uuml;bung oder Verteidigung von
        Rechtsanspr&uuml;chen dient.
      </p>
      <p>letzte Aktualisierung: 09.11.2022</p>
    </>
  );
};

export default Datasecurity;
