import slice from "lodash/slice";

// allow setting of config aliases as X.alias = X[Y]
/* eslint-disable prefer-destructuring */

export const fonts = [
  "MMPrice, sans-serif",
  "NotoSansDisplay, Arial, sans-serif",
  "NotoSansDisplay, Arial, sans-serif",
  "mm-icons, sans-serif",
  "MMHeadline, sans-serif",
];
fonts.price = fonts[0];
fonts.headline = fonts[1];
fonts.text = fonts[2];
fonts.icon = fonts[3];
fonts.headlineBig = fonts[4];

const black = "#000000";
const white = "#FFFFFF";

export const colors = {
  black,
  white,

  brand: {
    primary: "#DF0000",
    muted: "#A10000",
  },
  feedback: {
    success: "#70C118",
    warning: "#FFA500",
    error: "#FF0000",
  },
  shade: [white, "#F4F4F4", "#CDCACA", "#958F8F", "#4C4A4A", "#201D1D", black],
};

// sat requires another borderRadius
const pillLabelList = {
  borderRadius: "4px",
};

const flexHero = {
  maxWidth: "210px",
  borderBottomRightRadius: "40.8px",
  borderTopRightRadius: "14.16px",
  connectLogo: {
    positionRight: "-43px",
  },
  dynamicStageStyles: {
    borderBottomRightRadius: "45.8px",
    before: {
      left: "-100px",
    },
    after: {
      right: "-30px",
    },
  },
  corner: {
    clipPath: "none",
  },
  cornerxs: {
    clipPath: "none",
  },
};

const ColoredStageHeadline = {
  color: colors.brand.primary,
  fontsize: "40px",
};

/**
 * shim to not break old components
 *
 * @deprecated
 */
const colorsLegacy = {
  primary: [colors.brand.primary, colors.brand.muted],
  grey: [
    colors.black,
    ...slice(colors.shade, 1, colors.shade.length - 1),
    "#1f1d1d",
    "#d1d1d1",
    "#444444",
  ],
  white: colors.white,
  success: colors.feedback.success,
  warning: colors.feedback.warning,
  error: colors.feedback.error,
};

// Variants

const header = {
  primary: {
    backgroundColor: colors.brand.muted,
    color: colors.white,
  },
};

const navigation = {
  primary: {
    backgroundColor: colors.brand.primary,
  },
};

const eyecatcher = {
  before: {
    borderRadius: "4px",
  },
  after: {
    borderRadius: "4px",
  },
};

const button = {
  borderRadius: "4px",
};

const priceTextShadow =
  "0.04em 0 0 #fff, -0.04em 0 0 #fff, 0 -0.04em 0 #fff, 0 0.04em 0 #fff, 0.04em 0.04em 0 #fff, 0.04em -0.04em 0 #fff, -0.04em -0.04em 0 #fff, -0.04em 0.04em 0 #fff, 0.1em 0 0.05em rgba(0, 0, 0, 0.2), 0 0.1em 0.05em rgba(0, 0, 0, 0.2), 0.1em 0.1em 0.05em rgba(0, 0, 0, 0.2)";

const priceStyle = {
  textShadow: priceTextShadow,
  transform: "translateZ(0) skewX(-17deg) skewY(0)",
};
const priceDisplay = {
  primary: priceStyle,
  // secondary = primary in MM, but differs in SAT
  secondary: priceStyle,
};

const theme = {
  // base theme
  colors: { ...colors, ...colorsLegacy },
  fonts,

  // Variants
  header,
  navigation,
  priceDisplay,
  eyecatcher,
  button,
  // extend for more custom styles for MediaMarkt
  flexHero,
  pillLabelList,
  ColoredStageHeadline,
};

export default theme;
