import { ACTIONS as ModalActions } from "@/utils/context/ModalContext";
import dataLayer from "@/helper/dataLayer";
export const config = {
    tariffHeadline: "Dein Abonnement",
    contractPartner: "Media-Saturn Deutschland GmbH",
    contractPartnerModalContent: "Media-Saturn Deutschland GmbH<br />Media-Saturn-Straße 1<br /> 85053 Ingolstadt",
    hardwareHeadline: "Deine Hardware",
    forceExpandedSummary: true,
    metaLinks: (dispatch) => [
        {
            text: "Tarifdetails",
            onClick: (details) => {
                dispatch({
                    type: ModalActions.SET_MODAL,
                    payload: {
                        details,
                        title: "Tarifdetails",
                        type: "tariffDetails",
                    },
                });
                dataLayer({
                    event: "gaEvent",
                    eventCategory: "Details",
                    eventAction: "click",
                    eventLabel: "Summary:Tarifdetails",
                });
            },
        },
    ],
};
