import { useQuery } from "react-query";
import { extendURLParams } from "@/utils/fetchData";
export const useCheckout = (options) => {
    const { tenant, environment, restUrl } = extendURLParams();
    return useQuery(["useCheckout", tenant, environment], () => fetch(`${restUrl}/v1/checkout?tenant=${tenant}&environment=${environment}`, {
        method: "GET",
        credentials: "include",
    }).then(async (res) => {
        const data = await res.json();
        if (!res.ok || data.error) {
            throw new Error(data.error);
        }
        return data;
    }), {
        ...options,
        cacheTime: 0,
        refetchOnWindowFocus: false,
    });
};
