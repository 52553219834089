import React, { useEffect, useState } from "react";

import { Headline, Surface } from "@mm/ui";

import { DetailsAccordion } from "components/atoms";
import { useOfferConfigurator } from "features/Offer";

const Section = ({ data, headline }) => {
  return (
    <Surface variant="narrow" mt={[4, 4, 4, 6, 6]} mb={7}>
      <Headline variant="h3" mb={3}>
        {headline}
      </Headline>
      <DetailsAccordion details={data.details} />
    </Surface>
  );
};

const AccessoriesSection = ({ accessory }) => {
  if (!accessory) return null;

  return (
    <Section headline={`Zugabendetails ${accessory.name}`} data={accessory} />
  );
};

const InfoSection = () => {
  const [hardwareData, setHardwareData] = useState(null);
  const [tariffData, setTariffData] = useState(null);
  const [accessoriesData, setAccessoriesData] = useState([]);

  const {
    state: { activeTariff, rootInfo },
  } = useOfferConfigurator();

  useEffect(() => {
    if (rootInfo?.hardware) {
      setHardwareData(rootInfo.hardware);
    }
    if (activeTariff) {
      setTariffData(activeTariff);
    }
    if (rootInfo?.accessories) {
      setAccessoriesData(rootInfo.accessories);
    }
  }, [rootInfo, activeTariff]);

  return (
    <>
      {hardwareData && <Section headline="Gerätedetails" data={hardwareData} />}
      {tariffData && <Section headline="Tarifdetails" data={tariffData} />}
      {accessoriesData.map((acc) => (
        <AccessoriesSection key={acc.name} accessory={acc} />
      ))}
    </>
  );
};

InfoSection.propTypes = {};
InfoSection.defaultProps = {};

export default InfoSection;
