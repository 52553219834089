import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useCheckoutState } from "../../context/CheckoutContext";
import { checkoutStepLabel } from "../../ICheckoutConfig";
import { useCheckoutStep } from "../../hooks";
const StyledStepNavigation = styled.ol `
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 0.8125rem;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  @media (max-width: 760px) {
    font-size: 0.625rem;
  }
`;
const StepNavigationItem = styled.li `
  font-weight: bold;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  color: ${({ active, completed, theme }) => active || completed ? theme.colors.black : theme.colors.grey[2]};

  &:first-child {
    align-items: flex-start;
  }
  &:last-child {
    align-items: flex-end;
  }

  & a {
    text-decoration: none;
  }

  &:after {
    position: absolute;
    content: "";
    border-bottom: 0.25rem solid
      ${({ completed, theme }) => completed ? theme.colors.black : theme.colors.grey[2]};
    width: 100%;
    bottom: 0.625rem;
    left: 50%;
    z-index: 2;

    // TODO: calculate based on number of steps
    min-width: 100vw;
  }

  &:last-child::after {
    border-bottom: 0.25rem solid ${({ theme }) => theme.colors.white};
    min-width: auto;
  }
`;
const StepNavigationLink = styled(Link) `
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const ItemContent = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const NumberCircle = styled.div `
  border-radius: 50%;
  background-color: ${({ active, completed, theme }) => active || completed ? theme.colors.black : theme.colors.grey[2]};
  color: ${({ theme }) => theme.colors.white};
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  margin-top: 0.5rem;
`;
const fallbackItems = Array(4).fill({
    path: "",
    label: "-",
});
export const StepNavigation = ({ basePath, className, }) => {
    const { state: { currentConfig }, } = useCheckoutState();
    const { currentIndex } = useCheckoutStep();
    const items = currentConfig
        ? currentConfig.steps.map((step, index) => ({
            label: step.label ?? checkoutStepLabel[step.type],
            path: `${basePath}/checkout/step/${index + 1}`,
        }))
        : fallbackItems;
    return (_jsx(_Fragment, { children: _jsx(StyledStepNavigation, { className: className, role: "list", children: items.map(({ label, path }, index) => {
                const active = index + 1 === Number(currentIndex);
                const completed = index + 1 < Number(currentIndex);
                return (_jsx(StepNavigationItem, { active: active, completed: completed, children: completed ? (_jsxs(StepNavigationLink, { to: path, children: [label, _jsx(NumberCircle, { active: active, completed: completed, children: index + 1 })] })) : (_jsxs(ItemContent, { children: [label, _jsx(NumberCircle, { active: active, completed: completed, children: index + 1 })] })) }, `${path}-${index}`));
            }) }) }));
};
