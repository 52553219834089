import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
import { Button } from "@mm/ui";
const StyledExistingCustomerToggle = styled.div `
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grey[7]};
  padding: 0.875rem 1.25rem 1.5rem;
  border-radius: 4px;
  ${({ disabled }) => disabled && "opacity: 0.5;"}
`;
const Headline = styled.p ``;
const Row = styled.div `
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;
const Text = styled.p ``;
export const ExistingCustomerToggle = ({ contractPartner, disabled, isLoading, onChange, value, }) => {
    return (_jsxs(StyledExistingCustomerToggle, { disabled: disabled, children: [_jsxs(Headline, { id: "existing-customer-toggle-label", children: ["Bist du bereits Kunde bei ", contractPartner, "?"] }), _jsxs(Row, { role: "radiogroup", "aria-labelledby": "existing-customer-toggle-label", children: [_jsx(Button, { type: "button", size: "small", color: value ? "secondary" : "primary", disabled: (!value && isLoading) || disabled, loading: value && isLoading, onClick: () => {
                            if (!disabled) {
                                onChange(false);
                            }
                        }, "aria-checked": value, children: "Neukunde" }), _jsx(Button, { type: "button", size: "small", color: value ? "primary" : "secondary", disabled: (value && isLoading) || disabled, loading: !value && isLoading, onClick: () => {
                            if (!disabled) {
                                onChange(true);
                            }
                        }, "aria-checked": !value, children: "Bestandskunde" })] }), value && (_jsxs(Text, { children: ["Bitte beachten Sie, dass sich die Angebote ausschlie\u00DFlich an Kunden richten, in deren Haushalt in den letzten drei Monaten kein Internet- & Festnetz-Anschluss von Vodafone vorhanden war. Wenn Sie Fragen oder W\u00FCnsche zu Ihrem laufenden Vertrag haben, wenden Sie sich bitte direkt an Vodafone unter 0800-172 1212 oder an", " ", _jsx("a", { href: "https://www.vodafone.de/hilfe/kontakt-zu-vodafone.html", target: "_blank", rel: "noreferrer", children: "https://www.vodafone.de/hilfe/kontakt-zu-vodafone.html" })] }))] }));
};
