import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Wrapper = styled.div`
  position: relative;
  display: inherit;
  height: 100%;
`;

const StyledOverlay = styled.div`
  background-color: ${({ color }) => color};
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Overlay = ({ children, content, color }) => (
  <Wrapper>
    {children}
    <StyledOverlay color={color}>{content}</StyledOverlay>
  </Wrapper>
);

Overlay.propTypes = {
  children: PropTypes.node.isRequired,
  content: PropTypes.node,
  color: PropTypes.string,
};

Overlay.defaultProps = {
  content: null,
  // white at 70% opacity
  color: "rgba(255, 255, 255, .7)",
};

export default Overlay;
