import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
import { provideIsLoadingProp, } from "@/utils/context/LoadingContext";
import { THEME } from "@core/theme";
const { skeletonBase } = THEME;
const StyledMetaLink = styled.a.attrs(provideIsLoadingProp) `
  font-size: 0.75rem;
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.grey[4]};
  cursor: pointer;

  ${({ isLoading }) => isLoading && skeletonBase + "height: 0.875rem;"};
`;
// TODO: use something more generic like `IArticle` and merge interfaces
export const MetaLink = ({ details, modalContent, target, text, url, onClick, }) => (_jsx(StyledMetaLink, { href: url, onClick: (e) => {
        e.stopPropagation();
        onClick && onClick(details, modalContent);
    }, role: "link", target: target, children: text }));
