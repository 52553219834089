import React from "react";
import PropTypes from "prop-types";

import { Surface } from "@mm/ui";

import { ThemeProvider } from "@core/theme";

import { TwoToOneTeaserSection } from "../../components/organisms/Teaser";

const TwoToOneTeaser = ({ data, ...props }) => (
  <ThemeProvider>
    <Surface variant="narrow">
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <TwoToOneTeaserSection contents={data} {...props} />
    </Surface>
  </ThemeProvider>
);

TwoToOneTeaser.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.any, // TODO: Fix this
};

TwoToOneTeaser.defaultProps = {
  data: <></>,
};

export default TwoToOneTeaser;
