import { jsx as _jsx } from "react/jsx-runtime";
import SlickCarousel from "react-slick";
import { Surface } from "@mm/ui";
import { TileBox } from "@/components/atoms";
import OfferTeaserInternet from "../Teaser";
import { StyledTeaserCarousel, carouselSettings } from "./SlickCarouselSetting";
const InternetOfferTeaserRegards = ({ availabilityChecked, data, }) => {
    if (data.length === 0) {
        return (_jsx(Surface, { variant: "narrow", mt: "6", mb: "6", children: _jsx("div", { children: "Es k\u00F6nnen keine Angebote angezeigt werden" }) }));
    }
    return (_jsx(StyledTeaserCarousel, { children: _jsx(SlickCarousel, { ...carouselSettings, children: data.map(({ accessories, coupon, eyeCatchers, name, internetDetail, offerGroupUrl, offerId, pkCampaignId, accessoriesAlternativeImage, accessoriesAlternativePrice, tariff, }) => {
                return (_jsx(TileBox, { variant: "tile", children: _jsx(OfferTeaserInternet, { accessories: accessories, availabilityChecked: availabilityChecked, coupon: coupon, detailEyeCatcher: {
                            eyeCatcher: internetDetail?.eyeCatcher,
                            eyeCatcherInfo: internetDetail?.eyeCatcherInfo,
                        }, eyeCatchers: eyeCatchers, name: name, offerGroupUrl: offerGroupUrl, pkCampaignId: pkCampaignId, accessoriesAlternativeImage: accessoriesAlternativeImage, accessoriesAlternativePrice: accessoriesAlternativePrice, tariff: tariff }) }, offerId));
            }) }) }));
};
export default InternetOfferTeaserRegards;
