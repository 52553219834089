/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
import SlickCarousel from "react-slick";

import { Surface } from "@mm/ui";

import { OfferProvider } from "features/Offer";

import TariffHalfWidth from "./Tariff";
import PhoneHalfWidth from "./Phone";

import { StyledTeaserCarousel, carouselSettings } from "./SlickCarouselSetting";

const OfferTeaserTwoColumns = ({ data }) => {
  if (data.length === 0) {
    return (
      <Surface variant="narrow">
        <div>Es können keine Angebote angezeigt werden</div>
      </Surface>
    );
  }
  return (
    <StyledTeaserCarousel>
      <SlickCarousel {...carouselSettings}>
        {data.map((slide) => {
          return slide.hardware ? (
            <OfferProvider
              rootInfoData={slide}
              extendedVariantsData={slide.hardware.extendedVariants}
              key={`Provider-${slide.hardware.id}`}
            >
              <PhoneHalfWidth {...slide} key={slide.hardware.id} />
            </OfferProvider>
          ) : (
            <TariffHalfWidth {...slide} key={slide.tariff.id} />
          );
        })}
      </SlickCarousel>
    </StyledTeaserCarousel>
  );
};

OfferTeaserTwoColumns.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default OfferTeaserTwoColumns;
