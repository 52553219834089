import PropTypes from "prop-types";
import React from "react";
import { ThemeProvider as SCThemeProvider } from "styled-components";

import { ViewportProvider, THEME } from "../theme";

export default function ThemeProvider({ children }) {
  return (
    <SCThemeProvider theme={THEME}>
      <ViewportProvider>{children}</ViewportProvider>
    </SCThemeProvider>
  );
}

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
