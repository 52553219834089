import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useViewport } from "@core/theme";

import { Headline, Row, Col } from "@mm/ui";

import PhoneSvg from "@core/svgs/phone.svg";
import FaqSvg from "@core/svgs/faq.svg";
import ContactSvg from "@core/svgs/contact.svg";

import { HOTLINE_NUMBER, HOTLINE_NUMBER_CLICKABLE } from "helper/globals";

import Link from "../../atoms/Link/index";

const StyledServiceTile = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.grey[2]};
  border-radius: 4px;
  display: flex;
  font-size: 14px;
  flex-direction: column;
  height: calc(100% - ${(props) => props.theme.gutterWidths.xs}px);
  justify-content: space-between;
  line-height: 20px;
  margin-bottom: ${(props) => props.theme.gutterWidths.xs}px;
  padding: 24px 16px;

  @media (min-width: ${(props) => props.theme.breakpointsMap.md.min}px) {
    font-size: 16px;
    height: calc(100% - ${(props) => props.theme.gutterWidths.md}px);
    line-height: 24px;
    margin-bottom: ${(props) => props.theme.gutterWidths.md}px;
  }

  &.secondary {
    background-color: ${(props) => props.theme.colors.primary[0]};
    color: ${(props) => props.theme.colors.white};
  }
`;

const Header = styled.header`
  min-height: 24px;
  margin-bottom: 18px;
`;

const StyledPhoneSvg = styled(PhoneSvg)`
  width: 40px;
  height: 40px;
`;

const StyledFaqSvg = styled(FaqSvg)`
  min-width: 40px;
  width: 40px;
`;

const StyledContactSvg = styled(ContactSvg)`
  width: 40px;
  height: 40px;
`;

const StyledHeadline = styled(Headline)`
  color: ${(props) => props.theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.headlineBig};
  font-size: 24px;
  line-height: 32px;
  margin: 0;
  ${(props) =>
    props.className.includes("secondary") &&
    `
    @media (min-width: ${props.theme.breakpointsMap.lg.min}px) {
      font-size: 40px;
      line-height: 48px;
    }
  `};
`;

const Text = styled.p`
  margin: 0;
  white-space: pre-line;
  @media (min-width: ${(props) =>
      props.theme.breakpointsMap.md.min}px) and (max-width: ${(props) =>
      props.theme.breakpointsMap.md.max}px) {
    white-space: normal;
  }
`;

const Ul = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const Li = styled.li`
  align-items: center;
  display: flex;
  font-size: 14px;
  line-height: 2;
  margin-bottom: 16px;

  svg g use,
  svg g g {
    fill: ${(props) => props.theme.colors.primary[0]};
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  line-height: 22px;
  margin: 0 0 0 20px;
  @media (min-width: ${(props) => props.theme.breakpointsMap.md.min}px) {
    font-size: 20px;
    line-height: 26px;
  }
  a {
    ${(props) => {
      // eslint-disable-next-line no-param-reassign
      props.small = true;
      return Link.componentStyle.rules
        .map((rule) => {
          if (rule instanceof Function) {
            return rule(props);
          }
          return rule;
        })
        .join("");
    }}
    cursor: pointer;
    text-decoration: none;
    font-size: 16px;
    line-height: 22px;
    @media (min-width: ${(props) => props.theme.breakpointsMap.md.min}px) {
      font-size: 20px;
      line-height: 26px;
    }
  }
`;

const ContactInfo = (props) => {
  // eslint-disable-next-line camelcase
  const { title, content_md, className } = props;
  const viewport = useViewport();

  return (
    <Row>
      <Col xs={24} md={24} lg={12}>
        <StyledServiceTile className={className}>
          <div>
            <Row>
              <Col xs={24}>
                <Header className={className}>
                  <StyledHeadline variant="h3" className={className}>
                    {title}
                  </StyledHeadline>
                </Header>
              </Col>
              <Col xs={24}>
                <Ul>
                  <Li>
                    <StyledFaqSvg />
                    <TextWrapper>
                      <Text
                        variant="smallCopy"
                        highlighted
                        dangerouslySetInnerHTML={{
                          __html: content_md,
                        }}
                      />
                    </TextWrapper>
                  </Li>
                </Ul>
              </Col>
            </Row>
          </div>
        </StyledServiceTile>
      </Col>
      <Col xs={24} md={24} lg={12}>
        <StyledServiceTile className={className}>
          <div>
            <Row>
              <Col xs={24}>
                <Header className={className}>
                  <StyledHeadline variant="h3" className={className}>
                    Wollen Sie uns kontaktieren?
                  </StyledHeadline>
                </Header>
              </Col>
              <Col xs={24}>
                <Ul>
                  <Li>
                    <StyledPhoneSvg width={16} height={16} />
                    <TextWrapper>
                      {viewport.lessThan.lg ? (
                        <Text variant="smallCopy" highlighted>
                          Rufen Sie uns an:
                        </Text>
                      ) : (
                        <Text variant="smallCopy" highlighted>
                          Rufen Sie uns an:
                          <br />
                          {HOTLINE_NUMBER}
                        </Text>
                      )}
                      {viewport.lessThan.lg ? (
                        <span>
                          <a href={`tel:${HOTLINE_NUMBER_CLICKABLE}`}>
                            {HOTLINE_NUMBER}{" "}
                          </a>
                          (Mo.-Fr.: 8-20 Uhr, Sa. 10-18 Uhr)
                        </span>
                      ) : (
                        <Text variant="smallCopy" highlighted>
                          (Mo.-Fr.: 8-20 Uhr, Sa. 10-18 Uhr)
                        </Text>
                      )}
                    </TextWrapper>
                  </Li>
                  <Li>
                    <StyledContactSvg />
                    <TextWrapper>
                      <Text variant="smallCopy" highlighted>
                        Schreiben Sie uns:
                      </Text>
                      <a href="/kontakt/">Über unser Kontaktformular</a>
                    </TextWrapper>
                  </Li>
                </Ul>
              </Col>
            </Row>
          </div>
        </StyledServiceTile>
      </Col>
    </Row>
  );
};

ContactInfo.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  content_md: PropTypes.node,
};

ContactInfo.defaultProps = {
  className: "",
  title: "",
  content_md: "",
};

export default ContactInfo;
