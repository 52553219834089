import React, { useMemo, useEffect } from "react";
import styled from "styled-components";

import { Flex, Box } from "@mm/ui";

import { useViewport } from "@core/theme";

import { filterVariantsByColor } from "utils/tariffUtils";

import { useOfferConfigurator, SET_ACTIVE_VARIANT } from "features/Offer";
import dataLayer from "helper/dataLayer";

import { BorderBox, VariantsStorageDropdown } from "components/atoms";

import {
  VariantsColorDropdown,
  AccessoryImage,
  SoldOverlay,
} from "components/molecules";

import AccessoryDetailsModal from "components/organisms/AccessoryDetailsModal";

const DropdownContainer = styled.div`
  ${({ viewport, theme }) =>
    viewport.is.lg
      ? `
    display: flex;
    > * {
      flex: 1;
    }

    > *:nth-child(1) {
      margin-right: ${theme.space.l}px;
    }
  `
      : ""}
`;

const Wrapper = styled.div`
  flex: 3;
  margin-left: ${({ theme }) => theme.space.l}px;
`;

const StyledAccessoryImage = styled.div`
  flex: 1;
  height: 200px;
  text-align: center;
`;

const StyledVariantsStorageDropdown = styled(VariantsStorageDropdown)`
  ${({ viewport, theme }) =>
    !viewport.is.lg
      ? `
                margin-top: ${theme.space.l}px;
            `
      : ""}
`;

const TariffHardwareSelector = () => {
  const {
    dispatch,
    state: { extendedVariant, extendedVariants, activeVariantId, isTariff },
  } = useOfferConfigurator();

  const {
    variant: {
      colorGroup: { hex },
      ebootisId,
    },
    stock,
  } = extendedVariant;

  const availableVariants = useMemo(
    () => filterVariantsByColor(extendedVariants, hex),
    [activeVariantId, extendedVariants, hex]
  );

  const viewport = useViewport();

  useEffect(() => {
    const keys = Object.keys(availableVariants);
    if (!keys.includes(activeVariantId) && keys.length) {
      dispatch({
        type: SET_ACTIVE_VARIANT,
        payload: {
          activeVariantId: keys[0],
          index: 0,
        },
      });
    }
  }, [availableVariants, activeVariantId]);

  const setActiveAccessoryVariant = (value) =>
    dispatch({
      type: SET_ACTIVE_VARIANT,
      payload: {
        activeVariantId: value,
        index: 0,
      },
    });

  const isSoldOut = stock <= 0;

  const AccessoryImageComp = (
    <AccessoryImage detailView="zoom" variantId={activeVariantId} />
  );

  return (
    <BorderBox p={5}>
      <Flex alignItems="center">
        <StyledAccessoryImage>
          {isSoldOut ? (
            <SoldOverlay>{AccessoryImageComp}</SoldOverlay>
          ) : (
            AccessoryImageComp
          )}
        </StyledAccessoryImage>
        <Wrapper>
          <DropdownContainer viewport={viewport}>
            <VariantsColorDropdown
              extendedVariants={extendedVariants}
              activeVariantId={activeVariantId}
              onChange={(variantId) => {
                setActiveAccessoryVariant(variantId);
                dataLayer({
                  eventAction: "select",
                  eventCategory: "Accessory",
                  eventLabel: `Farbe:${variantId}`,
                });
              }}
            />
            <StyledVariantsStorageDropdown
              viewport={viewport}
              selected={activeVariantId}
              variants={availableVariants}
              onChange={(variantId) => {
                setActiveAccessoryVariant(variantId);
                dataLayer({
                  eventAction: "select",
                  eventCategory: "Accessory",
                  eventLabel: `Speicherkapazität:${variantId}`,
                });
              }}
            />
          </DropdownContainer>
          <Box mt={5}>
            <AccessoryDetailsModal
              isTariff={isTariff}
              id={extendedVariant.hardwareId}
              ebootisId={ebootisId}
            />
          </Box>
        </Wrapper>
      </Flex>
    </BorderBox>
  );
};

export default TariffHardwareSelector;
