import React from "react";
import PropTypes from "prop-types";

import { Surface } from "@mm/ui";

import { ThemeProvider } from "@core/theme";

import { SliderTeaserSection } from "../../components/organisms/Teaser";

const SliderTeaser = ({ data, type }) => (
  <ThemeProvider>
    <Surface variant="narrow">
      <SliderTeaserSection sectionType={type} contents={data} />
    </Surface>
  </ThemeProvider>
);

SliderTeaser.propTypes = {
  data: PropTypes.oneOfType([PropTypes.any]),
  type: PropTypes.string.isRequired,
};

SliderTeaser.defaultProps = {
  data: <></>,
};

export default SliderTeaser;
