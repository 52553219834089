import { jsx as _jsx } from "react/jsx-runtime";
import { useCheckout } from "../../../hooks/api";
import { OverviewGrid, CheckoutSection } from "../../../components";
import { getItemisedBillingLabel } from "../ItemisedBillingSection";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const TariffOverviewSection = (props) => {
    const { data } = useCheckout();
    return (_jsx(CheckoutSection, { children: _jsx(OverviewGrid, { items: [
                {
                    title: "Einzelverbindungsnachweis",
                    content: data?.data.itemisedBilling &&
                        getItemisedBillingLabel(data.data.itemisedBilling),
                },
                {
                    title: "Persönliche Kundenkennzahl",
                    content: data?.data.personalCustomerCode,
                },
            ] }) }));
};
