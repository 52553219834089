import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { variant, compose, space } from "styled-system";
import { Text } from "@mm/ui";

import { eyecatcherPropTypes } from "sharedPropTypes";

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 3px;
  list-style-type: none;
  margin: 0;
  padding: 1px;
  overflow: hidden;

  @media (min-width: ${({ theme }) => theme.breakpointsMap.lg.min}px) {
    min-height: 59px;
    ${variant({
      variants: {
        offerTeaser: {
          minHeight: "28px",
        },
      },
    })}
  }

  ${variant({
    variants: {
      default: {
        display: "flex",
        flexWrap: "wrap",
      },
      vertical: {},
      teaser: {
        marginBottom: "-42px",
        display: "flex",
        flexWrap: "wrap",
        backgroundColor: "#f4f4f4",
      },
      dynamicStage: {
        position: "absolute",
        zIndex: "15",
        top: "0",
      },
      offerItem: {
        margin: "0 -16px",
      },
      offerTeaser: {
        margin: "0 -16px",
      },
      affOffer: {
        position: "absolute",
        top: "-21px",
        left: "-2px",
        zIndex: "15",
      },
      noMinHeight: {
        minHeight: "unset !important",
      },
    },
  })}

  ${compose(space)}
`;

const Item = styled("li")`
  position: relative;
  height: 26px;
  font-size: ${({ theme }) => theme.space.s}px;
  color: ${({ theme }) => theme.colors.primary[0]};

  &::before,
  &::after {
    content: "";
    display: block;
    width: 10px;
    height: 24px;
    position: absolute;
    background: ${({ theme }) => theme.colors.white};
    transform: skew(-10deg);
  }

  &::before {
    top: 0px;
    left: 0px;
    border-radius: ${({ theme }) => theme.eyecatcher.before.borderRadius} 0px
      0px ${({ theme }) => theme.eyecatcher.before.borderRadius};
    border-top: solid 1px ${({ theme }) => theme.colors.brand.primary};
    border-right: none;
    border-bottom: solid 1px ${({ theme }) => theme.colors.brand.primary};
    border-left: solid 1px ${({ theme }) => theme.colors.brand.primary};

    ${variant({
      vertical: {
        transform: "none !important",
      },
    })}
  }

  &::after {
    top: 0px;
    right: 2px;
    border-radius: 0px ${({ theme }) => theme.eyecatcher.before.borderRadius}
      ${({ theme }) => theme.eyecatcher.before.borderRadius} 0px;
    border-top: solid 1px ${({ theme }) => theme.colors.brand.primary};
    border-right: solid 1px ${({ theme }) => theme.colors.brand.primary};
    border-bottom: solid 1px ${({ theme }) => theme.colors.brand.primary};
    border-left: none;
  }

  &:first-child {
    &::before {
      transform: none;
    }
  }

  &:not(:first-child) {
    margin-left: 2px;
  }

  span {
    display: block;
    height: 26px;
    line-height: 24px;
    white-space: nowrap;
    border-top: solid 1px ${({ theme }) => theme.colors.brand.primary};
    border-bottom: solid 1px ${({ theme }) => theme.colors.brand.primary};
    background: ${({ theme }) => theme.colors.white};
    padding: 0 6px;
    margin: 0 8px;
  }
`;

const Eyecatchers = ({ eyecatchers, variant: ListVariant, mt, mb }) => {
  return (
    <List variant={ListVariant} mt={mt} mb={mb}>
      {eyecatchers.round && (
        <Item variant={variant} data-codeception={eyecatchers.round}>
          <Text variant="s">{eyecatchers.round}</Text>
        </Item>
      )}
      {eyecatchers.small1 && (
        <Item variant={variant} data-codeception={eyecatchers.small1}>
          <Text variant="s">{eyecatchers.small1}</Text>
        </Item>
      )}
      {eyecatchers.small2 && (
        <Item variant={variant} data-codeception={eyecatchers.small2}>
          <Text variant="s">{eyecatchers.small2}</Text>
        </Item>
      )}
    </List>
  );
};

Eyecatchers.propTypes = {
  eyecatchers: eyecatcherPropTypes.isRequired,
  variant: PropTypes.oneOf([
    "default",
    "vertical",
    "dynamicStage",
    "teaser",
    "offerItem",
    "affOffer",
    "offerTeaser",
    "noMinHeight",
  ]),
  mt: PropTypes.number,
  mb: PropTypes.number,
};

Eyecatchers.defaultProps = {
  variant: "default",
  mt: 0,
  mb: 0,
};

export default Eyecatchers;
