import "core-js/stable";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { HelmetProvider } from "react-helmet-async";

import "regenerator-runtime/runtime";

import { ThemeProvider } from "@core/theme";
import "../scss/app.scss";
import { IntlProvider, messages } from "@core/text";

import { ModalsProvider } from "features/Modals";

import Pages from "./reactEntries";
import { ModalProvider } from "../utils/context/ModalContext";
import { LoadingStateProvider } from "../utils/context/LoadingContext";
import Router from "../router";

const queryClient = new QueryClient();

const PAGE = {
  tariff: Pages.TariffPDP,
  hardware: Pages.SmartphonePDP,
  homepage: Pages.Homepage,
  router: () => <Router />,
  tariffs: Pages.TariffListingPage,
  smartphones: () => Pages.HardwareListingPage("smartphone"),
  tablets: () => Pages.HardwareListingPage("tablets"),
  elektronik: () => Pages.HardwareListingPage("elektronik"),
}[window.PAGE_TYPE];

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider>
      <IntlProvider locale="de" messages={messages}>
        <HelmetProvider>
          <QueryClientProvider client={queryClient}>
            {/* TODO: replace old `ModalsProvider` with new `ModalProvider` */}
            <ModalsProvider>
              <ModalProvider>
                <LoadingStateProvider>
                  <Suspense fallback={null}>
                    {React.createElement(PAGE)}
                  </Suspense>
                </LoadingStateProvider>
              </ModalProvider>
            </ModalsProvider>
          </QueryClientProvider>
        </HelmetProvider>
      </IntlProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
