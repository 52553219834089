import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledHeadline = styled.span`
  color: ${(props) => props.theme.colors.primary[0]};
  display: flex;
  font-family: ${({ theme }) => theme.fonts.headlineBig};
  font-size: 32px;
  line-height: 40px;
  margin: 4px 0 14px;
  height: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 2;
  flex-direction: column;
  padding-left: 4px;

  @media (min-width: ${(props) => props.theme.breakpointsMap.lg.min}px) {
    font-size: 40px;
    line-height: 48px;
    height: 96px;
  }
`;

const StageHeadline = ({ className, children }) => (
  <StyledHeadline
    variant="teaserBig"
    color="brand.primary"
    className={className}
  >
    {children}
  </StyledHeadline>
);

StageHeadline.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

StageHeadline.defaultProps = {
  className: "",
};

export default StageHeadline;
