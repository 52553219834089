import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState, useRef } from "react";
import SlickCarousel from "react-slick";
import styled from "styled-components";
import { Surface } from "@mm/ui";
import { ThemeProvider } from "@core/theme";
import { Spinner } from "@/components/atoms";
import { OfferTeaserSimOnly } from "@/components/organisms";
import { OfferProvider } from "@/features/Offer";
import { useSimOnlyProvider } from "@/features/SimOnly";
import { StyledTeaserCarousel, carouselSettings } from "./SlickCarouselSetting";
const SpinnerWrapper = styled.div `
  display: flex;
  justify-content: center;
`;
const SilderRemote = styled.div `
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  border: 1px solid #cdcaca;
  border-radius: 4px;
  margin-bottom: 40px;

  div:first-child:not(.tariff-button--active) {
    ::after {
      display: none;
    }
  }

  .tariff {
    &-button {
      position: relative;
      text-align: center;
      padding: 10px;

      ::after {
        content: "";
        display: block;
        position: absolute;
        top: 10px;
        left: 0;
        width: 1px;
        height: calc(100% - 40px);
        background-color: #cdcaca;
        z-index: 0;
        padding: 10px 0;
      }

      &--active {
        ::after {
          content: "";
          display: block;
          position: absolute;
          top: -2px;
          left: -2px;
          width: 100%;
          height: calc(100% - 20px);
          border-radius: 4px;
          border: 2px solid red;
          background-color: transparent;
          z-index: 1;
        }
      }
    }
    &-dataVolume {
      display: block;
      font-size: 16px;
      font-weight: bold;
    }
    &-monthlyPrice {
      display: block;
      font-size: 14px;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpointsMap.md.min}px) {
    display: none;
  }
`;
const SimOnlyPromotionOfferTeaser = ({ queryParams }) => {
    const { state: { promotionOffers, showOffers, isLoadingOffers }, } = useSimOnlyProvider();
    const [activeSlide, setActiveSlide] = useState(0);
    const [slider, setSlider] = useState();
    const sliderRef = useRef(null);
    useEffect(() => {
        if (sliderRef.current && !slider) {
            setSlider(sliderRef.current);
        }
    }, [sliderRef, slider]);
    if (showOffers && isLoadingOffers) {
        return (_jsx(Surface, { variant: "narrow", mt: "44px", children: _jsx(SpinnerWrapper, { children: _jsx(Spinner, { color: "ghost", size: "4rem" }) }) }));
    }
    if (promotionOffers.length < 1) {
        return (_jsx(Surface, { variant: "narrow", mt: "44px", children: _jsx("b", { children: "Es k\u00F6nnen keine Angebote angezeigt werden." }) }));
    }
    return (_jsx(ThemeProvider, { children: _jsxs(Surface, { variant: "narrow", children: [_jsx(SilderRemote, { children: promotionOffers.map((offer, index) => {
                        const { tariff } = offer;
                        return (_jsxs("div", { className: `tariff-button ${activeSlide == index ? "tariff-button--active" : ""}`, onClick: () => {
                                sliderRef.current && sliderRef.current.slickGoTo(index);
                                setActiveSlide(index);
                            }, children: [_jsxs("span", { className: "tariff-dataVolume", children: [tariff.dataVolume, "GB"] }), _jsxs("span", { className: "tariff-monthlyPrice", children: ["\u20AC ", tariff.monthlyPrice.toString().replace(".", ","), " mtl."] })] }, offer.id));
                    }) }), _jsx(StyledTeaserCarousel, { children: _jsx(SlickCarousel, { ...carouselSettings, ref: sliderRef, beforeChange: (current, next) => setActiveSlide(next), children: promotionOffers.map((offer, index) => {
                            const { accessories, coupon, eyeCatchers, tariff, newCheckout } = offer;
                            return (_jsx(OfferProvider, { queryParams: queryParams, isListingPage: true, isTariff: true, children: _jsx(OfferTeaserSimOnly, { accessories: accessories, coupon: coupon, eyeCatchers: eyeCatchers, tariff: tariff, name: tariff.name, offerGroupUrl: tariff.link, cartButtonQaName: `promoOffer${index}`, newCheckout: newCheckout, offer: offer }) }, offer.offerId));
                        }) }) })] }) }));
};
export default SimOnlyPromotionOfferTeaser;
