/*
 * Custom hook to extract the active variant on hover of color and/or storage options
 * */

import { useEffect, useState } from "react";

import { useOfferConfigurator } from "features/Offer";
import { findVariantByColorAndStorage } from "utils/variantUtils";

const useActiveVariant = (internalActiveVariant) => {
  const [activeVariant, setActiveVariant] = useState([]);

  const {
    state: {
      activeVariantId: storeActiveVariantId,
      extendedVariant: storeExtendedVariant,
      extendedVariants,
    },
  } = useOfferConfigurator();

  useEffect(() => {
    if (!extendedVariants) return;
    const internalColor =
      internalActiveVariant.color || storeExtendedVariant?.variant?.color?.hex;
    const internalStorage =
      internalActiveVariant.storage || storeExtendedVariant?.variant?.storage;

    const localExtendedVariant = findVariantByColorAndStorage(
      extendedVariants,
      internalColor,
      internalStorage
    );

    const activeVariantId =
      localExtendedVariant?.variant?.ebootisId || storeActiveVariantId;
    const activeExtendedVariant = localExtendedVariant || storeExtendedVariant;

    setActiveVariant([activeVariantId, activeExtendedVariant]);
  }, [
    internalActiveVariant,
    storeActiveVariantId,
    storeExtendedVariant,
    extendedVariants,
  ]);

  return activeVariant;
};

export default useActiveVariant;
