import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
import { NavigationButton } from "@/components/atoms";
export const StyledTeaserCarousel = styled("div") `
  position: relative;

  .slick-arrow {
    &.slick-disabled {
      background-color: #958f8f;
      border: solid 2px #958f8f;
    }
    &.slick-prev {
      position: absolute;
      top: calc(50% - 16px);
      left: inherit;
      left: -5px;
      z-index: 10;

      @media (min-width: ${({ theme }) => theme.breakpointsMap.sm.min}px) {
        left: -12px;
      }

      svg {
        right: calc(50% - 5px);
      }
    }

    &.slick-next {
      position: absolute;
      top: calc(50% - 16px);
      left: inherit;
      right: -5px;
      z-index: 10;

      @media (min-width: ${({ theme }) => theme.breakpointsMap.sm.min}px) {
        right: -12px;
      }

      svg {
        left: calc(50% - 5px);
        transform: rotate(90deg);
      }
    }
  }

  .slick-list {
    overflow: hidden;
    padding: 20px 0 24px;
    margin-left: -12px;
    margin-right: -12px;
  }

  .slick-track {
    display: flex;
  }

  .slick-slide {
    padding-left: 12px;
    padding-right: 12px;
    > div,
    > div > a {
      height: 100%;
      min-height: 100%;
    }

    > div {
      &:active,
      &:focus,
      &:hover {
        transition-duration: 0.15s;
        transition-timing-function: ease-in-out;
        transition-property: border-color, box-shadow, transform;
        box-shadow: rgb(0 0 0 / 7%) 0px 8px 10px 1px,
          rgb(0 0 0 / 6%) 0px 3px 14px 2px, rgb(0 0 0 / 10%) 0px 5px 5px -3px;

        div[class*="BorderBox"] {
          border-color: rgba(0, 0, 0, 0);
        }
      }
    }
  }

  .slick-dots {
    position: absolute;
    bottom: 3px;
    text-align: center;
    width: 100%;
    z-index: 1;
  }

  .control-dots {
    margin: 0;
    padding: 0;

    ::marker {
      display: none;
    }

    > li {
      position: relative;
      transition: opacity 0.25s ease-in;
      opacity: 0.3;
      filter: alpha(opacity=30);
      background: #fff;
      border-radius: 50%;
      border: 1px solid black;
      width: 8px;
      height: 8px;
      cursor: pointer;
      display: inline-block;
      margin: 0 8px;

      button {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 8px;
        height: 8px;
        padding: 0;
        margin: 0;
        opacity: 0;
        border: 0;
        cursor: pointer;
      }

      &.slick-active {
        background: #000000;
        opacity: 1;
      }
    }
  }
`;
export const carouselSettings = {
    autoplay: false,
    arrows: true,
    slidesToScroll: 1,
    slidesToShow: 2,
    prevArrow: (_jsx(NavigationButton, { className: "slick-prev slider-nav-btn", ariaLabel: "Ein Karussell-Element zur\u00FCck" })),
    nextArrow: (_jsx(NavigationButton, { className: "slick-next slider-nav-btn", ariaLabel: "Ein Karussell-Element vor" })),
    centerMode: false,
    centerPadding: "0",
    dots: true,
    // eslint-disable-next-line react/display-name
    appendDots: (dots) => (_jsx("div", { children: _jsx("ul", { className: "control-dots", children: dots }) })),
    // eslint-disable-next-line react/display-name
    customPaging: (i) => (_jsx("button", { type: "button", "aria-label": `gehe zu Karussell-Element ${i + 1}` })),
    lazyLoad: "ondemand",
    infinite: false,
    speed: 500,
    initialSlide: 0,
    responsive: [
        {
            breakpoint: 751,
            settings: {
                slidesToScroll: 1,
                slidesToShow: 1,
            },
        },
        {
            breakpoint: 1230,
            settings: {
                slidesToScroll: 1,
                slidesToShow: 2,
            },
        },
    ],
};
