import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { useViewport } from "@core/theme";
import { Col, Row } from "@mm/ui";

import { useFilterProvider } from "features/Filter/FilterProvider";

import {
  Eyecatchers,
  TileBox,
  TileAnchor,
  Bulletlist,
  OfferHeadline,
} from "components/atoms";
import { ProductAccessoryDisplay, SoldOverlay } from "components/molecules";
import { hardwarePropTypes, eyecatcherPropTypes } from "sharedPropTypes";

import { useOfferConfigurator } from "features/Offer";

import HardwarePriceDisplay from "../../molecules/PriceDisplay/HardwarePriceDisplay";

const TileWrapper = styled(TileBox)`
  ${({ isSoldOut }) => !isSoldOut && "cursor: pointer"};
`;

const StyledRow = styled(Row)`
  align-items: flex-end;
`;

const StyledCol = styled(Col)`
  display: flex;
  justify-content: ${({ isRowMode, viewport }) =>
    isRowMode && viewport.greaterThan.sm ? "center" : "start"};

  :not(:last-child) {
    margin-bottom: ${({ isRowMode, viewport }) =>
      (!isRowMode || viewport.lessThan.md) && "24px"};
  }
`;

const StyledImageCol = styled(StyledCol)`
  justify-content: center;
`;

const StyledPriceCol = styled(StyledCol)`
  justify-content: ${({ isRowMode }) =>
    isRowMode ? "flex-end" : "flex-start"};
  ${({ isRowMode }) =>
    isRowMode &&
    `
        padding-bottom: 0.5em;
    `}
`;

const HardwareOfferListItem = ({
  offer,
  accessories,
  offer: {
    eyeCatchers,
    manufacturer,
    name,
    defaultVariant,
    variants,
    bullets,
    idx,
    isSoldOut,
  },
  isActive,
  mode,
  onClick,
  qaName,
}) => {
  const pdpLink = variants[defaultVariant].url;

  const {
    state: { isListingPage },
  } = useOfferConfigurator();

  const {
    state: { productType },
  } = useFilterProvider();

  const viewport = useViewport();

  const currentVariant = variants[defaultVariant];

  const {
    color: { name: colorName },
    storageSize,
    price,
  } = currentVariant;

  const isRowMode = mode === "row";

  const showSpecs = !isListingPage;

  const component = (
    <>
      {eyeCatchers && <Eyecatchers eyecatchers={eyeCatchers} />}
      <OfferHeadline
        prefix={manufacturer}
        text={name}
        additionalData={showSpecs ? ` ${storageSize} GB ${colorName}` : ""}
        lines={2}
      />
      <StyledRow>
        <StyledImageCol
          isRowMode={isRowMode}
          viewport={viewport}
          md={isRowMode ? 6 : 24}
        >
          <ProductAccessoryDisplay
            manufacturer={manufacturer}
            name={name}
            image={currentVariant.images[0]}
            accessories={accessories}
            imageHeight="160px"
          />
        </StyledImageCol>
        {isRowMode && (
          <StyledCol
            isRowMode={isRowMode}
            viewport={viewport}
            md={isRowMode ? 10 : 24}
          >
            {bullets && (
              <Bulletlist variant="checkmark" list={bullets} size="m" />
            )}
          </StyledCol>
        )}
        <StyledPriceCol
          isRowMode={isRowMode}
          viewport={viewport}
          md={isRowMode ? 8 : 24}
        >
          <HardwarePriceDisplay price={price} size="s" />
        </StyledPriceCol>
      </StyledRow>
    </>
  );

  const TileAnchorWrapper = ({ children, href }) => (
    <TileBox>
      <TileAnchor
        href={href}
        dataLayerObj={{
          eventCategory: "Produktteaser",
          eventAction: "click",
          eventLabel: `Details:${pdpLink}:${idx}:${productType}OfferTeaser`,
        }}
        qaName={qaName}
      >
        {children}
      </TileAnchor>
    </TileBox>
  );

  const renderAnchorTag = isListingPage;

  const Wrapper = renderAnchorTag ? TileAnchorWrapper : TileWrapper;

  return (
    <Wrapper
      hasShadow={isActive}
      isSoldOut={isSoldOut}
      onClick={!isSoldOut ? () => onClick(offer) : null}
      href={pdpLink}
      qaName={qaName}
    >
      {isSoldOut ? <SoldOverlay>{component}</SoldOverlay> : component}
    </Wrapper>
  );
};

HardwareOfferListItem.propTypes = {
  mode: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  // required only for list page not pdp
  accessories: PropTypes.arrayOf(hardwarePropTypes),
  offer: PropTypes.shape({
    eyeCatchers: eyecatcherPropTypes,
    name: PropTypes.string.isRequired,
    manufacturer: PropTypes.string.isRequired,
    defaultVariant: PropTypes.string.isRequired,
    variants: PropTypes.shape({}).isRequired,
    bullets: PropTypes.arrayOf(PropTypes.string).isRequired,
    isSoldOut: PropTypes.bool.isRequired,
  }).isRequired,
  isActive: PropTypes.bool,
  qaName: PropTypes.string,
};

HardwareOfferListItem.defaultProps = {
  accessories: [],
  mode: "default",
  isActive: false,
  qaName: undefined,
};

export default HardwareOfferListItem;
