import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  compose,
  typography,
  space,
  layout,
  color,
  variant as themeVariant,
} from "styled-system";

const Span = styled.span(
  { fontFamily: ({ theme }) => theme.fonts.text },
  ({ highlighted }) => ({
    fontWeight: highlighted ? "600" : "normal",
  }),
  compose(typography, space, layout, color),
  themeVariant({
    scale: "text",
    // https://github.com/styled-system/styled-system/issues/1145#issuecomment-587389487
    variants: {
      xs: {},
      s: {},
      smallCopy: {},
      copy: {},
      l: {},
      btn: {},
      h1: {},
      h2: {},
      h3: {},
      h4: {},
      h5: {},
    },
  })
);

export const Text = ({ variant, ...rest }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Span variant={variant} {...rest} />
);

Text.defaultProps = {
  highlighted: false,
  variant: "copy",
};

Text.propTypes = {
  variant: PropTypes.oneOf([
    "xs",
    "s",
    "smallCopy",
    "copy",
    "l",
    "btn",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
  ]),
  highlighted: PropTypes.bool,
};

export default Text;
